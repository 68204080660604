import { collection, getDocs } from "firebase/firestore";
import { db } from "../../config/firebase-config";

export const getInviteHistory = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, "InviteHistory"));
    // console.log('querySnapshot.docs: ', querySnapshot.docs);
    const dataList = querySnapshot.docs.map((doc) => {
      return({
        ...doc.data(),
        key: doc.id
    })});
    console.log("dataList: ", dataList);
    return dataList;
  } catch (err) {
    console.error("Error fetching invite history:", err.message);
    throw new Error(err.message);
  }
};
