import React, { useState, useEffect, useRef } from "react";

const DEFAULT_ZOOM_STEP = 0.3;
const DEFAULT_LARGE_ZOOM = 4;

function getXY(e) {
  let x = 0;
  let y = 0;
  if (e.touches && e.touches.length) {
    x = e.touches[0].pageX;
    y = e.touches[0].pageY;
  } else {
    x = e.pageX;
    y = e.pageY;
  }
  return { x, y };
}

const Cond = (props) => (props.condition ? <>{props.children}</> : null);

const LightBox = (props) => {
  const imageRef = useRef(null);

  let initX = 0;
  let initY = 0;
  let lastX = 0;
  let lastY = 0;

  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [loading, setLoading] = useState(true);
  const [moving, setMoving] = useState(false);
  const [imageWidth, setImageWidth] = useState(100);
  const [current, setCurrent] = useState(props?.startIndex ?? 0);
  const [customButtonTitle, setCustomButtonTitle] = useState(
    props?.customButtonTitle ?? ""
  );
  const [multi, setMulti] = useState(!!props?.images?.length);

  const _cont = useRef();

  const createTransform = (x, y, zoom, rotate) =>
    `translate3d(${x}px,${y}px,0px) scale(${zoom}) rotate(${rotate}deg)`;

  const stopSideEffect = (e) => e.stopPropagation();

  const getCurrentImage = (s, p) => {
    if (!s.multi) return p.image ?? "";
    return p.images[s.current]?.url ?? p.images?.[s.current] ?? "";
  };

  const getCurrentTitle = (s, p) => {
    if (!s.multi) return p.title ?? "";
    return p.images?.[s.current]?.title ?? "";
  };

  const resetZoom = () => {
    setX(0);
    setY(0);
    setZoom(1);
  };

  const shockZoom = (e) => {
    let {
      zoomStep = DEFAULT_ZOOM_STEP,
      allowZoom = true,
      doubleClickZoom = DEFAULT_LARGE_ZOOM,
    } = props;

    if (!allowZoom || !doubleClickZoom) return false;

    stopSideEffect(e);

    if (zoom > 1) return resetZoom();

    const _z =
      (zoomStep < 1 ? Math.ceil(doubleClickZoom / zoomStep) : zoomStep) *
      zoomStep;

    const _xy = getXY(e);
    const _cbr = _cont.current?.getBoundingClientRect?.();
    const _ccx = _cbr.x + _cbr.width / 2;
    const _ccy = _cbr.y + _cbr.height / 2;

    const newX = (_xy.x - _ccx) * -1 * _z;
    const newY = (_xy.y - _ccy) * -1 * _z;

    setX(newX);
    setY(newY);
    setZoom(_z);
  };

  const navigateImage = (direction, e) => {
    stopSideEffect(e);

    let newCurrent = 0;

    switch (direction) {
      case "next":
        newCurrent = current + 1;
        break;
      case "prev":
        newCurrent = current - 1;
        break;
    }

    if (newCurrent >= props.images.length) newCurrent = 0;
    else if (newCurrent < 0) newCurrent = props.images.length - 1;

    setCurrent(newCurrent);
    setX(0);
    setY(0);
    setZoom(1);
    setRotate(0);
    setLoading(true);

    if (typeof props.onNavigateImage === "function") {
      props.onNavigateImage(newCurrent);
    }
  };

  const startMove = (e) => {
    if (zoom <= 1) return false;

    setMoving(true);

    let xy = getXY(e);
    setX(xy.x - lastX);
    setY(xy.y - lastY);
  };

  const duringMove = (e) => {
    if (!moving) return false;

    let xy = getXY(e);
    // setLastX(xy.x - initX);
    // setLastY(xy.y - initY);
    setX(xy.x - initX);
    setY(xy.y - initY);
  };

  const endMove = () => setMoving(false);

  const applyZoom = (type) => {
    let { zoomStep = DEFAULT_ZOOM_STEP } = props;

    switch (type) {
      case "in":
        setZoom((prevZoom) => prevZoom + zoomStep);
        break;
      case "out":
        let newZoom = zoom - zoomStep;
        if (newZoom < 1) break;
        else if (newZoom === 1) resetZoom();
        else setZoom(newZoom);
        break;
      case "reset":
        resetZoom();
        break;
    }
  };

  const applyRotate = (type) => {
    switch (type) {
      case "cw":
        setRotate((prevRotate) => prevRotate + 90);
        break;
      case "acw":
        setRotate((prevRotate) => prevRotate - 90);
        break;
    }
  };

  const reset = (e) => {
    stopSideEffect(e);
    setX(0);
    setY(0);
    setZoom(1);
    setRotate(0);
  };

  const exit = (e) => {
    if (typeof props.onClose === "function") return props.onClose(e);

    console.error(
      "No Exit function passed on prop: onClose. Clicking the close button will do nothing"
    );
  };

  const customButtonClick = (e) => {
    if (typeof props.onCustomButtonClick === "function")
      return props.onCustomButtonClick(current);

    console.error(
      "No onCustomButtonClick function passed on prop: onCustomButtonClick. Clicking the onCustomButtonClick button will do nothing"
    );
  };

  const shouldShowReset = () => x || y || zoom !== 1 || rotate !== 0;

  const canvasClick = (e) => {
    let { clickOutsideToExit = true } = props;

    if (clickOutsideToExit && zoom <= 1) return exit(e);
  };

  const keyboardNavigation = (e) => {
    let { allowZoom = true, allowReset = true } = props;

    switch (e.key) {
      case "ArrowLeft":
        if (multi && zoom === 1) navigateImage("prev", e);
        else if (zoom > 1) setX((prevX) => prevX - 20);
        break;
      case "ArrowRight":
        if (multi && zoom === 1) navigateImage("next", e);
        else if (zoom > 1) setX((prevX) => prevX + 20);
        break;
      case "ArrowUp":
        if (zoom > 1) setY((prevY) => prevY + 20);
        break;
      case "ArrowDown":
        if (zoom > 1) setY((prevY) => prevY - 20);
        break;
      case "+":
        if (allowZoom) applyZoom("in");
        break;
      case "-":
        if (allowZoom) applyZoom("out");
        break;
      case "Escape":
        if (allowReset && shouldShowReset()) reset(e);
        else exit(e);
        break;
    }
  };

  useEffect(() => {
    document.body.classList.add("lb-open-lightbox");
    let { keyboardInteraction = true } = props;

    if (keyboardInteraction)
      document.addEventListener("keyup", keyboardNavigation);

    return () => {
      document.body.classList.remove("lb-open-lightbox");
      let { keyboardInteraction = true } = props;

      if (keyboardInteraction)
        document.removeEventListener("keyup", keyboardNavigation);
    };
  }, [props]);

  useEffect(() => {
    if (imageRef.current) {
      const { height } = imageRef.current;
      setImageWidth(height);
    }
  }, [imageRef]);

  let image = getCurrentImage(
    { x, y, zoom, rotate, multi, loading, moving, current },
    props
  );
  let title = getCurrentTitle(
    { x, y, zoom, rotate, multi, loading, moving, current },
    props
  );

  if (!image) {
    console.warn("Not showing lightbox because no image(s) was supplied");
    return null;
  }

  let {
    allowZoom = true,
    allowRotate = true,
    buttonAlign = "flex-end",
    showTitle = true,
    allowReset = true,
  } = props;

  let _reset = allowReset && shouldShowReset();
  const objDetail = props?.images[current];

  return (
    <div className="lb-container">
      <div className="lb-header" style={{ justifyContent: buttonAlign }}>
        {customButtonTitle && (
          <button
            style={{
              fontSize: 15,
              fontWeight: "600",
              height: 40,
              alignSelf: "center",
            }}
            onClick={customButtonClick}
            className="ui primary button"
          >
            {customButtonTitle}
          </button>
        )}
        <Cond condition={showTitle && title}>
          <div
            className="lb-title"
            style={{
              display: buttonAlign === "center" ? "none" : "flex",
              order: buttonAlign === "flex-start" ? "2" : "unset",
            }}
          >
            <span
              title={title}
              style={{
                textAlign: buttonAlign === "flex-start" ? "right" : "left",
              }}
            >
              {title}
            </span>
          </div>
        </Cond>
        <Cond condition={buttonAlign === "center" || _reset}>
          <div
            title="Reset"
            style={{ order: buttonAlign === "flex-start" ? "1" : "unset" }}
            className={`lb-button lb-icon-reset lb-hide-mobile reload ${
              _reset ? "" : "lb-disabled"
            }`}
            onClick={reset}
          ></div>
        </Cond>
        <Cond condition={multi}>
          <div
            title="Previous"
            className="lb-button lb-icon-arrow prev lb-hide-mobile"
            onClick={(e) => navigateImage("prev", e)}
          ></div>
          <div
            title="Next"
            className="lb-button lb-icon-arrow next lb-hide-mobile"
            onClick={(e) => navigateImage("next", e)}
          ></div>
        </Cond>
        <Cond condition={allowZoom}>
          <div
            title="Zoom In"
            className="lb-button lb-icon-zoomin zoomin"
            onClick={() => applyZoom("in")}
          ></div>
          <div
            title="Zoom Out"
            className={`lb-button lb-icon-zoomout zoomout ${
              zoom <= 1 ? "lb-disabled" : ""
            }`}
            onClick={() => applyZoom("out")}
          ></div>
        </Cond>
        <Cond condition={allowRotate}>
          <div
            title="Rotate left"
            className="lb-button lb-icon-rotate rotatel"
            onClick={() => applyRotate("acw")}
          ></div>
          <div
            title="Rotate right"
            className="lb-button lb-icon-rotate rotater"
            onClick={() => applyRotate("cw")}
          ></div>
        </Cond>
        <div
          title="Close"
          className="lb-button lb-icon-close close"
          style={{ order: buttonAlign === "flex-start" ? "-1" : "unset" }}
          onClick={(e) => exit(e)}
        ></div>
      </div>
      <div
        className={`lb-canvas${loading ? " lb-loading" : ""}`}
        ref={_cont}
        onClick={(e) => canvasClick(e)}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <div
            style={{
              minWidth: props.DetailPartComponent ? imageWidth : "100vw",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              ref={imageRef}
              draggable="false"
              style={{
                transform: createTransform(x, y, zoom, rotate),
                cursor: zoom > 1 ? "grab" : "unset",
                transition: moving ? "none" : "all 0.1s",
              }}
              onMouseDown={(e) => startMove(e)}
              onTouchStart={(e) => startMove(e)}
              onMouseMove={(e) => duringMove(e)}
              onTouchMove={(e) => duringMove(e)}
              onMouseUp={(e) => endMove(e)}
              onMouseLeave={(e) => endMove(e)}
              onTouchEnd={(e) => endMove(e)}
              onClick={(e) => stopSideEffect(e)}
              onDoubleClick={(e) => shockZoom(e)}
              onLoad={(e) => setLoading(false)}
              className={`lb-img${loading ? " lb-loading" : ""}`}
              title={title}
              src={image}
              alt={title}
            />
          </div>
          <div>
            {props.DetailPartComponent && (
              <props.DetailPartComponent objList={objDetail} />
            )}
          </div>
        </div>
        <div className="mobile-controls lb-show-mobile">
          {multi ? (
            <div
              title="Previous"
              className="lb-button lb-icon-arrow prev"
              onClick={(e) => navigateImage("prev", e)}
            ></div>
          ) : null}
          {_reset ? (
            <div
              title="Reset"
              className="lb-button lb-icon-reset reload"
              onClick={reset}
            ></div>
          ) : null}
          {multi ? (
            <div
              title="Next"
              className="lb-button lb-icon-arrow next"
              onClick={(e) => navigateImage("next", e)}
            ></div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default LightBox;
