import { Card, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

function CustomCard(props) {
  const {
    heading,
    isPond,
    count,
    color,
    isClickable,
    onClickCard,
    onClickGoNavigate,
    isUpgrade,
    onClickUpgrade,
    isPaymentPendingDueDateExpired,
    isCursorChange,
    card,
    description,
    isLoader,
    buttonText
  } = props;
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        padding: 1.5,
        boxShadow: "1px 3px 3px 4px #e0e0e0",
        height: 150,
        // cursor: isCursorChange ? "pointer" : "auto",
      }}
      onClick={onClickCard}
    >
      <div className="card-icons-block">
        <img src={card} alt="image" />
      </div>
      <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
        {heading}
      </Typography>
      <span className="span-description-css">{description}</span>
      <div style={styles.container}>
        {isLoader ?
          <div style={{ marginTop: 15 }}>
            <div id={`load`} className="loader" />
          </div> :
          <>
            <Typography
              sx={{
                // fontSize: isPond ? 40 : 30,
                fontSize: 40,
                fontWeight: "800",
                color: color,
                marginTop: 1.5,
              }}
            >
              {count.toLocaleString()}
            </Typography>
            <div style={{ display:"flex" }}>
              {isUpgrade && !isPaymentPendingDueDateExpired && (
                <button
                  className="ui primary button"
                  style={{ width: "auto", height: 40, marginTop: 0 , marginRight: 10}}
                  onClick={() => onClickUpgrade()}
                >
                  {isPaymentPendingDueDateExpired
                    ? t("home.restricted")
                    : t("home.enable_billing")}
                </button>
              )}
              {isClickable && buttonText && (
                <button onClick={onClickGoNavigate} className="ui button" style={{margin:0}}>
                  {buttonText}
                </button>
              )}
            </div>
          </>
        }
      </div>
    </Card>
  );
}

const styles = {
  container: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default CustomCard;
