import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ResponsiveDrawer from "../../../common/ResponsiveDrawer";
import { getPondRecord } from "../../../database/user/pond";
import "../../../style/loader.css";
import "../../../style/lightbox.css";
import {
  Modal,
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  styled,
  InputBase,
} from "@mui/material";
import colors from "../../../common/colors";
import no_alarms_found from "../../../images/no_alarms_found.png";
import LightBox from "../../../common/LightBox";
import { getTimestampWithFormatDate } from "../../../common/utils";
import { CYCLE_ID, POND_ID } from "../../../database/config";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "8px 26px 8px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

function RecordImages() {
  const navigation = useNavigate();
  const { t } = useTranslation();
  const [openLightBox, setOpenLightBox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const [imagesList, setImagesList] = useState([]);
  const [isData, setIsData] = useState(false);
  const [isImageModal, setIsImageModal] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [recordList, setRecordList] = useState([]);
  const [isViewMore, setIsViewMore] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [showLight, setShowLight] = useState(null);
  const [sortList, setSortList] = useState([]);
  const [sortValue, setSortValue] = useState("All");

  useEffect(() => {
    getCycleData();
  }, []);
  const getCycleData = async () => {
    try {
      if (window.history.state.usr && window.history.state.usr.sortList) {
        setSortList(window.history.state.usr.sortList);
      }
    } catch (error) {
      setIsData(true);
    }
  };

  useEffect(() => {
    getList();
  }, [sortValue]);

  const getList = async () => {
    try {
      setIsData(false);
      const pondRecordId = sortValue === "All" ? window.history.state.usr.cardData.id : sortValue;
      const recordKey = sortValue === "All" ? POND_ID : CYCLE_ID;
      let records = await getPondRecord(pondRecordId, null, "HEALTH", 10,recordKey);
      if (records.length > 0) {
        let images = [];
        for (const val of records) {
          if (val.topImage) {
            images.push({
              ...val,
              imageType: t("home.top"),
              src: val.topImage,
              thumbnail: val.topImage,
              thumbnailWidth: "auto",
              thumbnailHeight: 300,
            });
            images.push({
              ...val,
              imageType: t("home.side"),
              src: val.sideImage,
              thumbnail: val.sideImage,
              thumbnailWidth: "auto",
              thumbnailHeight: 300,
            });
          } else {
            images.push({
              ...val,
              imageType: t("home.top"),
              src: val.picture,
              thumbnail: val.picture,
              thumbnailWidth: "auto",
              thumbnailHeight: 300,
            });
          }
        }
        setIsViewMore(recordList.length >= 20);
        setImagesList(images);
        setRecordList(records);
        setIsData(true);
      } else {
        setIsData(true);
        setImagesList([]);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  async function onClickViewMore() {
    try {
      let records = await getPondRecord(
        window.history.state.usr.cycleId,
        recordList[recordList.length - 1].recordDate,
        "HEALTH",
        10
      );

      if (records.length > 0) {
        let images = [];
        for (const val of records) {
          if (val.topImage) {
            images.push({
              ...val,
              imageType: t("home.top"),
              src: val.topImage,
              thumbnail: val.topImage,
              thumbnailWidth: "auto",
              thumbnailHeight: 300,
            });
            images.push({
              ...val,
              imageType: t("home.side"),
              src: val.sideImage,
              thumbnail: val.sideImage,
              thumbnailWidth: "auto",
              thumbnailHeight: 300,
            });
          } else {
            images.push({
              ...val,
              imageType: t("home.top"),
              src: val.picture,
              thumbnail: val.picture,
              thumbnailWidth: "auto",
              thumbnailHeight: 300,
            });
          }
        }
        setImagesList(imagesList.concat(images));
        setRecordList(recordList.concat(records));
        setIsViewMore(recordList.length >= 20);
      } else {
        setIsViewMore(false);
      }
      setIsDisable(false);
    } catch (error) {
      setIsDisable(false);
    }
  }

  //lightbox gallary-------------------------------------------------------------------------
  const Gallerys = () => {
    const showLightBox = (url) => {
      var index = imagesList.findIndex((image) => image.src == url);
      setShowLight({
        startIndex: index,
      });
    };

    const hideLightBox = () => {
      setShowLight(null);
    };

    const viewRecord = (e) => {
      setShowLight({
        startIndex: e,
      });
      setImageData(imagesList[e]);
      setIsImageModal(true);
    };

    return (
      <>
        {recordList.map((image, index) => (
          <>
            <div className="collaps_gallary_main" key={index}>
              <div className="collaps_gallary">
                <span>
                  <img
                    src={image.topImage}
                    alt={"GallaryForCollections"}
                    onClick={() => showLightBox(image.topImage)}
                  />
                </span>
                <span>
                  <img
                    src={image.sideImage}
                    alt={"GallaryForCollections"}
                    onClick={() => showLightBox(image.sideImage)}
                  />
                </span>
              </div>
            </div>
          </>
        ))}
        {showLight ? (
          <LightBox
            images={imagesList.map((img) => ({
              ...img,
              url: img.src,
              title: img.caption,
            }))}
            // customButtonTitle={t("ponds.view_record")}
            startIndex={showLight.startIndex}
            onClose={hideLightBox}
            onCustomButtonClick={viewRecord}
            DetailPartComponent={DetailCom}
          />
        ) : null}
      </>
    );
  };

  return (
    <ResponsiveDrawer
      isShowAppBar={!showLight}
      headText={`${t("home.pond")}: ${
        window.history.state.usr.cardData.pondName
      }`}
      isHideToolBar={true}
      isBackArrow={true}
      onClickBack={() => {
        navigation(`/pond_detail`, {
          state: {
            cardData: window.history.state.usr.cardData,
            // cycleData: window.history.state.usr.cycleData,
          },
        });
      }}
    >
      {/* ----- HEAD SEARCHES ----- */}
      <div
        style={{ marginTop: -20 }}
        className="head-css-block hedera_ui_css_block"
      >
        <div className="hedera_flex_block_row">
          {sortList.length > 0 && (
            <div className="head_two select-main-block ms-0">
              <Box sx={{ minWidth: 260 }}>
                <Typography>{t("ponds.sort_by_harvest_cycle")}</Typography>
                <FormControl>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={sortValue}
                    onChange={(e) => {
                      console.log("e.target.value: 309", e.target.value);
                      setSortValue(e.target.value);
                    }}
                    input={<BootstrapInput />}
                  >
                    <MenuItem value={"All"}>
                      {t("ponds.all")}
                    </MenuItem>
                    {sortList.map((val) => {
                      return (
                        <MenuItem key={Math.random()} value={val.id}>
                          {val.cycleName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            </div>
          )}
        </div>
      </div>
      <div style={{ marginTop: 35 }}>
        {imagesList.length > 0 ? (
          <>
            {/* <Gallery
            images={imagesList}
            enableImageSelection={false}
            margin={4}
            rowHeight={250}
            onClickThumbnail={(index, e) => {
     
              setSelectedImage(index);
              setOpenLightBox(true);
            }}
            // lightboxWillOpen={(index) => {
     
            //   setSelectedImage(index);
            // }}
       
            // // customControls={[
            // //   <button style={{fontSize: 17,fontWeight: '600'}} onClick={() => {
            // //        // how to determine current image inside the lightbox?
            // //    }}>View Record</button>]}
          /> */}
            <div className="gallery_block_row">
              <Gallerys />
            </div>
          </>
        ) : isData ? (
          <div
            style={{
              display: "flex",
              height: "80vh",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
            className="no-alarms-found-css"
          >
            <img src={no_alarms_found} alt="no_alarms_found" />
            <Typography sx={{ fontSize: 17, fontWeight: "600" }}>
              {t("ponds.data_not_found")}
            </Typography>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              height: "80vh",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div id={`load`} className="loader" />
          </div>
        )}
        {/* ----- VIEW MORE (PAGINATION) ----- */}
        {isViewMore && recordList.length > 0 && (
          <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <Typography
              sx={{
                cursor: "pointer",
                color: colors.slatery_blue,
                fontSize: 16,
                fontWeight: "600",
                padding: 1,
              }}
              onClick={async () => {
                if (isDisable) {
                } else {
                  setIsDisable(true);
                  onClickViewMore();
                }
              }}
            >
              {t("ponds.view_more")}
            </Typography>
          </div>
        )}
        {/* ------ SHOW RECORD MODAL ------ */}
        <Modal
          open={isImageModal}
          onClose={() => setIsImageModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={boxStyle}>
            <div
              style={{
                display: "flex",
                width: "100%",
                backgroundColor: colors.slatery_blue,
                justifyContent: "space-between",
                padding: 8,
              }}
            >
              <Typography
                sx={{ fontSize: 18, color: "white", fontWeight: "600" }}
              >
                {t("ponds.record")}
              </Typography>
              <i
                className="x icon"
                style={{ color: "white", fontSize: 22 }}
                onClick={() => {
                  setImageData(null);
                  setIsImageModal(false);
                }}
              />
            </div>

            <div style={{ padding: 10 }}>
              <div style={{ display: "flex", width: "100%", marginTop: 5 }}>
                <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
                  {t("home.pond")}:&nbsp;
                  <span style={{ fontSize: 16, fontWeight: "500" }}>
                    {imageData && imageData.pondName}
                  </span>
                </Typography>
              </div>

              <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
                <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
                  {t("ponds.cycle")}:&nbsp;
                  <span style={{ fontSize: 16, fontWeight: "500" }}>
                    {imageData && imageData.cycleNo}
                  </span>
                </Typography>
              </div>

              <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
                <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
                  {t("ponds.imageType")}:&nbsp;
                  <span style={{ fontSize: 16, fontWeight: "500" }}>
                    {imageData && imageData.imageType}
                  </span>
                </Typography>
              </div>

              <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
                <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
                  {t("alarm.shrimp_health")}:&nbsp;
                  <span style={{ fontSize: 16, fontWeight: "500" }}>
                    {imageData
                      ? imageData.shrimpSickStatus === "Yes"
                        ? "Bad"
                        : imageData.shrimpSickStatus === "No"
                        ? "Good"
                        : imageData.shrimpSickStatus
                      : ""}
                  </span>
                </Typography>
              </div>

              {imageData ? (
                imageData.shrimpSickStatus === "Yes" ? (
                  <div
                    style={{ display: "flex", width: "100%", marginTop: 20 }}
                  >
                    <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
                      {t("ponds.lethal")}:&nbsp;
                      <span style={{ fontSize: 16, fontWeight: "500" }}>
                        {imageData
                          ? imageData.shrimpSickStatus === "Yes"
                            ? "Yes"
                            : imageData.shrimpSickStatus === "No"
                            ? ""
                            : imageData.shrimpSickStatus
                          : ""}
                      </span>
                    </Typography>
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}

              <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
                <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
                  {t("ponds.shrimp_weight")}:&nbsp;
                  <span style={{ fontSize: 16, fontWeight: "500" }}>
                    {imageData && imageData.shrimpWeight}
                  </span>
                </Typography>
              </div>

              <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
                <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
                  {t("home.description")}:&nbsp;
                  <span style={{ fontSize: 16, fontWeight: "500" }}>
                    {imageData && imageData.comment}
                  </span>
                </Typography>
              </div>

              <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
                <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
                  {t("home.type")}:&nbsp;
                </Typography>
                <Typography sx={{ fontSize: 16, fontWeight: "500" }}>
                  {imageData && imageData.type}
                </Typography>
              </div>

              <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
                <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
                  {t("home.reported_by")}:&nbsp;{" "}
                  <span style={{ fontSize: 16, fontWeight: "500" }}>
                    {imageData &&
                      (imageData.reportedUser
                        ? imageData.reportedUser
                        : imageData.reportedBy)}
                  </span>
                </Typography>
              </div>
              <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
                <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
                  {t("ponds.reported_date")}:&nbsp;
                  <span style={{ fontSize: 16, fontWeight: "500" }}>
                    {imageData &&
                      (getTimestampWithFormatDate(
                        imageData.recordDate,
                        "DD-MM-YYYY"
                      )
                        ? getTimestampWithFormatDate(
                            imageData.recordDate,
                            "DD-MM-YYYY"
                          )
                        : imageData.reportedBy)}
                  </span>
                </Typography>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </ResponsiveDrawer>
  );
}

const DetailCom = ({ objList }) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        padding: 10,
        color: "#fff",
        backgroundColor: "black",
        padding: "15px",
        borderRadius: "15px",
      }}
    >
      <div style={{ display: "flex", width: "100%", marginTop: 5 }}>
        <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
          {t("home.pond")}:&nbsp;
          <span style={{ fontSize: 16, fontWeight: "500" }}>
            {objList && objList.pondName}
          </span>
        </Typography>
      </div>

      <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
        <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
          {t("ponds.cycle")}:&nbsp;
          <span style={{ fontSize: 16, fontWeight: "500" }}>
            {objList && objList.cycleNo}
          </span>
        </Typography>
      </div>

      <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
        <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
          {t("ponds.imageType")}:&nbsp;
          <span style={{ fontSize: 16, fontWeight: "500" }}>
            {objList && objList.imageType}
          </span>
        </Typography>
      </div>

      <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
        <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
          {t("alarm.shrimp_health")}:&nbsp;
          <span style={{ fontSize: 16, fontWeight: "500" }}>
            {objList
              ? objList.shrimpSickStatus === "Yes"
                ? "Bad"
                : objList.shrimpSickStatus === "No"
                ? "Good"
                : objList.shrimpSickStatus
              : ""}
          </span>
        </Typography>
      </div>

      {objList ? (
        objList.shrimpSickStatus === "Yes" ? (
          <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
            <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
              {t("ponds.lethal")}:&nbsp;
              <span style={{ fontSize: 16, fontWeight: "500" }}>
                {objList
                  ? objList.shrimpSickStatus === "Yes"
                    ? "Yes"
                    : objList.shrimpSickStatus === "No"
                    ? ""
                    : objList.shrimpSickStatus
                  : ""}
              </span>
            </Typography>
          </div>
        ) : (
          ""
        )
      ) : (
        ""
      )}

      <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
        <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
          {t("ponds.shrimp_weight")}:&nbsp;
          <span style={{ fontSize: 16, fontWeight: "500" }}>
            {objList && objList.shrimpWeight}
          </span>
        </Typography>
      </div>

      <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
        <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
          {t("home.description")}:&nbsp;
          <span style={{ fontSize: 16, fontWeight: "500" }}>
            {objList && objList.comment}
          </span>
        </Typography>
      </div>

      <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
        <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
          {t("home.type")}:&nbsp;
        </Typography>
        <Typography sx={{ fontSize: 16, fontWeight: "500" }}>
          {objList && objList.type}
        </Typography>
      </div>

      <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
        <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
          {t("home.reported_by")}:&nbsp;{" "}
          <span style={{ fontSize: 16, fontWeight: "500" }}>
            {objList &&
              (objList.reportedUser
                ? objList.reportedUser
                : objList.reportedBy)}
          </span>
        </Typography>
      </div>
      <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
        <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
          {t("ponds.reported_date")}:&nbsp;
          <span style={{ fontSize: 16, fontWeight: "500" }}>
            {objList &&
              (getTimestampWithFormatDate(objList.recordDate, "DD-MM-YYYY")
                ? getTimestampWithFormatDate(objList.recordDate, "DD-MM-YYYY")
                : objList.reportedBy)}
          </span>
        </Typography>
      </div>
    </div>
  );
};
const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 360 ? 400 : 270,
  // width: 500,
  bgcolor: "background.paper",
  border: "2px solid",
  borderColor: "gray",
  borderRadius: 0,
  boxShadow: 24,
};

export default RecordImages;
