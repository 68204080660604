import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Line, Bar } from "react-chartjs-2";
import { useLocation } from "react-router-dom";
import ResponsiveDrawer from "../../common/ResponsiveDrawer";
import { useNavigate } from "react-router-dom";

import Chart from "chart.js/auto";
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    LineElement,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    Filler,
} from "chart.js";
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
ChartJS.register(
    Title,
    Tooltip,
    LineElement,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    Filler
);

var bgcolor = ["#e00c1d", "#f5751d", "#f8ba10", "#2f9542", "#426acf", "#ba499b", "#e00c1d", "#f5751d", "#f8ba10", "#2f9542", "#426acf", "#ba499b"];

const ReportAll = () => {
    const navigation = useNavigate();
    const { t } = useTranslation();
    const location = useLocation();
    const { reportData } = location.state

    const [widthCount, setWidthCount] = useState(window.screen.width);

    const actualWidth = () => {
        setWidthCount(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", actualWidth)

        return () => {
            window.removeEventListener("resize", actualWidth)
        }
    }, []);


    const amountStockedGraph = {
        labels: reportData.graphs.amountStockedGraph.labels,
        datasets: [
            {
                data: reportData.graphs.amountStockedGraph.datasets,
                backgroundColor: bgcolor,
                borderWidth: 1,
                barPercentage: 0.20,
            },
        ],
    };

    const amountHarvestedGraph = {
        labels: reportData.graphs.amountHarvestedGraph.labels,
        datasets: [
            {
                data: reportData.graphs.amountHarvestedGraph.datasets,
                backgroundColor: bgcolor,
                borderWidth: 1,
                barPercentage: 0.20,
            },
        ],
    };

    const amountOfFeedGraph = {
        labels: reportData.graphs.amountOfFeedGraph.labels,
        datasets: [
            {
                data: reportData.graphs.amountOfFeedGraph.datasets,
                backgroundColor: bgcolor,
                borderWidth: 1,
                barPercentage: 0.20,
            },
        ],
    };

    const lethalAlarmsGraph = {
        labels: reportData.graphs.lethalAlarmsGraph.labels,
        datasets: [
            {
                data: reportData.graphs.lethalAlarmsGraph.datasets,
                backgroundColor: bgcolor,
                borderWidth: 1,
                barPercentage: 0.20,
            },
        ],
    };

    const nonLethalAlarmsGraph = {
        labels: reportData.graphs.nonLethalAlarmsGraph.labels,
        datasets: [
            {
                data: reportData.graphs.nonLethalAlarmsGraph.datasets,
                backgroundColor: bgcolor,
                borderWidth: 1,
                barPercentage: 0.20,
            },
        ],
    };

    const checkNeededAlarmsGraph = {
        labels: reportData.graphs.checkNeededAlarmsGraph.labels,
        datasets: [
            {
                data: reportData.graphs.checkNeededAlarmsGraph.datasets,
                backgroundColor: bgcolor,
                borderWidth: 1,
                barPercentage: 0.20,
            },
        ],
    };

    const feedConversionGraph = {
        labels: reportData.graphs.feedConversionGraph.labels,
        datasets: [
            {
                data: reportData.graphs.feedConversionGraph.datasets,
                backgroundColor: bgcolor,
                borderWidth: 1,
                barPercentage: 0.20,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                    barPercentage: 0.5,
                    borderColor: "#fff",
                },
                angleLines: {
                    display: false,
                },
                ticks: {
                    font: {
                        size: 14,
                        weight: 500,
                        color: "red",
                    },
                },
            },
            y: {
                grid: {
                    borderColor: "#fff",
                    borderDash: [4, 7],
                    color: "#bebebe",
                },
                ticks: {
                    callback: (label) => `${label}`,
                    font: {
                        size: 14,
                        weight: 500,
                    },
                },
            },
        },
    };

    return (
        <ResponsiveDrawer
            isShowAppBar={true}
            headText={t("report.report_all")}
            isHideToolBar={true}
            isBackArrow={true}
            onClickBack={() => {
                navigation("/report");
                localStorage.setItem("report", "1");
                (localStorage.getItem("pondActive") === "0" ? localStorage.setItem("pondActive", "0") : localStorage.setItem("pondActive", "1"))
            }}
        >
            <div className='report_main_wrapper_page'>
                <div className='reports_download'>
                    <button className='ui primary button' onClick={() => {
                        html2canvas(document.querySelector("#print"), {
                            // width: 1080,
                            // scale: 1
                        }).then(canvas => {
                            const imgData = canvas.toDataURL('image/png');
                            // const pdf = new jsPDF();
                            var pdf = new jsPDF('l', 'mm', 'a4');
                            // var pdf = new jsPDF('l', 'mm');
                            var margin = 3;
                            // var imgWidth = 213 - 3 * margin;
                            // var imgHeight = canvas.height * imgWidth / canvas.width;

                            // var imgWidth = widthCount > 1700 ? 290 : 220;
                            // var imgHeight = canvas.height * imgWidth / canvas.width;

                            var width = pdf.internal.pageSize.getWidth();
                            var height = pdf.internal.pageSize.getHeight();
                            // pdf.addImage(imgData, 'PNG', 3, 3, imgWidth, imgHeight - `${widthCount > 1700 ? 30 : 100}`);
                            pdf.addImage(imgData, 'PNG', 3, 3, width - 6, height - 4);

                            // pdf.output('dataurlnewwindow');
                            pdf.save("AllPond_Report.pdf");
                        });
                    }}>Download</button>
                </div>
                <div className='reports_main_wrapper'>
                    <div id="print">
                        <div className='report_main_wrapper'>
                            <div className='report_pond_wrap'>
                                <div className='text_sam_block'>
                                    <h6>{t("ReportPond.report_parameters")}</h6>
                                    <div className='description_content'>
                                        <ul>
                                            {/* <li>
                                                <p className='description_sam_text'>{t("ReportPond.s_date_e_date")}:</p>
                                                <span className='description_sam_text'></span>
                                            </li> */}
                                            {reportData.data.isCycle &&  <li>
                                                <p className='description_sam_text'>{t("ReportPond.pond")}:</p>
                                                <span className='description_sam_text'>{reportData.data.pondName}</span>
                                            </li>}
                                            <li>
                                                <p className='description_sam_text'>{reportData.data.isCycle ? t("ReportPond.cycles") : t("ReportPond.pond")}:</p>
                                                <span className='description_sam_text'>{reportData.data.pondsNameString}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='text_sam_block'>
                                    <h6>{t("ReportPond.summary")}</h6>
                                    <div className='description_content'>
                                        <div className='summary_row_block'>
                                            <ul>
                                                <li>
                                                    <p className='description_sam_text'>{reportData.data.isCycle ? t("ReportAll.amountOfCycles") : t("ReportAll.amount_of_Ponds")}:</p>
                                                    <span className='description_sam_text'>{reportData.data.amountOfPonds}</span>
                                                </li>
                                                <li>
                                                    <p className='description_sam_text'>{t("ReportPond.amount_stocked")}:</p>
                                                    <span className='description_sam_text'>{reportData.data.amountStocked}</span>
                                                </li>
                                                <li>
                                                    <p className='description_sam_text'>{t("ReportPond.amount_harvested")}:</p>
                                                    <span className='description_sam_text'>{reportData.data.harvestedAmount}</span>
                                                </li>
                                                <li>
                                                    <p className='description_sam_text'>{t("ReportPond.amount_feed_used")}:</p>
                                                    <span className='description_sam_text'>{reportData.data.amountOfFeed}</span>
                                                </li>
                                            </ul>
                                            <ul>
                                                <li>
                                                    <p className='description_sam_text'>{t("ReportPond.amount_lethal_alarms")}:</p>
                                                    <span className='description_sam_text'>{reportData.data.lethalCountTotal}</span>
                                                </li>
                                                <li>
                                                    <p className='description_sam_text'>{t("ReportPond.amount_non_lethal_alarms")}:</p>
                                                    <span className='description_sam_text'>{reportData.data.nonLethalCountTotal}</span>
                                                </li>
                                                <li>
                                                    <p className='description_sam_text'>{t("ReportPond.amount_check_needed_alarms")}:</p>
                                                    <span className='description_sam_text'>{reportData.data.checkNeededCountTotal}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='growth_average_row'>
                                    <div className='growth_bar_col'>
                                        <p className='description_sam_text'>{t("ReportAll.growth_average_each")}</p>
                                        <div className="chart_block">
                                            <Bar data={amountStockedGraph} options={options} />
                                        </div>
                                    </div>
                                    <div className='growth_bar_col'>
                                        <p className='description_sam_text'>{t("ReportAll.amount_stocked_par")}</p>
                                        <div className="chart_block">
                                            <Bar data={amountStockedGraph} options={options} />
                                        </div>
                                    </div>
                                    <div className='growth_bar_col'>
                                        <p className='description_sam_text'>{t("ReportAll.amount_harvested")}</p>
                                        <div className="chart_block">
                                            <Bar data={amountHarvestedGraph} options={options} />
                                        </div>
                                    </div>
                                    <div className='growth_bar_col'>
                                        <p className='description_sam_text'>{t("ReportAll.amount_faad")}</p>
                                        <div className="chart_block">
                                            <Bar data={amountOfFeedGraph} options={options} />
                                        </div>
                                    </div>
                                    <div className='growth_bar_col'>
                                        <p className='description_sam_text'>{t("ReportAll.amount_lethal_alarms")}</p>
                                        <div className="chart_block">
                                            <Bar data={lethalAlarmsGraph} options={options} />
                                        </div>
                                    </div>
                                    <div className='growth_bar_col'>
                                        <p className='description_sam_text'>{t("ReportAll.amount_non_lethal_alarms")}</p>
                                        <div className="chart_block">
                                            <Bar data={nonLethalAlarmsGraph} options={options} />
                                        </div>
                                    </div>
                                    <div className='growth_bar_col'>
                                        <p className='description_sam_text'>{t("ReportAll.amount_check_needed_alarms")}</p>
                                        <div className="chart_block">
                                            <Bar data={checkNeededAlarmsGraph} options={options} />
                                        </div>
                                    </div>
                                    <div className='growth_bar_col'>
                                        <p className='description_sam_text'>{t("ReportAll.feed_conversion_ratio")}</p>
                                        <div className="chart_block">
                                            <Bar data={feedConversionGraph} options={options} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ResponsiveDrawer>
    )
}

export default ReportAll;