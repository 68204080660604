import React from "react";

function CustomSegments(props) {
  const { segmentList, selectedSegment, onClickSelectedSegment } = props;

  return (
    <div className="ui horizontal segments Settings-roow-color">
      {segmentList.length > 0 &&
        segmentList.map((val, index) => {
          return (
            <div
              key={index.toString()}
              className={`ui ${selectedSegment === val.id ? "blue inverted" : ""
                } segment`}
              onClick={() => onClickSelectedSegment(val.id)}
            >
              {val.title}
            </div>
          );
        })}
    </div>
  );
}

export default CustomSegments;
