import axios from "axios";
import { STRIPE_TOKEN } from "../common/configs";

export const getCurrentSubscription = async (custId) => {
  if (custId && custId !== "") {
    const { data } = await axios.get(
      `https://api.stripe.com/v1/subscriptions?customer=${custId}`,
      {
        headers: {
          Authorization:
            "Bearer " + STRIPE_TOKEN,
        },
      }
    );
    if (data.data && data.data.length > 0 && data.data[0].id) {
      const dataInvoice = await axios.get(
        `https://api.stripe.com/v1/invoices/search?query=subscription:"${data.data[0].id}"`,
        {
          headers: {
            Authorization:
              "Bearer " + STRIPE_TOKEN,
          },
        }
      );
      if (dataInvoice && dataInvoice.data && dataInvoice.data.data.length > 0) {
        try {
          dataInvoice.data.data[0]["cancel_at_period_end"] = data.data[0].cancel_at_period_end
          dataInvoice.data.data[0]["cancel_at"] = data.data[0].cancel_at
          dataInvoice.data.data[0]["canceled_at"] = data.data[0].canceled_at
          dataInvoice.data.data[0]["productId"] = data.data[0].plan.product
        } catch (e) {
        }
        return dataInvoice.data.data[0];
      } else {
        return null
      }
    } else {
      return null
    }
  } else {
    return null;
  }
};

export const getInvoiceList = async (custId) => {
  if (custId && custId !== "") {
    const { data } = await axios.get(
      `https://api.stripe.com/v1/invoices?customer=${custId}&limit=100`,
      {
        headers: {
          Authorization:
            "Bearer " + STRIPE_TOKEN,
        },
      }
    );
    return data.data;
  } else {
    return [];
  }
};
