import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  // onSnapshot,
  orderBy,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "../../config/firebase-config";
import { getFarms, getPond } from "./pond";
import { getStartOfTimestamp } from "../../common/utils";
import {
  FIRESTORE_LICENSES,
  FIRESTORE_PLANS,
  FIRESTORE_POND_CYCLE,
  FREE,
  PARENT_ID,
  POND_ID,
  STATUS,
  STATUS_ACTIVE,
  TYPE,
} from "../config";
import { CLOUD_LICENSE } from "../../common/configs";

export const getTopAlarmsFromDb = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let pId = localStorage.getItem("pId");
      const q = query(
        collection(db, "alarms"),
        where("pId", "==", `${pId}`),
        where("status", "!=", "Cleared"),
        orderBy("status", "asc"),
        orderBy("isLethal", "desc"),
        orderBy("recordDate", "desc"),
        limit(10)
      );
      const currentCycleIdsSnap = query(
        collection(db, "Ponds"),
        where("pId", "==", `${pId}`),
        where("currentCycleId", "!=", "")
      );
      let dataMain = [];
      let data = [];
      let activeCycles = [];
      // onSnapshot(q, (snapshot) => {
      //   snapshot.docs.forEach((element) => {
      //     if (element.exists()) {
      //       data.push({ id: element.id, ...element.data() });
      //     }
      //   });
      //   return resolve(data);
      // });
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((val) => {
        data.push({ id: val.id, ...val.data() });
      });

      const qCycles = await getDocs(currentCycleIdsSnap);
      qCycles.forEach((val) => {
        activeCycles.push(val.data().currentCycleId);
      });

      for (const cycleId of activeCycles) {
        for (const alarm of data) {
          if (alarm.cycleId == cycleId) {
            dataMain.push(alarm);
          }
        }
      }
      return resolve(dataMain);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getCountersDataFromDb = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let pId = localStorage.getItem("pId");
      // const docRef = doc(db, "counters", "appCounters");
      const docRef = doc(db, "counters", `${pId}`);
      const docSnap = await getDoc(docRef);
      // const farms = await getFarms();
      let data = docSnap.data();
      // if (farms && farms.length > 0) {
      //   let pId = localStorage.getItem("pId");
      //   const pondData = await getPond(pId, null, farms);
      //   data = {
      //     ...data,
      //     ponds: pondData.length
      //   }
      // }

      if (docSnap.exists()) {
        return resolve(data);
      } else {
        await setDoc(doc(db, `counters`, `${pId}`), {
          biomass: 0,
          abnormalBiomass: 0,
          ponds: 0,
          records: 0,
          users: 0,
          devices: 0,
        });
        return resolve(null);
      }
    } catch (error) {
      return reject(error);
    }
  });
};

export const getCountersMonthDataFromDb = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let pId = localStorage.getItem("pId");
      const monthFirstDay = await getStartOfTimestamp("month");
      const docRef = doc(
        db,
        "counters",
        `${pId}/monthlyRecords/${monthFirstDay}`
      );
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return resolve(docSnap.data());
      } else {
        return resolve(null);
      }
    } catch (error) {
      return reject(error);
    }
  });
};

export const getPlansRecordsFromDb = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let pId = localStorage.getItem("pId");
      const q = query(
        collection(db, FIRESTORE_LICENSES),
        where(PARENT_ID, "==", pId),
        where(TYPE,"==",CLOUD_LICENSE)
      );
      const docRef = doc(db, FIRESTORE_PLANS, FREE);
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        let data = [];
        querySnapshot.forEach((doc) => {
          data.push(doc.data());
        });
        resolve(data[0]);
      } else {
        const docSnap = await getDoc(docRef);
        resolve({ plansRecords: docSnap.data().numberOfRecords ,planUsers: docSnap.data().numberOfUsers});
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const getActivePondCycleFromDb = (pondIds) => {
  console.log('pondIds: ', pondIds);
  return new Promise(async (resolve, reject) => {
    try {
      let pId = localStorage.getItem("pId");
      
      // Function to chunk an array into smaller arrays of a specific size
      const chunkArray = (array, size) => {
        const result = [];
        for (let i = 0; i < array.length; i += size) {
          result.push(array.slice(i, i + size));
        }
        return result;
      };

      // Chunk the pondIds array into arrays of 10 items each
      const pondIdChunks = chunkArray(pondIds, 10);

      let data = [];

      for (const chunk of pondIdChunks) {
        const q = query(
          collection(db, FIRESTORE_POND_CYCLE),
          where(PARENT_ID, "==", `${pId}`),
          where(POND_ID, "in", chunk),
          where(STATUS, "==", STATUS_ACTIVE)
        );

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((val) => {
          data.push({ id: val.id, ...val.data() });
        });
      }

      return resolve(data);
    } catch (error) {
      console.log('error home page', error);
      return reject(error);
    }
  });
};
