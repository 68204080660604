import {
  Typography,
  Modal,
  Box,
  TextField,
  Alert,
  FormControl,
  Select,
  MenuItem,
  styled,
  InputBase,
  Switch,
  Divider,
  Autocomplete,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import colors from "../../../common/colors";
import ResponsiveDrawer from "../../../common/ResponsiveDrawer";
import TableRow from "../../../components/ponds/TableRow";
import {
  addPond,
  AttachImageInCounter,
  deletePond,
  getActivePondCycle,
  getActivePondCycleByCycleId,
  getFarms,
  getFarmsWithKey,
  getPond,
  updatePond,
} from "../../../database/user/pond";
import "../../../style/user/Ponds.css";
import "../../../style/loader.css";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";
import { Save } from "@mui/icons-material";
import imagePath from "../../../common/imagePath";
import ImageMarker from "react-image-marker";
import no_alarms_found from "../../../images/no_alarms_found.png";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import SaveIcon from "@mui/icons-material/Save";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "8px 26px 8px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));
const sortingOptionList = [
  {
    name: "Pond Name",
    value: "pond_name",
  },
  {
    name: "Number of Biomass",
    value: "number_of_biomass",
  },
  {
    name: "Nursery",
    value: "nursery",
  },
];
function Ponds() {
  const navigation = useNavigate();
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [name, setName] = useState("");
  const [farmName, setFarmName] = useState(
    window.history.state.usr && window.history.state.usr.farm
      ? window.history.state.usr.farm
      : ""
  );
  const [size, setSize] = useState("");
  const [farms, setFarms] = useState([]);
  const [showError, setShowError] = useState({
    isName: false,
    isFarmName: false,
    isSizeErr: false,
    isHealthErr: false,
    isBiomassErr: false,
  });
  const [items, setItems] = useState([]);
  console.log("items: 101", items);
  const [ponds, setPonds] = useState([]);
  const [isDisable, setIsDisable] = useState(false);
  const [sortValueFarm, setSortValueFarm] = useState(
    window.history.state.usr && window.history.state.usr.farm
      ? window.history.state.usr.farm
      : "All Ponds"
  );

  const [sortBy, setSortBy] = useState("Sort by");
  const [sort_by, setSort_by] = useState("");
  const [sort_asc, setSort_asc] = useState(false);
  const [sortValue, setSortValue] = useState("All");
  const [isData, setIsData] = useState(false);
  const [first, setIsFirst] = useState(true);
  const [center, setCenter] = useState({
    lat: 55.6761,
    lng: 12.5683,
  });
  const [map, setMap] = useState({
    lat: 55.6761,
    lng: 12.5683,
  });
  const [enableMap, setEnableMap] = useState(true);
  const [enableImage, setEnableImage] = useState(false);
  const [imgFile, setImgFile] = useState(null);
  const [attachImgFile, setAttachImgFile] = useState(null);
  const [attachImgFile2, setAttachImgFile2] = useState(null);
  const [openMenuId, setOpenMenuId] = useState(-1);
  const [selectedSegment, setSelectedSegment] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [editIdImg, setEditIdImg] = useState(null);
  const [isShowAppBar, setIsShowAppBar] = useState(true);
  const [markers, setMarkers] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [getDataLoader, setGetDataLoader] = useState(false);
  const [isNurserySelected, setIsNurserySelected] = useState(false);

  const [farmItemsSearch, setFarmItemsSearch] = useState();

  const isModalclose = () => {
    setIsOpenModal(false);
    setName("");
    setIsNurserySelected(false);
    setEnableMap(true);
    setSize("");
  };

  const handleOnSearch = (string, results) => {
    setFarmName(string);
    setShowError({ ...showError, isName: false });
  };

  const handleOnSelect = (item) => {
    setFarmName(item.name);
    // the item selected
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        setCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        setMap({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAARdYXZPxgm25l-W4YSO49BgAswqE_G5o",
  });

  useEffect(() => {
    getFarmsData();
  }, []);

  const getFarmsData = async () => {
    const farms = await getFarms();
    setFarms(farms);
    setFarmsSelection(farms);
    if (!farms || farms.length <= 0) {
      setIsData(true);
    }
  };

  const setFarmsSelection = (farmsAll) => {
    let farms = [];
    if (Array.isArray(farmsAll) && farmsAll.length > 0) {
      for (const farm of farmsAll) {
        const data = {
          id: farm,
          name: farm,
        };
        farms.push(data);
      }
    }
    setFarmItemsSearch(farms);
  };
  useEffect(() => {
    getPondsData();
  }, [farms]);

  const getPondsData = async () => {
    try {
      setGetDataLoader(true);
      let pId = localStorage.getItem("pId");
      let data = await getPond(pId, null, farms);
      data.sort(function (a, b) {
        return a.pondNo - b.pondNo;
      });
      let newDataArr = [];
      for (let i = 0; i < data.length; i++) {
        let arr = await getActivePondCycleByCycleId(data[i].currentCycleId);
        let obj = { ...data[i] };
        if (arr.length > 0) {
          obj = {
            ...arr[0],
            ...data[i],
            cycleId: arr[0].id,
            stockingAmountCycle: arr[0].stockingAmount?.toLocaleString(),
            currentCycleStartDate: arr[0]?.startDate,
          };
        }
        newDataArr.push(obj);
      }
      setPonds(newDataArr);
      setIsFirst(false);

      setItems(newDataArr);
      setGetDataLoader(false);
      if (data.length <= 0) {
        setIsData(true);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const onClickAdd = async (e) => {
    e.stopPropagation();
    setIsDisable(true);
    try {
      if (name === "") {
        setShowError({ ...showError, isName: true });
        setIsDisable(false);
        return;
      }
      if (
        farmName === "" ||
        farmName == "All Ponds" ||
        farmName == t("ponds.all_ponds")
      ) {
        toast(t("ponds.selectFarm"));
        setShowError({ ...showError, isFarmName: true });
        setIsDisable(false);
        return;
      }
      if (size === "") {
        setShowError({ ...showError, isSizeErr: true });
        setIsDisable(false);
        return;
      }
      if (isEdit) {
        let data = {
          pondName: name,
          farmName: farmName,
          pondSize: size,
        };
        if (enableMap) {
          data = {
            ...data,
            latitude: map.lat,
            longitude: map.lng,
            imageMarker: null,
          };
        } else if (enableImage) {
          data = {
            ...data,
            latitude: null,
            longitude: null,
            imageMarker: markers,
          };
        }
        const updated = await updatePond(editIdImg, data, imgFile);
        let newArr = [];
        items.map((ele) => {
          let obj = ele;
          if (ele.id === editIdImg.id) {
            obj = { ...ele, ...data };
          }
          newArr.push(obj);
        });
        setItems(newArr);
        setSortValue(t("ponds.all"));
        setSortValueFarm(farmName);
        setSearchText("");
        getPondsData();
      } else {
        let data = {
          pondNo: items.length + 1,
          pondName: name,
          farmName: farmName,
          pondSize: size,
          isNursery: isNurserySelected,
          healthCondition: "No Data",
          pId: localStorage.getItem("pId"),
        };
        if (enableMap) {
          let mLoc = map !== null ? map : center;
          data = { ...data, latitude: mLoc.lat, longitude: mLoc.lng };
        } else if (enableImage) {
          data = { ...data, imageMarker: markers };
        }
        const addId = await addPond(data, imgFile);
        // let getData = await getPond();
        let newArr = items;
        newArr.push({ ...data, id: addId });
        setItems(newArr);
        setSortValue(t("ponds.all"));
        setSortValueFarm(farmName);
        setSearchText("");
        getPondsData();
      }
      setOpenMenuId(-1);
      setMarkers([]);
      setShowPopup(false);
      setEnableImage(false);
      setSelectedSegment(0);
      setName("");
      setSize("");
      setImgFile(null);
      setEnableMap(true);
      setIsEdit(false);
      setMap(null);
      setIsOpenModal(false);
      setIsDisable(false);
    } catch (error) {
      setIsDisable(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSearch(searchText.trim());
    }, 1000);
  }, [searchText]);

  useEffect(() => {
    filterData();
  }, [search, sortValueFarm, sortValue, ponds]);

  const autoValue = (value) => {
    const selectedObj =
      farmItemsSearch && farmItemsSearch.find((item) => item.name === value);
    return selectedObj;
  };

  const sortDataNew = () => {
    // Step 1: Map and process data to include temporary fields for sorting
    let lstTmpForSort = [...ponds].map((obj) => ({
        ...obj,
        stockingAmountCycleTmp: obj.stockingAmountCycle ? parseInt(obj.stockingAmountCycle.replace(/,/g, ''), 10) : 0,
        currentCycleStartDateTmp: obj.currentCycleStartDate ? obj.currentCycleStartDate : 0,
        isClosedCycle: obj.status !== 'Active', // Add a flag to identify closed cycles
    }));

    // Step 2: Filter by farmName if needed
    if (sortValueFarm && sortValueFarm !== "All Ponds") {
        lstTmpForSort = lstTmpForSort.filter(pond => pond.farmName === sortValueFarm);
    }

    // Step 3: Sort data
    const data = lstTmpForSort.sort((a, b) => {
        // First, sort by open/closed status
        if (a.isClosedCycle !== b.isClosedCycle) {
            return a.isClosedCycle ? 1 : -1; // Open cycles first
        }
        
        // Then sort by the chosen attribute (sort_by)
        if (a[sort_by] < b[sort_by]) return sort_asc ? -1 : 1;
        if (a[sort_by] > b[sort_by]) return sort_asc ? 1 : -1;
        return 0;
    });

    // Step 4: Set the sorted items
    setItems(data);
}
  const sortData = () => {
    console.log("sortBy: ", sortBy);

    let filteredData = [...ponds];
    // Filter the data based on the selected farm
    if (sortValueFarm && sortValueFarm !== "All Ponds") {
      console.log("sortValueFarm: ", sortValueFarm);
      console.log(
        'sortValueFarm !== "All Ponds": ',
        sortValueFarm !== "All Ponds"
      );
      filteredData = filteredData.filter(
        (pond) => pond.farmName === sortValueFarm
      );
    }

    // Apply sorting after filtering
    if (sortBy?.value === "pond_name") {
      const data = filteredData.sort((a, b) => {
        if (a.pondName < b.pondName) return -1;
        if (a.pondName > b.pondName) return 1;
        return 0;
      });
      setItems(data);
      if (data.length <= 0 && !first) {
        setIsData(true);
      }
    } else if (sortBy?.value === "number_of_biomass") {
      const data = filteredData
        .map((obj) => ({
          ...obj,
          stockingAmountCycleTmp: obj.stockingAmountCycle ? parseInt(obj.stockingAmountCycle.replace(/,/g, ''), 10) : 0,
        }))
        .sort((a, b) => b.stockingAmountCycleTmp - a.stockingAmountCycleTmp);
      setItems(data);
      if (data.length <= 0 && !first) {
        setIsData(true);
      }
    } else if (sortBy?.value === "nursery") {
      const data = filteredData.sort((a, b) => b.isNursery - a.isNursery);
      setItems(data);
      if (data.length <= 0 && !first) {
        setIsData(true);
      }
    }
  };

  useEffect(() => {
    sortData();
  }, [sortBy, sortValueFarm]);

  useEffect(() => {
    sortDataNew();
  }, [sort_asc, sort_by]);

  const filterData = () => {
    console.log("ponds: ", ponds);
    let dataAll = ponds;
    if (search) {
      let data = ponds.filter((val) => {
        return val.pondName.toLowerCase().includes(search.toLowerCase());
      });
      dataAll = data;
      if (data.length <= 0) {
        setIsData(true);
      }
    }
    if (sortValueFarm) {
      if (sortValueFarm !== "All Ponds") {
        let data = dataAll.filter((ele) => {
          return ele.farmName === sortValueFarm;
        });
        dataAll = data;
      }
    }
    if (sortValue) {
      if (sortValue !== "All") {
        let data = dataAll.filter((ele) => {
          return ele.healthCondition === sortValue && ele.records;
        });
        dataAll = data;
      }
    }
    setItems(dataAll);
    if (dataAll.length <= 0 && !first) {
      setIsData(true);
    }
  };

  function dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  useEffect(() => {
    async function uploadImageInCounter() {
      try {
        let res = await AttachImageInCounter(attachImgFile);
        setAttachImgFile2(res ? res.blueprint : attachImgFile2);
        setAttachImgFile(null);
      } catch (error) {
        console.log("error: ", error);
      }
    }
    if (attachImgFile) {
      uploadImageInCounter();
    }
  }, [attachImgFile]);

  const CustomMarker = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          position: "relative",
        }}
      >
        {showPopup && name && name !== "" && (
          <div
            style={{
              position: "absolute",
              zIndex: 5,
              bottom: 22,
              backgroundColor: colors.lightWhite,
              padding: 5,
              borderRadius: 5,
              width: "auto",
            }}
          >
            <Typography
              sx={{
                width: "auto",
                minWidth: 60,
                maxWidth: 100,
                textAlign: "center",
              }}
            >
              {name}
            </Typography>
          </div>
        )}
        <img
          src={imagePath.marker_red}
          style={{ width: 20, height: 20 }}
          onClick={() => setShowPopup(!showPopup)}
        />
      </div>
    );
  };

  if (getDataLoader) {
    return (
      <ResponsiveDrawer
        isShowAppBar={isShowAppBar}
        headText={t("ponds.ponds")}
        isHideToolBar={true}
        isBackArrow={true}
        onClickBack={() => {
          navigation("/selectFarm");
        }}
      >
        <div
          div
          style={{
            display: "flex",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div id={`load`} className="loader" />
        </div>
      </ResponsiveDrawer>
    );
  }
  console.log("sortValueFarm: 477", sortValueFarm);
  return (
    <div>
      <ResponsiveDrawer
        isShowAppBar={isShowAppBar}
        headText={t("ponds.ponds")}
        isHideToolBar={true}
        isBackArrow={true}
        onClickBack={() => {
          navigation("/selectFarm");
        }}
      >
        <div
          style={{ marginTop: -50 }}
          onClick={(e) => {
            e.preventDefault();
            if (openMenuId !== -1) {
              setOpenMenuId(-1);
            }
            // setIsOpenMenu(!isOpenMenu);
          }}
        >
          {/* ----- HEAD SEARCHES ----- */}
          <div className="head_container hedera_ui_css_block">
            <div className="hedera_flex_block_row">
              <div
                className="head_two select-main-block"
                style={{ marginRight: 15 }}
              >
                <Box sx={{ minWidth: 260 }}>
                  <Typography>{t("ponds.filter_by_farm")}</Typography>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sortValueFarm}
                      label="Age"
                      onChange={async (e) => {
                        e.stopPropagation();
                        try {
                          setSortValueFarm(e.target.value);
                          setFarmName(e.target.value);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                      input={<BootstrapInput />}
                    >
                      <MenuItem value={"All Ponds"}>
                        {t("ponds.all_ponds")}
                      </MenuItem>
                      {farms &&
                        farms.length > 0 &&
                        farms.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item}>
                              {item}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Box>
              </div>

              {/* <div
                className="head_two select-main-block"
                style={{ marginRight: 15 }}
              >
                <Box sx={{ minWidth: 260 }}>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sortBy}
                      label="Age"
                      onChange={async (e) => {
                        e.stopPropagation();
                        try {
                          console.log("e.target.value: ", e.target.value);
                          setSortBy(e.target.value);
                          // setFarmName(e.target.value);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                      input={<BootstrapInput />}
                    >
                      <MenuItem value={"Sort by"}>
                        {t("ponds.sort_by")}
                      </MenuItem>
                      {sortingOptionList.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </div> */}
              {/* <div className="head_two select-main-block">
                <Box sx={{ minWidth: 260 }}>
                  <Typography>
                    {t("ponds.filter_by_health_condition")}
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sortValue}
                      label="Age"
                      onChange={async (e) => {
                        e.stopPropagation();
                        setSortValue(e.target.value);
                      }}
                      input={<BootstrapInput />}
                    >
                      <MenuItem value={"All"}>{t("ponds.all")}</MenuItem>
                      <MenuItem value="Good">{t("ponds.good")}</MenuItem>
                      <MenuItem value="Bad">{t("ponds.bad")}</MenuItem>
                      <MenuItem value="Concern">{t("ponds.concern")}</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div> */}
              <div className="head_one">
                <div className="ui stackable two column grid">
                  <div
                    className="column"
                    style={{ width: "260px", padding: "0" }}
                  >
                    <div
                      className="ui icon input input-css-block"
                      style={{ width: "260px" }}
                    >
                      <input
                        className="prompt"
                        type="text"
                        placeholder={t("ponds.search")}
                        onChange={(e) => {
                          e.stopPropagation();
                          if (e.target.value === "") {
                            setItems(items);
                            setSearchText(e.target.value);
                            setIsData(false);
                          } else {
                            setSearchText(e.target.value);
                            setIsData(false);
                          }
                        }}
                        value={searchText}
                        style={{ border: "1px solid #ced4da", height: 43 }}
                      />
                      <i className="search icon"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column base_container pons-button-css-block">
              <button
                className="ui primary button"
                style={{ width: "auto", height: 40 }}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowError({
                    ...showError,
                    isName: false,
                    isFarmName: false,
                    isSizeErr: false,
                    isHealthErr: false,
                    isBiomassErr: false,
                  });
                  setIsOpenModal(true);
                }}
              >
                {t("ponds.add_pond")}
              </button>
              <input
                type="file"
                name="image"
                id="attachImage"
                onChange={(e) => {
                  const reader = new FileReader();
                  reader.onload = (event) => {
                    const uploadPhoto = true;
                    const selectedPicture = event.target.result;
                    setAttachImgFile2(selectedPicture);
                    const file = dataURLtoFile(selectedPicture, "images");
                    setAttachImgFile(file);
                    setShowError({ ...showError, isImg: false });
                  };
                  reader.readAsDataURL(e.target.files[0]);
                }}
                style={{ visibility: "hidden" }}
              />
            </div>
          </div>
          {/* ----- CARDS TABLE ----- */}
          <div className="cards_main_block">
            <div className="inventory_table_wrap">
              <div className="table-responsive cm_table_responsive">
                {items.length > 0 ? (
                  <table className="cm_table fold-table">
                    <thead className="cm_thead">
                      <tr className="cm_thead_list">
                        <th
                          className="th_list cm_name sorting_heading"
                          onClick={() => {
                            if (sort_by === "pondName") {
                              setSort_asc(!sort_asc);
                            } else {
                              setSort_asc(true);
                            }
                            setSort_by("pondName");
                          }}
                        >
                          <div className="th_item_wrap">
                            <h6>{t("ponds.Ponds_Name")}</h6>
                            <SortingArrows
                              sort_by={sort_by}
                              sort_asc={sort_asc}
                              currentKey={"pondName"}
                            />
                          </div>
                        </th>
                        <th
                          className="th_list cm_name sorting_heading"
                          onClick={() => {
                            if (sort_by === "stockingAmountCycleTmp") {
                              setSort_asc(!sort_asc);
                            } else {
                              setSort_asc(true);
                            }
                            setSort_by("stockingAmountCycleTmp");
                          }}
                        >
                          <div className="th_item_wrap">
                            <h6>{t("ponds.number_of_biomass")}</h6>
                            <SortingArrows
                              sort_by={sort_by}
                              sort_asc={sort_asc}
                              currentKey={"stockingAmountCycleTmp"}
                            />
                          </div>
                        </th>
                        <th
                          className="th_list cm_name sorting_heading"
                          onClick={() => {
                            if (sort_by === "isNursery") {
                              setSort_asc(!sort_asc);
                            } else {
                              setSort_asc(true);
                            }
                            setSort_by("isNursery");
                          }}
                        >
                          <div className="th_item_wrap">
                            <h6>{t("ponds.nursery")}</h6>
                            <SortingArrows
                              sort_by={sort_by}
                              sort_asc={sort_asc}
                              currentKey={"isNursery"}
                            />
                          </div>
                        </th>
                        <th
                          className="th_list cm_name sorting_heading"
                          onClick={() => {
                            if (sort_by === "currentCycleStartDateTmp") {
                              setSort_asc(!sort_asc);
                            } else {
                              setSort_asc(true);
                            }
                            setSort_by("currentCycleStartDateTmp");
                          }}
                        >
                          <div className="th_item_wrap">
                            <h6>{t("ponds.cycle")}</h6>
                            <SortingArrows
                              sort_by={sort_by}
                              sort_asc={sort_asc}
                              currentKey={"currentCycleStartDateTmp"}
                            />
                          </div>
                        </th>
                        {/* <th className="th_list cm_name">
                          <div className="th_item_wrap">
                            <h6>{t("ponds.average_size")}</h6>
                          </div>
                        </th> */}
                        {/* <th className="th_list cm_name">
                          <div className="th_item_wrap">
                            <h6>{t("ponds.health_status")}</h6>
                          </div>
                        </th> */}
                        <th className="th_list cm_name"></th>
                      </tr>
                    </thead>
                    <tbody className="cm_tbody">
                      {items.map((val, index) => {
                        return (
                          <>
                            <tr key={index} className="cm_tr view">
                              <TableRow
                                val={val}
                                onClickCard={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  navigation(`/pond_detail`, {
                                    state: {
                                      cardData: val,
                                      sortValueFarm: sortValueFarm,
                                    },
                                  });
                                }}
                                onClickMenu={(e) => {
                                  e.stopPropagation();
                                  setOpenMenuId(
                                    val.id === openMenuId ? -1 : val.id
                                  );
                                }}
                                openId={openMenuId}
                                onClickEdit={(e) => {
                                  e.stopPropagation();
                                  setIsEdit(true);
                                  setEditIdImg({
                                    id: val.id,
                                    img: val.imgName,
                                  });
                                  setName(val.pondName);
                                  setFarmName(val.farmName);
                                  setSize(val.pondSize);
                                  setIsNurserySelected(val.isNursery);
                                  if (val.latitude && val.longitude) {
                                    setMap({
                                      lat: Number(val.latitude),
                                      lng: Number(val.longitude),
                                    });
                                    setCenter({
                                      lat: Number(val.latitude),
                                      lng: Number(val.longitude),
                                    });
                                  }
                                  setEnableMap(
                                    val.latitude && val.longitude ? true : false
                                  );
                                  setEnableImage(
                                    val.imageMarker &&
                                      val.imageMarker.length > 0
                                      ? true
                                      : false
                                  );
                                  if (
                                    val.imageMarker &&
                                    val.imageMarker.length > 0
                                  ) {
                                    setMarkers(val.imageMarker);
                                  }
                                  setSelectedSegment(
                                    val.imageMarker &&
                                      val.imageMarker.length > 0
                                      ? 1
                                      : 0
                                  );
                                  setOpenMenuId(-1);
                                  setIsOpenModal(true);
                                }}
                                onClickDelete={(e) => {
                                  e.stopPropagation();
                                  setDeleteData(val);
                                  setOpenMenuId(-1);
                                  setDeleteModal(true);
                                }}
                              />
                            </tr>
                            <tr className="cm_hi"></tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                ) : isData ? (
                  <div
                    style={{
                      display: "flex",
                      height: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                    }}
                    className="no-alarms-found-css"
                  >
                    <img src={no_alarms_found} alt="no_alarms_found" />
                    <Typography sx={{ fontSize: 17, fontWeight: "600" }}>
                      {t("ponds.pond_data_not_found")}
                    </Typography>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      height: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <div id={`load`} className="loader" />
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* ------ ADD MODAL ------ */}
          <Modal
            open={isOpenModal}
            onClose={isModalclose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={boxStyle} className="add-Pond-location">
              {/* <div style={{ height: "600px", overflow: "auto" }}> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "auto",
                  // maxHeight: "750px",
                  // height: "640px",
                  // overflow: "auto",
                  justifyContent: "space-between",
                }}
              >
                <div
                  className="hiding-pond-css"
                  style={{
                    display: "flex",
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    width: "100%",
                    backgroundColor: colors.slatery_blue,
                    justifyContent: "space-between",
                    padding: 8,
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 18,
                      color: "white",
                      fontWeight: "600",
                      marginLeft: 1,
                    }}
                  >
                    {isEdit ? t("ponds.edit_pond") : t("ponds.add_pond")}
                  </Typography>
                  <i
                    className="x icon"
                    style={{ color: "white", fontSize: 22 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (isDisable) {
                      } else {
                        setMarkers([]);
                        setShowPopup(false);
                        setIsEdit(false);
                        setName("");
                        setFarmName("");
                        setSize("");
                        setEnableImage(false);
                        setImgFile(null);
                        setEnableMap(true);
                        setSelectedSegment(0);
                        setIsOpenModal(false);
                        setIsNurserySelected(false);
                      }
                    }}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "auto",
                    overflow: "auto",
                    // height: enableMap ? "auto" : "650px",
                    // overflow: enableMap ? "none" : "auto",
                    // backgroundColor: "red",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <TextField
                      id="name"
                      label={t("ponds.pond_name")}
                      type="text"
                      variant="standard"
                      required
                      onChange={(e) => {
                        setName(e.target.value);
                        setShowError({ ...showError, isName: false });
                      }}
                      value={name}
                      sx={{
                        width: "95%",
                        fontSize: 18,
                        marginTop: 1.5,
                        marginLeft: 2,
                      }}
                    />
                    {showError.isName && (
                      <Alert severity="error">
                        {t("ponds.pond_name_error")}
                      </Alert>
                    )}
                    {sortValueFarm === "All Ponds" && (
                      <div className="mt-4 ms-3 me-3">
                        <Autocomplete
                          value={autoValue(farmName)}
                          onChange={(event, newValue) => {
                            setFarmName(newValue.name);
                          }}
                          options={farmItemsSearch}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Farm Name"
                              variant="outlined"
                            />
                          )}
                        />
                      </div>
                    )}
                    {showError.isFarmName && (
                      <Alert severity="error">{t("ponds.farmNameError")}</Alert>
                    )}
                    <TextField
                      id="size"
                      label={t("ponds.enterSizeInHectares")}
                      type="number"
                      variant="standard"
                      required
                      onChange={(e) => {
                        setSize(Number(e.target.value));
                        setShowError({ ...showError, isSizeErr: false });
                      }}
                      value={size}
                      sx={{
                        width: "95%",
                        fontSize: 18,
                        marginTop: 1.5,
                        marginLeft: 2,
                      }}
                    />
                    {showError.isSizeErr && (
                      <Alert severity="error">{t("ponds.add_size")}</Alert>
                    )}

                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        marginTop: 10,
                      }}
                    ></div>
                    <div style={{ marginLeft: 15 }}>
                      <input
                        disabled={isEdit}
                        type="checkbox"
                        key={Math.random()}
                        checked={isNurserySelected}
                        onChange={() => {
                          setIsNurserySelected(!isNurserySelected);
                        }}
                        style={{ marginRight: 10, cursor: "pointer" }}
                      />
                      <label
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (!isEdit) {
                            setIsNurserySelected(!isNurserySelected);
                          }
                        }}
                      >
                        {t("ponds.nursery")}
                      </label>
                    </div>
                    {/* MAP LOCATION */}
                    {selectedSegment === 0 && isLoaded && (
                      <div
                        style={{
                          marginTop: 5,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "95%",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              color: colors.slatery_blue,
                              width: "95%",
                              fontSize: 18,
                              fontWeight: "500",
                              textAlign: "left",
                            }}
                          >
                            {t("ponds.location")}
                          </Typography>
                          <Switch
                            aria-label="Switch"
                            checked={enableMap}
                            onClick={() => {
                              setEnableMap(!enableMap);
                            }}
                          />
                        </div>
                        {enableMap && (
                          <div style={{ width: "95%" }}>
                            <Typography
                              sx={{ textAlign: "left", marginBottom: 0.7 }}
                            >
                              {t(
                                "ponds.move_red_indicator_to_pinpoint_location"
                              )}
                            </Typography>
                            <div className="google-map-css">
                              <GoogleMap
                                mapContainerStyle={{
                                  height: 400,
                                  width: "100%",
                                }}
                                center={center}
                                zoom={15}
                                onClick={(e) => {
                                  setMap({
                                    lat: e.latLng.lat(),
                                    lng: e.latLng.lng(),
                                  });
                                }}
                                clickableIcons={false}
                                options={{
                                  styles: [
                                    {
                                      elementType: "labels",
                                      featureType: "poi",
                                      stylers: [{ visibility: "off" }],
                                    },
                                  ],
                                }}
                              >
                                <Marker
                                  position={map !== null ? map : center}
                                  draggable={true}
                                  onDragEnd={(e) => {
                                    setMap({
                                      lat: e.latLng.lat(),
                                      lng: e.latLng.lng(),
                                    });
                                  }}
                                ></Marker>
                              </GoogleMap>
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {/* IMAGE UPLOADER */}
                    {selectedSegment === 1 && enableImage && (
                      <div
                        style={{
                          marginTop: 5,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "95%",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: 10,
                          }}
                        >
                          <Typography
                            sx={{
                              color: colors.slatery_blue,
                              width: "95%",
                              fontSize: 18,
                              fontWeight: "500",
                              textAlign: "left",
                            }}
                          >
                            {t("ponds.attach_tag")} (
                            <span style={{ fontSize: 16 }}>
                              {t(
                                "ponds.click_on_image_to_tag_your_pond_location"
                              )}
                            </span>
                            )
                          </Typography>
                        </div>
                        {selectedSegment === 1 && enableImage && (
                          <div
                            style={{
                              display: "flex",
                              width: "95%",
                              justifyContent: "center",
                            }}
                          >
                            <ImageMarker
                              src={attachImgFile2}
                              markers={markers}
                              onAddMarker={(marker) => {
                                setMarkers((prev) => [marker]);
                              }}
                              markerComponent={CustomMarker}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: 10,
                      marginTop: 10,
                    }}
                  >
                    <LoadingButton
                      onClick={onClickAdd}
                      loading={isDisable}
                      loadingPosition="start"
                      startIcon={<Save />}
                      variant="contained"
                      sx={{ backgroundColor: colors.slatery_blue }}
                    >
                      {t("ponds.save")}
                    </LoadingButton>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </Box>
          </Modal>

          {/* ----- DELETE MODAL ----- */}
          <Modal
            open={deleteModal}
            onClose={() => setDeleteModal(false)}
            // onClose={deleteModalclose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={[boxStyle, { width: 400 }]}
              className="logout-css-modal-block"
            >
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{
                  paddingTop: 2,
                  textAlign: "center",
                  marginBottom: 2,
                  paddingLeft: 1,
                  paddingRight: 1,
                }}
              >
                {t("ponds.are_you_sure_you_want_to_delete")}&nbsp;"
                {deleteData ? deleteData.pondName : ""}"
              </Typography>

              <Divider />
              <div className="logout-button-block">
                <Typography
                  sx={textStyle}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (isDisable) {
                    } else {
                      setDeleteData(null);
                      setDeleteModal(false);
                    }
                  }}
                >
                  {t("ponds.cancel")}
                </Typography>
                <div
                  style={{
                    width: "2px",
                    border: "1px solid #999",
                    opacity: 0.5,
                  }}
                />
                <LoadingButton
                  loading={deleteLoader}
                  loadingPosition="start"
                  startIcon={deleteLoader ? <SaveIcon /> : ""}
                  className="delete_bg_color"
                  sx={[
                    textStyle,
                    {
                      color: "red",
                      fontSize: 16,
                      textTransform: "capitalize",
                      padding: "4px 16px",
                    },
                  ]}
                  onClick={async (e) => {
                    e.stopPropagation();
                    if (isDisable) {
                    } else {
                      try {
                        setDeleteLoader(true);
                        setIsDisable(true);
                        await deletePond(deleteData);
                        let arr = ponds.filter((val) => {
                          return val.id !== deleteData.id;
                        });
                        setItems(arr);
                        setPonds(arr);
                        setDeleteModal(false);
                        setDeleteData(null);
                        setIsDisable(false);
                        setDeleteLoader(false);
                      } catch (error) {
                        setIsDisable(false);
                      }
                    }
                  }}
                >
                  {t("ponds.delete")}
                </LoadingButton>
                {/* <Typography
                  className="delete_bg_color"
                  sx={[textStyle, { color: "red", cursor: "pointer" }]}
                  onClick={async (e) => {
                    e.stopPropagation();
                    if (isDisable) {
                    } else {
                      try {
                        setIsDisable(true);
                        await deletePond(deleteData);
                        let arr = items.filter((val) => {
                          return val.id !== deleteData.id;
                        });
                        setItems(arr);
                        setDeleteModal(false);
                        setDeleteData(null);
                        setIsDisable(false);
                      } catch (error) {

                        setIsDisable(false);
                      }
                    }
                  }}
                >
                  {t("ponds.delete")}
                </Typography> */}
              </div>
            </Box>
          </Modal>
        </div>
      </ResponsiveDrawer>
    </div>
  );
}

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 375 ? 400 : 270,
  width: 550,
  bgcolor: "background.paper",
  border: "2px solid",
  borderColor: "gray",
  borderRadius: 3,
  boxShadow: 24,
  maxHeight: "90vh",
  overflowY: "auto"
};

const textStyle = {
  color: colors.slatery_blue,
  fontSize: 17,
  textAlign: "center", 
  width: "100%",
  fontWeight: "600",
  cursor: "pointer",
};

export default Ponds;

const SortingArrows = ({ sort_by, sort_asc, currentKey }) => {
  if (sort_by === currentKey) {
    if (sort_asc) {
      return <ArrowDownwardIcon className="sort_icon" />
    } else {
      return <ArrowUpwardIcon className="sort_icon" />
    }
  }
}