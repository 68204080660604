import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
  Modal,
  InputBase,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import colors from "../../../common/colors";
import ResponsiveDrawer from "../../../common/ResponsiveDrawer";
import DetailCard from "../../../components/ponds/DetailCard";
import {
  getExceededOrNot,
  getPondCycle,
  getShrimpWeightAllCycle,
  getWeeklyAverage,
  addWaterQuality,
  getWaterQuality,
  fetchTotalRecordsOfAssociatedCycles,
  getCountingRecords,
} from "../../../database/user/pond";
import "../../../style/user/Ponds.css";
import "../../../style/loader.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import "react-image-lightbox/style.css";
import health_yellow from "../../../images/health_yellow.png";
import health from "../../../images/health_icon.png";
import health_green from "../../../images/health_green.png";
import records from "../../../images/Number_records.png";
import Number_users from "../../../images/Number_users.png";
import cycle_image from "../../../images/cycle.png";
import heart_rate from "../../../images/heart_rate.png";
import Abnormal_biomass_pond from "../../../images/Abnormal_biomass_pond.png";
import Water_parameters from "../../../images/Water_parameters.png";
import viewMoreBoxIcon from "../../../images/viewMoreBoxIcon.png";
import { Success } from "../../../common/CustomToast";
import {
  getCalculateDaysDifference,
  getTimestampWithFormatDate,
} from "../../../common/utils";

ChartJS.register(
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler
);

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "8px 26px 8px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

function PondDetail() {
  const navigation = useNavigate();
  const { t } = useTranslation();
  const objPond = window?.history?.state?.usr?.cardData;
  const [cycleList, setCycleList] = useState([]);
  const [sortList, setSortList] = useState([]);
  console.log("sortList: 100", sortList);
  const [sortValue, setSortValue] = useState("");
  console.log("sortValue: ", sortValue);
  const [sortData, setSortData] = useState(null);
  console.log("sortData: ", sortData);
  const [isLoader, setIsLoader] = useState(true);
  // const [isRecordLoader, setIsRecordLoader] = useState(false);
  const [averageSize, setAverageSize] = useState(0);
  const [isSurvivalRateLoading, setIsSurvivalRateLoading] = useState(false);
  const [currentCycleTotalCount, setCurrentCycleTotalCount] = useState(0);
  const [isValidForSurvivalRate, setIsValidForSurvivalRate] = useState(false);
  const [labelDataset, setLabelDataset] = useState([]);
  const [shrimpWeightData, setShrimpWeightData] = useState([]);
  const [noOfBiomass, setNoOfBiomass] = useState(0);
  const [waterParam, setNoOfWaterParam] = useState(0);
  const [isImageModal, setIsImageModal] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [isUpdateBiomass, setIsUpdateBiomass] = useState(false);
  const [isWaterQualityModal, setIsWaterQualityModal] = useState(false);
  const [addInput, setAddInput] = useState({
    phMinimum: "",
    phMaximum: "",
    salinityMinimum: "",
    salinityMaximum: "",
    dissolvedOxygenMinimum: "",
    dissolvedOxygenMaximum: "",
    nitriteMinimum: "",
    nitriteMaximum: "",
    ammoniaMinimum: "",
    ammoniaMaximum: "",
    temperatureMinimum: "",
    temperatureMaximum: "",
    turbidityMinimum: "",
    turbidityMaximum: "",
  });
  const { state } = useLocation();
  console.log('state:1234', state?.cycleName);

  // const [errors, setErrors] = useState({
  //   phMinimum: "",
  //   phMaximum: "",
  //   salinityMinimum: "",
  //   salinityMaximum: "",
  //   dissolvedOxygenMinimum: "",
  //   dissolvedOxygenMaximum: "",
  //   nitriteMinimum: "",
  //   nitriteMaximum: "",
  //   ammoniaMinimum: "",
  //   ammoniaMaximum: "",
  //   temperatureMinimum: "",
  //   temperatureMaximum: "",
  //   turbidityMinimum: "",
  //   turbidityMaximum: "",
  // });

  const [isDisable, setIsDisable] = useState(false);
  const [isExceeded, setIsExceeded] = useState(false);
  const [isNurseryPond, setNurseryPond] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0); // SHOW THIS IF POND IS GROW-OUT, since we need the associated nursery pond's data, we need to fetch all cycles total records

  useEffect(() => {
    // async function getTotalRecordsIfGrowOutPond(pondCycleId) {
    //   setIsRecordLoader(true);
    //   const data = await fetchTotalRecordsOfAssociatedCycles(pondCycleId);
    //   setIsRecordLoader(false);
    //   console.log("dataaaa",data);
    //   let count = 0;
    //   if(data && data.length > 0){
    //     for (const element of data) {
    //       // console.log("records",element.records);
    //       if(element.records && element.records>0){
    //         count = Number(count) + Number(element.records)
    //       }
    //     }
    //     // console.log("count",count);
    //     setTotalRecords(count)
    //   }
    // }
    // const isNursery = window.history.state.usr.cardData && window.history.state.usr.cardData.isNursery ? window.history.state.usr.cardData.isNursery : false
    // setNurseryPond(isNursery)
    // if (sortData && sortData.id && !isNursery) {
    //   getTotalRecordsIfGrowOutPond(sortData.id);
    // }
  }, [sortData, window.history.state.usr.cardData]);

  // const validateForm = () => {
  //   let newErrors = {};
  //   if (!addInput.phMinimum) {
  //     newErrors.phMinimum = t("ponds.ph_minimum");
  //   } else if (!addInput.phMaximum) {
  //     newErrors.phMaximum = t("ponds.ph_maximum");
  //   } else if (!addInput.salinityMinimum) {
  //     newErrors.salinityMinimum = t("ponds.salinity_minimum");
  //   }
  //   else if (!addInput.salinityMaximum) {
  //     newErrors.salinityMaximum = t("ponds.salinity_maximum");
  //   }
  //   else if (!addInput.dissolvedOxygenMinimum) {
  //     newErrors.dissolvedOxygenMinimum = t("ponds.dissolved_oxygen_minimum");
  //   }
  //   else if (!addInput.dissolvedOxygenMaximum) {
  //     newErrors.dissolvedOxygenMaximum = t("ponds.dissolved_oxygen_maximum");
  //   }
  //   else if (!addInput.nitriteMinimum) {
  //     newErrors.nitriteMinimum = t("ponds.nitrite_minimum");
  //   }
  //   else if (!addInput.nitriteMaximum) {
  //     newErrors.nitriteMaximum = t("ponds.nitrite_maximum");
  //   }
  //   else if (!addInput.ammoniaMinimum) {
  //     newErrors.ammoniaMinimum = t("ponds.ammonia_minimum");
  //   }
  //   else if (!addInput.ammoniaMaximum) {
  //     newErrors.ammoniaMaximum = t("ponds.ammonia_maximum");
  //   }
  //   else if (!addInput.temperatureMinimum) {
  //     newErrors.temperatureMinimum = t("ponds.temperature_minimum");
  //   }
  //   else if (!addInput.temperatureMaximum) {
  //     newErrors.temperatureMaximum = t("ponds.temperature_maximum");
  //   }
  //   else if (!addInput.turbidityMinimum) {
  //     newErrors.turbidityMinimum = t("ponds.turbidity_minimum");
  //   }
  //   else if (!addInput.turbidityMaximum) {
  //     newErrors.turbidityMaximum = t("ponds.turbidity_maximum");
  //   }
  //   setErrors(newErrors);
  //   return Object.values(newErrors).every((error) => !error);
  // };

  const onInputChange = (e) => {
    setAddInput({
      ...addInput,
      [e.target.name]: e.target.value,
    });
    // setErrors({
    //   ...errors,
    //   [e.target.name]: ''
    // });
  };

  const addWaterQualityDB = async () => {
    let data = {
      phMinimum: addInput.phMinimum,
      phMaximum: addInput.phMaximum,
      salinityMinimum: addInput.salinityMinimum,
      salinityMaximum: addInput.salinityMaximum,
      dissolvedOxygenMinimum: addInput.dissolvedOxygenMinimum,
      dissolvedOxygenMaximum: addInput.dissolvedOxygenMaximum,
      nitriteMinimum: addInput.nitriteMinimum,
      nitriteMaximum: addInput.nitriteMaximum,
      ammoniaMinimum: addInput.ammoniaMinimum,
      ammoniaMaximum: addInput.ammoniaMaximum,
      temperatureMinimum: addInput.temperatureMinimum,
      temperatureMaximum: addInput.temperatureMaximum,
      turbidityMinimum: addInput.turbidityMinimum,
      turbidityMaximum: addInput.turbidityMaximum,
    };

    const addId = await addWaterQuality(
      data,
      window.history.state.usr.cardData.id
    );
    // renderWaterQuality();
    await checkExceededOrNot(sortValue);
  };

  const onClickWaterQuality = (e) => {
    e.preventDefault();
    setIsDisable(true);

    const {
      phMinimum,
      phMaximum,
      salinityMinimum,
      salinityMaximum,
      dissolvedOxygenMinimum,
      dissolvedOxygenMaximum,
      nitriteMinimum,
      nitriteMaximum,
      ammoniaMinimum,
      ammoniaMaximum,
      temperatureMinimum,
      temperatureMaximum,
      turbidityMinimum,
      turbidityMaximum,
    } = addInput;
    setIsWaterQualityModal(false);
    addWaterQualityDB();
    // setErrors({
    //   phMinimum: "",
    //   phMaximum: "",
    //   salinityMinimum: "",
    //   salinityMaximum: "",
    //   dissolvedOxygenMinimum: "",
    //   dissolvedOxygenMaximum: "",
    //   nitriteMinimum: "",
    //   nitriteMaximum: "",
    //   ammoniaMinimum: "",
    //   ammoniaMaximum: "",
    //   temperatureMinimum: "",
    //   temperatureMaximum: "",
    //   turbidityMinimum: "",
    //   turbidityMaximum: "",
    // })

    // if (validateForm()) {
    // }
  };

  useEffect(() => {
    getCycleData();
  }, []);
  const getCycleData = async () => {
    setIsLoader(true);
    try {
      const arrList = await getPondCycle(window.history.state.usr.cardData.id);
      if (!arrList || arrList.length <= 0) {
        setIsLoader(false);
        return;
      }
      arrList.sort(function (a, b) {
        return b.cycleNo - a.cycleNo;
      });
      setCycleList(arrList);
      let data = [];
      if (arrList.length > 0) {
        for (let i = 0; i < arrList.length; i++) {
          if (arrList[i].status === "Active") {
            setIsUpdateBiomass(true);
            data.push(arrList[i]);
          }
        }
        for (let i = 0; i < arrList.length; i++) {
          if (arrList[i].status !== "Active") {
            data.push(arrList[i]);
          }
        }
      }
      if (data.length > 0) {
        setSortList(data);
        const recentClose = data.reduce((latest, current) => {
          return current.endDate > (latest.endDate || 0) ? current : latest;
        }, {});
        console.log("latestEndDate: ", recentClose);
        setSortData(data[0]);
        // const totalaverage = await getWeeklyAverage(data[0].id)
        // setAverageSize(totalaverage);
        setNoOfBiomass(data[0].biomass ? data[0].biomass : 0);
        setNoOfWaterParam(data[0].waterParam ? data[0].waterParam : 0);
        setSortValue(state?.cycleId ? state?.cycleId : data[0].id);
        setIsLoader(false);
        // await getRecordsData(data[0].id);
        await checkExceededOrNot(data[0].id);
      }
    } catch (error) {
      setIsLoader(false);
    }
  };

  // const getRecordsData = async (cycleId) => {
  //   try {
  //     if (cycleId) {
  //       let shrimpWeightRateData = await getShrimpWeightAllCycle(cycleId);
  //       let forXAxis = [];
  //       let forYAxis = [];
  //       if (shrimpWeightRateData.length > 0) {
  //         for (let i = 0; i < shrimpWeightRateData.length; i++) {
  //           forXAxis.push(
  //             getTimestampWithFormatDate(shrimpWeightRateData[i].id, "DD MMM")
  //           );
  //           forYAxis.push(
  //             shrimpWeightRateData[i].weight /
  //               shrimpWeightRateData[i].noOfRecord
  //           );
  //         }
  //       }
  //       setLabelDataset(forXAxis);
  //       setShrimpWeightData(forYAxis);
  //     }
  //   } catch (error) {
  //     console.log("error: ", error);
  //   }
  // };

  const checkExceededOrNot = async (cycleId) => {
    try {
      if (cycleId) {
        let isExceeded = await getExceededOrNot(cycleId);
        setIsExceeded(isExceeded);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const graphOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: t("ponds.chart_for_shrimp_growth_rates"),
      },
    },
  };

  const options_Line = {
    responsive: true,
    plugins: {
      legend: {
        // display: false,
        position: "top",
        labels: {
          padding: 10,
        },
      },
      title: {
        display: true,
        text: t("ponds.chart_for_shrimp_growth_rates"),
        font: {
          size: 18,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          barPercentage: 0.5,
          borderColor: "#fff",
        },
        ticks: {
          font: {
            size: 16,
            weight: 500,
            color: "red",
          },
        },
        gridLines: {
          borderDash: [8, 4],
          color: "#348632",
        },
      },
      y: {
        grid: {
          borderColor: "#fff",
          borderDash: [4, 7],
          color: "#bebebe",
        },
        ticks: {
          precision: 1,
          callback: (label) => `${label} GM`,
          font: {
            size: 16,
            weight: 500,
            color: "red",
          },
        },
      },
    },
  };
  // const lineData = {
  //   labels: labelDataset,
  //   datasets: [
  //     {
  //       label: t("ponds.average_growth"),
  //       data: shrimpWeightData,
  //       fill: "start",
  //       backgroundColor: (context: ScriptableContext<"line">) => {
  //         const ctx = context.chart.ctx;
  //         const gradient = ctx.createLinearGradient(25, 0, 0, 300);
  //         gradient.addColorStop(0, "rgb(238 124 67 / 30%)");
  //         gradient.addColorStop(1, "rgba(238, 124 ,67,0)");
  //         return gradient;
  //       },
  //       borderColor: "rgb(238 124 67)",
  //       borderWidth: 1,
  //       pointRadius: 4,
  //       tension: 0.4,
  //       responsive: true,
  //     },
  //   ],
  // };

  const handleCardClick = () => {
    Success(t("ponds.comingSoon"), false);
  };

  const handleRecordsClick = () => {
    if (sortData?.stockingAmount) {
      navigation("/countingReports", {
        state: {
          pondData: window.history.state.usr.cardData,
          cycleData: sortData  
        },
      });
    } else {
      // Error(`${t("ponds.please_enter_valid_amount_for_stocking")}`)
    }
  };
  async function getReport() {
    setIsSurvivalRateLoading(true);
    const reportsLocal = await getCountingRecords(sortData?.id);
    console.log('reportsLocal: ', reportsLocal);
    const isAllow = reportsLocal.find((obj) => obj.deviceId)
    const sumCountForNullToPondId = reportsLocal
      .filter(item => item.toPondId === null || item.toPondId === "")
      .reduce((acc, item) => acc + (item.count || 0), 0);
    setCurrentCycleTotalCount(sumCountForNullToPondId)
    setIsValidForSurvivalRate(isAllow ? true : false)
    setIsSurvivalRateLoading(false)
  }
  useEffect(() => {
    if (sortData?.id) {
      getReport();
    }
  }, [sortData?.id]);

  const getSurvivalRateCount = () => {
    const initialNumberOfShrimps = currentCycleTotalCount
    const noOfShrimpLost = objPond?.stockingAmountCycle?parseInt(objPond?.stockingAmountCycle?.replace(/,/g, ''), 10):0;
    // const noOfShrimpLost = parseInt(objPond.stockingAmountCycle.replace(/,/g, ''), 10)
    const shrimpThatSurvived = initialNumberOfShrimps - noOfShrimpLost
    const survivalRate = (shrimpThatSurvived / initialNumberOfShrimps) * 100
    return sortData?.status === "Active" || !isValidForSurvivalRate ? 'N/A' : survivalRate.toFixed(1) + '%'
  }

  useEffect(() => {
    handleSelectData(sortValue);
  },[sortValue])

  const handleSelectData = async (id) => {
    let data = sortList?.filter((ele) => {
      if (
        ele.id === id &&
        ele.status === "Active"
      ) {
        setIsUpdateBiomass(true);
      }
      return ele.id === id;
    });
    await checkExceededOrNot(data[0]?.id);
    setSortData(data[0]);
    setNoOfBiomass(
      data[0]?.biomass ? data[0]?.biomass : 0
    );
    setNoOfWaterParam(
      data[0]?.waterParam ? data[0]?.waterParam : 0
    )
  }

  return (
    <ResponsiveDrawer
      isShowAppBar={true}
      // headText={`${t("home.pond")}: ${
      //   window.history.state.usr.cardData.pondName
      // }`}
      headText={
        localStorage.getItem("lang")
          ? localStorage.getItem("lang") === "en"
            ? `Pond: ${window.history.state.usr.cardData.pondName}`
            : `Estanque: ${window.history.state.usr.cardData.pondName}`
          : `Pond: ${window.history.state.usr.cardData.pondName}`
      }
      isHideToolBar={false}
      isBackArrow={true}
      onClickBack={() => {
        navigation("/ponds", {
          state: {
            farm: window.history.state.usr.sortValueFarm,
          },
        });
      }}
    >
      {isLoader ? (
        <div
          style={{
            display: "flex",
            height: "90vh",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div id={`load`} className="loader" />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // marginBottom: 30,
            marginTop: 5,
          }}
        >
          {/* ----- HEAD SEARCHES ----- */}
          <div className="head_container head-css-block hedera_ui_css_block">
            <div className="hedera_flex_block_row">
              {sortList.length > 0 && (
                <div className="head_two select-main-block ms-0">
                  <Box sx={{ minWidth: 260 }}>
                    <Typography>{t("ponds.sort_by_harvest_cycle")}</Typography>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={sortValue}
                        label="Age"
                        onChange={async (e) => {
                          try {
                            setSortValue(e.target.value);
                            setIsUpdateBiomass(false);
                            // await getRecordsData(data[0].id);
                            setIsLoader(true);
                            await handleSelectData(e?.target?.value);
                            setIsLoader(false);
                          } catch (error) {}
                        }}
                        input={<BootstrapInput />}
                      >
                        {sortList.map((val) => {
                          return (
                            <MenuItem key={Math.random()} value={val.id}>
                              {val.cycleName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </div>
              )}
            </div>
            <div className="head_one">
              <div
                style={{ display: "flex", width: "100%", alignItems: "center" }}
                className="report-button-css-block"
              >
                <button
                  className="ui primary button"
                  style={{ width: "auto", height: 40 }}
                  onClick={() => {
                    navigation("/pondCycle", {
                      state: {
                        cardData: window.history.state.usr.cardData,
                        cycleData: cycleList,
                      },
                    });
                  }}
                >
                  {t("ponds.manage_cycle")}
                </button>
              </div>
            </div>
          </div>
          <div className="column-block-css">
            {/* ----- CARDS ONE ----- */}
            <div className="ui stackable three column grid">
              <div className="green_box column css-hover-block disabled_card">
                <DetailCard
                  heading={t("ponds.shrimp_measurement")}
                  color={colors.mid_blue}
                  isPond={true}
                  isRecord={true}
                  card={health_green}
                  // onClickRecords={() => {
                  //   // navigation("/measurementReports", {
                  //   //   state: {
                  //   //     pondData: window.history.state.usr.cardData,
                  //   //     cycleData: sortData,
                  //   //   },
                  //   // });
                  //   Success(t("ponds.comingSoon"), false);
                  // }}
                  onClickCard={handleCardClick}
                />
              </div>
              {/* <div className="column azure_box  css-hover-block">
                <DetailCard
                  card={records}
                  heading={t("ponds.records")}
                  count={
                    isNurseryPond ? sortData ? (sortData.records ? sortData.records : 0) : 0 : totalRecords
                  }
                  color={colors.mid_blue}
                  isRecord={true}
                  onClickRecords={() => {
                    navigation("/pond_record", {
                      state: {
                        cardData: window.history.state.usr.cardData,
                        cycleId: sortData ? sortData.id : null,
                        cycleName: sortData ? sortData.cycleName : null,
                      },
                    });
                  }}
                  isRecordLoader={isRecordLoader}
                />
              </div> */}
              <div
                className={`${
                  sortData?.stockingAmount
                    ? "column yellow_box  css-hover-block"
                    : "green_box column css-hover-block disabled_card"
                }`}
              >
                <DetailCard
                  card={Number_users}
                  heading={t("ponds.biomass")}
                  color={colors.mid_blue}
                  isRecord={true}
                  count={
                    sortData
                      ? sortData.stockingAmount
                        ? sortData.stockingAmount
                        : 0
                      : 0
                  }
                  // count={
                  //   window.history.state.usr.cardData.stockingAmount
                  //     ? window.history.state.usr.cardData.stockingAmount
                  //     : 0
                  // }
                  onClickUpdateBiomass={handleRecordsClick}
                  isPrefix={true}
                  isUpdate={sortData && sortData.stockingAmount ? true : false}

                />
              </div>
              <div className={sortData?.status === "Active" ? "column green_box  css-hover-block" : "column green_box css-hover-block disabled_card"}>
                <DetailCard
                  card={cycle_image}
                  heading={t("ponds.cycle")}
                  color={colors.mid_blue}
                  isRecord={false}
                  count={
                    sortData
                      ? `${t("ponds.days")}:${sortData.status === "Active" 
                          ? getCalculateDaysDifference(sortData.startDate) 
                          : getCalculateDaysDifference(sortData.startDate, sortData.endDate)
                        }`
                      :0
                  }
                // count={
                //   window.history.state.usr.cardData.stockingAmount
                //     ? window.history.state.usr.cardData.stockingAmount
                //     : 0
                // }
                // onClickRecords={() => {
                //   navigation("/countingReports", {
                //     state: {
                //       pondData: window.history.state.usr.cardData,
                //       cycleData: sortData,
                //     },
                //   });
                // }}
                />
              </div>
              {objPond?.isNursery &&
                <div className={sortData?.status !== "Active" && isValidForSurvivalRate ? "column green_box  css-hover-block" : "column green_box css-hover-block disabled_card"}>
                  <DetailCard
                    card={heart_rate}
                    heading={t("ReportPond.survival_rate")}
                    color={colors.mid_blue}
                    isRecord={false}
                    count={currentCycleTotalCount ? getSurvivalRateCount() : '-'}
                    isRecordLoader={isSurvivalRateLoading}
                  />
                </div>
              }
            </div>

            {/* ----- CARDS TWO ----- */}
            <div className="ui stackable two column grid pond-details-row">
              {/* <div className="column blue_box css-hover-block">
              <DetailCard
                heading={t("ponds.weekly_average")}
                count={`${averageSize}g`}
                color={colors.mid_blue}
                card={Average_size}
              />
            </div> */}
              {/* <div className="view_more_box">
                <div className="line_chart_main  css-hover-block">
                  <span>
                    <img src={viewMoreBoxIcon} alt="viewMoreBoxIcon" />
                  </span>
                  <h5>{t("ponds.shrimp_images")}</h5>
                  <div
                    className="view_more_btn"
                    onClick={() => {
                      navigation("/image_record", {
                        state: {
                          cardData: window.history.state.usr.cardData,
                          cycleId: sortValue,
                          sortList: sortList,
                        },
                      });
                    }}
                  >
                    <button>{t("ponds.view_images")}</button>
                  </div>
                </div>
              </div> */}
              {/* <div className="column pink_box css-hover-block">
                <DetailCard
                  card={Abnormal_biomass_pond}
                  heading={t("home.abnormal_biomass")}
                  count={
                    sortData
                      ? sortData.abnormalBiomass
                        ? sortData.abnormalBiomass
                        : "0"
                      : "0"
                  }
                  color={colors.red}
                  isClickable={true}
                  onClickUpdateBiomass={async () => {
                    try {
                      setIsLoader(true);
                      let data = cycleList;
                      if (cycleList.length > 0) {
                        data.sort((a, b) => {
                          return b.cycleNo - a.cycleNo;
                        });
                      }
                      setIsLoader(false);
                      navigation("/alarm", {
                        state: {
                          pond: window.history.state.usr.cardData,
                          cycle: data,
                          selectedCycle: sortData,
                        },
                      });
                    } catch (error) {
                      console.log("error: ", error);
                    }
                  }}
                />
              </div> */}
              {/* <div className="column red_box css-hover-block Water_parameters_box">
                <DetailCard
                  card={Water_parameters}
                  heading={t("home.water_parameters")}
                  count={waterParam}
                  // color={colors.mid_blue}
                  isClickable={true}
                  onClickUpdateBiomass={() => {
                    navigation("/parameters_records", {
                      state: {
                        cardData: window.history.state.usr.cardData,
                        cycleData: sortData,
                      },
                    });
                  }}
                />
              </div> */}
              {/* <div
                className={`column ${
                  isExceeded ? "red_box" : "azure_box"
                } css-hover-block Water_parameters_box`}
              >
                <DetailCard
                  card={health_green}
                  heading={t("ponds.water_quality_condition")}
                  count={
                    isExceeded ? t("home.threshold_exceeded") : t("ponds.good")
                  }
                  // color={colors.mid_blue}
                  isWaterQuality={true}
                  onClickUpdateBiomass={async () => {
                    setIsWaterQualityModal(true);
                    let data = await getWaterQuality(
                      window.history.state.usr.cardData.id
                    );
                    setAddInput({
                      phMinimum: data.phMinimum,
                      phMaximum: data.phMaximum,
                      salinityMinimum: data.salinityMinimum,
                      salinityMaximum: data.salinityMaximum,
                      dissolvedOxygenMinimum: data.dissolvedOxygenMinimum,
                      dissolvedOxygenMaximum: data.dissolvedOxygenMaximum,
                      nitriteMinimum: data.nitriteMinimum,
                      nitriteMaximum: data.nitriteMaximum,
                      ammoniaMinimum: data.ammoniaMinimum,
                      ammoniaMaximum: data.ammoniaMaximum,
                      temperatureMinimum: data.temperatureMinimum,
                      temperatureMaximum: data.temperatureMaximum,
                      turbidityMinimum: data.turbidityMinimum,
                      turbidityMaximum: data.turbidityMaximum,
                    });
                    // navigation("/parameters_records", {
                    //   state: {
                    //     cardData: window.history.state.usr.cardData,
                    //     cycleData: sortData,
                    //   },
                    // })
                  }}
                />
              </div> */}
            </div>
          </div>
          {/* <div className="view_more_row view_more_chart">
            {labelDataset.length > 0 && shrimpWeightData.length > 0 && (
              <div className="line_chart_main_block">
                <div className="line_chart_main">
                  <div className="chart_block">
                    <Line
                      data={lineData}
                      options={options_Line}
                      id="chart"
                    ></Line>
                  </div>
                </div>
              </div>
            )}
          </div> */}
          {/* ------ SHOW RECORD MODAL ------ */}
          <Modal
            open={isImageModal}
            onClose={() => setIsImageModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={boxStyle}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  backgroundColor: colors.slatery_blue,
                  justifyContent: "space-between",
                  padding: 8,
                }}
              >
                <Typography
                  sx={{ fontSize: 18, color: "white", fontWeight: "600" }}
                >
                  {t("ponds.record")}
                </Typography>
                <i
                  className="x icon"
                  style={{ color: "white", fontSize: 22 }}
                  onClick={() => {
                    setImageData(null);
                    setIsImageModal(false);
                  }}
                />
              </div>

              <div style={{ padding: 10 }}>
                <div style={{ display: "flex", width: "100%", marginTop: 5 }}>
                  <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
                    {t("home.pond")}:&nbsp;
                    <span style={{ fontSize: 16, fontWeight: "500" }}>
                      {imageData && imageData.pondName}
                    </span>
                  </Typography>
                </div>

                <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
                  <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
                    {t("ponds.cycle")}:&nbsp;
                    <span style={{ fontSize: 16, fontWeight: "500" }}>
                      {imageData && imageData.cycleNo}
                    </span>
                  </Typography>
                </div>

                <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
                  <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
                    {t("alarm.shrimp_health")}:&nbsp;
                    <span style={{ fontSize: 16, fontWeight: "500" }}>
                      {imageData
                        ? imageData.shrimpSickStatus === "Yes"
                          ? t("ponds.bad")
                          : imageData.shrimpSickStatus === "No"
                          ? t("ponds.good")
                          : imageData.shrimpSickStatus
                        : ""}
                    </span>
                  </Typography>
                </div>

                <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
                  <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
                    {t("ponds.shrimp_weight")}:&nbsp;
                    <span style={{ fontSize: 16, fontWeight: "500" }}>
                      {imageData && imageData.shrimpWeight}
                    </span>
                  </Typography>
                </div>

                <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
                  <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
                    {t("home.description")}:&nbsp;
                    <span style={{ fontSize: 16, fontWeight: "500" }}>
                      {imageData && imageData.comment}
                    </span>
                  </Typography>
                </div>

                <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
                  <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
                    {t("home.type")}:&nbsp;
                  </Typography>
                  <Typography sx={{ fontSize: 16, fontWeight: "500" }}>
                    {imageData && imageData.type}
                  </Typography>
                </div>

                <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
                  <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
                    {t("home.reported_by")}:&nbsp;{" "}
                    <span style={{ fontSize: 16, fontWeight: "500" }}>
                      {imageData &&
                        (imageData.reportedUser
                          ? imageData.reportedUser
                          : imageData.reportedBy)}
                    </span>
                  </Typography>
                </div>
              </div>
            </Box>
          </Modal>

          {/* ------ Water Quality Condition MODAL ------ */}
          <Modal
            open={isWaterQualityModal}
            onClose={() => setIsWaterQualityModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={boxStyle} style={{ borderRadius: "12px", width: 750 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "auto",
                  maxHeight: "750px",
                  // height: "640px",
                  // overflow: "auto",
                  justifyContent: "space-between",
                  borderRadius: "10px",
                }}
              >
                <div
                  className="hiding-pond-css"
                  style={{
                    display: "flex",
                    width: "100%",
                    backgroundColor: "#1e274c",
                    justifyContent: "space-between",
                    padding: 8,
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 18,
                      color: "white",
                      fontWeight: "600",
                      marginLeft: 1,
                    }}
                  >
                    {t("ponds.set_threshold")}
                  </Typography>
                  <i
                    className="x icon"
                    style={{ color: "white", fontSize: 22, cursor: "pointer" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsWaterQualityModal(false);
                      // setErrors({
                      //   phMinimum: "",
                      //   phMaximum: "",
                      //   salinityMinimum: "",
                      //   salinityMaximum: "",
                      //   dissolvedOxygenMinimum: "",
                      //   dissolvedOxygenMaximum: "",
                      //   nitriteMinimum: "",
                      //   nitriteMaximum: "",
                      //   ammoniaMinimum: "",
                      //   ammoniaMaximum: "",
                      //   temperatureMinimum: "",
                      //   temperatureMaximum: "",
                      //   turbidityMinimum: "",
                      //   turbidityMaximum: "",
                      // })
                    }}
                  />
                </div>
                <form>
                  <div className="logout-css-modal-block">
                    <div className="input_row_12">
                      <div className="add_parameters_input">
                        <label>{t("ponds.ph_minimum")}</label>
                        <input
                          type="number"
                          placeholder={
                            t("ponds.enter") + " " + t("ponds.ph_minimum")
                          }
                          name="phMinimum"
                          value={addInput.phMinimum}
                          onChange={onInputChange}
                        />
                        {/* {errors.phMinimum && (
                        <div className="ui pointing red basic label">
                          {t("ponds.please_fill_the") + " " + t(errors.phMinimum) + " " + t("ponds.field")}
                        </div>
                      )} */}
                      </div>
                      <div className="add_parameters_input">
                        <label>{t("ponds.ph_maximum")}</label>
                        <input
                          type="number"
                          placeholder={
                            t("ponds.enter") + " " + t("ponds.ph_maximum")
                          }
                          name="phMaximum"
                          value={addInput.phMaximum}
                          onChange={onInputChange}
                        />
                        {/* {errors.phMaximum && (
                        <div className="ui pointing red basic label">
                          {t("ponds.please_fill_the") + " " + t(errors.phMaximum) + " " + t("ponds.field")}
                        </div>
                      )} */}
                      </div>
                    </div>
                    <div className="input_row_12">
                      <div className="add_parameters_input">
                        <label>{t("ponds.salinity_minimum")} (%)</label>
                        <input
                          type="number"
                          placeholder={
                            t("ponds.enter") +
                            " " +
                            t("ponds.salinity_minimum") +
                            " " +
                            "(%)"
                          }
                          name="salinityMinimum"
                          value={addInput.salinityMinimum}
                          onChange={onInputChange}
                        />
                        {/* {errors.salinityMinimum && (
                        <div className="ui pointing red basic label">
                          {t("ponds.please_fill_the") + " " + t(errors.salinityMinimum) + " " + t("ponds.field")}
                        </div>
                      )} */}
                      </div>
                      <div className="add_parameters_input">
                        <label>{t("ponds.salinity_maximum")} (%)</label>
                        <input
                          type="number"
                          placeholder={
                            t("ponds.enter") +
                            " " +
                            t("ponds.salinity_maximum") +
                            " " +
                            "(%)"
                          }
                          name="salinityMaximum"
                          value={addInput.salinityMaximum}
                          onChange={onInputChange}
                        />
                        {/* {errors.salinityMaximum && (
                        <div className="ui pointing red basic label">
                          {t("ponds.please_fill_the") + " " + t(errors.salinityMaximum) + " " + t("ponds.field")}
                        </div>
                      )} */}
                      </div>
                    </div>
                    <div className="input_row_12">
                      <div className="add_parameters_input">
                        <label>
                          {t("ponds.dissolved_oxygen_minimum")} (mg/l)
                        </label>
                        <input
                          type="number"
                          placeholder={
                            t("ponds.enter") +
                            " " +
                            t("ponds.dissolved_oxygen_minimum") +
                            " " +
                            "(mg/l)"
                          }
                          name="dissolvedOxygenMinimum"
                          value={addInput.dissolvedOxygenMinimum}
                          onChange={onInputChange}
                        />
                        {/* {errors.dissolvedOxygenMinimum && (
                        <div className="ui pointing red basic label">
                          {t("ponds.please_fill_the") + " " + t(errors.dissolvedOxygenMinimum) + " " + t("ponds.field")}
                        </div>
                      )} */}
                      </div>
                      <div className="add_parameters_input">
                        <label>
                          {t("ponds.dissolved_oxygen_maximum")} (mg/l)
                        </label>
                        <input
                          type="number"
                          placeholder={
                            t("ponds.enter") +
                            " " +
                            t("ponds.dissolved_oxygen_maximum") +
                            " " +
                            "(mg/l)"
                          }
                          name="dissolvedOxygenMaximum"
                          value={addInput.dissolvedOxygenMaximum}
                          onChange={onInputChange}
                        />
                        {/* {errors.dissolvedOxygenMaximum && (
                        <div className="ui pointing red basic label">
                          {t("ponds.please_fill_the") + " " + t(errors.dissolvedOxygenMaximum) + " " + t("ponds.field")}
                        </div>
                      )} */}
                      </div>
                    </div>
                    <div className="input_row_12">
                      <div className="add_parameters_input">
                        <label>{t("ponds.nitrite_minimum")} (mg/l)</label>
                        <input
                          type="number"
                          placeholder={
                            t("ponds.enter") +
                            " " +
                            t("ponds.nitrite_minimum") +
                            " " +
                            "(mg/l)"
                          }
                          name="nitriteMinimum"
                          value={addInput.nitriteMinimum}
                          onChange={onInputChange}
                        />
                        {/* {errors.nitriteMinimum && (
                        <div className="ui pointing red basic label">
                          {t("ponds.please_fill_the") + " " + t(errors.nitriteMinimum) + " " + t("ponds.field")}
                        </div>
                      )} */}
                      </div>
                      <div className="add_parameters_input">
                        <label>{t("ponds.nitrite_maximum")} (mg/l)</label>
                        <input
                          type="number"
                          placeholder={
                            t("ponds.enter") +
                            " " +
                            t("ponds.nitrite_maximum") +
                            " " +
                            "(mg/l)"
                          }
                          name="nitriteMaximum"
                          value={addInput.nitriteMaximum}
                          onChange={onInputChange}
                        />
                        {/* {errors.nitriteMaximum && (
                        <div className="ui pointing red basic label">
                          {t("ponds.please_fill_the") + " " + t(errors.nitriteMaximum) + " " + t("ponds.field")}
                        </div>
                      )} */}
                      </div>
                    </div>
                    <div className="input_row_12">
                      <div className="add_parameters_input">
                        <label>{t("ponds.ammonia_minimum")} (mg/l)</label>
                        <input
                          type="number"
                          placeholder={
                            t("ponds.enter") +
                            " " +
                            t("ponds.ammonia_minimum") +
                            " " +
                            "(mg/l)"
                          }
                          name="ammoniaMinimum"
                          value={addInput.ammoniaMinimum}
                          onChange={onInputChange}
                        />
                        {/* {errors.ammoniaMinimum && (
                        <div className="ui pointing red basic label">
                          {t("ponds.please_fill_the") + " " + t(errors.ammoniaMinimum) + " " + t("ponds.field")}
                        </div>
                      )} */}
                      </div>
                      <div className="add_parameters_input">
                        <label>{t("ponds.ammonia_maximum")} (mg/l)</label>
                        <input
                          type="number"
                          placeholder={
                            t("ponds.enter") +
                            " " +
                            t("ponds.ammonia_maximum") +
                            " " +
                            "(mg/l)"
                          }
                          name="ammoniaMaximum"
                          value={addInput.ammoniaMaximum}
                          onChange={onInputChange}
                        />
                        {/* {errors.ammoniaMaximum && (
                        <div className="ui pointing red basic label">
                          {t("ponds.please_fill_the") + " " + t(errors.ammoniaMaximum) + " " + t("ponds.field")}
                        </div>
                      )} */}
                      </div>
                    </div>
                    <div className="input_row_12">
                      <div className="add_parameters_input">
                        <label>{t("ponds.temperature_minimum")} (c)</label>
                        <input
                          type="number"
                          placeholder={
                            t("ponds.enter") +
                            " " +
                            t("ponds.temperature_minimum") +
                            " " +
                            "(c)"
                          }
                          name="temperatureMinimum"
                          value={addInput.temperatureMinimum}
                          onChange={onInputChange}
                        />
                        {/* {errors.temperatureMinimum && (
                        <div className="ui pointing red basic label">
                          {t("ponds.please_fill_the") + " " + t(errors.temperatureMinimum) + " " + t("ponds.field")}
                        </div>
                      )} */}
                      </div>
                      <div className="add_parameters_input">
                        <label>{t("ponds.temperature_maximum")} (c)</label>
                        <input
                          type="number"
                          placeholder={
                            t("ponds.enter") +
                            " " +
                            t("ponds.temperature_maximum") +
                            " " +
                            "(c)"
                          }
                          name="temperatureMaximum"
                          value={addInput.temperatureMaximum}
                          onChange={onInputChange}
                        />
                        {/* {errors.temperatureMaximum && (
                        <div className="ui pointing red basic label">
                          {t("ponds.please_fill_the") + " " + t(errors.temperatureMaximum) + " " + t("ponds.field")}
                        </div>
                      )} */}
                      </div>
                    </div>
                    <div className="input_row_12">
                      <div className="add_parameters_input">
                        <label>{t("ponds.turbidity_minimum")} (NTU)</label>
                        <input
                          type="number"
                          placeholder={
                            t("ponds.enter") +
                            " " +
                            t("ponds.turbidity_minimum") +
                            " " +
                            "(NTU)"
                          }
                          name="turbidityMinimum"
                          value={addInput.turbidityMinimum}
                          onChange={onInputChange}
                        />
                        {/* {errors.turbidityMinimum && (
                        <div className="ui pointing red basic label">
                          {t("ponds.please_fill_the") + " " + t(errors.turbidityMinimum) + " " + t("ponds.field")}
                        </div>
                      )} */}
                      </div>
                      <div className="add_parameters_input">
                        <label>{t("ponds.turbidity_maximum")} (NTU)</label>
                        <input
                          type="number"
                          placeholder={
                            t("ponds.enter") +
                            " " +
                            t("ponds.turbidity_maximum") +
                            " " +
                            "(NTU)"
                          }
                          name="turbidityMaximum"
                          value={addInput.turbidityMaximum}
                          onChange={onInputChange}
                        />
                        {/* {errors.turbidityMaximum && (
                        <div className="ui pointing red basic label">
                          {t("ponds.please_fill_the") + " " + t(errors.turbidityMaximum) + " " + t("ponds.field")}
                        </div>
                      )} */}
                      </div>
                    </div>
                    <div className="logout-button-block submit-logout-button-block">
                      <Typography
                        sx={textStyle}
                        onClick={() => {
                          setIsWaterQualityModal(false);
                          // setErrors({
                          //   phMinimum: "",
                          //   phMaximum: "",
                          //   salinityMinimum: "",
                          //   salinityMaximum: "",
                          //   dissolvedOxygenMinimum: "",
                          //   dissolvedOxygenMaximum: "",
                          //   nitriteMinimum: "",
                          //   nitriteMaximum: "",
                          //   ammoniaMinimum: "",
                          //   ammoniaMaximum: "",
                          //   temperatureMinimum: "",
                          //   temperatureMaximum: "",
                          //   turbidityMinimum: "",
                          //   turbidityMaximum: "",
                          // })
                        }}
                      >
                        {t("ponds.cancel")}
                      </Typography>
                      <span></span>
                      <button
                        type="submit"
                        autoFocus
                        onClick={onClickWaterQuality}
                      >
                        {t("ponds.add")}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Box>
          </Modal>
        </div>
      )}
    </ResponsiveDrawer>
  );
}

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 360 ? 400 : 270,
  // width: 500,
  bgcolor: "background.paper",
  border: "2px solid",
  borderColor: "gray",
  borderRadius: 0,
  boxShadow: 24,
};
const textStyle = {
  color: colors.slatery_blue,
  fontSize: 17,
  padding: 1,
  textAlign: "center",
  width: "100%",
  fontWeight: "600",
};
export default PondDetail;
