import { onAuthStateChanged, signOut } from "firebase/auth";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db,auth } from "./config/firebase-config";
import { onSnapshot,doc } from "firebase/firestore";
import { getRoleByUid } from "./database/auth/login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { Error } from "./common/CustomToast";

const ProtectedRoute = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    async function checkLock(userLocal){
        if(userLocal && userLocal.uid){
            const unsub = onSnapshot(
                doc(db, "Users", userLocal.uid.toString()),
                async(doc) => {
                  const data = doc.data();
                //   console.log("Current data: ", data);
                  if (data) {
                    if (data["isLock"] !== undefined && data["isLock"]) {
                        Error(t("lockMessage"));
                        logout()
                        unsub()
                    } else if (data["isDelete"] !== undefined && data["isDelete"]) {
                        Error(t("deleteMessage"));
                        logout()
                        unsub()
                    } else {
                        const userRole = await getRoleByUid(userLocal.uid.toString())
                        if (userRole === "admin") {
                            setIsLoggedIn(true)
                        } else {
                            navigate('/404');
                        }
                    }
                  }
                }
              );
        }
    }

    async function logout(){
        try {
            await signOut(auth);
            localStorage.setItem("isLogin", "0");
            localStorage.setItem("manager_user", "false");
            localStorage.setItem("uid", null);
            localStorage.setItem("invoiceCid", null);
            localStorage.setItem("premiumCust", null);
            localStorage.setItem("stickyDays", null);
            localStorage.clear();
            navigate("/login");
          } catch (error) {
            console.log(error);
          }
    }

    const onLoad = useCallback(() => {
        onAuthStateChanged(auth, async (user) => {
            if (user && user.uid) {
                checkLock(user)
            } else {
                setIsLoggedIn(false)
                navigate('/login');
            }
        })
    }, [navigate])

    useEffect(() => {
        onLoad()
    }, [onLoad])

    return (
        <React.Fragment>
            {
                isLoggedIn ? props.children : <div className="four_zero_four_image_container" style={{ marginTop: 15 }}>
                    <div id={`load`} className="loader" />
                </div>
            }
        </React.Fragment>
    );
}
export default ProtectedRoute;