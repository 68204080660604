import React from 'react';
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import ResponsiveDrawer from "../../common/ResponsiveDrawer";
import { useNavigate, useHistory } from "react-router-dom";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";

ChartJS.register(
    Title,
    Tooltip,
    LineElement,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    Filler
);

const ReportPond = () => {
    const navigation = useNavigate();

    const { t } = useTranslation();
    const location = useLocation();
    const { reportData } = location.state
    const options_Line = {
        responsive: true,
        plugins: {
            legend: {
                // display: false,
                position: "top",
                labels: {
                    padding: 0,
                },
            },
            title: {
                display: true,
                text: t("ponds.chart_for_shrimp_growth_rates"),
                font: {
                    size: 14,
                },
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                    barPercentage: 0.5,
                    borderColor: "#fff",
                },
                ticks: {
                    font: {
                        size: 14,
                        weight: 500,
                        color: "red",
                    },
                },
                gridLines: {
                    borderDash: [8, 4],
                    color: "#348632",
                },
            },
            y: {
                grid: {
                    borderColor: "#fff",
                    borderDash: [4, 7],
                    color: "#bebebe",
                },
                ticks: {
                    precision: 1,
                    callback: (label) => `${label} GM`,
                    font: {
                        size: 14,
                        weight: 500,
                        color: "red",
                    },
                },
            },
        },
    };
    const lineData = {
        labels: reportData.graphData.forXAxis,
        datasets: [
            {
                label: t("ponds.average_growth"),
                data: reportData.graphData.forYAxis,
                fill: "start",
                backgroundColor: (context: ScriptableContext<"line">) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(25, 0, 0, 300);
                    gradient.addColorStop(0, "rgb(238 124 67 / 30%)");
                    gradient.addColorStop(1, "rgba(238, 124 ,67,0)");
                    return gradient;
                },
                borderColor: "rgb(238 124 67)",
                borderWidth: 1,
                pointRadius: 4,
                tension: 0.4,
                responsive: true,
            },
        ],
    };

    return (
        <ResponsiveDrawer
            isShowAppBar={true}
            headText={t("report.report_pond")}
            isHideToolBar={true}
            isBackArrow={true}
            onClickBack={() => {
                navigation("/report");
                (localStorage.getItem("pondActive") === "0" ? localStorage.setItem("pondActive", "1") : localStorage.setItem("pondActive", "0"))
                localStorage.setItem("report", "1");
            }}
        >
            <div className='report_main_wrapper_page'>
                <div className='reports_download'>
                    <button className='ui primary button' onClick={() => {
                        html2canvas(document.querySelector("#print"), {
                            // width: 1080,
                            // scale: 1
                            // canvas: canvas,
                        }).then(canvas => {
                            // const imgData = canvas.toDataURL('image/png');
                            // const pdf = new jsPDF();
                            // var margin = 3;
                            // var imgWidth = 213 - 3 * margin;
                            // var imgHeight = canvas.height * imgWidth / canvas.width;
                            // pdf.addImage(imgData, 'JPEG', margin, 3, imgWidth, imgHeight);
                            const imgData = canvas.toDataURL('image/png');
                            // const pdf = new jsPDF();
                            var pdf = new jsPDF('l', 'mm', 'a4');

                            var width = pdf.internal.pageSize.getWidth();
                            var height = pdf.internal.pageSize.getHeight();

                            pdf.addImage(imgData, 'PNG', 3, 3, width - 6, height - 4);

                            // pdf.output('dataurlnewwindow');
                            pdf.save(reportData.pondName + " " + reportData.cycleName + " Report.pdf");
                        });
                    }}>{t("licenseDetails.download")}</button>
                </div>
                <div className='reports_main_wrapper'>
                    <div id="print">
                        <div className='report_main_wrapper'>
                            <div className='report_pond_wrap'>
                                <div className='text_sam_block'>
                                    <h6>{t("ReportPond.description")}</h6>
                                    <div className='description_content'>
                                        <p className='description_sam_text'>{t("ReportPond.comprehensive_report")}</p>
                                    </div>
                                </div>
                                <div className='text_sam_block'>
                                    <h6>{t("ReportPond.report_parameters")}</h6>
                                    <div className='description_content'>
                                        <ul>
                                            <li>
                                                <p className='description_sam_text'>{t("ReportPond.s_date_e_date")}:</p>
                                                <span className='description_sam_text'>{reportData.startDate} - {reportData.endDate}</span>
                                            </li>
                                            <li>
                                                <p className='description_sam_text'>{t("ReportPond.pond")}:</p>
                                                <span className='description_sam_text'>{reportData.pondName}</span>
                                            </li>
                                            <li>
                                                <p className='description_sam_text'>{t("ReportPond.cycle")}:</p>
                                                <span className='description_sam_text'>{reportData.cycleName}</span>
                                            </li>
                                            {/* <li>
                                                <p className='description_sam_text'>{t("ReportPond.brand_feed")}:</p>
                                                <span className='description_sam_text'>FeedCompany1</span>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                                <div className='text_sam_block'>
                                    <h6>{t("ReportPond.summary")}</h6>
                                    <div className='description_content'>
                                        <div className='summary_row_block'>
                                            <ul>
                                                <li>
                                                    <p className='description_sam_text'>{t("ReportPond.amount_stocked")}:</p>
                                                    <span className='description_sam_text'>{reportData.amountStocked}</span>
                                                </li>
                                                <li>
                                                    <p className='description_sam_text'>{t("ReportPond.amount_harvested")}:</p>
                                                    <span className='description_sam_text'>{reportData.harvestedAmount} {t("kg")}</span>
                                                </li>
                                                <li>
                                                    <p className='description_sam_text'>{t("ReportPond.amount_feed_used")}:</p>
                                                    <span className='description_sam_text'>{reportData.amountOfFeed} {t("kg")}</span>
                                                </li>
                                                <li>
                                                    <p className='description_sam_text'>{t("ReportPond.weekly_average_growth_rate")}:</p>
                                                    <span className='description_sam_text'>{reportData.weeklyAverageGrowthRate} {t("grams")}</span>
                                                </li>
                                                <li>
                                                    <p className='description_sam_text'>{t("ReportPond.amount_lethal_alarms")}:</p>
                                                    <span className='description_sam_text'>{reportData.lethalCount}</span>
                                                </li>
                                                <li>
                                                    <p className='description_sam_text'>{t("ReportPond.amount_non_lethal_alarms")}:</p>
                                                    <span className='description_sam_text'>{reportData.nonLethalCount}</span>
                                                </li>
                                                <li>
                                                    <p className='description_sam_text'>{t("ReportPond.amount_check_needed_alarms")}:</p>
                                                    <span className='description_sam_text'>{reportData.checkNeededCount}</span>
                                                </li>
                                            </ul>
                                            <ul>
                                                <li>
                                                    <p className='description_sam_text'>{t("ReportPond.survival_rate")}:</p>
                                                    <span className='description_sam_text'>{reportData.sirvivalRate}%</span>
                                                </li>
                                                <li>
                                                    <p className='description_sam_text'>{t("ReportPond.average_size_harvest")}:</p>
                                                    <span className='description_sam_text'>{reportData.averageSize} {t("grams")}</span>
                                                </li>
                                                <li>
                                                    <p className='description_sam_text'>{t("ReportPond.stocking_density")}:</p>
                                                    <span className='description_sam_text'>{reportData.stockingDensity} m2</span>
                                                </li>
                                                <li>
                                                    <p className='description_sam_text'>{t("ReportPond.pond_size")}:</p>
                                                    <span className='description_sam_text'>{reportData.pondSize} {t("hectares")}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='growth_chart'>
                                    <p className='description_sam_text'>{t("ReportPond.growth_rate_through")}</p>
                                    <div className="chart_block">
                                        <Line
                                            data={lineData}
                                            options={options_Line}
                                            id="chart"
                                        ></Line>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ResponsiveDrawer>
    )
}

export default ReportPond;