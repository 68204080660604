import React from 'react'
import four_zero_four from "../images/404.png";

const Page404 = () => {
  return (
    <div className='four_zero_four_image_container'>
          <img src={four_zero_four} alt="404 image" />
    </div>
  )
}

export default Page404