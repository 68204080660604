import { collection, getDocs, limit, query, where } from "firebase/firestore";
import { db } from "../../config/firebase-config";

export const getUnpaidInvoice = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const pId = localStorage.getItem("pId");
      if (pId) {
        const q = query(
          collection(db, "invoices"),
          where("uid", "==", pId),
          where("stripeInvoiceStatus", "!=", "paid"),
          limit(1)
        );
        let data = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((val) => {
          data.push({ id: val.id, ...val.data() });
        });
        return resolve(data);
      } else {
        return resolve([]);
      }
    } catch (error) {
      return reject(error);
    }
  });
};
