import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import imagePath from "../common/imagePath";
import colors from "../common/colors";
import NotificationsIcon from "@mui/icons-material/Notifications";
import * as Sentry from "@sentry/react";
// import HomeIcon from "@mui/icons-material/Home";
// import DashboardIcon from "@mui/icons-material/Dashboard";
// import MapIcon from "@mui/icons-material/Map";
// import ArticleIcon from "@mui/icons-material/Article";
// import PeopleIcon from "@mui/icons-material/People";
// import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SettingsIcon from "@mui/icons-material/Settings";

import { AccountCircle, AddCircle } from "@mui/icons-material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import LogoutIcon from "@mui/icons-material/Logout";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import { auth } from "../config/firebase-config";
import { onAuthStateChanged, signOut } from "firebase/auth";
// import { Alert,Button } from "react-bootstrap";
import {
  Box,
  FormControl,
  Typography,
  TextField,
  Button,
  Modal,
  NativeSelect,
  Switch,
  Divider,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getUserData } from "../database/user/pond";

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import CloseIcon from "@mui/icons-material/Close";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { APP_VERSION } from "./configs";
const drawerWidth = 240;
const totalWidth = window.innerWidth;

function ResponsiveDrawer(props) {
  const {
    window,
    children,
    headText,
    isAddNewCycle,
    onClickNewCycleAdd,
    isHideDrawer,
    isHideToolBar,
    onClickAdd,
    isSearch,
    onChangeSearch,
    searchText,
    isBackArrow,
    onClickBack,
    searchPlaceholder,
    isShowAppBar,
    // isFilter,
    // onChangeFilter,
  } = props;
  const location = useLocation();
  const { t } = useTranslation();
  const navigation = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [heading, setHeading] = React.useState(headText);
  const [showAlert, setShowAlert] = React.useState(false);
  const [companyName, setCompanyName] = React.useState(
    localStorage.getItem("comName")
  );
  const [userData, setUserData] = React.useState();

  React.useEffect(() => {
    let devicesPool = localStorage.getItem("superAdmin");
    if (devicesPool === "true") {
      // navigation("/superadmin/devices_pool")
    }
  }, []);

  React.useEffect(() => {
    setHeading(headText);
  }, [headText])

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  let email = localStorage.getItem("email")
    ? localStorage.getItem("email")
    : "";

  React.useEffect(() => {
    async function getUser() {
      let uid = localStorage.getItem("uid");
      let userData = await getUserData(uid);
      setUserData(userData);
      let comName = localStorage.getItem("comName");
      if (comName) {
        setCompanyName(comName);
      } else {
        if (userData && userData.comName) {
          localStorage.setItem("comName", userData.comName);
          setCompanyName(userData.comName);
        }
      }
    }
    getUser();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        if (user.isAnonymous) {
          localStorage.setItem("isLogin", "0");
          localStorage.setItem("stickyDays", null);
          localStorage.clear();
          navigation("/login");
        }
        email = user.email;
      } else {
        localStorage.setItem("isLogin", "0");
        localStorage.setItem("stickyDays", null);
        localStorage.clear();
        navigation("/login");
      }
    });
  }, []);

  let Items =
    localStorage.getItem("manager_user") === "true"
      ? [
        { id: 0, title: t("home.home"), icon: <HomeOutlinedIcon />, path: "/home" },
        {
          id: 1,
          title: t("ponds.ponds"),
          icon: <GridViewOutlinedIcon />,
          path: "/selectFarm",
          stack: "Ponds",
          token: "Farms",
          token1: "Pond",
          token2: "Water Parameters"
        },
        // {
        //   title: "Pond: 1",
        //   icon: <GridViewOutlinedIcon />,
        //   path: "/pond_detail",
        // },
        // { id: 2, title: t("map.map"), icon: <MapOutlinedIcon />, path: "/map" },
        // {
        //   id: 3,
        //   title: t("alarm.alarm"),
        //   icon: <NotificationsIcon />,
        //   path: "/alarm",
        // },
        // {
        //   id: 4,
        //   title: t("report.reports"),
        //   icon: <AssignmentOutlinedIcon />,
        //   path: "/report",
        // },
        // {
        //   title: t("invoice.invoice"),
        //   icon: <ReceiptIcon />,
        //   path: "/invoice",
        // },
        // {
        //   title: t("profile.profile"),
        //   icon: <AccountCircle />,
        //   path: "/profile",
        // },
        {
          id: 5,
          title: t("settings.settings"),
          icon: <SettingsOutlinedIcon />,
          path: "/setting",
        },
        { id: 6, title: t("logout"), icon: <LogoutIcon /> },
      ]
      :
      localStorage.getItem("superAdmin") === "true" ?
        [
          {
            id: 2,
            title: t("userManagement.users"),
            icon: <AccountCircleIcon />,
            path: "/superadmin/users",
          },
          {
            id: 1,
            title: t("device.devices_pool"),
            icon: <PhoneAndroidOutlinedIcon />,
            path: "/superadmin/devices_pool",
          },
          { id: 3, title: t("logout"), icon: <LogoutIcon /> },
        ] : [
          { id: 0, title: t("home.home"), icon: <HomeOutlinedIcon />, path: "/home" },
          {
            id: 1,
            title: t("ponds.ponds"),
            icon: <GridViewOutlinedIcon />,
            path: "/selectFarm",
            stack: "Ponds",
            token: "Farms",
            token1: "Pond",
            token2: "Water Parameters"
          },
          // { id: 2, title: t("map.map"), icon: <MapOutlinedIcon />, path: "/map" },
          // {
          //   id: 3,
          //   title: t("alarm.alarm"),
          //   icon: <NotificationsNoneOutlinedIcon />,
          //   path: "/alarm",
          // },
          // {
          //   id: 4,
          //   title: t("report.reports"),
          //   icon: <AssignmentOutlinedIcon />,
          //   path: "/report",
          //   stack: t("ReportAll.report")
          // },
          // {
          //   title: t("userManagement.userManagement"),
          //   icon: <PeopleIcon />,
          //   path: "/management",
          // },
          {
            title: t("device.devices"),
            icon: <PhoneAndroidOutlinedIcon />,
            path: "/device",
          },
          {
            title: t("device.devices_pool"),
            icon: <PhoneAndroidOutlinedIcon />,
            path: "/superadmin/devices_pool",
          },
          // {
          //   title: t("invoice.invoice"),
          //   icon: <ReceiptIcon />,
          //   path: "/invoice",
          // },
          // {
          //   title: t("profile.profile"),
          //   icon: <AccountCircle />,
          //   path: "/profile",
          // },
          {
            id: 5,
            title: t("settings.settings"),
            icon: <SettingsOutlinedIcon />,
            path: "/setting",
          },
          { id: 6, title: t("logout"), icon: <LogoutIcon /> },
        ];


  const [sidebar, setsidebar] = React.useState(true);

  const Onclose = () => {
    setsidebar(true);
  };

  const Onopen = () => {
    setsidebar(false);
  };

  const drawer = (
    <>
    <div>
      <SidebarHtml onclose={Onclose} onopen={Onopen} sidebar={sidebar} />
      <Toolbar
        className="toolbar-block-css"
        style={{
          padding: "30px 10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#fff",
        }}
      >
        <div
          className="logo-block"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={imagePath.main_logo}
            alt="logo"
            style={{ marginBottom: 10, borderRadius: 10 }}
          ></img>
          {/* <h3>{companyName}</h3> */}
        </div>
      </Toolbar>
      <Divider />
      {/* <div style={{backgroundColor: 'red'}} onClick={() => navigation(`profile`)}> */}
      <div className="admin-block-css">
        <h4
          style={{
            fontWeight: "600",
            marginLeft: 10,
            marginTop: 29,
            fontFamily: "'Poppins', sans-serif",
            color: "white",
            wordBreak: "break-all",
            maxWidth: 200
          }}
        >
          {userData?.isSuperAdmin ?  t("admin") : userData?.fullName ? userData?.fullName : "-"}
        </h4>
        <h6
          style={{
            marginLeft: 10,
            marginBottom: 20,
            marginTop: 0,
            fontFamily: "'Poppins', sans-serif",
            color: "white",
          }}
        >
          {email}
        </h6>
      </div>
      {/* </div> */}
      <List className="ul-main-block">
        {Items.map((val, index) => {
          const listItemClick = async () => {
            if (val.title === t("logout")) {
              try {
                setShowAlert(true);
              } catch (error) {
                console.log(error);
              }
            } else {
              if (t("ponds.farms") !== headText) {
                setHeading(val.title);
              }
              navigation(val.path);
            }
          }

          // let isPremium = "true";
          // if (val.title === t("device.devices")) {
          //   isPremium = localStorage.getItem("premiumCust");
          // }
          // if (!isPremium || isPremium === "null" || isPremium === "") {
          //   return;
          // }

          let isDevicesPool = "true";
          if (val.title === t("device.devices_pool")) {
            isDevicesPool = localStorage.getItem("superAdmin");
          }
          if (!isDevicesPool || isDevicesPool === "null" || isDevicesPool === "") {
            return;
          }
          return (
            <>
              <ListItem
                // className={`list-item-block`}
                className={
                  `list-item-block ${(val.title === heading ||
                    (val.stack && heading.includes(val.stack))) ||
                    (val.token && headText.includes(val.token)) ||
                    (val.token1 && headText.includes(val.token1)) ||
                    (val.token2 && headText.includes(val.token2))
                    ? "active" : ""}`}
                button
                key={val.title}
                onClick={listItemClick}
              // sx={
              //   val.title === heading
              //     ? { backgroundColor: colors.sky_blue }
              //     : {}
              // }
              >
                <div className="list_a_block">
                  <ListItemIcon>{val.icon}</ListItemIcon>
                  <ListItemText
                    sx={{ fontSize: 25, fontWeight: "bold" }}
                    primary={val.title}
                    className="list-span"
                  />
                </div>
              </ListItem>
            </>
          );
        })}
      </List>
      {/* <Divider />
      <Toolbar style={{height: 40}}>
        <div>
          <h5 style={{}}>Logout</h5>
        </div>
      </Toolbar>
      <Divider /> */}
    </div>
    <h6 className="version_code">{t("app_version")} {APP_VERSION}</h6>
    </>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }} className={`h-100 ${sidebar ? "toggled" : ""}`}>
      {/* <CssBaseline /> */}
      {isShowAppBar && (
        <AppBar
          className="app-bar-block"
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            backgroundColor: colors.slatery_blue,
            marginTop: localStorage.getItem("stickyDays")
              ? localStorage.getItem("stickyDays") !== "null"
                ? 5
                : 0
              : 0,
          }}
        >
          <Toolbar
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              className="app_bar_svg_block"
            >
              {isBackArrow && (
                <KeyboardBackspaceIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => onClickBack()}
                />
              )}
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={() => handleDrawerToggle()}
                sx={{ mr: 2, display: { sm: "none" } }}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                className="font-block-appbar"
                variant="h6"
                noWrap
                component="div"
                sx={{ fontSize: 20, fontWeight: "500" }}
              >
                {heading}
              </Typography>
            </div>
            {isAddNewCycle && (
              <>
                {/* <button
                  className="ui primary button"
                  style={{ width: 120, marginLeft: 5 }}
                  onClick={() => onClickNewCycleAdd()}
                >
                  {t("new_cycle")}
                </button> */}
              </>
            )}
            {isHideDrawer && (
              <AddCircle
                sx={{ width: 25, height: 25 }}
                onClick={() => onClickAdd()}
              />
            )}
          </Toolbar>
          {isSearch && (
            <Toolbar>
              <div
                className="ui icon input"
                style={{
                  // width: isFilter ? totalWidth - 200 : totalWidth,
                  width: totalWidth,
                  marginLeft: 5,
                  marginRight: 3,
                  marginBottom: 5,
                }}
              >
                <input
                  onChange={(e) => onChangeSearch(e)}
                  value={searchText}
                  className=""
                  type="text"
                  placeholder={searchPlaceholder}
                />
                <i className="search icon"></i>
              </div>
              {/* {isFilter && (
              <NativeSelect
                defaultValue={"Question Tag"}
                onChange={(e) => onChangeFilter(e)}
                inputProps={{
                  name: "select_cat",
                  id: "uncontrolled-native",
                }}
                sx={{
                  fontSize: 14,
                  backgroundColor: "white",
                  width: 190,
                  height: 38,
                  borderRadius: 1,
                  marginBottom: 0.5,
                  marginLeft: 0.5,
                  paddingLeft: 1,
                }}
              >
                {[
                  { value: "Question Tag", label: "Question Tag" },
                  {
                    value: "Question",
                    label: "Question",
                  },
                ].map((val) => {
                  return (
                    <option key={val.value} value={val.value} style={{}}>
                      {val.label}
                    </option>
                  );
                })}
              </NativeSelect>
            )} */}
            </Toolbar>
          )}
        </AppBar>
      )}
      {isShowAppBar && (
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
          className="nav_box_block"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          paddingTop: isHideDrawer ? (totalWidth > 360 ? 5 : 4) : 3,
          paddingBottom: isHideDrawer ? (totalWidth > 360 ? 5 : 4) : 3,
          width: { sm: `calc(100% - ${drawerWidth + 50}px)` },
        }}
        className="main_box_block_css"
      >
        {/* {isHideDrawer && customHeader} */}
        {isHideToolBar && <Toolbar />}
        {children}
      </Box>

      {/* ----- LOGOUT MODAL ----- */}
      <Modal
        open={showAlert}
        onClose={() => setShowAlert(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={boxStyle} className="logout-css-modal-block">
          <Typography
            id="modal-modal-head"
            // variant="h4"
            // component="h2"
            sx={{
              paddingTop: 2,
              textAlign: "center",
              marginBottom: -1,
              paddingLeft: 1,
              paddingRight: 1,
              fontSize: 20,
              fontWeight: "500",
            }}
          >
            {t("logout")}
          </Typography>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              paddingTop: 2,
              textAlign: "center",
              marginBottom: 2,
              paddingLeft: 1,
              paddingRight: 1,
            }}
          >
            {t("are_you_sure_you_want_to_logout")}{" "}
          </Typography>

          <Divider />
          <div className="logout-button-block">
            <Typography sx={textStyle} onClick={() => setShowAlert(false)}>
              {t("ponds.cancel")}
            </Typography>
            <div
              style={{ width: "2px", border: "1px solid #999", opacity: 0.5 }}
            />
            <Typography
              sx={[textStyle]}
              onClick={async () => {
                try {
                  await signOut(auth);
                  localStorage.setItem("isLogin", "0");
                  localStorage.setItem("manager_user", "false");
                  localStorage.setItem("uid", null);
                  localStorage.setItem("invoiceCid", null);
                  localStorage.setItem("premiumCust", null);
                  localStorage.setItem("stickyDays", null);
                  localStorage.clear();
                  setShowAlert(false);
                  navigation("/login");
                  Sentry.setUser(null)
                } catch (error) {
                }
              }}
              className="delete_bg_color"
            >
              {t("logout")}
            </Typography>
          </div>
        </Box>
      </Modal>
    </Box>
  );
}

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 360 ? 400 : 270,
  bgcolor: "background.paper",
  border: "2px solid",
  borderColor: "gray",
  borderRadius: 3,
  boxShadow: 24,
};
const textStyle = {
  color: colors.slatery_blue,
  fontSize: 17,
  padding: 1,
  textAlign: "center",
  width: "100%",
  fontWeight: "600",
};

const SidebarHtml = ({ onclose, onopen, sidebar }) => {
  return (
    <div className={sidebar ? "toggled_icon" : ""}>
      <div className="close_icon_block">
        <CloseIcon onClick={onopen} />
      </div>
      <div className="open_icon_block" onClick={onclose}>
        <MenuIcon />
      </div>
    </div>
  );
};

export default ResponsiveDrawer;
export { SidebarHtml };
