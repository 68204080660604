import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { createAnonymousUser } from "../../database/auth/login";
import { getLinkRecords, getPondRecordById } from "../../database/user/pond";
import LightBox from "../../common/LightBox";
import { Typography } from "@mui/material";
import { getAlarmsById } from "../../database/user/alarm";
import { getCurrentTimestampInMillis } from "../../common/utils";

export default function ViewLinkRecord() {
  let { recordId } = useParams();
  const { t } = useTranslation();
  const [imagesList, setImagesList] = useState([]);
  const [showLight, setShowLight] = useState(null);
  const [data, setData] = useState(null);
  const [isExpired, setExpired] = useState(false);
  const [isLoading, setLoading] = useState(true);
  // const [imageData, setImageData] = useState(null);
  // const [isImageModal, setIsImageModal] = useState(false);
  useEffect(() => {
    async function createUser() {
      await createAnonymousUser();
      getRecordData();
    }
    if (recordId) {
      let uid = localStorage.getItem("uid");
      if (!uid) {
        createUser();
      } else {
        getRecordData();
      }
    }
  }, []);

  async function getRecordData() {
    const recordData = await getLinkRecords(recordId);

    const expireOn = getCurrentTimestampInMillis();

    if (!recordData || recordData.expireOn < expireOn) {
      setExpired(true);
      setLoading(false)
      return;
    }
    let data = null
    if (recordData.type == "record") {
      data = await getPondRecordById(recordId);
    } else {
      data = await getAlarmsById(recordId);
    }

    setData(data);
    setLoading(false)
    let images = [];
    if (data) {
      if (data.topImage) {
        images.push({
          ...data,
          imageType: t("home.top"),
          src: data.topImage,
          thumbnail: data.topImage,
          thumbnailWidth: "auto",
          thumbnailHeight: 300,
        });
        images.push({
          ...data,
          imageType: t("home.side"),
          src: data.sideImage,
          thumbnail: data.sideImage,
          thumbnailWidth: "auto",
          thumbnailHeight: 300,
        });
      } else {
        images.push({
          ...data,
          imageType: t("home.top"),
          src: data.picture,
          thumbnail: data.picture,
          thumbnailWidth: "auto",
          thumbnailHeight: 300,
        });
      }
    }
    setImagesList(images);
  }

  const Gallerys = () => {
    const showLightBox = (url) => {
      var index = imagesList.findIndex((image) => image.src == url);
      setShowLight({
        startIndex: index,
      });
    };

    const hideLightBox = () => {
      setShowLight(null);
    };

    // const viewRecord = (e) => {
    //   setShowLight({
    //     startIndex: e,
    //   });
    //   setImageData(imagesList[e]);
    //   setIsImageModal(true);
    // };

    return (
      <>
        {isExpired && !isLoading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 10,
              marginTop: 10,
              marginLeft: 10,
            }}
          >
            <Typography sx={{ fontSize: 17, fontWeight: "600" }}>
              {t("ponds.linkExpired")}
            </Typography>
          </div>
        )}
        {data && !isLoading && (
          <div className="collaps_gallary_main">
            <div className={`${data.sideImage ? "collaps_gallary" : "collaps_gallary_100"}`}>
              <span>
                <img
                  src={data.topImage}
                  alt={"GallaryForCollections"}
                  onClick={() => showLightBox(data.topImage)}
                />
              </span>
              {data.sideImage && (
                <span>
                  <img
                    src={data.sideImage}
                    alt={"GallaryForCollections"}
                    onClick={() => showLightBox(data.sideImage)}
                  />
                </span>
              )}
            </div>
          </div>
        )}
        {!isLoading && showLight ? (
          <LightBox
            images={imagesList.map((img) => ({
              url: img.src,
              title: img.caption,
            }))}
            // customButtonTitle={t("ponds.view_record")}
            startIndex={showLight.startIndex}
            onClose={hideLightBox}
          // onCustomButtonClick={viewRecord}
          />
        ) : null}
        {isLoading ? (<div
          style={{
            display: "flex",
            height: "50vh",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div id={`load`} className="loader" /></div>) : null}
      </>
    );
  };
  return (
    <div className="gallery_block_row viewlink_records_block">
      <Gallerys />
    </div>
  );
}
