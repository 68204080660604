import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Line, Bar } from "react-chartjs-2";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import Chart from "chart.js/auto";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
} from "chart.js";
import ResponsiveDrawer from "../../../common/ResponsiveDrawer";
import { getTimestampWithFormatDate } from "../../../common/utils";
ChartJS.register(
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler
);

var bgcolor = [
  "#FF0000",
  "#00FF00",
  "#0000FF",
  "#FFFF00",
  "#FF00FF",
  "#00FFFF",
  "#800000",
  "#008000",
  "#000080",
  "#808000",
  "#800080",
  "#008080",
  "#808080",
  "#C0C0C0",
  "#FFA500",
  "#FF4500",
  "#00CED1",
  "#8A2BE2",
  "#9932CC",
  "#FF1493",
  "#1E90FF",
];

const MeasurementReportsDetail = () => {
  const navigation = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const { report, cardData, cycleData } = location.state;
  console.log("report 47", report);
  const [widthCount, setWidthCount] = useState(window.screen.width);
  const [reportData, setReportData] = useState();
  console.log("reportData: 63", reportData);
  const [sizeData, setSizeData] = useState([]);

  const actualWidth = () => {
    setWidthCount(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", actualWidth);

    return () => {
      window.removeEventListener("resize", actualWidth);
    };
  }, []);

  useEffect(() => {
    if (report && report.groupData) {
      const Config = {
        //SIZE CHART GROUPS LABEL
        GROUP_ONE: "0.1-0.35g",
        GROUP_TWO: "0.35-0.6g",
        GROUP_THREE: "0.6-0.85g",
        GROUP_FOUR: "0.85-1.1g",
        GROUP_FIVE: "1.1-1.35g",
        GROUP_SIX: "1.35g-1.6g",
        GROUP_SEVEN: "1.6g-1.85g",
        GROUP_EIGHT: "1.85g-2.1g",
        GROUP_NINE: "2.1g-2.35g",
        GROUP_TEN: "2.35g-2.6g",
        GROUP_ELEVEN: "2.6g-2.85g",
        GROUP_TWELVE: "2.85g-3.1g",
        GROUP_THIRTEEN: "3.1g-3.35g",
        GROUP_FOURTEEN: "3.35g-3.6g",
        GROUP_FIFTEEN: "3.6g-3.85g",
        GROUP_SIXTEEN: "3.85g-4.1g",
        GROUP_SEVENTEEN: "4.1g-4.35g",
        GROUP_EIGHTEEN: "4.35g-4.6g",
        GROUP_NINETEEN: "4.6g-4.85g",
        GROUP_TWENTY: "4.85g-5.1g",
        GROUP_TWENTY_ONE: "5.1g+",
      };

      const combinedData = Object.values(Config).map((sizeName, index) => ({
        sizeName,
        color: bgcolor[index],
      }));
      setSizeData(combinedData);

      setReportData([
        report.groupData[Config.GROUP_ONE],
        report.groupData[Config.GROUP_TWO],
        report.groupData[Config.GROUP_THREE],
        report.groupData[Config.GROUP_FOUR],
        report.groupData[Config.GROUP_FIVE],
        report.groupData[Config.GROUP_SIX],
        report.groupData[Config.GROUP_SEVEN],
        report.groupData[Config.GROUP_EIGHT],
        report.groupData[Config.GROUP_NINE],
        report.groupData[Config.GROUP_TEN],
        report.groupData[Config.GROUP_ELEVEN],
        report.groupData[Config.GROUP_TWELVE],
        report.groupData[Config.GROUP_THIRTEEN],
        report.groupData[Config.GROUP_FOURTEEN],
        report.groupData[Config.GROUP_FIFTEEN],
        report.groupData[Config.GROUP_SIXTEEN],
        report.groupData[Config.GROUP_SEVENTEEN],
        report.groupData[Config.GROUP_EIGHTEEN],
        report.groupData[Config.GROUP_NINETEEN],
        report.groupData[Config.GROUP_TWENTY],
        report.groupData[Config.GROUP_TWENTY_ONE],
      ]);
    }
  }, [report]);
  console.log("reportData: 138", reportData);
  let labels = [];
  let filteredData = [];
  if (reportData) {
    filteredData = reportData.filter(
      (item) => !isNaN(item) && item !== 0 && item !== undefined
    );
    console.log("filteredData: ", filteredData);
    labels = new Array(filteredData.length).fill("");
  }
  console.log("labels: ", labels);
  const amountStockedGraph = {
    labels: labels,
    datasets: [
      {
        data: filteredData,
        backgroundColor: bgcolor,
        borderWidth: 1,
        barPercentage: 0.2,
        barThickness: 30,
        borderRadius: 30,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          // display: true,
          // barPercentage: 0.9,
          // borderColor: "#fff",
        },
        angleLines: {
          display: false,
        },
        ticks: {
          font: {
            size: 14,
            weight: 500,
            color: "red",
          },
        },
      },
      y: {
        grid: {
          // borderColor: "#fff",
          borderDash: [4, 7],
          color: "#bebebe",
        },
        ticks: {
          callback: (label) => `${label}`,
          font: {
            size: 14,
            weight: 500,
          },
        },
      },
    },
  };

  return (
    <ResponsiveDrawer
      isShowAppBar={true}
      headText={t("ponds.measurement_reports_detail")}
      isHideToolBar={true}
      isBackArrow={true}
      onClickBack={() => {
        navigation("/measurementReports", {
          state: {
            pondData: cardData,
            cycleData: cycleData,
          },
        });
        localStorage.setItem("report", "1");
        localStorage.getItem("pondActive") === "0"
          ? localStorage.setItem("pondActive", "0")
          : localStorage.setItem("pondActive", "1");
      }}
    >
      <div className="report_main_wrapper_page">
        {/* <div className="reports_download">
          <button
            className="ui primary button"
            onClick={() => {
              html2canvas(document.querySelector("#print"), {
                // width: 1080,
                // scale: 1
              }).then((canvas) => {
                const imgData = canvas.toDataURL("image/png");
                // const pdf = new jsPDF();
                var pdf = new jsPDF("l", "mm", "a4");
                // var pdf = new jsPDF('l', 'mm');
                var margin = 3;
                // var imgWidth = 213 - 3 * margin;
                // var imgHeight = canvas.height * imgWidth / canvas.width;

                // var imgWidth = widthCount > 1700 ? 290 : 220;
                // var imgHeight = canvas.height * imgWidth / canvas.width;

                var width = pdf.internal.pageSize.getWidth();
                var height = pdf.internal.pageSize.getHeight();
                // pdf.addImage(imgData, 'PNG', 3, 3, imgWidth, imgHeight - `${widthCount > 1700 ? 30 : 100}`);
                pdf.addImage(imgData, "PNG", 3, 3, width - 6, height - 4);

                // pdf.output('dataurlnewwindow');
                pdf.save("AllPond_Report.pdf");
              });
            }}
          >
            Download
          </button>
        </div> */}
        <div className="reports_main_wrapper measurement_reports_detail">
          <div id="print">
            <div className="report_main_wrapper">
              <div className="report_pond_wrap">
                <div className="text_sam_block">
                  <h6>{t("ReportPond.report_parameters")}</h6>
                  {/* <div className="description_content">
                    <ul>
                      <li>
                        <p className="description_sam_text">
                          {t("ReportPond.pond")}:
                        </p>
                        <span className="description_sam_text">
                          {cardData && cardData.pondName}
                        </span>
                      </li>
                    </ul>
                  </div> */}
                </div>
                <div className="text_sam_block">
                  <div className="description_content">
                    <div className="summary_row_block">
                      <ul>
                        <li>
                          <p className="description_sam_text">
                            {t("ReportPond.pond")}:
                          </p>
                          <span className="description_sam_text">
                            {cardData && cardData.pondName}
                          </span>
                        </li>
                        <li>
                          <p className="description_sam_text">
                            {t("ReportPond.measured")}:
                          </p>
                          <span className="description_sam_text">
                            {report.records}
                          </span>
                        </li>
                        <li>
                          <p className="description_sam_text">
                            {t("ReportPond.date")}:
                          </p>
                          <span className="description_sam_text">
                            {getTimestampWithFormatDate(report.timeStamp,"DD-MM-YYYY @ hh:mma")}
                          </span>
                        </li>
                        {/* <li>
                          <p className="description_sam_text">
                            {t("ReportPond.smallest")}:
                          </p>
                          <span className="description_sam_text">
                            {Number(report.smallest).toFixed(1)}
                          </span>
                        </li> */}
                        <li>
                          <p className="description_sam_text">
                            {t("ReportPond.average")}:
                          </p>
                          <span className="description_sam_text">
                            {Number(report.averageSize).toFixed(1)}
                          </span>
                        </li>
                        {/* <li>
                          <p className="description_sam_text">
                            {t("ReportPond.biggest")}:
                          </p>
                          <span className="description_sam_text">
                            {Number(report.biggest).toFixed(1)}
                          </span>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="growth_average_row">
                  <div className="growth_bar_col" style={{ width: "60%" }}>
                    <div className="chart_block">
                      <Bar data={amountStockedGraph} options={options} />
                      <div>
                        <ul
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            padding: 0,
                          }}
                        >
                          {sizeData.map((item, index) => {
                            return (
                              <li
                                key={index}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  width: "33.33%",
                                  marginTop: 5,
                                  marginBottom: 5,
                                }}
                              >
                                <span
                                  style={{
                                    backgroundColor: item.color,
                                    width: 20,
                                    height: 20,
                                    borderRadius: 20,
                                    display: "block",
                                  }}
                                ></span>
                                <p style={{ margin: 0, paddingLeft: 7 }}>
                                  {item.sizeName}
                                </p>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ResponsiveDrawer>
  );
};

export default MeasurementReportsDetail;
