import React, { useEffect, useState } from "react";
import "../../App.css";
import "../../style/auth/SignUp.css";
import colors from "../../common/colors";
import { useNavigate, useParams } from "react-router-dom";
import imagePath from "../../common/imagePath";
import { singUpUserInvite } from "../../database/auth/signUp";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Error } from "../../common/CustomToast";
import { useTranslation } from "react-i18next";
import { Autocomplete, LoadingButton } from "@mui/lab";
import { TextField } from "@mui/material";
import { TIMEZONE } from "../../common/commonList";
import { getInviteHistoryById } from "../../database/user/users";

function InviteSignUp() {
  const { t } = useTranslation();
  let { inviteId } = useParams();
  console.log('inviteId: 19', inviteId);
  const [user, setUser] = useState({
    fullname: "",
    jobTitle: "",
    comName: "",
    email: "",
    password: "",
    phone: "",
    confirmPassword: "",
    timezone: "",
  });
  const [isNameErr, setIsNameErr] = useState(false);
  const [isjobTitleErr, setIsjobTitleErr] = useState(false);
  const [iscomNameErr, setIscomNameErr] = useState(false);
  const [isTimezoneErr, setIsTimezoneErr] = useState(false);
  const [isEmailErr, setIsEmailErr] = useState(false);
  const [isPasswordErr, setIsPasswordErr] = useState(false);
  const [isConfirmPasswordErr, setIsConfirmPasswordErr] = useState(false);
  const [isPhoneErr, setIsPhoneErr] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [ismainLoader, setIsmainLoader] = useState(false);
  const [isAlreadyDone, setIsAlreadyDone] = useState(false);
  const navigation = useNavigate();
  const timeZoneList = TIMEZONE;

  const onLoad = async () => {
    setIsmainLoader(true);
    if (inviteId) {
      const res = await getInviteHistoryById(inviteId)
      setUser({
        ...user,
        comName: res.companyName,
        email: res.email,
        fullname: res.fullName
      })
      setIsAlreadyDone(res.isSignup || false)
    }
    setIsmainLoader(false);
  }

  useEffect(() => {
    onLoad()
  }, [inviteId])

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsDisable(true);
    let emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let passwordRegex = /^(?=.*).{6,}$/;
    let phoneRegex = /^\d{10,12}$/;
    let fullNameRegex = /^[a-zA-Z\s]+/gi;
    if (user.fullname === "") {
      setIsDisable(false);
      return setIsNameErr(true);
    } else if (!fullNameRegex.test(user.fullname)) {
      setIsDisable(false);
      return setIsNameErr(true);
    }
    if (user.jobTitle === "") {
      setIsDisable(false);
      return setIsjobTitleErr(true);
    }
    if (user.comName === "") {
      setIsDisable(false);
      return setIscomNameErr(true);
    }
    if (user.timezone === "") {
      setIsDisable(false);
      return setIsTimezoneErr(true);
    }
    if (user.email === "") {
      setIsDisable(false);
      return setIsEmailErr(true);
    } else if (!emailRegex.test(user.email)) {
      setIsDisable(false);
      return setIsEmailErr(true);
    }
    if (user.phone === "") {
      setIsDisable(false);
      return setIsPhoneErr(true);
    } else if (!user.phone?.startsWith("+")) {
      setIsDisable(false);
      return setIsPhoneErr(true);
    }
    if (user.password === "") {
      setIsDisable(false);
      return setIsPasswordErr(true);
    } else if (!passwordRegex.test(user.password)) {
      setIsDisable(false);
      return setIsPasswordErr(true);
    }
    if (user.confirmPassword === "") {
      setIsDisable(false);
      return setIsConfirmPasswordErr(true);
    } else if (!passwordRegex.test(user.confirmPassword)) {
      setIsDisable(false);
      return setIsConfirmPasswordErr(true);
    }
    if (user.password !== user.confirmPassword) {
      setIsDisable(false);
      return setIsConfirmPasswordErr(true);
    }
    try {
      // setIsLoading(true);
      const data = {
        fullName: user.fullname,
        jobTitle: user.jobTitle,
        comName: user.comName,
        email: user.email,
        phone: user.phone,
        timezone: user.timezone,
      };
      await singUpUserInvite(user.email, user.password, data, inviteId);
      setIsDisable(false);
      navigation("/login");
    } catch (error) {
      if (error.code !== "" && error.code === "auth/email-already-in-use") {
        Error("This email is already in use.");
      } else if (error.message !== "") {
      }
      setIsDisable(false);
      setIsLoading(false);
    }
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "fullname" && isNameErr) {
      setIsNameErr(false);
    } else if (name === "jobTitle" && isjobTitleErr) {
      setIsjobTitleErr(false);
    } else if (name === "comName" && iscomNameErr) {
      setIscomNameErr(false);
    } else if (name === "timezone" && isTimezoneErr) {
      setIsTimezoneErr(false);
    } else if (name === "email" && isEmailErr) {
      setIsEmailErr(false);
    } else if (name === "password" && isPasswordErr) {
      setIsPasswordErr(false);
    } else if (name === "confirmPassword" && isConfirmPasswordErr) {
      setIsPasswordErr(false);
    } else if (name === "phone" && isPhoneErr) {
      setIsPhoneErr(false);
    } else if (name === "checkbox") {
      setIsDisable(!isDisable);
    }
    setUser((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });
  };

  const autoValue = (value) => {
    const selectedObj =
      timeZoneList && timeZoneList.find((item) => item.text === value);
    return selectedObj;
  };

  const nameClassName = `field ${isNameErr ? "error" : ""}`;
  const jobClassName = `field ${isjobTitleErr ? "error" : ""}`;
  const comNameClassName = `field ${iscomNameErr ? "error" : ""}`;
  const timezoneClassName = `field autocomplete_country ${isTimezoneErr ? "error" : ""}`;
  const emailClassName = `field ${isEmailErr ? "error" : ""}`;
  const pwdClassName = `field ${isPasswordErr ? "error" : ""}`;
  const conPwdClassName = `field ${isConfirmPasswordErr ? "error" : ""}`;
  const phoneClassName = `field ${isPhoneErr ? "error" : ""}`;
  return (
    <div className="App d-flex flex-column align-items-center justify-content-center w-100 m-auto">
      {ismainLoader ? (
        <div
          style={{
            display: "flex",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div id={`load`} className="loader" />
        </div>) : (
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              // minWidth: window.innerWidth > 1100 ? 600 : 260,
              width: "100%",
              // maxWidth: 450,
            }}
            className="main-form-block"
          >
            <form className="ui form segment error">
              {/* ----- IMAGE ----- */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  marginBottom: 5,
                }}
                className="main-logo-block-css"
              >
                <img
                  src={imagePath.main_logo}
                  alt="logo"
                  width="250"
                  height="55"
                  style={{ marginBottom: 10, borderRadius: 10 }}
                ></img>
                {isAlreadyDone ?
                  <h2 style={{ fontWeight: "600" }}>Signup already done</h2>
                  :
                  <h2 style={{ fontWeight: "600" }}>Finish Signup</h2>
                }
              </div>
              {!isAlreadyDone &&
                <div>
                  <div className="signUp_form_row">
                    {/* ----- FULL NAME ----- */}
                    <div className={nameClassName}>
                      <label>Full Name</label>
                      <input
                        onChange={onInputChange}
                        value={user.fullname}
                        type="text"
                        name="fullname"
                        placeholder="Enter Full Name"
                        required
                        disabled
                      />
                      {isNameErr && (
                        <div className="ui pointing red basic label">
                          {user.fullname === ""
                            ? "Please fill the full name field."
                            : "Inavalid full name!"}
                        </div>
                      )}
                    </div>

                    {/* ----- JOB TITLE ----- */}
                    <div className={jobClassName}>
                      <label>Job Title</label>
                      <input
                        onChange={onInputChange}
                        value={user.jobTitle}
                        type="text"
                        name="jobTitle"
                        placeholder="Enter Job Title"
                        required
                      />
                      {isjobTitleErr && (
                        <div className="ui pointing red basic label">
                          {user.jobTitle === ""
                            ? "Please fill the job title field."
                            : "Inavalid job title!"}
                        </div>
                      )}
                    </div>

                    {/* ----- COMPANY NAME ----- */}
                    <div className={comNameClassName}>
                      <label>Company Name</label>
                      <input
                        onChange={onInputChange}
                        value={user.comName}
                        type="text"
                        name="comName"
                        placeholder="Enter Company Name"
                        required
                        disabled
                      />
                      {iscomNameErr && (
                        <div className="ui pointing red basic label">
                          {user.comName === ""
                            ? "Please fill the company name field."
                            : "Inavalid company name!"}
                        </div>
                      )}
                    </div>

                    {/* ----- EMAIL ----- */}
                    <div className={emailClassName}>
                      <label>Email</label>
                      <input
                        onChange={onInputChange}
                        value={user.email}
                        type="email"
                        name="email"
                        placeholder="Enter Email"
                        required
                        disabled
                      />
                      {isEmailErr && (
                        <div className="ui pointing red basic label">
                          {user.email === ""
                            ? "Please fill the email field."
                            : "Inavalid email address!"}
                        </div>
                      )}
                    </div>

                    {/* ----- PHONE ----- */}
                    <div className={phoneClassName}>
                      <label>Phone Number</label>
                      <input
                        onChange={onInputChange}
                        value={user.phone}
                        type="tel"
                        name="phone"
                        placeholder="Enter Phone Number"
                        required
                      />
                      {isPhoneErr && (
                        <div className="ui pointing red basic label">
                          {user.phone === ""
                            ? "Please fill the phone number field."
                            : "Please add your country code e.g. +45"}
                        </div>
                      )}
                      <p className="">
                        Put area code in front (example: +45 60378266)
                      </p>
                    </div>

                    {/* ----- Timezone ----- */}
                    <div className={timezoneClassName}>
                      <label>Timezone</label>
                      <Autocomplete
                        options={timeZoneList}
                        getOptionLabel={(option) => option.text}
                        renderInput={(params) => (
                          <TextField {...params} />
                        )}
                        value={autoValue(user.timezone)}
                        onChange={(event, value) => {
                          if (value) {
                            onInputChange({
                              target: {
                                value: value.utc[0],
                                name: "timezone",
                              },
                            });
                          } else {
                            onInputChange({
                              target: {
                                value: "",
                                name: "timezone",
                              },
                            });
                          }
                        }}
                      />
                      {isTimezoneErr && (
                        <div className="ui pointing red basic label">
                          {user.timezone === ""
                            ? "Please select the timezone"
                            : ""}
                        </div>
                      )}
                    </div>

                    {/* ----- PASSWORD ----- */}
                    <div className={pwdClassName}>
                      <label>Password</label>
                      <input
                        onChange={onInputChange}
                        value={user.password}
                        type="password"
                        name="password"
                        placeholder="Enter Password"
                        autoComplete="off"
                        required
                      />
                      {isPasswordErr && (
                        <div className="ui pointing red basic label">
                          {Number(user.password.length) > 0
                            ? Number(user.password.length) < 6
                              ? "Password must be atleast 6 characters."
                              : `Wrong password!`
                            : "Please fill the password field."}
                        </div>
                      )}
                    </div>

                    {/* ----- confirm password ----- */}
                    <div className={conPwdClassName}>
                      <label>Confirm Password</label>
                      <input
                        onChange={onInputChange}
                        value={user.confirmPassword}
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        autoComplete="off"
                        required
                      />
                      {isConfirmPasswordErr && (
                        <div className="ui pointing red basic label">
                          {Number(user.confirmPassword.length) > 0
                            ? user.password !== user.confirmPassword
                              ? "Passwords does not match"
                              : `Wrong password!`
                            : "Password is mendatory"}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="signuo_privacy_notice_block">
                    <div>
                      <input
                        type="checkbox"
                        id="checkbox"
                        name="checkbox"
                        value={!isDisable}
                        onChange={onInputChange}
                      ></input>
                      <p>
                        {t("i_agree_to_the")}
                        <a
                          href="https://www.sincereaqua.com/terms-and-conditions"
                          target="/"
                        >
                          {t("terms_of_Service")}
                        </a>
                      </p>
                    </div>
                    <p>
                      {t("we_will_process_your_personal")}
                      <a href="https://www.sincereaqua.com/privacy-policy" target="/">
                        {t("privacy_notice")}
                      </a>
                    </p>
                  </div>

                  {/* ----- SIGN-UP BUTTON ----- */}
                  <div className="loading_button">
                    <LoadingButton
                      onClick={onSubmit}
                      className="ui primary submit button"
                      style={{ width: "100%", backgroundColor: colors.slatery_blue }}
                      type="submit"
                      loadingPosition="start"
                      loading={isLoading}
                      disabled={isDisable}
                    >
                      Sign Up
                    </LoadingButton>
                  </div>
                </div>
              }

              {/* ----- MOVE LOGIN PAGE ----- */}
              <div className="move_login_container move_register_container">
                <p className="move_login_text move_register_text">
                  Got an account?
                  <span
                    className="move_login move_register"
                    onClick={() => navigation("/login")}
                  >
                    &nbsp;Click here to login
                  </span>
                </p>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default InviteSignUp;
