import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getInvoiceList } from "../../api/api";
import ResponsiveDrawer from "../../common/ResponsiveDrawer";
import { getProfileData } from "../../database/user/profile";
import colors from "../../common/colors";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Card } from "@mui/material";
import CustomSegments from "../../components/CustomSegments";
import Invoice from "../user/Invoice";
import Subscriptions from "./Subscriptions";

function Billing(props) {
  const { t } = useTranslation();
  const [invoiceList, setInvoiceList] = useState([]);
  const [isData, setIsData] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedIndexData, setSelectedIndexData] = useState(null);
  const [segmentList, setSegmentList] = useState([
    { id: 1, title: t("settings.Subscription_plans") },
    { id: 2, title: t("settings.Billing_history") },
  ]);
  const [selectedSegment, setSelectedSegment] = useState(1);
  const [profileData, setProfileData] = useState(null);
  useEffect(() => {
    async function getDatas() {
      try {
        const profile = await getProfileData();
        setProfileData(profile);
        let custId = localStorage.getItem("invoiceCid");
        if (profile && profile.customerId) {
          custId = profile.customerId;
          localStorage.setItem("invoiceCid", profile.customerId);
        } else {
          custId = null;
          localStorage.setItem("invoiceCid", null);
        }
        if (profile && profile.isPremium) {
          localStorage.setItem("premiumCust", "true");
        } else if (profile && profile.isSuperPremium) {
          localStorage.setItem("premiumCust", "true");
        } else {
          localStorage.setItem("premiumCust", null);
        }
        const data = await getInvoiceList(custId);
        if (data && data.length > 0) {
          data.filter((ele) => {
            if (ele.status === "paid") {
              localStorage.setItem("stickyDays", null);
            }
            return;
          });
          setInvoiceList(data);
        } else {
          setIsData(true);
        }
      } catch (error) {
      }
    }
    getDatas();
  }, []);

  return (
    <div className="inner_dropdown_block">
      <CustomSegments
        segmentList={segmentList}
        selectedSegment={selectedSegment}
        onClickSelectedSegment={(ind) => {
          setSelectedSegment(ind);
        }}
      />
      <div
        sx={{ padding: 2, boxShadow: "0.5px 1px 1px 2px #e0e0e0" }}
        className="setting_max_block"
      >
        {selectedSegment === 1 && (
          <Subscriptions profile={profileData} selected={props.selected} objCloud={props.objCloud}/>
        )}
        {selectedSegment === 2 && <Invoice profile={profileData} />}
      </div>
    </div>
  );
}

export default Billing;
