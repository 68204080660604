export default {
  logo: require("../images/logo.png"),
  logo2: require("../images/logo_white_new.png"),
  main_logo: require("../images/main_logo.png"),
  logo3: require("../images/logo2.png"),
  noImg: require("../images/noImg.png"),
  marker_red: require("../images/marker_1.png"),
  marker_green: require("../images/marker_2.png"),
  marker_yellow: require("../images/marker_3.png"),
};
