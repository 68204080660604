import { onAuthStateChanged } from "firebase/auth";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "./config/firebase-config";
import { getRoleByUid } from "./database/auth/login";
const SuperAdminProtectedRoute = (props) => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const onLoad = useCallback(() => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                const userRole = await getRoleByUid(user.uid)
                if (userRole === "superAdmin") {
                    setIsLoggedIn(true)
                } else {
                // navigate('/404');
                }
            } else {
                setIsLoggedIn(false)
                navigate('/login');
            }
        })
    }, [navigate])

    useEffect(() => {
        onLoad()
    }, [onLoad])

    return (
        <React.Fragment>
            {
                isLoggedIn ? props.children :  <div className="four_zero_four_image_container" style={{ marginTop: 15 }}>
                <div id={`load`} className="loader" />
              </div>
            }
        </React.Fragment>
    );
}
export default SuperAdminProtectedRoute;