export default {
  slatery_blue: "#304863",
  sky_blue: "#e6f6fa",
  mid_blue: "#34b4eb",
  blue: "#0583b5",
  lightGray: "#b0b0b0",
  midGray: "#6e6d6d",
  green: "#77dd77",
  red: "#b51e10",
  lightRed: "#e34234 ", //#ce2029
  lightWhite: "#fafafa",
  yellow: "#ffc061",
  white: "#5eb4f8",
};
