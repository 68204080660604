import { Box, Modal, Typography, Divider } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import colors from "../common/colors";

function AlertModal(props) {
  const {
    isOpenAlertModal,
    setIsOpenAlertModal,
    onClickCancel,
    onClickUpgrade,
    message,
  } = props;
  const { t } = useTranslation();

  return (
    <Modal
      open={isOpenAlertModal}
      onClose={() => setIsOpenAlertModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={[boxStyle, { width: 400 }]} className="logout-css-modal-block">
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{
            paddingTop: 2,
            textAlign: "center",
            marginBottom: 2,
            paddingLeft: 1,
            paddingRight: 1,
          }}
        >
          {message}
        </Typography>

        <Divider />
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-around",
          }}
          className="logout-button-block"
        >
          <Typography sx={textStyle} onClick={onClickCancel}>
            {t("ponds.cancel")}
          </Typography>
          <div
            style={{ width: "2px", border: "1px solid #999", opacity: 0.5 }}
          />
          <Typography
            sx={[textStyle, { color: "red" }]}
            onClick={onClickUpgrade}
          >
            {t("ponds.upgrade")}
          </Typography>
        </div>
      </Box>
    </Modal>
  );
}

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: window.innerWidth > 375 ? 400 : 270,
  width: 650,
  bgcolor: "background.paper",
  border: "2px solid",
  borderColor: "gray",
  borderRadius: 3,
  boxShadow: 24,
};
const textStyle = {
  color: colors.slatery_blue,
  fontSize: 17,
  padding: 1,
  textAlign: "center",
  width: "100%",
  fontWeight: "600",
};

export default AlertModal;
