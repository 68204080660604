import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  increment,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../config/firebase-config";
import { getCountersMonthDataFromDb } from "./home";
import {  getStartOfTimestamp } from "../../common/utils";

export const getAllUserByPid = (pid, AdminId) => {
  return new Promise(async (resolve, reject) => {
    try {
      // let pid = localStorage.getItem("uid");
      if (pid) {
        const q = query(collection(db, "Users"), where("pId", "==", pid));
        let adminData = null;
        if (AdminId) {
          const docRef = doc(db, "Users", AdminId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            adminData = docSnap.data();
          } else {
            adminData = null;
          }
        }
        let data = [];
        // onSnapshot(q, (snapshot) => {
        //   snapshot.docs.forEach((element) => {
        //     if (element.exists()) {
        //       data.push({ id: element.id, ...element.data() });
        //     }
        //   });
        //   return resolve(data);
        // });
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((val) => {
          data.push({ id: val.id, ...val.data() });
        });
        return resolve({ data: data, adminData: adminData });
      } else {
        return resolve([]);
      }
    } catch (error) {
      return reject(error);
    }
  });
};

export const updateUserById = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (id) {
        const docRef = doc(db, "Users", id);
        await updateDoc(docRef, data);
        return resolve("Updated Successfully");
      } else {
        return resolve(null);
      }
    } catch (error) {
      return reject(error);
    }
  });
};

export const addOrUpdateUsersCountById = (pId, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (pId) {
        const counterdata = await getCountersMonthDataFromDb();
        const monthFirstDay = getStartOfTimestamp("month");
        const docRef = doc(
          db,
          "counters",
          `${pId}/monthlyRecords/${monthFirstDay}`
        );
        if (counterdata) {
          await updateDoc(docRef, {
            users: increment(1),
          });
        } else {
          const countRef = doc(db, "counters", `${pId}`);
          const docSnap = await getDoc(countRef);
          let users = 0;
          if(docSnap.exists()){
            users = docSnap.data().users;
          }
          await setDoc(docRef, {
            records: 0,
            users: users,
          });
        }
        return resolve("Updated Successfully");
      } else {
        return resolve(null);
      }
    } catch (error) {
      return reject(error);
    }
  });
};

export const deleteUsersFromDb = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let pId = localStorage.getItem("pId");
      await deleteDoc(doc(db, `Users`, id));
      const countRef = doc(db, "counters", `${pId}`);
      await updateDoc(countRef, {
        users: increment(-1),
      });
      return resolve("Successfully deleted");
    } catch (error) {
      return reject(error);
    }
  });
};
