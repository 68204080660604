import "./App.css";
import Router from './routes';
import main_wrapper_bg from "./images/main-wrapper-bg.jpg";
import moment from 'moment-timezone';
import { UTC } from "./common/configs";
import { ToastContainer } from "react-toastify";

// Set the default timezone
moment.tz.setDefault(UTC);

function App() {
  return (
    <div className="main-wrapper">
      <div className="main-wrapper-bg-img">
        <img src={main_wrapper_bg} alt="main_wrapper_bg" />
      </div>
      <div className="h-100">
        <div style={{ padding: 20, height: "100%" }}>
          <Router />
          {/* ----- TOAST MESSAGE ----- */}
          <ToastContainer />
        </div>
      </div>
    </div>
  );
}
export default App;
