import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import annotationPlugin from 'chartjs-plugin-annotation';
ChartJS.register(annotationPlugin);

const LineChart = ({titleText,labelText,labelsData,chartData,waterQualityMin,waterQualityMax,minimumText,maximumText}) => {

    const options_Line = {
        responsive: true,
        plugins: {
            annotation: {
                annotations: {
                    line1: {
                        type: 'line',
                        yMin: waterQualityMax,
                        yMax: waterQualityMax,
                        borderColor: 'red',
                        borderWidth: 1,
                        // label: {
                        //     backgroundColor: "#ee7c45",
                        //     content: `${maximumText} : ${waterQualityMax}`,
                        //     enabled: true,
                        //     font: {
                        //         size: 12,
                        //     },
                        // }
                    },
                    line2: {
                        type: 'line',
                        yMin: waterQualityMin,
                        yMax: waterQualityMin,
                        borderColor: 'blue',
                        borderWidth: 1,
                        // label: {
                        //     backgroundColor: "#ee7c45",
                        //     content: `${minimumText} : ${waterQualityMin}`,
                        //     enabled: true,
                        //     font: {
                        //         size: 12,
                        //     },
                        // }
                    },
                }
            },
            legend: {
                // display: false,
                position: "top",
                labels: {
                    padding: 10,
                },
            },
            title: {
                display: true,
                text: titleText,
                font: {
                    size: 18,
                },
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                    barPercentage: 0.5,
                    borderColor: "#fff",
                },
                ticks: {
                    font: {
                        size: 16,
                        weight: 500,
                        color: "red",
                    },
                },
                gridLines: {
                    borderDash: [8, 4],
                    color: "#348632",
                },
            },
            y: {
                grid: {
                    borderColor: "#fff",
                    borderDash: [4, 7],
                    color: "#bebebe",
                },
                ticks: {
                    precision: 1,
                    callback: (label) => `${label}`,
                    font: {
                        size: 14,
                        weight: 500,
                        color: "red",
                    },
                },
            },
        },
    };
    const lineData = {
        labels: labelsData,
        datasets: [
            {
                label: labelText,
                data: chartData,
                fill: "start",
                backgroundColor: (context: ScriptableContext<"line">) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(25, 0, 0, 300);
                    gradient.addColorStop(0, "rgb(238 124 67 / 30%)");
                    gradient.addColorStop(1, "rgba(238, 124 ,67,0)");
                    return gradient;
                },
                borderColor: "rgb(238 124 67)",
                borderWidth: 1,
                pointRadius: 4,
                tension: 0.4,
                responsive: true,
            },
        ],
    };
    return (
        <Line
            data={lineData}
            options={options_Line}
            id="chart"
        ></Line>
    );
}
export default LineChart;
