import {
  Typography,
  Modal,
  Box,
  TextField,
  Alert,
  FormControl,
  Select,
  MenuItem,
  styled,
  InputBase,
  FormControlLabel,
  Radio,
  FormLabel,
  RadioGroup,
  Autocomplete,
  Switch,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import colors from "../../../common/colors";
import ResponsiveDrawer from "../../../common/ResponsiveDrawer";
import "../../../style/user/Ponds.css";
import "../../../style/loader.css";
import { useTranslation } from "react-i18next";
import no_alarms_found from "../../../images/no_alarms_found.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  CLOUD_LICENSE,
  DEVICE_LICENSE,
  DEVICE_SC_20K,
  DEVICE_SC_4K,
  DEVICE_SP_1,
  M12,
  M3,
  NA,
  NO,
  N_A,
  PAID,
  SLA,
  UNPAID,
  WEBAPP,
  YES,
} from "../../../common/configs";
import {
  generateCode,
  getFormatDateFromUnixTimestamp,
  getTimestampWithFormatDate,
  nanoTimeToDateTimeString,
} from "../../../common/utils";
import { Timestamp } from "firebase/firestore";
import {
  addLicenseToDB,
  deleteLicenses,
  getLicenses,
  getPlans,
  updateLicenseToDB,
} from "../../../database/user/licensing";
import { getDevicesByPid } from "../../../database/user/devices";
import moment from "moment";
import * as XLSX from "xlsx";
import { Error } from "../../../common/CustomToast";
import { NUMBER_OF_USERS_UNLIMITED } from "../../../database/config";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "8px 26px 8px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const label = { inputProps: { 'aria-label': 'Size switch demo' } };

function LicenseDetails() {
  const navigation = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const userData = location.state?.userData;
  const [isShowAppBar, setIsShowAppBar] = useState(true);
  const [isAddLoading, setIsAddLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [licenseTab, setLicenseTab] = useState(0);
  const [lstDevicePool, setDstDevicePool] = useState([]);
  const [lstCloudLicense, setLstCloudLicense] = useState([]);
  const [licenseData, setLicenseData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [oldSelectedDeviceName, setOldSelectedDeviceName] = useState({});
  const [inputFields, setInputFields] = useState({
    name: "",
    licenseType: SLA,
    deviceType: DEVICE_SC_4K,
    deviceName: "",
    duration: M3,
    recurring: YES,
    price: 0,
    paymentStatus: PAID,
    cloudLicense: "",
    plansPrice: 0,
    plansRecords: 0,
    planUsers: 0,
    active: false,
    isActivateDevice: true,
    lastPaymentDate: "",
  });
  const [errors, setErrors] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteObj] = useState();
  const [licensesLoading, setLicensesLoading] = useState(true);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputFields({ ...inputFields, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const isModalClose = () => {
    setInputFields({
      name: "",
      licenseType: SLA,
      deviceType: DEVICE_SC_4K,
      deviceName: "",
      duration: M3,
      recurring: YES,
      price: 0,
      paymentStatus: PAID,
      cloudLicense: "",
      plansPrice: 0,
      plansRecords: 0,
      planUsers: 0,
      active: false,
      isActivateDevice: false,
      lastPaymentDate: "",
    });
    onLoad();
    setIsOpenModal(false);
    setIsEdit(false);
    setIsAddLoading(false);
  };

  const onClickEdit = (objLicense) => {
    console.log('objLicense: 166', objLicense);
    // objLicense.deviceId
    setLicenseTab(objLicense.type === CLOUD_LICENSE ? 1 : 0);
    setIsEdit(true);
    setInputFields({
      id: objLicense.id,
      name: objLicense.name,
      licenseType: objLicense.type,
      deviceType: objLicense.deviceType,
      deviceName: objLicense.deviceId,
      duration: objLicense.duration,
      recurring: objLicense.isRecurring ? YES : NO,
      price: objLicense.price,
      paymentStatus: objLicense.paymentStatus,
      cloudLicense: objLicense.tier ? objLicense.tier : objLicense.type,
      plansPrice: objLicense.plansPrice,
      plansRecords: objLicense.plansRecords,
      planUsers: objLicense.planUsers,
      type: objLicense.type,
      active: objLicense.isActive,
      isActivateDevice: objLicense.isActivateDevice,
      lastPaymentDate: objLicense?.lastPaymentDate
        ? getTimestampWithFormatDate(objLicense?.lastPaymentDate, "YYYY-MM-DD")
        : objLicense?.lastPaymentDate,
    });
    setOldSelectedDeviceName({
      deviceType: objLicense.deviceType,
      deviceId: objLicense.deviceId,
    });
    setIsOpenModal(true);
  };

  function hasCloudLicense() {
    for (const obj of licenseData) {
      if (obj.type === CLOUD_LICENSE) {
        return true;
      }
    }
    return false;
  }
  const onClickAdd = async () => {
    if (licenseTab === 1 && !isEdit) {
      if (hasCloudLicense()) {
        Error(t("licenseDetails.this_user_already_has_active_cloud_license"));
        return;
      }
    }
    setIsAddLoading(true);
    let errors = {};

    // Validation logic - for example, checking if the fields are not empty
    if (inputFields.name.trim() === "") {
      errors.name = t("licenseDetails.name_is_required");
    }
    if (
      licenseTab === 0 &&
      inputFields.licenseType === "SLA" &&
      inputFields.deviceName === ""
    ) {
      errors.deviceName = t("licenseDetails.please_select_device");
    }

    if (licenseTab === 1) {
      if (inputFields.cloudLicense === "") {
        errors.cloudLicense = t("licenseDetails.please_select_cloud_license");
      } else if (
        inputFields.cloudLicense === "Custom" &&
        inputFields.plansRecords === 0
      ) {
        errors.plansRecords = t("licenseDetails.plan_records_is_required");
      } else if (
        inputFields.cloudLicense === "Custom" &&
        inputFields.planUsers === 0
      ) {
        errors.planUsers = t("licenseDetails.plan_users_is_required");
      }
    }
    if (
      inputFields.paymentStatus == "Paid" &&
      inputFields?.lastPaymentDate.trim() === ""
    ) {
      errors.lastPaymentDate = t("licenseDetails.payment_date_is_required");
    }

    // If there are errors, set them and return
    if (Object.keys(errors).length > 0) {
      setIsAddLoading(false);
      setErrors(errors);
      return;
    }
    const startDateTime = moment().toDate();
    const endDateTime = moment(startDateTime)
      .clone()
      .add(inputFields.duration === M3 ? 3 : 12, "months")
      .toDate();
    let objFinal = {
      pId: userData.uid, // -pId (main admin id)
      licenseName: generateCode(inputFields.licenseType === SLA ? "S" : "D"), // -licenseName(visible to user, random alphanumeric)
      name: inputFields.name, // -name
      type: inputFields.licenseType, // -type(SLA,Device,Cloud)
      duration: inputFields.duration, // -duration(it will be type, 3M,12M etc)
      deviceType: inputFields.deviceType, // -deviceType
      consumedByUserName: userData.fullName, // -consumedByUserName
      paymentStatus: inputFields.paymentStatus, // -paymentStatus (paid, not paid)
      paymentDate: startDateTime, // -paymentDate
      deviceId: inputFields.deviceName, // -deviceId (which device has this license, no deviceId means the license is not applied to any device yet)
      isRecurring: inputFields.recurring === YES ? true : false, // -isRecurring
      price: inputFields.price, // -price
      createdAt: startDateTime,
      isActivateDevice: inputFields.isActivateDevice
    };
    console.log('inputFields.isActivateDevice: 269', inputFields.isActivateDevice);
    if (inputFields?.lastPaymentDate) {
      objFinal = {
        ...objFinal,
        lastPaymentDate: moment(inputFields?.lastPaymentDate).toDate(),
      };
    }
    if (inputFields.paymentStatus == UNPAID) {
      objFinal = {
        ...objFinal,
        lastPaymentDate: "",
      };
    }
    if (inputFields.licenseType === SLA) {
      objFinal = {
        ...objFinal,
        startedOn: startDateTime, // -startedOn (server timestamp)
        endedOn: endDateTime, // -endedOn (server timestamp)
      };
    }
    let cloudLicenseObj = {
      pId: userData.uid, // -pId (main admin id)
      name: inputFields.name, // -name
      type: CLOUD_LICENSE,
      tier: inputFields.cloudLicense,
      plansPrice: inputFields.plansPrice,
      plansRecords: inputFields.plansRecords,
      planUsers: inputFields.planUsers,
      deviceType: WEBAPP,
      duration: NA,
      paymentStatus: inputFields.paymentStatus, // -paymentStatus (paid, not paid)
      price: inputFields.price, // -price
      createdAt: startDateTime,
      isActive: isEdit ? inputFields.active : true,
      lastPaymentDate: moment(inputFields?.lastPaymentDate).toDate(),
    };
    if (inputFields?.lastPaymentDate) {
      cloudLicenseObj = {
        ...cloudLicenseObj,
        lastPaymentDate: moment(inputFields?.lastPaymentDate).toDate(),
      };
    }
    if (inputFields.paymentStatus == UNPAID) {
      cloudLicenseObj = {
        ...cloudLicenseObj,
        lastPaymentDate: "",
      };
    }
    if (isEdit) {
      const res = await updateLicenseToDB(
        licenseTab === 1
          ? { ...cloudLicenseObj, id: inputFields.id }
          : { ...objFinal, id: inputFields.id }
      );
    } else {
      const res = await addLicenseToDB(
        licenseTab === 1 ? cloudLicenseObj : objFinal
      );
    }
    isModalClose();
    setIsAddLoading(false);
  };

  function manipulateArray(data) {
    // Remove the object with name "FREE"
    data = data.filter((item) => item.name !== "FREE");

    // Add new objects
    data.push({
      id: "custom_id_1",
      name: "Custom",
      numberOfRecords: 0,
      numberOfUsers: 0,
      productId: "custom_product_id_1",
      isDeviceAvailable: true,
      price: 0,
      lastPaymentDate: "",
    });

    return data;
  }

  const onLoad = async () => {
    if (inputFields.deviceType) {
      setLicensesLoading(true);
      const licenseData = await getLicenses(userData.uid);
      setLicenseData(licenseData);
      const plansData = await getPlans();
      setLstCloudLicense(manipulateArray(plansData));
      setLicensesLoading(false);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  const getDevicesList = async () => {
    const data = await getDevicesByPid(
      inputFields.deviceType,
      inputFields.licenseType
    );
    if (data) {
      if (
        oldSelectedDeviceName.deviceId &&
        oldSelectedDeviceName.deviceType === inputFields.deviceType
      ) {
        setDstDevicePool([
          ...data,
          { deviceId: oldSelectedDeviceName.deviceId },
        ]);
      } else {
        setDstDevicePool(data);
      }
    }
  };

  useEffect(() => {
    getDevicesList();
  }, [inputFields.deviceType, oldSelectedDeviceName, inputFields.licenseType]);

  const downloadCSV = (data, filename) => {
    const filteredData = data.map((item) => {
      // Convert timestamps to human-readable time strings
      const startedOn = item.startedOn
        ? getFormatDateFromUnixTimestamp(item.startedOn.seconds, "DD-MM-YYYY")
        : "";
      const paymentDate = item.paymentDate
        ? getFormatDateFromUnixTimestamp(item.paymentDate.seconds, "DD-MM-YYYY")
        : "";
      const createdAt = item.createdAt
        ? getFormatDateFromUnixTimestamp(item.createdAt.seconds, "DD-MM-YYYY")
        : "";
      const endedOn = item.endedOn
        ? getFormatDateFromUnixTimestamp(item.endedOn.seconds, "DD-MM-YYYY")
        : "";
      const lastPaymentDate = item.lastPaymentDate
        ? getFormatDateFromUnixTimestamp(
            item.lastPaymentDate.seconds,
            "DD-MM-YYYY"
          )
        : "";

      return {
        Name: item.name,
        "License Type": item.type,
        "Device Type": item.deviceType,
        "Device ID": item.deviceId,
        Duration: item.duration,
        Recurring: item.isRecurring,
        "Start Date": startedOn,
        "Expiry Date": endedOn,
        "Consumed by": item.consumedByUserName,
        "Payment Status": item.paymentStatus,
        Price: item.price,
        "Payment Date": paymentDate,
        "Created Date": createdAt,
        "Last Payment Date": lastPaymentDate,
      };
    });

    // Convert filtered data to worksheet
    const ws = XLSX.utils.json_to_sheet(filteredData);

    // Convert worksheet to CSV format
    const csv = XLSX.utils.sheet_to_csv(ws);

    // Create a Blob object for the CSV data
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });

    // Create a temporary download link
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename || "licenseList.csv";
    link.click();
  };

  const filterOptions = (options, { inputValue }) => {
    return options.filter((option) =>
      option.deviceId.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const autoValue = (value) => {
    const selectedObj =
      lstDevicePool && lstDevicePool.find((item) => item.deviceId === value);
    return selectedObj;
  };

  const cloudLicenseAutoValue = (value) => {
    const selectedObj =
      lstCloudLicense && lstCloudLicense.find((item) => item.name === value);
    return selectedObj;
  };

  return (
    <div>
      <ResponsiveDrawer
        isShowAppBar={isShowAppBar}
        headText={`${t("licenseDetails.license")} - ${userData?.fullName}`}
        isHideToolBar={true}
        isBackArrow={true}
        onClickBack={() => {
          navigation("/superadmin/users");
        }}
      >
        <div style={{ marginTop: -50 }}>
          {/* ----- HEAD SEARCHES ----- */}
          <div className="head_container hedera_ui_css_block">
            <div></div>
            <div className="column base_container pons-button-css-block">
              {licenseData.length !== 0 && (
                <button
                  className="ui primary button"
                  style={{ width: "auto", height: 40, marginRight: 15 }}
                  onClick={() => {
                    downloadCSV(licenseData, "licenseList.csv");
                  }}
                >
                  {t("licenseDetails.export_csv")}
                </button>
              )}
              <button
                className="ui primary button"
                style={{ width: "auto", height: 40 }}
                onClick={(e) => {
                  // e.stopPropagation();
                  setLicenseTab(0);
                  setIsOpenModal(true);
                  setIsEdit(false);
                  setInputFields({
                    name: "",
                    licenseType: SLA,
                    deviceType: DEVICE_SC_4K,
                    deviceName: "",
                    duration: M3,
                    recurring: YES,
                    price: 0,
                    paymentStatus: PAID,
                    cloudLicense: "",
                    plansPrice: 0,
                    plansRecords: 0,
                    planUsers: 0,
                    active: false,
                    isActivateDevice: false,
                    lastPaymentDate: "",
                  });
                  setOldSelectedDeviceName({});
                }}
              >
                {t("licenseDetails.add_license")}
              </button>
            </div>
          </div>
          {/* ----- CARDS TABLE ----- */}
          <div className="cards_main_block">
            <div className="inventory_table_wrap">
              <div className="table-responsive cm_table_responsive">
                {licensesLoading ? (
                  <div
                    style={{
                      display: "flex",
                      height: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <div id={`load`} className="loader" />
                  </div>
                ) : licenseData.length === 0 ? (
                  <div
                    style={{
                      display: "flex",
                      height: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                    }}
                    className="no-alarms-found-css"
                  >
                    <img src={no_alarms_found} alt="no_alarms_found" />
                    <Typography sx={{ fontSize: 17, fontWeight: "600" }}>
                      {t("ponds.pond_data_not_found")}
                    </Typography>
                  </div>
                ) : (
                  <table className="cm_table fold-table">
                    <thead className="cm_thead">
                      <tr className="cm_thead_list">
                        <th className="th_list cm_name">
                          <div className="th_item_wrap">
                            <h6>{t("userManagement.name")}</h6>
                          </div>
                        </th>
                        <th className="th_list cm_name">
                          <div className="th_item_wrap">
                            <h6>{t("home.type")}</h6>
                          </div>
                        </th>
                        <th className="th_list cm_name">
                          <div className="th_item_wrap">
                            <h6>{t("licenseDetails.duration")}</h6>
                          </div>
                        </th>
                        <th className="th_list cm_name">
                          <div className="th_item_wrap">
                            <h6>{t("device.device_type")}</h6>
                          </div>
                        </th>
                        <th className="th_list cm_name">
                          <div className="th_item_wrap">
                            <h6>{t("device.device_name")}</h6>
                          </div>
                        </th>
                        <th className="th_list cm_name">
                          <div className="th_item_wrap">
                            <h6>{t("licenseDetails.payment_status")}</h6>
                          </div>
                        </th>
                        <th className="th_list cm_name">
                          <div className="th_item_wrap">
                            <h6>{t("licenseDetails.last_payment_date")}</h6>
                          </div>
                        </th>
                        <th className="th_list cm_name"></th>
                      </tr>
                    </thead>
                    <tbody className="cm_tbody">
                      {licenseData.map((objLicense) => {
                        return (
                          <>
                            <tr
                              key={Math.random()}
                              className={`cm_tr view ${
                                objLicense.paymentStatus == UNPAID
                                  ? "unpaid-line"
                                  : ""
                              }`}
                            >
                              <td className="cm_td">
                                <h4>{objLicense.name}</h4>
                              </td>
                              <td className="cm_td">
                                <h4>
                                  {objLicense.tier
                                    ? objLicense.tier
                                    : objLicense.type}
                                </h4>
                              </td>
                              <td className="cm_td">
                                <h4>{objLicense.duration}</h4>
                              </td>
                              <td className="cm_td">
                                <h4>{objLicense.deviceType}</h4>
                              </td>
                              <td className="cm_td">
                                <h4>
                                  {objLicense.deviceId
                                    ? objLicense.deviceId
                                    : "-"}
                                </h4>
                              </td>
                              <td className="cm_td">
                                <h4>{objLicense.paymentStatus}</h4>
                              </td>
                              <td className="cm_td">
                                <h4>
                                  {objLicense.lastPaymentDate ? getTimestampWithFormatDate(
                                    objLicense.lastPaymentDate,
                                    "DD-MM-YYYY"
                                  ) : "-"}
                                </h4>
                              </td>
                              <td className="cm_td dev_pool_cm">
                                <p
                                  onClick={() => {
                                    onClickEdit(objLicense);
                                  }}
                                  className="look_icon_css green_icon_edit"
                                >
                                  <EditOutlinedIcon />
                                </p>
                                <p
                                  onClick={() => {
                                    setDeleteObj(objLicense);
                                    setDeleteModal(true);
                                  }}
                                  className="look_icon_css red_icon_eye"
                                >
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M2.5 5H4.16667H17.5"
                                      stroke="#EF5350"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                    <path
                                      d="M15.8332 5.00008V16.6667C15.8332 17.1088 15.6576 17.5327 15.345 17.8453C15.0325 18.1578 14.6085 18.3334 14.1665 18.3334H5.83317C5.39114 18.3334 4.96722 18.1578 4.65466 17.8453C4.3421 17.5327 4.1665 17.1088 4.1665 16.6667V5.00008M6.6665 5.00008V3.33341C6.6665 2.89139 6.8421 2.46746 7.15466 2.1549C7.46722 1.84234 7.89114 1.66675 8.33317 1.66675H11.6665C12.1085 1.66675 12.5325 1.84234 12.845 2.1549C13.1576 2.46746 13.3332 2.89139 13.3332 3.33341V5.00008"
                                      stroke="#EF5350"
                                      strokeWidth="1.4"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                    <path
                                      d="M8.3335 9.16675V14.1667"
                                      stroke="#EF5350"
                                      strokeWidth="1.4"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                    <path
                                      d="M11.6665 9.16675V14.1667"
                                      stroke="#EF5350"
                                      strokeWidth="1.4"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                  </svg>
                                </p>
                              </td>
                            </tr>
                            <tr className="cm_hi"></tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>

          {/* ------ ADD MODAL ------ */}
          <Modal
            open={isOpenModal}
            onClose={isModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={boxStyle} className="add-Pond-location">
              {/* <div style={{ height: "600px", overflow: "auto" }}> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "auto",
                  justifyContent: "space-between",
                }}
              >
                <div
                  className="hiding-pond-css"
                  style={{
                    display: "flex",
                    width: "100%",
                    backgroundColor: colors.slatery_blue,
                    justifyContent: "space-between",
                    padding: 8,
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 18,
                      color: "white",
                      fontWeight: "600",
                      marginLeft: 1,
                    }}
                  >
                    {isEdit
                      ? t("licenseDetails.edit_license")
                      : t("licenseDetails.add_license")}
                  </Typography>
                  <i
                    className="x icon"
                    style={{ color: "white", fontSize: 22 }}
                    onClick={(e) => {
                      // e.stopPropagation();
                      setIsOpenModal(false);
                    }}
                  />
                </div>
                <div className="license_tab_block">
                  <ul className="tabs">
                    <li
                      style={{
                        pointerEvents:
                          !isEdit || inputFields.type === DEVICE_LICENSE
                            ? "auto"
                            : "none",
                      }}
                      className={`tab-item ${licenseTab === 0 ? "active" : ""}`}
                      onClick={() => setLicenseTab(0)}
                    >
                      {t("device.devices")}
                    </li>
                    <li
                      style={{
                        pointerEvents:
                          !isEdit || inputFields.type === CLOUD_LICENSE
                            ? "auto"
                            : "none",
                      }}
                      className={`tab-item ${licenseTab === 1 ? "active" : ""}`}
                      onClick={() => setLicenseTab(1)}
                    >
                      {t("licenseDetails.cloud")}
                    </li>
                  </ul>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "650px",
                    overflow: "auto",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="radio_group_block">
                    <TextField
                      id="name"
                      label={t("home.name")}
                      type="text"
                      // variant="standard"
                      name="name"
                      value={inputFields.name}
                      onChange={handleInputChange}
                      sx={{
                        width: "100%",
                        fontSize: 18,
                      }}
                      disabled={
                        inputFields.type === CLOUD_LICENSE ? false : isEdit
                      }
                    />
                    {errors.name && (
                      <p style={{ color: "red" }}>{errors.name}</p>
                    )}
                  </div>

                  {licenseTab === 0 ? (
                    <>
                      <div className="radio_group_block">
                        <h5>{t("licenseDetails.license_type")}</h5>
                        <RadioGroup
                          aria-label="licenseType"
                          name="licenseType"
                          value={inputFields.licenseType}
                          onChange={(e) => {
                            setInputFields({
                              ...inputFields,
                              licenseType: e.target.value,
                            });
                            setErrors({
                              ...errors,
                              deviceName: "",
                            });
                          }}
                        >
                          <FormControlLabel
                            value={SLA}
                            control={<Radio />}
                            label={t("licenseDetails.sla")}
                            disabled={isEdit}
                          />
                          <FormControlLabel
                            value={DEVICE_LICENSE}
                            control={<Radio />}
                            label={t("licenseDetails.device_license")}
                            disabled={isEdit}
                          />
                        </RadioGroup>
                      </div>
                      <div className="radio_group_block">
                        <h5>{t("device.device_type")}</h5>
                        <RadioGroup
                          aria-label="gender"
                          name="deviceType"
                          value={inputFields.deviceType}
                          onChange={(e) => {
                            setInputFields({
                              ...inputFields,
                              deviceType: e.target.value,
                              deviceName: "",
                            });
                          }}
                        >
                          <FormControlLabel
                            value={DEVICE_SC_4K}
                            control={<Radio />}
                            label={DEVICE_SC_4K}
                            disabled={isEdit}
                          />
                          <FormControlLabel
                            value={DEVICE_SC_20K}
                            control={<Radio />}
                            label={DEVICE_SC_20K}
                            disabled={isEdit}
                          />
                          <FormControlLabel
                            value={DEVICE_SP_1}
                            control={<Radio />}
                            label={DEVICE_SP_1}
                            disabled={isEdit}
                          />
                        </RadioGroup>
                      </div>
                      <div className="radio_group_block">
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          <h5 style={{ marginRight: 5 }}>
                            {t("device.device-name")}
                          </h5>
                          {isEdit && oldSelectedDeviceName.deviceId ? (
                            <p style={{ fontSize: 15 }}>
                              :- {inputFields.deviceName}
                            </p>
                          ) : (
                            <Box style={{ width: "100%", marginTop: 10 }}>
                              <Autocomplete
                                options={lstDevicePool}
                                getOptionLabel={(option) => option.deviceId}
                                filterOptions={filterOptions}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={t("device.device-name")}
                                    variant="outlined"
                                  />
                                )}
                                value={autoValue(inputFields.deviceName)}
                                onChange={(event, value) => {
                                  handleInputChange({
                                    target: {
                                      value: value?.deviceId,
                                      name: "deviceName",
                                    },
                                  });
                                }}
                              />
                              {errors.deviceName && (
                                <p style={{ color: "red" }}>
                                  {errors.deviceName}
                                </p>
                              )}
                            </Box>
                          )}
                        </div>
                      </div>
                      <div 
                      className="radio_group_block" 
                      style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                      }}>
                        <h5>{t("licenseDetails.activate_device")}</h5>
                        <Switch 
                          {...label} 
                          name="isActivateDevice"
                          checked={inputFields.isActivateDevice}
                          onChange={(e) => {
                            setInputFields({
                              ...inputFields,
                              isActivateDevice: e.target.checked,
                          });
                        }}
                        disabled={isEdit}
                        />
                      </div>
                      <div className="radio_group_block">
                        <h5>{t("licenseDetails.duration")}</h5>
                        <RadioGroup
                          aria-label="licenseType"
                          name="duration"
                          value={inputFields.duration}
                          onChange={handleInputChange}
                        >
                          <FormControlLabel
                            value={M3}
                            control={<Radio />}
                            label={M3}
                          />
                          <FormControlLabel
                            value={M12}
                            control={<Radio />}
                            label={M12}
                          />
                        </RadioGroup>
                      </div>
                      <div className="radio_group_block">
                        <h5>{t("licenseDetails.recurring")}</h5>
                        <RadioGroup
                          aria-label="licenseType"
                          name="recurring"
                          value={inputFields.recurring}
                          onChange={handleInputChange}
                        >
                          <FormControlLabel
                            value={YES}
                            control={<Radio />}
                            label={t("ponds.yes")}
                          />
                          <FormControlLabel
                            value={NO}
                            control={<Radio />}
                            label={t("ponds.no")}
                          />
                        </RadioGroup>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="radio_group_block">
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          <h5 style={{ marginRight: 5 }}>
                            {t("device.cloud_license")}
                          </h5>
                          {isEdit ? (
                            <p style={{ fontSize: 15 }}>
                              :- {inputFields.cloudLicense}
                            </p>
                          ) : (
                            <Box style={{ width: "100%", marginTop: 10 }}>
                              <Autocomplete
                                options={lstCloudLicense}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={t("device.cloud_license")}
                                    variant="outlined"
                                  />
                                )}
                                value={cloudLicenseAutoValue(inputFields.name)}
                                onChange={(event, value) => {
                                  console.log("value", value);
                                  setInputFields({
                                    ...inputFields,
                                    cloudLicense: value?.name,
                                    plansPrice: value?.price,
                                    plansRecords: value.numberOfRecords,
                                    planUsers: value.numberOfUsers,
                                  });
                                  setErrors({
                                    ...errors,
                                    cloudLicense: "",
                                    plansRecords: "",
                                    planUsers: "",
                                  });
                                }}
                              />
                              {errors.cloudLicense && (
                                <p style={{ color: "red" }}>
                                  {errors.cloudLicense}
                                </p>
                              )}
                            </Box>
                          )}
                        </div>
                      </div>
                      {inputFields.cloudLicense ? (
                        <div className="radio_group_block">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <h5>{t("home.active")} :-</h5>
                            <Switch
                              name="active"
                              onChange={() => {
                                setInputFields({
                                  ...inputFields,
                                  active: !inputFields.active,
                                });
                              }}
                              checked={inputFields.active}
                            />
                          </div>
                        </div>
                      ) : null}
                      <div className="radio_group_block">
                        <h5 style={{ marginRight: 5, marginBottom: 10 }}>
                          {t("device.plan_price")}
                        </h5>
                        <TextField
                          id="plansPrice"
                          label={t("device.plan_price")}
                          type="number"
                          name="plansPrice"
                          value={inputFields.plansPrice}
                          onChange={handleInputChange}
                          sx={{
                            width: "100%",
                            fontSize: 18,
                          }}
                          disabled={!(inputFields.cloudLicense === "Custom")}
                        />
                      </div>
                      <div className="radio_group_block">
                        <h5 style={{ marginRight: 5, marginBottom: 10 }}>
                          {t("device.plan_records")}
                        </h5>
                        <TextField
                          id="plansRecords"
                          label={t("device.plan_records")}
                          type="number"
                          name="plansRecords"
                          value={inputFields.plansRecords}
                          onChange={handleInputChange}
                          sx={{
                            width: "100%",
                            fontSize: 18,
                          }}
                          disabled={!(inputFields.cloudLicense === "Custom")}
                        />
                        {errors.plansRecords && (
                          <p style={{ color: "red" }}>{errors.plansRecords}</p>
                        )}
                      </div>
                      <div className="radio_group_block">
                        <h5 style={{ marginRight: 5, marginBottom: 10 }}>
                          {t("device.plan_users")}
                        </h5>
                        <TextField
                          id="planUsers"
                          label={t("device.plan_users")}
                          type={
                            inputFields.planUsers == NUMBER_OF_USERS_UNLIMITED
                              ? "text"
                              : "number"
                          }
                          name="planUsers"
                          value={inputFields.planUsers}
                          onChange={handleInputChange}
                          sx={{
                            width: "100%",
                            fontSize: 18,
                          }}
                          disabled={!(inputFields.cloudLicense === "Custom")}
                        />
                        {errors.planUsers && (
                          <p style={{ color: "red" }}>{errors.planUsers}</p>
                        )}
                      </div>
                    </>
                  )}

                  <div className="radio_group_block">
                    <h5>{t("licenseDetails.payment_status")}</h5>
                    <RadioGroup
                      aria-label="paymentStatus"
                      name="paymentStatus"
                      value={inputFields.paymentStatus}
                      onChange={handleInputChange}
                    >
                      <FormControlLabel
                        value={PAID}
                        control={<Radio />}
                        label={t("userManagement.paid")}
                      />
                      <FormControlLabel
                        value={UNPAID}
                        control={<Radio />}
                        label={t("invoice.unpaid")}
                      />
                    </RadioGroup>
                  </div>
                  {licenseTab === 0 ? (
                    <>
                      <div className="radio_group_block">
                        <TextField
                          id="Price"
                          label={t("licenseDetails.price")}
                          type="number"
                          // variant="standard"
                          name="price"
                          value={inputFields.price}
                          onChange={handleInputChange}
                          sx={{
                            width: "100%",
                            fontSize: 18,
                          }}
                        />
                      </div>
                    </>
                  ) : null}
                  {inputFields.paymentStatus == "Paid" && (
                    <div className="radio_group_block">
                      <h5 style={{ marginRight: 5, marginBottom: 10 }}>
                        {t("licenseDetails.last_payment_date")}
                      </h5>
                      <TextField
                        id="lastPaymentDate"
                        label={" "}
                        type="date"
                        name="lastPaymentDate"
                        value={inputFields.lastPaymentDate}
                        onChange={handleInputChange}
                        sx={{
                          width: "100%",
                          fontSize: 18,
                        }}
                      />
                      {errors.lastPaymentDate && (
                        <p style={{ color: "red" }}>{errors.lastPaymentDate}</p>
                      )}
                    </div>
                  )}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: 10,
                      marginTop: 10,
                    }}
                  >
                    <LoadingButton
                      className={isAddLoading ? "license_generate_btn" : ""}
                      onClick={onClickAdd}
                      loadingPosition="start"
                      // startIcon={<Save />}
                      variant="contained"
                      sx={{ backgroundColor: colors.slatery_blue }}
                      loading={isAddLoading}
                      disabled={isAddLoading}
                    >
                      {t("ponds.submit")}
                    </LoadingButton>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </Box>
          </Modal>
          {/* ------ Delete MODAL ------ */}
          <Modal
            open={deleteModal}
            onClose={() => setDeleteModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={[boxStyle, { width: 400 }]}
              className="logout-css-modal-block"
            >
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{
                  paddingTop: 2,
                  textAlign: "center",
                  marginBottom: 2,
                  paddingLeft: 1,
                  paddingRight: 1,
                }}
              >
                {t("ponds.are_you_sure_you_want_to_delete_this")}
              </Typography>

              <div className="logout-button-block">
                <Typography
                  sx={textStyle}
                  onClick={() => {
                    setDeleteModal(false);
                  }}
                >
                  {t("ponds.cancel")}
                </Typography>
                <div
                  style={{
                    width: "2px",
                    border: "1px solid #999",
                    opacity: 0.5,
                  }}
                />
                <Typography
                  className="delete_bg_color"
                  sx={[textStyle, { color: "red" }]}
                  onClick={async () => {
                    setDeleteModal(false);
                    await deleteLicenses(deleteId);
                    onLoad();
                  }}
                >
                  {t("ponds.delete")}
                </Typography>
              </div>
            </Box>
          </Modal>
        </div>
      </ResponsiveDrawer>
    </div>
  );
}

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: window.innerWidth > 375 ? 400 : 270,
  width: 550,
  bgcolor: "background.paper",
  border: "2px solid",
  borderColor: "gray",
  borderRadius: 3,
  boxShadow: 24,
};

const textStyle = {
  color: colors.slatery_blue,
  fontSize: 17,
  padding: 1,
  textAlign: "center",
  width: "100%",
  fontWeight: "600",
};

export default LicenseDetails;
