import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getInviteHistory } from "../../../database/user/inviteHistory";
import { Typography } from "@mui/material";
import "../../../style/loader.css";
import "../../../style/user/Ponds.css";
import ResponsiveDrawer from "../../../common/ResponsiveDrawer";
import { useNavigate } from "react-router-dom";
import UserList from "../../../components/user/UserList";
// import CopyToClipboard from "react-copy-to-clipboard";
// import ContentCopyIcon from '@mui/icons-material/ContentCopy';

function InviteHistory() {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const [inviteHistory, setInviteHistory] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchInviteHistory = async () => {
      try {
        const data = await getInviteHistory();
        setInviteHistory(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchInviteHistory();
  }, []);

  return (
    <>
      <ResponsiveDrawer
        isShowAppBar={true}
        headText={t("inviteHistory.invite_History")}
        isHideToolBar={true}
        isBackArrow={true}
        onClickBack={() => {
          navigation("/superadmin/users");
        }}
      >
        <div className="cards_main_block">
          <div className="inventory_table_wrap">
            <div className="table-responsive cm_table_responsive">
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <div id={`load`} className="loader" />
                </div>
              ) : inviteHistory.length > 0 ? (
                <table className="cm_table fold-table super_admin_table">
                  <thead className="cm_thead" style={{ paddingRight: 14 }}>
                    <tr className="cm_thead_list">
                      <th className="th_list cm_name sorting_heading">
                        <div className="th_item_wrap">
                          <h6>{t("home.name")}</h6>
                        </div>
                      </th>
                      <th className="th_list cm_name sorting_heading">
                        <div className="th_item_wrap">
                          <h6>{t("home.email")}</h6>
                        </div>
                      </th>
                      <th className="th_list cm_name sorting_heading">
                        <div className="th_item_wrap">
                          <h6>{t("licenseDetails.company_name")}</h6>
                        </div>
                      </th>
                      <th className="th_list cm_name sorting_heading">
                        <div className="th_item_wrap">
                          <h6>{t("signedUp")}</h6>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="cm_tbody">
                    {inviteHistory.map((item) => (
                      <>
                        <tr key={item.id} className="cm_tr view">
                          <td className="cm_td">{item.fullName}</td>
                          <td className="cm_td">{item.email}</td>
                          <td className="cm_td">{item.companyName}</td>
                          <td className="cm_td">
                            {item.isSignup ? (
                              <svg
                                width="25"
                                height="25"
                                viewBox="0 0 64 64"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M56 2L18.8 42.909L8 34.729H2L18.8 62L62 2H56Z"
                                  fill="#ee7c45"
                                />
                              </svg>
                            ) : (
                              "-"
                            )}
                          </td>
                          {/* <td className="cm_td">
                            <CopyToClipboard
                              text={`${window.location.origin}/inviteSignUp/${item.key}`}
                            >
                              <button>
                                <ContentCopyIcon />
                              </button>
                            </CopyToClipboard>
                          </td> */}
                        </tr>
                        <tr className="cm_hi"></tr>
                      </>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div
                  style={{
                    display: "flex",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Typography sx={{ fontSize: 17, fontWeight: "600" }}>
                    {t("device.device_not_found")}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </div>
      </ResponsiveDrawer>
    </>
  );
}

export default InviteHistory;
