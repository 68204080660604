import React from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useTranslation } from "react-i18next";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { getTimestampWithFormatDate } from "../../common/utils";

const TableRow = (props) => {
  const { val, onClickCard, onClickEdit, onClickDelete, showActions = true } = props;
  const { t } = useTranslation();
  
  // Example of conditional rendering
  return (
    <>
      <td className="cm_td" onClick={onClickCard}>
        <h4>{val.pondName}</h4>
      </td>
      <td className="cm_td" onClick={onClickCard}>
        <h4>{val.stockingAmountCycle ? val.stockingAmountCycle : 0}</h4>
      </td>
      <td className="cm_td dev_pool_cm" onClick={onClickCard}>
        {val.isNursery ? (
          <svg
            width="25"
            height="25"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M56 2L18.8 42.909L8 34.729H2L18.8 62L62 2H56Z"
              fill="#ee7c45" />
          </svg>
        ) : (
          "-"
        )}
      </td>
      <td className="cm_td" onClick={onClickCard}>
        <h4>{val?.currentCycleStartDate ? getTimestampWithFormatDate(val?.currentCycleStartDate,"DD/MM/YYYY") : t("ponds.closed")}</h4>
      </td>
      {/* Conditionally render actions */}
      {showActions && (
        <td className="cm_td">
          <p onClick={onClickCard} className="look_icon_css blue_icon_eye">
            <RemoveRedEyeIcon />
          </p>
          <p onClick={onClickEdit} className="look_icon_css green_icon_edit">
            <EditOutlinedIcon />
          </p>
          <p onClick={onClickDelete} className="look_icon_css red_icon_eye">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.5 5H4.16667H17.5"
                stroke="#EF5350"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M15.8332 5.00008V16.6667C15.8332 17.1088 15.6576 17.5327 15.345 17.8453C15.0325 18.1578 14.6085 18.3334 14.1665 18.3334H5.83317C5.39114 18.3334 4.96722 18.1578 4.65466 17.8453C4.3421 17.5327 4.1665 17.1088 4.1665 16.6667V5.00008M6.6665 5.00008V3.33341C6.6665 2.89139 6.8421 2.46746 7.15466 2.1549C7.46722 1.84234 7.89114 1.66675 8.33317 1.66675H11.6665C12.1085 1.66675 12.5325 1.84234 12.845 2.1549C13.1576 2.46746 13.3332 2.89139 13.3332 3.33341V5.00008"
                stroke="#EF5350"
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M8.3335 9.16675V14.1667"
                stroke="#EF5350"
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M11.6665 9.16675V14.1667"
                stroke="#EF5350"
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </p>
        </td>
      )}
    </>
  );
};

export default TableRow;
