import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInAnonymously
} from "firebase/auth";
import { query, collection, where, getDocs } from "firebase/firestore";
import { auth, db } from "../../config/firebase-config";

export const getUserByEmail = (email) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (email) {
        const q = query(
          collection(db, "Users"),
          where("email", "==", email)
        );
        let data = [];
        // onSnapshot(q, (snapshot) => {
        //   snapshot.docs.forEach((element) => {
        //     if (element.exists()) {
        //       data.push({ id: element.id, ...element.data() });
        //     }
        //   });
        //   return resolve(data);
        // });
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((val) => {
          data.push({ id: val.id, ...val.data() });
        });
        return resolve(data);
      } else {
        return resolve([]);
      }
    } catch (error) {
      return reject(error);
    }
  });
};

export const getRoleByUid = (uid) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (uid) {
        const q = query(
          collection(db, "Users"),
          where("uid", "==", uid)
        );
        let data = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((val) => {
          data.push({ id: val.id, ...val.data() });
        });

        const role = data[0].isSuperAdmin ? "superAdmin" : "admin"
        return resolve(role);
      } else {
        return resolve([]);
      }
    } catch (error) {
      return reject(error);
    }
  });
};


export const loginUser = async (email, password) => {
  return new Promise(async (resolve, reject) => {
    try {
      let userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      return resolve(userCredential.user);
    } catch (error) {
      return reject(error);
    }
  });
};

export const createAnonymousUser = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      let userCredential = await signInAnonymously(
        auth
      );
      return resolve(userCredential.user);
    } catch (error) {
      return reject(error);
    }
  });
};


export const resetPassword = async (email) => {
  return new Promise(async (resolve, reject) => {
    try {
      await sendPasswordResetEmail(auth, email);
      return resolve("Successfully send link to your email.");
    } catch (error) {
      return reject(error);
    }
  });
};
