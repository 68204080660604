import { Alert, Box, Card, Modal, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import colors from "../../common/colors";
import ResponsiveDrawer from "../../common/ResponsiveDrawer";
import CustomCard from "../../components/home/CustomCard";
import {
  getActivePondCycleFromDb,
  getCountersDataFromDb,
  getCountersMonthDataFromDb,
  getPlansRecordsFromDb,
  getTopAlarmsFromDb,
} from "../../database/user/home";
import "../../style/loader.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import imagePath from "../../common/imagePath";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import AlertModal from "../../components/AlertModal";
import { LoadingButton } from "@mui/lab";
import { Save } from "@mui/icons-material";
import { getProfileData, updateProfileById } from "../../database/user/profile";
import { getUnpaidInvoice } from "../../database/user/invoice";

import Ponds from "../../images/Ponds.png";
import Total_biomass from "../../images/Total_biomass.png";
import Number_users from "../../images/Number_users.png";
import Number_records from "../../images/Number_records.png";
import Number_devices from "../../images/Number_devices.png";
import Abnormal_biomass from "../../images/Abnormal_biomass.png";
import { getPond, getUserData } from "../../database/user/pond";
import no_alarms_found from "../../images/no_alarms_found.png";
import {
  getCalculateDaysDifference,
  getTimestampWithFormatDate,
} from "../../common/utils";
import { getDevicePool } from "../../database/user/devices";
import { ID } from "../../database/config";

function Home({ setsidebar, sidebar }) {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const [totalPonds, setTotalPonds] = useState(0);
  const [totalBiomass, setTotalBiomass] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [plansRecords, setPlansRecords] = useState(0);
  const [totalDevices, setTotalDevices] = useState(0);
  // const [abnormalBiomass, setAbnormalBiomass] = useState(0);
  // const [alarmData, setAlarmData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [picture, setPicture] = useState(null);
  const [isOpenPic, setIsOpenPic] = useState(false);
  const [isShowAppBar, setIsShowAppBar] = useState(true);

  const [isShowAlert, setIsShowAlert] = useState(false);
  const [isClickUser, setIsClickUser] = useState(false);
  const [isOpenAlertModal, setIsOpenAlertModal] = useState(false);
  // const [alarmDataLoader, setAlarmDataLoader] = useState(true);

  const [isOpenSubscribeModal, setIsOpenSubscribeModal] = useState(false);
  const [user, setUser] = useState({
    email: "",
    city: "",
    country: "",
    line1: "",
    line2: "",
    postalCode: "",
    state: "",
    name: "",
    phone: "",
  });
  const [showError, setShowError] = useState({
    isCity: false,
    isCountry: false,
    isLine1: false,
    isLine2: false,
    isPostalCode: false,
    isState: false,
    isName: false,
    isPhone: false,
  });
  const [isPremium, setIsPremium] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [isPaymentPendingDueDateExpired, setIsPaymentPendingDueDateExpired] =
    useState(false);

  useEffect(() => {
    getAllDatas();
    // getTopAlarms();
  }, []);

  // const getTopAlarms = async () => {
  //   const alarmList = await getTopAlarmsFromDb();
  //   let alarmArr = [];
  //   if (alarmList.length > 0) {
  //     for (let i = 0; i < alarmList.length; i++) {
  //       if (alarmList[i].shrimpSickStatus === "Yes" && alarmList[i].isLethal) {
  //         alarmArr.push(alarmList[i]);
  //       }
  //     }
  //     for (let i = 0; i < alarmList.length; i++) {
  //       if (alarmList[i].shrimpSickStatus === "Yes" && !alarmList[i].isLethal) {
  //         alarmArr.push(alarmList[i]);
  //       }
  //     }
  //     for (let i = 0; i < alarmList.length; i++) {
  //       if (alarmList[i].shrimpSickStatus === "Check needed") {
  //         alarmArr.push(alarmList[i]);
  //       }
  //     }
  //   }
  //   setAlarmData(alarmArr.length > 0 ? alarmArr : alarmList);
  //   setAlarmDataLoader(false);
  // };
  const getAllDatas = async () => {
    setIsLoader(true);
    try {
      let uid = localStorage.getItem("uid");
      if (!uid) {
        return;
      }
      let userData = await getUserData(uid);
      // 0.5 / 1 seconds
      if (!userData) {
        return;
      }
      let data = await getCountersDataFromDb();
      // 2 / 3 seconds
      let monthData = await getCountersMonthDataFromDb();
      let plansRecordsData = await getPlansRecordsFromDb();
      setPlansRecords(plansRecordsData);
      const pId = localStorage.getItem("pId");
      let devicePool = await getDevicePool(pId);
      let pondData = await getPond(pId);
      // console.log("pondData", pondData);
      const pondIds = [...new Set(pondData.map((item) => item[ID]))];
      // console.log("pondIds", pondIds);

      const profile = await getProfileData();
      if (localStorage.getItem("manager_user") !== "true") {
        const unpaidData = await getUnpaidInvoice();
        if (unpaidData.length > 0) {
          if (unpaidData[0].createdAt) {
            const days = getCalculateDaysDifference(unpaidData[0].createdAt);
            if (days > 7) {
              localStorage.setItem("stickyDays", `${days}`);
              setIsPaymentPendingDueDateExpired(true);
            } else {
              setIsPaymentPendingDueDateExpired(false);
            }
          }
        }
      }
      if (profile && profile.customerId) {
        localStorage.setItem("invoiceCid", profile.customerId);
      } else {
        localStorage.setItem("invoiceCid", null);
      }
      if (profile && profile.isPremium) {
        localStorage.setItem("premiumCust", "true");
      } else if (profile && profile.isSuperPremium) {
        localStorage.setItem("premiumCust", "true");
      } else {
        localStorage.setItem("premiumCust", null);
      }
      setUser({
        email: profile.email,
        city: profile.city ? profile.city : "",
        country: profile.country ? profile.country : "",
        line1: profile.line1 ? profile.line1 : "",
        line2: profile.line2 ? profile.line2 : "",
        postalCode: profile.postal_code ? profile.postal_code : "",
        state: profile.state ? profile.state : "",
        name: profile.comName,
        phone: profile.phone,
      });
      setIsPremium(profile.isPremium ? profile.isPremium : false);

      if (pondIds && pondIds.length > 0) {
        const activePondCycleList = await getActivePondCycleFromDb(pondIds);
        if (activePondCycleList.length > 0) {
          // let abnormalBiomassCount = 0;
          // for (let i = 0; i < activePondCycleList.length; i++) {
          //   abnormalBiomassCount = activePondCycleList[i].abnormalBiomass
          //     ? abnormalBiomassCount + activePondCycleList[i].abnormalBiomass
          //     : abnormalBiomassCount;
          // }

          console.log('activePondCycleList: 321', activePondCycleList);
          const totalStockingAmount = activePondCycleList.reduce((sum, item) => {
            // if(item.stockingAmount){
              // console.log('item.stockingAmount: ', item);
              return sum + (item.stockingAmount || 0);
            // }
        }, 0);
        
          console.log('totalStockingAmount: ', totalStockingAmount);
          setTotalBiomass(totalStockingAmount ? totalStockingAmount : 0);
          // setAbnormalBiomass(abnormalBiomassCount);
        }
      }
      setTotalPonds(data ? (data?.ponds  ? data.ponds  : 0) : 0);
      setTotalUsers(data ? (data.users ? data.users : 0) : 0);
      setTotalRecords(
        monthData ? (monthData.records ? monthData.records : 0) : 0
      );
      setTotalDevices(devicePool ? devicePool.length : 0);
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
    }
  };

  async function onClickSaveProfile() {
    setIsDisable(true);
    if (user.name === "") {
      setShowError({ ...showError, isName: true });
      setIsDisable(false);
      return;
    }
    if (user.phone === "") {
      setShowError({ ...showError, isPhone: true });
      setIsDisable(false);
      return;
    } else if (!user.phone?.startsWith("+")) {
      setShowError({ ...showError, isPhone: true });
      setIsDisable(false);
      return;
    }
    if (user.city === "") {
      setShowError({ ...showError, isCity: true });
      setIsDisable(false);
      return;
    }
    if (user.country === "") {
      setShowError({ ...showError, isCountry: true });
      setIsDisable(false);
      return;
    }
    if (user.state === "") {
      setShowError({ ...showError, isState: true });
      setIsDisable(false);
      return;
    }
    if (user.line1 === "") {
      setShowError({ ...showError, isLine1: true });
      setIsDisable(false);
      return;
    }
    if (user.line2 === "") {
      setShowError({ ...showError, isLine2: true });
      setIsDisable(false);
      return;
    }
    if (user.postalCode === "") {
      setShowError({ ...showError, isPostalCode: true });
      setIsDisable(false);
      return;
    }
    try {
      const data = {
        comName: user.name,
        phone: user.phone,
        postal_code: Number(user.postalCode),
        city: user.city,
        country: user.country,
        state: user.state,
        line1: user.line1,
        line2: user.line2,
      };
      await updateProfileById(data);
      setIsOpenSubscribeModal(false);
      setIsDisable(false);
      navigation("/setting", {
        state: {
          selected: 2,
        },
      });
    } catch (error) {
      setIsDisable(false);
    }
  }

  const onInputChange = (data) => {
    setUser((preVal) => {
      return { ...preVal, ...data };
    });
  };

  return (
    <ResponsiveDrawer
      isShowAppBar={isShowAppBar}
      headText={t("home.home")}
      isHideToolBar={true}
    >
      <div>
        <div style={{ marginTop: -30 }} className="column-block-css">
          {/* ----- BODY-1 ----- */}
          <div className="ui stackable three column grid">
            <div className="column green_box css-hover-block">
              <CustomCard
                isLoader={isLoader}
                heading={t("home.ponds")}
                count={totalPonds}
                color={colors.mid_blue}
                isClickable={true}
                isPond={true}
                onClickGoNavigate={() => navigation("/selectFarm")}
                isCursorChange={true}
                card={Ponds}
                buttonText={t("ponds.farms")}
              />
            </div>
            <div className="column yellow_box css-hover-block">
              <CustomCard
                isLoader={isLoader}
                heading={t("home.biomass")}
                count={totalBiomass}
                isClickable={false}
                color={colors.mid_blue}
                card={Number_users}
                description={t("home.biomass_description")}
              />
            </div>
            <div className="column blue_box css-hover-block">
              <CustomCard
                isLoader={isLoader}
                buttonText={t("userManagement.userManagement")}
                isClickable={true}
                card={Total_biomass}
                heading={t("home.users")}
                count={`${
                  isPremium || totalUsers > 3 ? totalUsers : totalUsers + `/${plansRecords.planUsers}`}`}
                color={colors.mid_blue}
                // isUpgrade={totalUsers >= 3 ? true : false}
                isPaymentPendingDueDateExpired={isPaymentPendingDueDateExpired}
                isUpgrade={
                  isPaymentPendingDueDateExpired
                    ? true
                    : isPremium
                    ? false
                    : totalUsers >= 3
                    ? true
                    : false
                }
                onClickUpgrade={() => {
                  navigation("/setting", {
                    state: {
                      selected: 2,
                    },
                  });
                  // setIsClickUser(true);
                  // setIsOpenAlertModal(true);
                  // setIsShowAlert(true);
                }}
                onClickGoNavigate={() => {
                  navigation("/setting", {
                    state: {
                      selected: 3,
                    },
                  });
                }}
              />
            </div>
          </div>

          {/* ----- BODY-2 ----- */}
          <div className="ui stackable three column grid">
            <div className="column azure_box css-hover-block">
              <CustomCard
                isLoader={isLoader}
                heading={t("home.records")}
                count={`${
                  isPremium || totalRecords > Number(plansRecords.plansRecords)
                    ? totalRecords
                    : totalRecords + `/${Number(plansRecords.plansRecords).toLocaleString()}`
                }`}
                color={colors.mid_blue}
                // isUpgrade={totalRecords >= 1000 ? true : false}
                isPaymentPendingDueDateExpired={isPaymentPendingDueDateExpired}
                isUpgrade={
                  isPaymentPendingDueDateExpired
                    ? true
                    : isPremium
                    ? false
                    : totalRecords >= 1000
                    ? true
                    : false
                }
                onClickUpgrade={() => {
                  setIsClickUser(false);
                  setIsOpenAlertModal(true);
                  setIsShowAlert(true);
                }}
                card={Number_records}
                description={t("home.Record_description")}
                isClickable={false}
                // buttonText={t("home.click_here_to_see")}
              />

            </div>
            <div className="column pink_box css-hover-block">
              <CustomCard
                isLoader={isLoader}
                heading={t("home.devices")}
                count={totalDevices}
                color={colors.mid_blue}
                card={Number_devices}
                isClickable={true}
                onClickGoNavigate={() => navigation("/device")}
                buttonText={t("device.devices")}
              />
            </div>
            {/* <div className="column red_box css-hover-block">
              <CustomCard
                isLoader={isLoader}
                heading={t("home.abnormal_biomass")}
                count={abnormalBiomass}
                color={colors.red}
                isClickable={true}
                onClickCard={() => navigation("/alarm")}
                isCursorChange={true}
                card={Abnormal_biomass}
              />
            </div> */}
          </div>

          {/* ----- BODY-3 (TABLE) ----- */}
          {/* <div className="ui stackable one column grid table-main-block">
            <div className="column">
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  alignItems: "center",
                  padding: 2,
                  boxShadow: "1px 3px 3px 4px #e0e0e0",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  className="css-p-table"
                >
                  <Typography sx={{ fontSize: 18, fontWeight: "700" }}>
                    {t("home.top_alarms")}
                  </Typography>
                  <div
                    style={{
                      fontSize: 15,
                      fontWeight: "600",
                      color: colors.mid_blue,
                      cursor: "pointer",
                      marginRight: "5px",
                      zIndex: "111",
                    }}
                    onClick={() => navigation("/alarm")}
                    className="view-hover-set"
                  >
                    {t("home.view_all")}
                  </div>
                </div>
                <div className="cards_main_block cards_main_block_home">
                  <div className="inventory_table_wrap">
                    {alarmDataLoader ? (
                      <div
                        style={{
                          display: "flex",
                          height: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <div id={`load`} className="loader" />
                      </div>
                    ) : alarmData.length > 0 ? (
                      <div className="table-responsive cm_table_responsive">
                        <table className="ui celled table table-a-css-block home_table cm_table fold-table">
                          <thead className="cm_thead">
                            <tr className="cm_thead_list">
                              <th className="th_list cm_name">
                                <div className="th_item_wrap">
                                  <h6>{t("home.top")}</h6>
                                </div>
                              </th>
                              <th className="th_list cm_name">
                                <div className="th_item_wrap">
                                  <h6>{t("home.side")}</h6>
                                </div>
                              </th>
                              <th className="th_list cm_name">
                                <div className="th_item_wrap">
                                  <h6>{t("home.time")}</h6>
                                </div>
                              </th>
                              <th className="th_list cm_name">
                                <div className="th_item_wrap">
                                  <h6>{t("home.pond")}</h6>
                                </div>
                              </th>
                              <th className="th_list cm_name">
                                <div className="th_item_wrap">
                                  <h6>{t("home.type")}</h6>
                                </div>
                              </th>
                              <th className="th_list cm_name">
                                <div className="th_item_wrap">
                                  <h6>{t("home.status")}</h6>
                                </div>
                              </th>
                              <th className="th_list cm_name">
                                <div className="th_item_wrap">
                                  <h6>{t("home.description")}</h6>
                                </div>
                              </th>
                              <th className="th_list cm_name">
                                <div className="th_item_wrap">
                                  <h6>{t("home.reported_by")}</h6>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="cm_tbody">
                            {alarmData.map((val) => {
                              return (
                                <>
                                  <tr
                                    className={`cm_tr ${
                                      val.status === "Cleared"
                                        ? "cm_colors_green"
                                        : val.shrimpSickStatus === "Yes"
                                        ? val.isLethal === true
                                          ? "cm_colors_lightRed"
                                          : "cm_colors_yellow"
                                        : "cm_colors_blue"
                                    }`}
                                    // style={{
                                    //   backgroundColor:
                                    //     val.status === "Cleared"
                                    //       ? colors.green
                                    //       : val.shrimpSickStatus === "Yes"
                                    //       ? val.isLethal === true
                                    //         ? colors.lightRed
                                    //         : colors.yellow
                                    //       : colors.white,
                                    // }}
                                    key={Math.random()}
                                  >
                                    <td
                                      className="cm_td"
                                      data-label={t("home.top")}
                                    >
                                      <div style={style.container}>
                                        {val.topImage && val.topImage !== "" ? (
                                          <div>
                                            <img
                                              src={val.topImage}
                                              style={{
                                                width: 25,
                                                height: 25,
                                              }}
                                              onClick={() => {
                                                setPicture(val.topImage);
                                                setIsShowAppBar(false);
                                                setIsOpenPic(true);
                                              }}
                                            />
                                            {isOpenPic && (
                                              <Lightbox
                                                mainSrc={
                                                  picture
                                                    ? picture
                                                    : imagePath.noImg
                                                }
                                                onCloseRequest={() => {
                                                  setIsOpenPic(false);
                                                  setIsShowAppBar(true);
                                                }}
                                                // reactModalStyle={{ zIndex: 1000 }}
                                                // imagePadding={250}
                                              />
                                            )}
                                          </div>
                                        ) : (
                                          <img
                                            src={imagePath.noImg}
                                            style={{ width: 25, height: 25 }}
                                          />
                                        )}
                                      </div>
                                    </td>
                                    <td
                                      className="cm_td"
                                      data-label={t("home.side")}
                                    >
                                      <div style={style.container}>
                                        {val.sideImage &&
                                        val.sideImage !== "" ? (
                                          <div>
                                            <img
                                              src={val.sideImage}
                                              style={{
                                                width: 25,
                                                height: 25,
                                              }}
                                              onClick={() => {
                                                setPicture(val.sideImage);
                                                setIsShowAppBar(false);
                                                setIsOpenPic(true);
                                              }}
                                            />
                                            {isOpenPic && (
                                              <Lightbox
                                                mainSrc={
                                                  picture
                                                    ? picture
                                                    : imagePath.noImg
                                                }
                                                onCloseRequest={() => {
                                                  setIsOpenPic(false);
                                                  setIsShowAppBar(true);
                                                }}
                                                // reactModalStyle={{ zIndex: 1000 }}
                                                // imagePadding={250}
                                              />
                                            )}
                                          </div>
                                        ) : (
                                          <img
                                            src={imagePath.noImg}
                                            style={{ width: 25, height: 25 }}
                                          />
                                        )}
                                      </div>
                                    </td>
                                    <td
                                      className="cm_td"
                                      data-label={t("home.time")}
                                      style={{ verticalAlign: "middle" }}
                                    >
                                      <h4>
                                        {getTimestampWithFormatDate(
                                          val.recordDate,
                                          "DD-MM-YYYY hh:mma"
                                        )}
                                      </h4>
                                    </td>
                                    <td
                                      className="cm_td"
                                      data-label={t("home.pond")}
                                      style={{ verticalAlign: "middle" }}
                                    >
                                      <h4>
                                        {val.pondName ? val.pondName : "N/A"}
                                      </h4>
                                    </td>
                                    <td
                                      className="cm_td"
                                      data-label={t("home.type")}
                                      style={{ verticalAlign: "middle" }}
                                    >
                                      <h4>
                                        <span></span>
                                        {val.shrimpSickStatus === "Yes"
                                          ? val.isLethal === true
                                            ? "HIGH"
                                            : "LOW"
                                          : "CHECK NEEDED"}
                                      </h4>
                                    </td>
                                    <td
                                      className="cm_td"
                                      data-label={t("home.status")}
                                      style={{ verticalAlign: "middle" }}
                                    >
                                      <h4>{val.status ? val.status : "N/A"}</h4>
                                    </td>
                                    <td
                                      className="cm_td"
                                      data-label={t("home.description")}
                                      style={{ verticalAlign: "middle" }}
                                    >
                                      <h4>
                                        {val.comment ? val.comment : "N/A"}
                                      </h4>
                                    </td>
                                    <td
                                      className="cm_td"
                                      data-label={t("home.reported_by")}
                                      style={{ verticalAlign: "middle" }}
                                    >
                                      <h4>
                                        {`${
                                          val.type === "Phone"
                                            ? "User:"
                                            : "Device:"
                                        } ${
                                          val.reportedUser
                                            ? val.reportedUser
                                            : val.reportedBy
                                        }`}
                                      </h4>
                                    </td>
                                  </tr>
                                  <tr className="cm_hi"></tr>
                                </>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          height: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                        }}
                        className="no-alarms-found-css"
                      >
                        <img src={no_alarms_found} alt="no_alarms_found" />
                        <Typography sx={{ fontSize: 17, fontWeight: "600" }}>
                          {t("ponds.pond_data_not_found")}
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>
              </Card>
            </div>
          </div> */}
        </div>
      </div>
      {isShowAlert && (
        <AlertModal
          isUser={isClickUser}
          isOpenAlertModal={isOpenAlertModal}
          setIsOpenAlertModal={setIsOpenAlertModal}
          isPaymentPendingDueDateExpired={
            isPaymentPendingDueDateExpired ? true : false
          }
          onClickPayInvoice={() => navigation("/setting")}
          onClickCancel={() => {
            setIsClickUser(false);
            setIsOpenAlertModal(false);
            setIsShowAlert(false);
          }}
          onClickUpgrade={() => {
            setIsClickUser(false);
            setIsOpenAlertModal(false);
            setIsShowAlert(false);
            setIsOpenSubscribeModal(true);
          }}
        />
      )}
      {/* ----- SUBSCRIBE MODAL ----- */}
      <Modal
        open={isOpenSubscribeModal}
        onClose={() => setIsOpenSubscribeModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={boxStyle}>
          <div
            style={{
              display: "flex",
              width: "100%",
              backgroundColor: colors.slatery_blue,
              justifyContent: "space-between",
              padding: 8,
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
          >
            <Typography
              sx={{
                fontSize: 18,
                color: "white",
                fontWeight: "600",
                marginLeft: 1,
              }}
            >
              {t("invoice.upgrade_to_premium")}
            </Typography>
            <i
              className="x icon"
              style={{ color: "white", fontSize: 22 }}
              onClick={() => {
                if (isDisable) {
                } else {
                  setIsOpenSubscribeModal(false);
                }
              }}
            />
          </div>

          <div
            style={{ height: "auto", maxHeight: "600vh", overflowY: "auto" }}
          >
            {/* -- NAME -- */}
            <TextField
              id="name"
              label={t("home.name")}
              type="text"
              variant="standard"
              onChange={(e) => {
                onInputChange({ name: e.target.value });
                if (showError.isName) {
                  setShowError({ ...showError, isName: false });
                }
              }}
              value={user.name}
              sx={{
                width: "95%",
                fontSize: 18,
                marginTop: 1.5,
                marginLeft: 2,
              }}
            />
            {showError.isName && (
              <Alert severity="error">{t("home.name_error")}</Alert>
            )}

            {/* -- EMAIL -- */}
            <TextField
              id="email"
              label={t("profile.email")}
              type="text"
              variant="standard"
              value={user.email}
              sx={{
                width: "95%",
                fontSize: 18,
                marginTop: 1.5,
                marginLeft: 2,
              }}
              disabled={true}
            />

            {/* -- PHONE -- */}
            <TextField
              id="phone"
              label={t("profile.phone_number")}
              type="text"
              variant="standard"
              onChange={(e) => {
                onInputChange({ phone: e.target.value });
                if (showError.isPhone) {
                  setShowError({ ...showError, isPhone: false });
                }
              }}
              value={user.phone}
              sx={{
                width: "95%",
                fontSize: 18,
                marginTop: 1.5,
                marginLeft: 2,
              }}
            />
            {showError.isPhone && (
              <Alert severity="error">
                {user.phone === ""
                  ? t("home.phone_error")
                  : t("profile.please_add_country_code")}
              </Alert>
            )}

            {/* -- CITY -- */}
            <TextField
              id="city"
              label={t("home.city")}
              type="text"
              variant="standard"
              required
              onChange={(e) => {
                onInputChange({ city: e.target.value });
                if (showError.isCity) {
                  setShowError({ ...showError, isCity: false });
                }
              }}
              value={user.city}
              sx={{
                width: "95%",
                fontSize: 18,
                marginTop: 1.5,
                marginLeft: 2,
              }}
            />
            {showError.isCity && (
              <Alert severity="error">{t("home.city_error")}</Alert>
            )}

            {/* -- COUNTRY -- */}
            <TextField
              id="country"
              label={t("home.country")}
              type="text"
              variant="standard"
              required
              onChange={(e) => {
                onInputChange({ country: e.target.value });
                if (showError.isCountry) {
                  setShowError({ ...showError, isCountry: false });
                }
              }}
              value={user.country}
              sx={{
                width: "95%",
                fontSize: 18,
                marginTop: 1.5,
                marginLeft: 2,
              }}
            />
            {showError.isCountry && (
              <Alert severity="error">{t("home.country_error")}</Alert>
            )}

            {/* -- STATE -- */}
            <TextField
              id="state"
              label={t("home.state")}
              type="text"
              variant="standard"
              required
              onChange={(e) => {
                onInputChange({ state: e.target.value });
                if (showError.isState) {
                  setShowError({ ...showError, isState: false });
                }
              }}
              value={user.state}
              sx={{
                width: "95%",
                fontSize: 18,
                marginTop: 1.5,
                marginLeft: 2,
              }}
            />
            {showError.isState && (
              <Alert severity="error">{t("home.state_error")}</Alert>
            )}

            {/* -- LINE-1 -- */}
            <TextField
              id="line1"
              label={t("home.line_1")}
              type="text"
              variant="standard"
              required
              onChange={(e) => {
                onInputChange({ line1: e.target.value });
                if (showError.isLine1) {
                  setShowError({ ...showError, isLine1: false });
                }
              }}
              value={user.line1}
              sx={{
                width: "95%",
                fontSize: 18,
                marginTop: 1.5,
                marginLeft: 2,
              }}
            />
            {showError.isLine1 && (
              <Alert severity="error">{t("home.line_1_error")}</Alert>
            )}

            {/* -- LINE-2 -- */}
            <TextField
              id="line2"
              label={t("home.line_2")}
              type="text"
              variant="standard"
              required
              onChange={(e) => {
                onInputChange({ line2: e.target.value });
                if (showError.isLine2) {
                  setShowError({ ...showError, isLine2: false });
                }
              }}
              value={user.line2}
              sx={{
                width: "95%",
                fontSize: 18,
                marginTop: 1.5,
                marginLeft: 2,
              }}
            />
            {showError.isLine2 && (
              <Alert severity="error">{t("home.line_2_error")}</Alert>
            )}

            {/* -- POSTAL CODE -- */}
            <TextField
              id="postalCode"
              label={t("home.postal_code")}
              type="number"
              variant="standard"
              required
              onChange={(e) => {
                onInputChange({ postalCode: e.target.value });
                if (showError.isPostalCode) {
                  setShowError({ ...showError, isPostalCode: false });
                }
              }}
              value={user.postalCode}
              sx={{
                width: "95%",
                fontSize: 18,
                marginTop: 1.5,
                marginLeft: 2,
              }}
            />
            {showError.isPostalCode && (
              <Alert severity="error">{t("home.postal_code_error")}</Alert>
            )}
          </div>

          {/* -- BUTTON -- */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 10,
              marginTop: 10,
            }}
          >
            <LoadingButton
              onClick={onClickSaveProfile}
              loading={isDisable}
              loadingPosition="start"
              startIcon={<Save />}
              variant="contained"
              sx={{ backgroundColor: colors.slatery_blue }}
            >
              {t("ponds.save")}
            </LoadingButton>
            {/* </div> */}
          </div>
        </Box>
      </Modal>
    </ResponsiveDrawer>
  );
}

const style = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 20,
  // width: window.innerWidth > 375 ? 400 : 270,
  width: 650,
  bgcolor: "background.paper",
  border: "2px solid",
  borderColor: "gray",
  borderRadius: 3,
  boxShadow: 24,
};
const textStyle = {
  color: colors.slatery_blue,
  fontSize: 17,
  padding: 1,
  textAlign: "center",
  width: "100%",
  fontWeight: "600",
};

export default Home;
