import {
  Box,
  Divider,
  FormControl,
  InputBase,
  MenuItem,
  Modal,
  Select,
  Typography,
  Alert,
  Autocomplete,
  TextField,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import colors from "../../../common/colors";
import ResponsiveDrawer from "../../../common/ResponsiveDrawer";
import "../../../style/loader.css";
import "../../../style/user/Ponds.css";
import NativeSelect from "@mui/material/NativeSelect";
import {
  addDevice,
  getDevices,
  deleteDevice,
  getAllParentUser,
} from "../../../database/user/pond";
import UserList from "../../../components/user/UserList";
import LoadingButton from "@mui/lab/LoadingButton";
import { createUser } from "../../../database/auth/signUp";
import { Save } from "@mui/icons-material";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Error } from "../../../common/CustomToast";
import { useNavigate } from "react-router-dom";
import { getCurrentTimestampInMillis } from "../../../common/utils";
import { addInviteHistory, deleteUserFromAdmin } from "../../../database/user/users";
import UserManagement from "../userManagement/UserManagement";
import { Card } from "react-bootstrap";
import Profile from "../Profile";
import { TIMEZONE } from "../../../common/commonList";
import CloseIcon from "@mui/icons-material/Close";
import { sendEmailMailgun } from "../../../components/sendMail";
// import { sendEmail } from "../../../components/sendMail";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "8px 26px 8px 12px",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

function Users() {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const [userList, setUserList] = useState([]);
  const [deviceList2, setDeviceList2] = useState([]);
  const [noOfDevices, setNoOfDevices] = useState(0);
  const [sortValue, setSortValue] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [userId, setDeviceId] = useState(null);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [listItem, setListItem] = useState();
  const [isDeviceModal, setIsDeviceModal] = useState(false);
  const [deviceIdVal, setDeviceIdVal] = useState("");
  const [deviceIdError, setDeviceIdError] = useState("");
  const [deviceTypeVal, setDeviceTypeVal] = useState("counter");
  const [isRefresh, setRefresh] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [user, setUser] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    cpassword: "",
    status: "Active",
  });
  const [isLoader, setIsLoader] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenSignupModal, setIsOpenSignupModal] = useState(false);
  const [isNameErr, setIsNameErr] = useState(false);
  const [isEmailErr, setIsEmailErr] = useState(false);
  const [isPasswordErr, setIsPasswordErr] = useState(false);
  const [isCPasswordErr, setIsCPasswordErr] = useState(false);

  const [isNameErrSignup, setIsNameErrSignup] = useState(false);
  const [isEmailErrSignup, setIsEmailErrSignup] = useState(false);
  const [isCompanyNameErrSignup, setIsCompanyNameErrSignup] = useState(false);

  const nameSignupClassName = `field ${isNameErrSignup ? "error" : ""}`;
  const emailSignupClassName = `field ${isEmailErrSignup ? "error" : ""}`;
  const companyClassName = `field ${isCompanyNameErrSignup ? "error" : ""}`;

  const [isUsersView, setIsUsersView] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const nameClassName = `field ${isNameErr ? "error" : ""}`;
  const emailClassName = `field ${isEmailErr ? "error" : ""}`;
  const pwdClassName = `field ${isPasswordErr ? "error" : ""}`;
  const cpwdClassName = `field ${isCPasswordErr ? "error" : ""}`;
  const phoneClassName = `field`;

  const [objSignupUser, setObjSignupUser] = useState({
    name: "",
    email: "",
    companyName: "",
  });

  const addDeviceIdDB = async () => {
    setIsDeviceModal(false);
    let data = {
      deviceId: deviceIdVal,
      type: deviceTypeVal,
    };
    setLoading(true);
    const addId = await addDevice(data, deviceIdVal);
    setRefresh(true);
    setDeviceIdVal("");
    setLoading(false);
    setDeviceTypeVal("counter");
  };

  const onClickDevice = () => {
    if (deviceIdVal == "") {
      setDeviceIdError(t("device.please_fill_the_dev_id"));
    } else {
      addDeviceIdDB();
    }
  };

  const onChangeDeviceIdVal = (e) => {
    e.preventDefault();
    setDeviceIdVal(e.target.value);
    setDeviceIdError("");
  };

  const onChangeDevice = (event) => {
    setDeviceTypeVal(event.target.value);
  };

  useEffect(() => {
    if (deviceList2.length > 0) {
      setTimeout(() => {
        setSearch(searchText);
      }, 1000);
    }
  }, [searchText]);

  useEffect(() => {
    searchId();
  }, [search, sortValue]);

  const searchId = () => {
    let mainData = deviceList2;
    if (search) {
      mainData = deviceList2.filter((val) => {
        return (
          val.email.toLowerCase().includes(search.toLowerCase()) ||
          val.fullName.toLowerCase().includes(search.toLowerCase())
        );
      });
    }
    if (sortValue == 2) {
      mainData = mainData.filter((ele) => {
        return ele.isPremium || ele.isSuperPremium;
      });
    } else if (sortValue == 3) {
      mainData = mainData.filter((ele) => {
        return !(ele.isPremium || ele.isSuperPremium);
      });
    }
    setUserList(mainData);
  };

  useEffect(() => {
    if (isRefresh) {
      getDevicesListData();
      setRefresh(false);
    }
  }, [isRefresh]);

  useEffect(() => {
    getDevicesListData();
  }, []);

  async function getDevicesListData() {
    try {
      let data = await getDevices();
      let data2 = await getAllParentUser();
      // console.log('data2: ', data2);

      setNoOfDevices(data2.length);
      setUserList(data2);
      setDeviceList2(data2);
      setLoading(false);
    } catch (error) {
      console.log("error: ", error);
    }
  }

  const onAddSubmit = async (e) => {
    e.preventDefault();
    setIsDisable(true);
    let emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let passwordRegex = /^(?=.*).{6,}$/;
    let phoneRegex = /^\d{10,12}$/;
    let fullNameRegex = /^[a-zA-Z\s]+/gi;

    if (user.name === "") {
      setIsDisable(false);
      return setIsNameErr(true);
    } else if (!fullNameRegex.test(user.name)) {
      setIsDisable(false);
      return setIsNameErr(true);
    }
    if (user.email === "") {
      setIsDisable(false);
      return setIsEmailErr(true);
    } else if (!emailRegex.test(user.email)) {
      setIsDisable(false);
      return setIsEmailErr(true);
    }
    // if (user.phone === "") {
    //   setIsDisable(false);
    //   return setIsPhoneErr(true);
    // } else if (!user.phone.startsWith("+")) {
    //   setIsDisable(false);
    //   return setIsPhoneErr(true);
    // }
    if (user.password === "") {
      setIsDisable(false);
      return setIsPasswordErr(true);
    } else if (!passwordRegex.test(user.password)) {
      setIsDisable(false);
      return setIsPasswordErr(true);
    }
    if (user.cpassword === "") {
      setIsDisable(false);
      return setIsCPasswordErr(true);
    } else if (!passwordRegex.test(user.cpassword)) {
      setIsDisable(false);
      return setIsCPasswordErr(true);
    }
    if (user.cpassword !== user.password) {
      setIsDisable(false);
      setIsCPasswordErr(true);
      return;
    }
    try {
      const data = {
        fullName: user.name,
        jobTitle: "Main Admin",
        comName: user.name,
        email: user.email,
        phone: user.phone,
        status: true,
        timestamp: getCurrentTimestampInMillis(),
      };
      let res = await createUser(t, user.password, data);
      // console.log("res",res);
      if (res.error) {
        // console.log("fff",res.error);
        Error(res.error);
      } else {
        let arr = userList;
        arr.unshift({ ...data, uid: res.uid });
        setUserList(arr);
        setUser({
          name: "",
          email: "",
          phone: "",
          password: "",
          status: "Active",
        });
        setIsOpenModal(false);
      }
      setIsDisable(false);
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      if (error.code !== "" && error.code === "auth/email-already-in-use") {
        Error(t("userManagement.this_email_is_already_in_use"));
      } else if (error.message !== "") {
      }
      setIsDisable(false);
    }
  };



  const onAddSignupSubmit = async (e) => {
    e.preventDefault();
    setIsDisable(true);
    let emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let fullNameRegex = /^[a-zA-Z\s]+/gi;

    if (objSignupUser.name === "") {
      setIsDisable(false);
      return setIsNameErrSignup(true);
    } else if (!fullNameRegex.test(objSignupUser.name)) {
      setIsDisable(false);
      return setIsNameErrSignup(true);
    }

    if (objSignupUser.email === "") {
      setIsDisable(false);
      return setIsEmailErrSignup(true);
    } else if (!emailRegex.test(objSignupUser.email)) {
      setIsDisable(false);
      return setIsEmailErrSignup(true);
    }

    if (objSignupUser.companyName === "") {
      setIsDisable(false);
      return setIsCompanyNameErrSignup(true);
    }


    try {
      const data = {
        fullName: objSignupUser.name,
        companyName: objSignupUser.companyName,
        email: objSignupUser.email,
        isSignup: false,
        timestamp: getCurrentTimestampInMillis(),
      };

      const res = await addInviteHistory(data)
      if (res === "user already exist") {
        Error(t("userManagement.this_email_is_already_in_use"));
      } else {
        sendEmailMailgun({
          toName: objSignupUser.name,
          fromName: 'Sincere Aqua Team',
          userEmail: objSignupUser.email,
          message: `${window.location.origin}/inviteSignUp/${res}`
        })
        // sendEmail({
        //   to_name: objSignupUser.name,
        //   from_name: 'Sincere Aqua Team',
        //   user_email: objSignupUser.email,
        //   message: `${window.location.origin}/inviteSignUp/${res}`
        // })
        setIsOpenSignupModal(false);
        setObjSignupUser({
          name: "",
          email: "",
          companyName: "",
        })
      }
      setIsDisable(false);
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      if (error.code !== "" && error.code === "auth/email-already-in-use") {
        Error(t("userManagement.this_email_is_already_in_use"));
      } else if (error.message !== "") {
      }
      setIsDisable(false);
    }
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "name" && isNameErr) {
      setIsNameErr(false);
    } else if (name === "email" && isEmailErr) {
      setIsEmailErr(false);
    } else if (name === "password" && isPasswordErr) {
      setIsPasswordErr(false);
    } else if (name === "cpassword" && isCPasswordErr) {
      setIsCPasswordErr(false);
    }
    setUser((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });
  };


  const onInputSignupUserChange = (e) => {
    const { name, value } = e.target;
    if (name === "name" && isNameErrSignup) {
      setIsNameErrSignup(false);
    } else if (name === "email" && isEmailErrSignup) {
      setIsEmailErrSignup(false);
    } else if (name === "companyName" && isCompanyNameErrSignup) {
      setIsCompanyNameErrSignup(false);
    }
    setObjSignupUser((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });
  };

  const onCloseAddModal = () => {
    setUser({
      name: "",
      email: "",
      phone: "",
      password: "",
      status: "Active",
    });
    // setNotif(false);
    setIsOpenModal(false);
    setIsLoader(false);
    setIsNameErr(false);
    setIsEmailErr(false);
    setIsPasswordErr(false);
    setIsCPasswordErr(false);
  };

  const onCloseUserSignupModal = () => {
    setObjSignupUser({
      name: "",
      email: "",
      companyName: "",
    });
    setIsOpenSignupModal(false);
    setIsLoader(false);
  };

  return (
    <ResponsiveDrawer
      isShowAppBar={true}
      headText={t("userManagement.users")}
      isHideToolBar={true}
    >
      <div
        className="column-block-css detail-css-block"
        style={{ marginTop: -20 }}
      >
        {/* ----- HEAD SEARCHES ----- */}
        <div className="head_container head_devices hedera_ui_css_block">
          <div className="hedera_flex_block_row">
            <div className="head_two select-main-block">
              <Box sx={{ minWidth: 260 }}>
                <Typography>{t("device.filter_by_status")}</Typography>
                <FormControl>
                  <Select
                    className="Select-bg-color"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={sortValue}
                    onChange={async (e) => {
                      e.stopPropagation();
                      setSortValue(e.target.value);
                    }}
                    input={<BootstrapInput />}
                  >
                    <MenuItem value={1}>{t("device.all")}</MenuItem>
                    <MenuItem value={2}>{t("userManagement.paid")}</MenuItem>
                    <MenuItem value={3}>{t("userManagement.free")}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="head_one">
              <div
                style={{ width: "100%", marginTop: 0, marginLeft: 0 }}
                className="ui stackable two column grid"
              >
                <div
                  className="column"
                  style={{ width: "260px", padding: "0" }}
                >
                  <div
                    className="ui icon input input-css-block"
                    style={{ width: "260px" }}
                  >
                    <input
                      className="prompt"
                      type="text"
                      placeholder={t("ponds.search")}
                      onChange={(e) => {
                        if (e.target.value === "") {
                          setSearchText(e.target.value);
                        } else {
                          setSearchText(e.target.value);
                        }
                      }}
                      value={searchText}
                      style={{ border: "1px solid #ced4da", height: 43 }}
                    />
                    <i className="search icon"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="button-row-block">
            <div className="column base_container">
              <LoadingButton
                loadingPosition="end"
                className="ui primary button"
                style={{
                  width: "auto",
                  height: 40,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  paddingRight: 25,
                  paddingLeft: 25,
                }}
                onClick={() => {
                  navigation("/superadmin/invite_history");
                }}
              >
                {t("inviteHistory.view_invite_History")}
              </LoadingButton>
            </div>
            <div className="column base_container">
              <LoadingButton
                loadingPosition="end"
                className="ui primary button"
                style={{
                  width: "auto",
                  height: 40,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  paddingRight: 25,
                  paddingLeft: 25,
                }}
                onClick={async () => {
                  try {
                    setIsLoader(true);
                    // setIsOpenModal(true);
                    setIsOpenSignupModal(true);
                    setIsNameErrSignup(false);
                    setIsEmailErrSignup(false);
                    setIsCompanyNameErrSignup(false);
                  } catch (error) {
                    console.log("error: ", error);
                  }
                }}
              >
                {t("userManagement.add_user")}
              </LoadingButton>
            </div>
          </div>
        </div>
        {/* ----- DEVICES LIST TABLE ----- */}
        <div className="cards_main_block">
          <div className="inventory_table_wrap">
            <div className="table-responsive cm_table_responsive">
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <div id={`load`} className="loader" />
                </div>
              ) : userList.length > 0 ? (
                <table className="cm_table fold-table super_admin_table">
                  <thead className="cm_thead">
                    <tr className="cm_thead_list">
                      <th className="th_list cm_name">
                        <div className="th_item_wrap">
                          {/* <h6>{t("device.device_id")}</h6> */}
                          <h6>{t("home.name")}</h6>
                        </div>
                      </th>
                      <th className="th_list cm_name">
                        <div className="th_item_wrap">
                          <h6>{t("home.email")}</h6>
                        </div>
                      </th>
                      <th className="th_list cm_name">
                        <div className="th_item_wrap">
                          <h6>{t("licenseDetails.company_name")}</h6>
                        </div>
                      </th>
                      <th className="th_list cm_name">
                        <div className="th_item_wrap">
                          <h6>{t("home.type")}</h6>
                        </div>
                      </th>
                      <th className="th_list cm_name">
                        <div className="th_item_wrap">
                          <h6>{t("lock")}</h6>
                        </div>
                      </th>
                      <th className="th_list cm_name">
                        <div className="th_item_wrap">
                          <h6>{t("licenseDetails.super_premium")}</h6>
                        </div>
                      </th>
                      <th className="th_list cm_name">
                        <div className="th_item_wrap">
                          <h6>{t("ofLicensesPaidTotal")}</h6>
                        </div>
                      </th>
                      <th className="th_list cm_name"></th>
                    </tr>
                  </thead>
                  <tbody className="cm_tbody">
                    {userList.map((val, index) => {
                      return (
                        <>
                          <tr key={Math.random()} className="cm_tr view">
                            <UserList
                              list={userList}
                              setList={setUserList}
                              val={val}
                              onClickList={() => {
                                navigation("/superadmin/license_details", {
                                  state: {
                                    userData: val,
                                  },
                                });
                              }}
                              onClickView={() => {
                                setIsUsersView(true);
                                setSelectedUser(val);
                              }}
                              openId={userId}
                              onClickDelete={() => {
                                setListItem(val);
                                setIsDeleteModal(true);
                              }}
                            />
                          </tr>
                          <tr className="cm_hi"></tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <div
                  style={{
                    display: "flex",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Typography sx={{ fontSize: 17, fontWeight: "600" }}>
                    {t("device.device_not_found")}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* ----- DELETE MODAL ----- */}
        <Modal
          open={isDeleteModal}
          onClose={() => setIsDeleteModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={[boxStyle, { width: 400 }]}
            className="logout-css-modal-block"
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{
                paddingTop: 2,
                textAlign: "center",
                marginBottom: 1,
                paddingLeft: 1,
                paddingRight: 1,
              }}
            >
              {t("ponds.are_you_sure_you_want_to_delete_this_user")} "
              {listItem?.fullName}"?
            </Typography>

            <p
              style={{
                paddingTop: 2,
                textAlign: "center",
                marginBottom: 12,
                color: "red",
                fontSize: 13,
              }}
            >
              {t("ponds.please_note_that_all_associated_data_also")}
            </p>

            <Divider />
            <div className="logout-button-block">
              <Typography
                sx={textStyle}
                onClick={() => {
                  if (isDisable) {
                  } else {
                    setDeviceId(null);
                    setIsDeleteModal(false);
                  }
                }}
              >
                {t("ponds.cancel")}
              </Typography>
              <div
                style={{ width: "2px", border: "1px solid #999", opacity: 0.5 }}
              />
              <Typography
                className="delete_bg_color"
                sx={[textStyle, { color: "red" }]}
                onClick={async () => {
                  setIsDeleteModal(false);
                  setLoading(true);
                  await deleteUserFromAdmin(listItem.uid);
                  setLoading(false);
                  getDevicesListData();
                }}
              >
                {t("ponds.delete")}
              </Typography>
            </div>
          </Box>
        </Modal>
        {/* ------ ADD MODAL ------ */}
        <Modal
          open={isDeviceModal}
          onClose={() => {
            setIsDeviceModal(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={boxStyle} className="logout-css-modal-block">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ paddingTop: 2, textAlign: "center", fontWeight: "600" }}
            >
              {t("ponds.add") + " " + t("device.devices")}
            </Typography>
            <div className="add_parameters_input">
              <label>{t("device.device_id")}</label>
              <input
                type="text"
                placeholder={t("ponds.enter") + " " + t("device.device_id")}
                name="device_id"
                value={deviceIdVal}
                onChange={(e) => onChangeDeviceIdVal(e)}
              />
              {deviceIdError && <Alert severity="error">{deviceIdError}</Alert>}
            </div>
            <div>
              <Typography sx={{ fontSize: 15, fontWeight: "600" }}>
                {t("device.type")}&nbsp;
              </Typography>
              <Box
                style={{
                  marginTop: 2,
                }}
              >
                <FormControl fullWidth>
                  <NativeSelect
                    labelId="demo-simple-select-cycle"
                    id="demo-simple-cycle"
                    value={deviceTypeVal}
                    onChange={onChangeDevice}
                    input={<BootstrapInput />}
                  >
                    <option value={t("device.counter")}>
                      {t("device.counter")}
                    </option>
                    <option value={t("device.probe")}>
                      {t("device.probe")}
                    </option>
                  </NativeSelect>
                </FormControl>
              </Box>
            </div>
            <div
              style={{ marginTop: 15 }}
              className="logout-button-block submit-logout-button-block"
            >
              <Typography
                sx={textStyle}
                onClick={() => {
                  setIsDeviceModal(false);
                }}
              >
                {t("ponds.cancel")}
              </Typography>
              <span></span>
              <button type="submit" onClick={onClickDevice}>
                {t("ponds.add")}
              </button>
            </div>
          </Box>
        </Modal>
        {/* ----- ADD USER MODAL ----- */}
        <Modal
          open={isOpenModal}
          onClose={() => {
            setIsOpenModal(false);
            setIsLoader(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description "
        >
          <Box sx={boxStyle} className="add-Pond-location">
            <div
              style={{
                display: "flex",
                width: "100%",
                backgroundColor: colors.slatery_blue,
                justifyContent: "space-between",
                padding: 8,
                position: "sticky",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
              }}
              className="hiding-pond-css"
            >
              <Typography
                sx={{
                  fontSize: 18,
                  color: "white",
                  fontWeight: "600",
                  marginLeft: 1,
                }}
              >
                {t("userManagement.add_user")}
              </Typography>
              <i
                className="x icon"
                style={{ color: "white", fontSize: 22 }}
                onClick={() => {
                  if (isDisable) {
                  } else {
                    // setIsOpenModal(false);
                    onCloseAddModal();
                  }
                }}
              />
            </div>

            <div style={{ maxHeight: "715px", overflow: "auto" }}>
              <form
                className="ui form container"
                style={{
                  marginTop: 15,
                  paddingLeft: 30,
                  paddingRight: 30,
                  paddingBottom: 15,
                }}
              // onSubmit={onAddSubmit}
              >
                {/* -- FULL NAME -- */}
                <div className={nameClassName}>
                  <label>{t("profile.full_name")}</label>
                  <input
                    type="text"
                    placeholder={t("profile.enter_full_name")}
                    name="name"
                    value={user.name}
                    onChange={onInputChange}
                  />
                  {isNameErr && (
                    <div className="ui pointing red basic label">
                      {user.name === ""
                        ? t("userManagement.name_msg_1")
                        : t("userManagement.name_msg_2")}
                    </div>
                  )}
                </div>

                {/* -- EMAIL -- */}
                <div className={emailClassName}>
                  <label>{t("profile.email")}</label>
                  <input
                    type="text"
                    placeholder={t("profile.enter_email")}
                    name="email"
                    value={user.email}
                    onChange={onInputChange}
                  />
                  {isEmailErr && (
                    <div className="ui pointing red basic label">
                      {user.email === ""
                        ? t("userManagement.email_msg_1")
                        : t("userManagement.email_msg_2")}
                    </div>
                  )}
                </div>

                {/* -- PHONE -- */}
                <div className={phoneClassName}>
                  <label>{t("profile.phone_number")}</label>
                  <input
                    type="text"
                    placeholder={t("profile.enter_phone_number")}
                    name="phone"
                    value={user.phone}
                    onChange={onInputChange}
                  />
                  {/* {isPhoneErr && (
                  <div className="ui pointing red basic label">
                    {user.phone === ""
                      ? t("userManagement.phone_msg_1")
                      : t("userManagement.phone_msg_2")}
                  </div>
                )} */}
                </div>

                {/* -- PASSWORD -- */}

                <div className={pwdClassName}>
                  <label>{t("userManagement.password")}</label>
                  <input
                    type="password"
                    placeholder={t("userManagement.enter_password")}
                    name="password"
                    value={user.password}
                    onChange={onInputChange}
                  />
                  {isPasswordErr && (
                    <div className="ui pointing red basic label">
                      {Number(user.password.length) > 0
                        ? Number(user.password.length) < 6
                          ? t("userManagement.password_msg_1")
                          : t("userManagement.password_msg_2")
                        : t("userManagement.password_msg_3")}
                    </div>
                  )}
                </div>

                {/* -- CONFIRM PASSWORD -- */}
                <div className={cpwdClassName}>
                  <label>{t("userManagement.confirm_password")}</label>
                  <input
                    type="password"
                    placeholder={t("userManagement.enter_confirm_password")}
                    name="cpassword"
                    value={user.cpassword}
                    onChange={onInputChange}
                  />
                  {isCPasswordErr && (
                    <div className="ui pointing red basic label">
                      {Number(user.cpassword.length) > 0
                        ? Number(user.cpassword.length) < 6
                          ? t("userManagement.confirm_msg_1")
                          : user.cpassword !== user.password
                            ? t("userManagement.confirm_msg_2")
                            : t("userManagement.confirm_msg_3")
                        : t("userManagement.confirm_msg_4")}
                    </div>
                  )}
                </div>

                {/* -- NOTIFICATION CHECKBOX -- */}
                {/* <div className="field">
                <label htmlFor="notif">
                  {t("userManagement.send_user_notification")}
                </label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="checkbox"
                    tabIndex="0"
                    className="hidden"
                    name="notif"
                    value={notif}
                    onChange={() => setNotif(!notif)}
                    style={{ marginRight: 10 }}
                  />
                  <label>
                    {t("userManagement.send_user_an_email_about_their_account")}
                  </label>
                </div>
              </div> */}
                {/* -- BUTTON -- */}
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {/* <button
                  className="ui primary submit button"
                  style={{
                    width: "auto",
                    backgroundColor: colors.slatery_blue,
                  }}
                  type="submit"
                  disabled={isDisable}
                >
                  {t("ponds.save")}
                </button> */}
                  <LoadingButton
                    onClick={onAddSubmit}
                    loading={isDisable}
                    loadingPosition="start"
                    startIcon={<Save />}
                    variant="contained"
                    sx={{ backgroundColor: colors.slatery_blue }}
                  >
                    {t("ponds.save")}
                  </LoadingButton>
                </div>
              </form>
            </div>
          </Box>
        </Modal>

        {/* ----- ADD Signup USER MODAL ----- */}
        <Modal
          open={isOpenSignupModal}
          onClose={() => {
            setIsOpenSignupModal(false);
            setIsLoader(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description "
        >
          <Box sx={boxStyle} className="add-Pond-location">
            <div
              style={{
                display: "flex",
                width: "100%",
                backgroundColor: colors.slatery_blue,
                justifyContent: "space-between",
                padding: 8,
                position: "sticky",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
              }}
              className="hiding-pond-css"
            >
              <Typography
                sx={{
                  fontSize: 18,
                  color: "white",
                  fontWeight: "600",
                  marginLeft: 1,
                }}
              >
                {t("userManagement.add_user")}
              </Typography>
              <i
                className="x icon"
                style={{ color: "white", fontSize: 22 }}
                onClick={() => {
                  if (isDisable) {
                  } else {
                    // setIsOpenModal(false);
                    onCloseUserSignupModal();
                  }
                }}
              />
            </div>

            <div style={{ maxHeight: "715px", overflow: "auto" }}>
              <form
                className="ui form container"
                style={{
                  marginTop: 15,
                  paddingLeft: 30,
                  paddingRight: 30,
                  paddingBottom: 15,
                }}
              // onSubmit={onAddSubmit}
              >
                {/* -- FULL NAME -- */}
                <div className={nameSignupClassName}>
                  <label>{t("profile.full_name")}</label>
                  <input
                    type="text"
                    placeholder={t("profile.enter_full_name")}
                    name="name"
                    value={objSignupUser.name}
                    onChange={onInputSignupUserChange}
                  />
                  {isNameErrSignup && (
                    <div className="ui pointing red basic label">
                      {objSignupUser.name === ""
                        ? t("userManagement.name_msg_1")
                        : t("userManagement.name_msg_2")}
                    </div>
                  )}
                </div>

                {/* -- EMAIL -- */}
                <div className={emailSignupClassName}>
                  <label>{t("profile.email")}</label>
                  <input
                    type="text"
                    placeholder={t("profile.enter_email")}
                    name="email"
                    value={objSignupUser.email}
                    onChange={onInputSignupUserChange}
                  />
                  {isEmailErrSignup && (
                    <div className="ui pointing red basic label">
                      {objSignupUser.email === ""
                        ? t("userManagement.email_msg_1")
                        : t("userManagement.email_msg_2")}
                    </div>
                  )}
                </div>

                {/* -- companyName -- */}
                <div className={companyClassName}>
                  <label>{t("profile.company_name")}</label>
                  <input
                    type="text"
                    placeholder={t("profile.company_name")}
                    name="companyName"
                    value={objSignupUser.companyName}
                    onChange={onInputSignupUserChange}
                  />
                  {isCompanyNameErrSignup && (
                    <div className="ui pointing red basic label">
                      {objSignupUser.companyName === ""
                        ? t("userManagement.please_fill_the_company_name")
                        : t("userManagement.invalid_company_name")}
                    </div>
                  )}
                </div>
                {/* -- BUTTON -- */}
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {/* <button
                  className="ui primary submit button"
                  style={{
                    width: "auto",
                    backgroundColor: colors.slatery_blue,
                  }}
                  type="submit"
                  disabled={isDisable}
                >
                  {t("ponds.save")}
                </button> */}
                  <LoadingButton
                    onClick={onAddSignupSubmit}
                    loading={isDisable}
                    loadingPosition="start"
                    startIcon={<Save />}
                    variant="contained"
                    sx={{ backgroundColor: colors.slatery_blue }}
                  >
                    {t("ponds.save")}
                  </LoadingButton>
                </div>
              </form>
            </div>
          </Box>
        </Modal>
        {/* ----- USER VIEW MODAL ----- */}
        <Modal
          open={isUsersView}
          onClose={() => setIsUsersView(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="form-general-css-block Settings_input_box"
          >
            <Box
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                width: "70%",
                maxWidth: "1200px",
                maxHeight: "80vh",
                margin: "auto",
                backgroundColor: "white",
                borderRadius: "8px",
                boxShadow: 24,
                outline: "none",
                overflow: "auto",
              }}
            >
              <div
                style={{
                  position: "sticky",
                  top: 0,
                  width: "100%",
                  backgroundColor: colors.slatery_blue,
                  display: "flex",
                  justifyContent: "space-between",
                  padding: 8,
                  zIndex: 1,
                }}
                className="hiding-pond-css"
              >
                <Typography
                  sx={{
                    fontSize: 18,
                    color: "white",
                    fontWeight: "600",
                    marginLeft: 1,
                  }}
                >
                  {t("userManagement.view_user")}
                </Typography>
                <i
                  className="x icon"
                  style={{ color: "white", fontSize: 22 }}
                  onClick={() => {
                    setIsUsersView(false);
                  }}
                />
              </div>
              <form
                className="ui form error"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  padding: "20px",
                }}
              >
                {/* --- FULL-NAME / EMAIL --- */}
                <div className="two fields">
                  <div className="field">
                    <label>{t("profile.full_name")}</label>
                    <input
                      type="text"
                      name="fullName"
                      placeholder=""
                      value={selectedUser?.fullName}
                      disabled
                    />
                  </div>
                  <div className="field">
                    <label>{t("profile.email")}</label>
                    <input
                      type="email"
                      name="email"
                      placeholder=""
                      value={selectedUser?.email}
                      disabled
                    />
                  </div>
                </div>

                {/* --- PHONE / JOB-TITLE --- */}
                <div className="two fields">
                  <div className="field">
                    <label>{t("profile.phone_number")}</label>
                    <input
                      type="text"
                      name="jobTitle"
                      placeholder=""
                      value={selectedUser?.phone}
                      disabled
                    />
                  </div>
                  <div className="field">
                    <label>{t("profile.job_title")}</label>
                    <input
                      type="text"
                      name="jobTitle"
                      placeholder=""
                      value={selectedUser?.jobTitle}
                      disabled
                    />
                  </div>
                </div>

                {/* --- COMPANY-NAME / VAT --- */}
                <div className="two fields">
                  <div className="field">
                    <label>{t("profile.company_name")}</label>
                    <input
                      type="text"
                      name="companyName"
                      placeholder=""
                      value={selectedUser?.comName}
                      disabled
                    />
                  </div>
                  <div className="field"></div>
                </div>
              </form>
            </Box>
          </div>
        </Modal>
      </div>
    </ResponsiveDrawer>
  );
}

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",
  border: "2px solid",
  borderColor: "gray",
  borderRadius: 3,
  boxShadow: 24,
};
const textStyle = {
  color: colors.slatery_blue,
  fontSize: 17,
  padding: 1,
  textAlign: "center",
  width: "100%",
  fontWeight: "600",
};

export default Users;
