import {
  Alert,
  Divider,
  Modal,
  Box,
  Typography,
  Card,
  CardContent,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ResponsiveDrawer from "../../../common/ResponsiveDrawer";
import moment from "moment-timezone";
import "../../../style/loader.css";
import "../../../style/user/Ponds.css";
import {
  addStockingAmount,
  addPondCycle,
  getPondCycle,
  updatePondCycleById,
  updatePondCycleNameById,
  removeCurrentCycleFromPond,
} from "../../../database/user/pond";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Error } from "../../../common/CustomToast";
import colors from "../../../common/colors";
import { useTranslation } from "react-i18next";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  getTimestampWithFormatDate,
  getCurrentTimestampInMillis,
} from "../../../common/utils";
import { CYCLE_ACTIVE, DEVICE_MODE_COUNTING } from "../../../database/config";
import { getUserData } from "../../../database/user/pond";

function ManageCycle() {
  const navigation = useNavigate();
  const { t } = useTranslation();
  const [cycleList, setCycleList] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [totalCycleLength, setTotalCycleLength] = useState(0);
  const [yearCycleCount, setYearCycleCount] = useState(0);
  const [isLoader, setIsLoader] = useState(false);
  const [isOpenEndModal, setIsOpenEndModal] = useState(false);
  const [isOpenAmount, setIsOpenAmount] = useState(false);
  const [hasActiveCycle, setHasActiveCycle] = useState(false);
  const [showError, setShowError] = useState({
    isShrimpWeight: false,
    isAmountOfFeed: false,
  });
  const [shrimpWeight, setShrimpWeight] = useState("");
  const [feedAmount, setFeedAmount] = useState("");
  //BATCH
  const [batchName, setBatchName] = useState("");
  const [stockingAmount, setStockingAMount] = useState("");

  const [isEditPondCycleModalOpen, setIsEditPondCycleModalOpen] =
    useState(false);
  const [selectedCycle, setSelectedCycle] = useState(null);
  const [editButtonLoader, setEditButtonLoader] = useState(false);
  const [error, setError] = useState("");

  const openEditPondCycleModal = (objCycle) => {
    setIsEditPondCycleModalOpen(true);
    setSelectedCycle(objCycle);
  };

  const closeEditPondCycleModal = () => {
    setIsEditPondCycleModalOpen(false);
  };
  useEffect(() => {
    // getCycle();
  }, []);

  useEffect(() => {
    if (cycleList && cycleList.length > 0) {
      let data = cycleList.filter((ele) => {
        return ele.status === "Active";
      });
      setHasActiveCycle(data && data.length > 0);
    } else {
      setHasActiveCycle(false);
    }
  }, [cycleList]);
  const getCycle = async () => {
    try {
      const data = window.history.state.usr.cycleData;
      data.sort(function (a, b) {
        return b.cycleNo - a.cycleNo;
      });
      setCycleList(data);
      if (window.history.state.usr.cardData) {
        setTotalCycleLength(
          window.history.state.usr.cardData.cycleCount
            ? window.history.state.usr.cardData.cycleCount + 1
            : 1
        );
        setYearCycleCount(
          window.history.state.usr.cardData.yearCycleCount
            ? window.history.state.usr.cardData.yearCycleCount
            : 0
        );
      }
    } catch (error) { }
  };

  useEffect(() => {
    getCycleData();
  }, []);
  const getCycleData = async () => {
    setIsLoader(true);
    try {
      const arrList = await getPondCycle(window.history.state.usr.cardData.id);
      setCycleList(arrList);
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
    }
  };

  const onEndCycle = async () => {
    setIsDisable(true);
    try {
      setIsOpenEndModal(false);
      setIsLoader(true);

      if (cycleList.length > 0) {
        for (let i = 0; i < cycleList.length; i++) {
          let item = {
            ...cycleList[i],
            status: "Inactive",
          };
          if (cycleList[i].status === "Active") {
            item = {
              ...cycleList[i],
              status: "Inactive",
              harvestedAmount: shrimpWeight,
              amountOfFeed: feedAmount,
              endDate: Number(getCurrentTimestampInMillis()),
            };
            await updatePondCycleById(cycleList[i].id, item);
            await removeCurrentCycleFromPond(cycleList[i].pondId);
          }
        }
      }
      let newArr = [];
      for (let i = 0; i < cycleList.length; i++) {
        let item = cycleList[i];
        if (cycleList[i].status === "Active") {
          item = {
            ...cycleList[i],
            status: "Inactive",
            endDate: Number(getCurrentTimestampInMillis()),
          };
        }
        newArr.push(item);
      }
      setCycleList(newArr);
      setIsLoader(false);
      setIsDisable(false);
      // setIsOpenModal(true)
    } catch (error) {
      setIsDisable(false);
    }
  };

  function generateBatchName() {
    return `${window.history.state.usr.cardData.pondName}_${Math.floor(
      1000 + Math.random() * 9000
    )}`;
  }

  const onClickNewCycle = async () => {

    const batchNameLocal = batchName ? batchName : generateBatchName();
    const isDuplicate = cycleList?.some((cycle) => cycle?.cycleName.trim() === batchNameLocal.trim());

    if (isDuplicate) {
      Error(t("ponds.error_same_name_cycle"), false);
      return;
    }

    setIsDisable(true);
    try {
      setIsOpenModal(false);
      setIsLoader(true);
      let pId = localStorage.getItem("pId");
      const batchNameLocal = batchName ? batchName : generateBatchName();
      let uid = localStorage.getItem("uid");
      let userData = await getUserData(uid);
      let countRecordsData = {
        count: Number(stockingAmount),
        mode: DEVICE_MODE_COUNTING,
        time: Number(getCurrentTimestampInMillis()),
        fromPondId: window.history.state.usr.cardData.id,
        toPondId: "",
        uploadedOn: moment().toDate(),
        reportedBy: userData?.uid,
        reportedUser: userData?.fullName,
      };

      let data = {
        cycleName: batchNameLocal,
        cycleNo: totalCycleLength,
        timeStamp: Number(getCurrentTimestampInMillis()),
        startDate: Number(getCurrentTimestampInMillis()),
        status: CYCLE_ACTIVE,
        pondName: window.history.state.usr.cardData.pondName,
        pondId: window.history.state.usr.cardData.id,
        pId: pId,
      };

      // console.log("batchData", batchData);
      let yearCount = yearCycleCount;

      if (cycleList.length > 0) {
        for (let i = 0; i < cycleList.length; i++) {
          let item = {
            ...cycleList[i],
            status: "Inactive",
          };
          if (cycleList[i].status === "Active") {
            item = {
              ...cycleList[i],
              status: "Inactive",
              endDate: Number(getCurrentTimestampInMillis()),
            };
            await updatePondCycleById(cycleList[i].id, item);
          }
        }
      }
      data = {
        ...data,
        stockingAmount: Number(stockingAmount),
      };
      let res = await addPondCycle(data);
      console.log("res", res);
      await addStockingAmount(countRecordsData, res);
      setTotalCycleLength(totalCycleLength + 1);
      let newArr = [];
      for (let i = 0; i < cycleList.length; i++) {
        let item = cycleList[i];
        if (cycleList[i].status === "Active") {
          item = {
            ...cycleList[i],
            status: "Inactive",
            endDate: Number(getCurrentTimestampInMillis()),
          };
        }
        newArr.push(item);
      }
      newArr.unshift({ ...data, id: res });
      setCycleList(newArr);
      setYearCycleCount(yearCount);
      setIsLoader(false);
      setIsDisable(false);
      setBatchName("")
      setStockingAMount("")
    } catch (error) {
      console.log("error", error);
      setIsDisable(false);
    }
  };

  const handleSubmitEdit = async () => {
    if (selectedCycle.cycleName.trim() === "") {
      setError(t("ponds.pond_cycle_name_error"));
      return;
    }

    const batchNameLocal = selectedCycle.cycleName ? selectedCycle.cycleName : generateBatchName();
    const isDuplicate = cycleList?.some((cycle) => cycle?.cycleName.trim() === batchNameLocal.trim());

    if (isDuplicate) {
      Error(t("ponds.error_same_name_cycle"), false);
      return;
    }

    setEditButtonLoader(true);
    const data = {
      cycleName: selectedCycle.cycleName,
    };
    try {
      const updatedData = await updatePondCycleNameById(selectedCycle.id, data);
      if (updatedData === "Successfully updated cycle.") {
        let lstTmp = cycleList.map((cycle) => {
          if (cycle.id === selectedCycle.id) {
            return { ...cycle, cycleName: selectedCycle.cycleName };
          } else {
            return cycle;
          }
        });
        setSelectedCycle(null);
        setIsEditPondCycleModalOpen(false);
        setCycleList(lstTmp);
      }
    } catch (error) {
      console.error("Error updating cycle:", error);
    } finally {
      setEditButtonLoader(false);
    }
  };
  return (
    <ResponsiveDrawer
      isShowAppBar={true}
      headText={`${t("ponds.manage_pond_cycle")}: ${window.history.state.usr.cardData.pondName
        } ${t("ponds.cycle")}`}
      isHideToolBar={true}
      isBackArrow={true}
      onClickBack={() => {
        navigation(`/pond_detail`, {
          state: {
            cardData: window.history.state.usr.cardData,
            cycleData: cycleList.length > 0 ? [cycleList[0]] : [],
          },
        });
      }}
      isAddNewCycle={true}
      onClickNewCycleAdd={() => {
        setIsOpenModal(true);
      }}
    >
      <div className="new_cycle_page_css">
        <button
          className="ui primary button"
          style={{ width: 120, marginLeft: 5 }}
          onClick={() => {
            if (hasActiveCycle) {
              setShowError({ ...showError, isShrimpWeight: false });
              if (window.history.state.usr.cardData.isNursery) {
                setIsOpenEndModal(true);
              } else {
                setIsOpenAmount(true);
              }
              setIsOpenModal(false);
            } else {
              setIsOpenEndModal(false);
              setIsOpenModal(true);
            }
          }}
        >
          {hasActiveCycle ? t("endCycle") : t("new_cycle")}
        </button>
      </div>
      {cycleList.length > 0 ? (
        <div>
          <div className={`ui four column grid manage-cycle-css`}>
            {cycleList.map((val) => {
              return (
                <div key={val.id} className="column">
                  <Card
                    sx={{
                      backgroundColor:
                        val.status === "Active"
                          ? colors.green
                          : colors.lightWhite,
                    }}
                  >
                    <CardContent
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <div style={{ position: "absolute", top: 25, right: 25 }}>
                        <p
                          className={
                            val.status === "Active"
                              ? "active_icon"
                              : "look_icon_css green_icon_edit"
                          }
                          onClick={() => openEditPondCycleModal(val)}
                        >
                          <EditOutlinedIcon />
                        </p>
                      </div>
                      <div>
                        <Typography
                          sx={{
                            fontSize: 15,
                            fontWeight: "400",
                            color: val.status === "Active" ? "white" : "black",
                            textAlign: "center",
                          }}
                        >
                          <span style={{ fontWeight: "600" }}>
                            {`${val.cycleName ? `${t("ponds.id")}  : ` : ""}`}
                          </span>
                          {val.cycleName}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: 15,
                            fontWeight: "400",
                            color: val.status === "Active" ? "white" : "black",
                            textAlign: "center",
                          }}
                        >
                          <span style={{ fontWeight: "600" }}>
                            {`${val.startDate ? t("ponds.from") : ""}`}&nbsp;
                          </span>
                          {val.startDate
                            ? getTimestampWithFormatDate(
                                val.startDate,
                                "DD/MM/YYYY"
                              )
                            : ""}
                          &nbsp;
                          <span style={{ fontWeight: "600" }}>
                            {`${val.endDate ? t("ponds.to") : ""}`}&nbsp;
                          </span>
                          {val.endDate
                            ? getTimestampWithFormatDate(
                                val?.endDate,
                                "DD/MM/YYYY"
                              )
                            : ""}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: 15,
                            fontWeight: "400",
                            color: val.status === "Active" ? "white" : "black",
                            textAlign: "center",
                          }}
                        >
                          <span style={{ fontWeight: "600" }}>
                            {`${val.status ? `${t("device.status")}  : ` : ""}`}
                          </span>
                          {val.status === "Active" ? t("home.active") : t("home.inactive")}
                        </Typography>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              );
            })}
          </div>
        </div>
      ) : !isLoader ? (
        <div
          style={{
            display: "flex",
            height: "80vh",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Typography sx={{ fontSize: 17, fontWeight: "600" }}>
            {t("ponds.no_cycle_found_in_this_pond")}
          </Typography>
        </div>
      ) : (
        <div />
      )}

      {/* Edit pond cycle */}
      <Modal
        open={isEditPondCycleModalOpen}
        onClose={closeEditPondCycleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={[boxStyle, { width: 400 }]} className="logout-css-modal-block">
          <div>
            <h5 style={{ marginBottom: "20px" }}>{t("ponds.pond_cycle")}</h5>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="ID"
              type="text"
              fullWidth
              value={selectedCycle?.cycleName}
              onChange={(e) => {
                setSelectedCycle({
                  ...selectedCycle,
                  cycleName: e.target.value,
                });
                if (e.target.value.trim() === "") {
                  setError(t("ponds.pond_cycle_name_error"));
                } else {
                  setError("");
                }
              }}
              error={error !== ""}
              helperText={error}
              variant="standard"
              style={{ marginTop: -7 }}
            />
            <div
              className="mt-3"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <button
                className="ui primary button"
                disabled={editButtonLoader}
                onClick={handleSubmitEdit}
              >
                {editButtonLoader
                  ? t("ponds.loading") + "..."
                  : t("ponds.submit")}
              </button>
            </div>
          </div>
        </Box>
      </Modal>

      {isLoader && (
        <div
          style={{
            position: "absolute",
            zIndex: 55,
            top: 45,
            left: 90,
            display: "flex",
            height: "80vh",
            // height: '100%',
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div id={`load`} className="loader" />
        </div>
      )}

      {/* ----- ADD ----- */}
      <Modal
        open={isOpenModal}
        onClose={() => {
          setIsOpenModal(false)
          setBatchName("")
          setStockingAMount("")
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={[boxStyle, { width: 400 }]} className="logout-css-modal-block">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              paddingTop: 2,
              textAlign: "center",
              marginBottom: 0,
              paddingLeft: 1,
              paddingRight: 1,
            }}
          >
            {/* {`${t("ponds.are_you_sure_you_want_to_create_cycle")} "${Number(
              moment().tz("CET").format("YYYY")
            )} ${t("ponds.cycle")} ${yearCycleCount === 0 ? 1 : yearCycleCount + 1
              }"`} */}
            {`${t("ponds.are_you_sure_you_want_to_create_cycle")}?`}
          </Typography>
          <TextField
            id="batchName"
            label={t("ponds.batch_id")}
            type="text"
            variant="standard"
            onChange={(e) => {
              setBatchName(e.target.value);
            }}
            value={batchName}
            sx={{
              width: "95%",
              fontSize: 18,
              marginTop: 0,
              marginLeft: 2,
            }}
          />

          {/* <TextField
            id="stockingAmount"
            label={t("ponds.stocking_amount")}
            type="number"
            variant="standard"
            required
            onChange={(e) => {
              const value = e.target.value;
              if (value !== "0") {
                setStockingAMount(e.target.value);
              }
            }}
            value={stockingAmount}
            sx={{
              width: "95%",
              fontSize: 18,
              marginTop: 1.5,
              marginBottom: 2,
              marginLeft: 2,
            }}
          /> */}
          <TextField
            id="stockingAmount"
            label={t("ponds.stocking_amount")}
            variant="standard"
            required
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d+$/.test(value) && value !== "0") {
                setStockingAMount(value);
              } else {
                setStockingAMount("");
              }
            }}
            value={stockingAmount}
            sx={{
              width: "95%",
              fontSize: 18,
              marginTop: 1.5,
              marginBottom: 2,
              marginLeft: 2,
            }}
          />

          <Divider sx={{ backgroundColor: "#999" }} />
          <div style={{ marginTop: 10 }} className="logout-button-block">
            <Typography
              sx={textStyle}
              onClick={() => {
                if (isDisable) {
                } else {
                  setIsOpenModal(false);
                  setBatchName("")
                  setStockingAMount("")
                }
              }}
            >
              {t("ponds.cancel")}
            </Typography>
            <div
              style={{ width: "2px", border: "1px solid #999", opacity: 0.5 }}
            />
            <Typography
              sx={[
                textStyle,
                {
                  backgroundColor: isDisable ? colors.lightWhite : colors.blue,
                  color: isDisable ? "grey" : "white",
                  borderBottomRightRadius: 10,
                },
              ]}
              onClick={() => {
                if (isDisable) {
                } else {
                  onClickNewCycle();
                }
              }}
            >
              {t("ponds.yes")}
            </Typography>
          </div>
        </Box>
      </Modal>

      {/* ----- END CYCLE ----- */}
      <Modal
        open={isOpenEndModal}
        onClose={() => setIsOpenEndModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={[boxStyle, { width: 400 }]} className="logout-css-modal-block">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              paddingTop: 2,
              textAlign: "center",
              marginBottom: 2,
              paddingLeft: 1,
              paddingRight: 1,
            }}
          >
            {`${t("ponds.are_you_sure_you_want_to_end_cycle")}`}
          </Typography>

          <Divider sx={{ backgroundColor: "#999" }} />
          <div className="logout-button-block">
            <Typography
              sx={textStyle}
              onClick={() => {
                if (isDisable) {
                } else {
                  setIsOpenEndModal(false);
                }
              }}
            >
              {t("ponds.cancel")}
            </Typography>
            <div
              style={{ width: "2px", border: "1px solid #999", opacity: 0.5 }}
            />
            <Typography
              sx={[
                textStyle,
                {
                  backgroundColor: isDisable ? colors.lightWhite : colors.blue,
                  color: isDisable ? "grey" : "white",
                  borderBottomRightRadius: 10,
                },
              ]}
              onClick={() => {
                if (isDisable) {
                } else {
                  onEndCycle();
                }
              }}
            >
              {t("ponds.yes")}
            </Typography>
          </div>
        </Box>
      </Modal>

      {/* ----- HARVEST AMOUNT ----- */}
      <Modal
        open={isOpenAmount}
        onClose={() => setIsOpenAmount(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={[boxStyle, { width: 400 }]} className="logout-css-modal-block">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              paddingTop: 2,
              textAlign: "center",
              marginBottom: 0,
              paddingLeft: 1,
              paddingRight: 1,
            }}
          >
            {`${t("ponds.how_much_was_harvested")}`}
          </Typography>

          {/* <Divider sx={{ backgroundColor: "#999" }} /> */}
          <TextField
            id="shrimpWeight"
            label={t("ponds.enter_amount_in_kg")}
            type="number"
            variant="standard"
            required
            onChange={(e) => {
              setShrimpWeight(e.target.value);
              setShowError({ ...showError, isShrimpWeight: false });
            }}
            value={shrimpWeight}
            sx={{
              width: "95%",
              fontSize: 18,
              marginTop: 0,
              marginBottom: 2.5,
              marginLeft: 2,
            }}
          />
          {showError.isShrimpWeight && (
            <Alert style={{ marginBottom: 10 }} severity="error">
              {t("ponds.shrimp_harvested_weight_error")}
            </Alert>
          )}
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              paddingTop: 2,
              textAlign: "center",
              marginBottom: 0,
              paddingLeft: 1,
              paddingRight: 1,
            }}
          >
            {`${t("ponds.how_much_feed")}`}
          </Typography>
          <TextField
            id="shrimpWeight"
            label={t("ponds.enter_amountof_feed_in_kg")}
            type="number"
            variant="standard"
            onChange={(e) => {
              setFeedAmount(e.target.value);
              setShowError({ ...showError, isAmountOfFeed: false });
            }}
            value={feedAmount}
            sx={{
              width: "95%",
              fontSize: 18,
              marginTop: 0,
              marginBottom: 2.5,
              marginLeft: 2,
            }}
          />
          {showError.isAmountOfFeed && (
            <Alert style={{ marginBottom: 10 }} severity="error">
              {t("ponds.shrimp_feed_amount_error")}
            </Alert>
          )}
          <div className="logout-button-block">
            <Typography
              sx={textStyle}
              onClick={() => {
                if (isDisable) {
                } else {
                  setIsOpenAmount(false);
                  setIsOpenEndModal(true);
                }
              }}
            >
              {t("ponds.cancel")}
            </Typography>
            <div
              style={{ width: "2px", border: "1px solid #999", opacity: 0.5 }}
            />
            <Typography
              sx={[
                textStyle,
                {
                  backgroundColor: isDisable ? colors.lightWhite : colors.blue,
                  color: isDisable ? "grey" : "white",
                  borderBottomRightRadius: 10,
                },
              ]}
              onClick={() => {
                if (isDisable) {
                } else {
                  if (shrimpWeight === "") {
                    setShowError({ ...showError, isShrimpWeight: true });
                    setIsDisable(false);
                    return;
                  }
                  setShowError({ ...showError, isShrimpWeight: false });
                  setIsOpenAmount(false);
                  setIsOpenEndModal(true);
                }
              }}
            >
              {t("ponds.add")}
            </Typography>
          </div>
        </Box>
      </Modal>
    </ResponsiveDrawer>
  );
}

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: window.innerWidth > 360 ? 400 : 270,
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid",
  borderColor: "gray",
  borderRadius: 3,
  boxShadow: 24,
};
const textStyle = {
  color: colors.slatery_blue,
  fontSize: 17,
  padding: 1,
  textAlign: "center",
  width: "100%",
  fontWeight: "600",
};

export default ManageCycle;
