import React, { useState } from "react";
import {
    GoogleMap,
    useJsApiLoader,
    Marker,
} from "@react-google-maps/api";
import "../../../style/loader.css";
import colors from "../../../common/colors";
import "../../../style/user/Map.css";

const marker_1 =
    "https://firebasestorage.googleapis.com/v0/b/sincere-aquaculture.appspot.com/o/markers%2Fmarker_1.png?alt=media&token=f9298a11-90ae-4dd4-9926-0563edcbe132";
const marker_2 =
    "https://firebasestorage.googleapis.com/v0/b/sincere-aquaculture.appspot.com/o/markers%2Fmarker_2.png?alt=media&token=a2d5957a-e270-4436-8e59-2b188c128261";
const marker_3 =
    "https://firebasestorage.googleapis.com/v0/b/sincere-aquaculture.appspot.com/o/markers%2Fmarker_3.png?alt=media&token=44acb20c-400a-4b40-801d-ec440febe602";

function PondRecodeMap({ modalData }) {


    const [center, setCenter] = useState({
        lat: Number(modalData.latitude),
        lng: Number(modalData.longitude),
    });

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: "AIzaSyAARdYXZPxgm25l-W4YSO49BgAswqE_G5o",
    });

    return (
        <div className="select-main-block" >
            {isLoaded ? (
                <div className="google-map-css">
                    {modalData.latitude && modalData.longitude && (modalData.latitude != "0" && modalData.longitude != "0") ? (
                        <div style={{ marginTop: 10 }}>
                            <GoogleMap
                                mapContainerStyle={{
                                    height: window.innerHeight - 450,
                                    width: "100%",
                                }}
                                options={{
                                    streetViewControl: false,
                                    styles: [
                                        {
                                            elementType: "labels",
                                            featureType: "poi",
                                            stylers: [{ visibility: "off" }],
                                        },
                                    ],
                                }}
                                center={center}
                                zoom={15}
                            >
                                <Marker
                                    position={{ lat: Number(modalData.latitude), lng: Number(modalData.longitude) }}
                                    icon={
                                        modalData.shrimpSickStatus === "Yes" ? marker_1 : modalData.shrimpSickStatus === "No" ? marker_2 : modalData.shrimpSickStatus === "Check needed" ? marker_3 : marker_3
                                    }
                                >
                                </Marker>
                            </GoogleMap>
                        </div>
                    ) :
                        null
                    }

                </div>
            ) : (
                <div
                    style={{
                        display: "flex",
                        height: "80vh",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                    }}
                >
                    <div id={`load`} className="loader" />
                </div>
            )
            }
        </div>
    );
}

const style = {
    text_1: { textAlign: "center", fontSize: 14, fontWeight: "600" },
    text_2: { textAlign: "center", fontSize: 14, fontWeight: "400" },
    viewButton: {
        height: 30,
        paddingBottom: 2,
        border: "none",
        borderRadius: 8,
        backgroundColor: colors.slatery_blue,
        color: "white",
        fontWeight: "500",
        marginTop: 8,
    },
};

export default PondRecodeMap;
