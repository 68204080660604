import React, { useEffect, useState } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import "../../style/auth/Login.css";
import imagePath from "../../common/imagePath";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Error, Info, Success } from "../../common/CustomToast";
import i18next from "i18next";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../config/firebase-config";

function ForgotPassword() {
  const { t } = useTranslation();
  const [isEmailErr, setIsEmailErr] = useState(false);
  const [user, setUser] = useState({
    email: "",
  });
  const [isDisable, setIsDisable] = useState(false);
  const navigation = useNavigate();

  useEffect(() => {
    window.history.forward();
  }, []);

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    if (lang) {
      i18next.changeLanguage(lang);
    }
  }, []);

  const onSubmitForgotPassword = async (e) => {
    e.preventDefault();
    setIsDisable(true);
    let emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (user.email === "") {
      setIsDisable(false);
      return setIsEmailErr(true);
    } else if (!emailRegex.test(user.email)) {
      setIsDisable(false);
      return setIsEmailErr(true);
    }

    try {
      await sendPasswordResetEmail(auth, user?.email)
      setIsDisable(false);
      Success('Your password has been changed successfully');
      navigation("/login");
    } catch (error) {    
      if (error.code === 'auth/user-not-found') {
        Error('User not found')
        setIsDisable(false);
      }
    }
  }

  const onInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "email" && isEmailErr) {
      setIsEmailErr(false);
    }
    setUser((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });
  };

  const emailClassName = `field ${isEmailErr ? "error" : ""}`;
  return (
    <div className="App d-flex flex-column align-items-center justify-content-center w-100 m-auto">
      {/* <Container> */}
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "65%",
          }}
        >
          <div className="main-form-block">
            <form className="ui form segment error">
              {/* ----- IMAGE ----- */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  marginBottom: 20,
                }}
                className="main-logo-block-css"
              >
                <img
                  src={imagePath.main_logo}
                  alt="logo"
                  style={{ marginBottom: 10, borderRadius: 10 }}
                ></img>
                <h3 style={{ fontWeight: "600", marginTop: 10 }}>Forgot Password</h3>
              </div>

              {/* ----- EMAIL ----- */}
              <div className={emailClassName}>
                <label>Email</label>
                <input
                  onChange={onInputChange}
                  value={user.email}
                  type="email"
                  name="email"
                  placeholder="Enter Email"
                  required
                />
                {isEmailErr && (
                  <div className="ui pointing red basic label">
                    {user.email === ""
                      ? "Please fill the email field."
                      : "Inavalid email address!"}
                  </div>
                )}
              </div>             

              {/* ----- LOGIN BUTTON ----- */}
              <div className="loading_button" style={{marginTop:20}}>
                <LoadingButton
                  onClick={onSubmitForgotPassword}
                  className="ui primary submit button"
                  // style={{ width: "100%" }}
                  type="submit"
                  loadingPosition="start"
                  loading={isDisable}
                >
                  Submit
                </LoadingButton>
              </div>
              {/* ----- MOVE SIGNUP PAGE ----- */}
              <div className="move_register_container" style={{marginTop:18}}>
                <p className="move_register_text">
                  Go to{" "}
                  <span
                    className="move_register"
                    onClick={() => navigation("/login")}
                  >
                    Login
                  </span>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* </Container> */}
    </div>
  );
}

export default ForgotPassword;
