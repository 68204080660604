import React, { useEffect, useState } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import "../../style/auth/Login.css";
import colors from "../../common/colors";
import imagePath from "../../common/imagePath";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Error, Info } from "../../common/CustomToast";
import { getUserByEmail, loginUser } from "../../database/auth/login";
import i18next from "i18next";
import { getProfileData } from "../../database/user/profile";
import { LoadingButton } from "@mui/lab";
import { getUserData } from "../../database/user/pond";
import { useTranslation } from "react-i18next";
import {
  Box,
  Typography,
  Modal,
  Divider,
} from "@mui/material";
import * as Sentry from "@sentry/react";
import {
  AUTH_WRONG_PASSWORD,
  AUTH_USER_NOT_FOUND,
  AUTH_INVALID_EMAIL,
  AUTH_EMAIL_DISABLED,
  AUTH_TOO_MANY_REQUESTS,
  AUTH_OPERATION_NOT_ALLOWED,
  AUTH_NETWORK_REQUEST_FAILED,
  AUTH_REQUIRES_RECENT_LOGIN,
} from "../../database/config";
import { emailVerification } from "../../database/auth/signUp";

function Login() {
  const { t } = useTranslation();
  const [isEmailErr, setIsEmailErr] = useState(false);
  const [isPasswordErr, setIsPasswordErr] = useState(false);
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [loginUserRes, setLoginUserRes] = useState();
  const navigation = useNavigate();

  useEffect(() => {
    window.history.forward();
  }, []);

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    if (lang) {
      i18next.changeLanguage(lang);
    }
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let passwordRegex = /^(?=.*).{6,}$/;
    // /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/;

    if (user.email === "") {
      setIsLoading(false);
      return setIsEmailErr(true);
    } else if (!emailRegex.test(user.email)) {
      setIsLoading(false);
      return setIsEmailErr(true);
    }
    if (user.password === "") {
      setIsLoading(false);
      return setIsPasswordErr(true);
    } else if (!passwordRegex.test(user.password)) {
      setIsLoading(false);
      return setIsPasswordErr(true);
    }

    try {
      try {
        let res = await loginUser(user.email, user.password);
        if(!res || res == undefined || res == null){
          setIsLoading(false);
          return
        }
        setLoginUserRes(res);
        // if (!res?.emailVerified) {
        //   setShowAlert(true);
        //   return;
        // }
        Sentry.setUser({
          id:res.uid,
          email:user.email
        })
        let result = await getUserByEmail(user.email);
        if (result[0] && result[0].role === 2) {
          localStorage.setItem("manager_user", "true");
        } 
        if (result[0] && result[0].role === 3) {
          Error("You are not allowed to login here. Please contact admin.");
          setIsLoading(false);
          return;
        } else if (result[0].status === false) {
          Error("Your account is disabled. Please contact admin.");
          setIsLoading(false);
          return;
        }
        else if (result[0]?.isDelete) {
          Error(t("deleteMessage"));
          setIsLoading(false);
          return;
        }
        else if (result[0]?.isLock) {
          Error(t("lockMessage"));
          setIsLoading(false);
          return;
        } else {
          localStorage.setItem("email", user.email);
          localStorage.setItem("uid", res ? res.uid : null);
          localStorage.setItem("pId", res ? res.uid : null);
          if (res.uid) {
            let data = await getProfileData();
            if (data && data.customerId) {
              localStorage.setItem("invoiceCid", data.customerId);
            }
            if (data && data.isPremium) {
              localStorage.setItem("premiumCust", "true");
            }
            if (data && data.isSuperPremium) {
              localStorage.setItem("premiumCust", "true");
            }
            if (data && data.isSuperAdmin) {
              localStorage.setItem("superAdmin", "true");
            }
            if (data && data.pId) {
              localStorage.setItem("pId", data.pId);
            }
            if (data && data.comName) {
              localStorage.setItem("comName", data.comName);
            }
            if (data && data.role) {
              localStorage.setItem("role", data.role);
            }
            if (data && data.timezone) {
              localStorage.setItem("timezone", data.timezone);
            }
            if (data && data.selectedLanguage) {
              localStorage.setItem(
                "lang",
                data.selectedLanguage ? data.selectedLanguage : "en"
              );
              i18next.changeLanguage(
                data.selectedLanguage ? data.selectedLanguage : "en"
              );
            }
          }
          let mainUserId = localStorage.getItem("pId");
          let mainUserData = await getUserData(mainUserId);
          localStorage.setItem("mainUserId", mainUserData.uid);
          
          localStorage.setItem("isLogin", "1");
          localStorage.getItem("superAdmin") === "true"
          ? navigation("/superadmin/users")
          : navigation("/home");
        }
        setIsLoading(false);

        // let mainUserId = localStorage.getItem("pId");
        // let mainUserData = await getUserData(mainUserId);
        // localStorage.setItem("mainUserId", mainUserData.uid);
        localStorage.setItem("isLogin", "1");
        localStorage.getItem("superAdmin") === "true"
          ? navigation("/superadmin/users")
          : navigation("/home");

      } catch (e) {
        console.log('e: ', e);
        if (e && e.code) {
          handleLoginErrors(e);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      Error("User Not Found");
    }
  };

  const handleLoginErrors = (e) => {
    const message = e.message;
    const errorCode = e.code;
    switch (errorCode) {
      case AUTH_WRONG_PASSWORD:
        Error(t("login.wrong_password"));
        break;
      case AUTH_USER_NOT_FOUND:
        Error(t("login.user_not_found"));
        break;
      case AUTH_INVALID_EMAIL:
        Error(t("login.invalid_email"));
        break;
      case AUTH_EMAIL_DISABLED:
        Error(t("login.user_disabled"));
        Sentry.captureException(e);
        break;
      case AUTH_TOO_MANY_REQUESTS:
        Error(t("login.too_many_requests"));
        break;
      case AUTH_OPERATION_NOT_ALLOWED:
        Error(t("login.operation_not_allowed"));
        Sentry.captureException(e);
        break;
      case AUTH_NETWORK_REQUEST_FAILED:
        Error(t("login.network_request_failed"));
        Sentry.captureException(e);
        break;
      case AUTH_REQUIRES_RECENT_LOGIN:
        Error(t("login.requires_recent_login"));
        Sentry.captureException(e);
        break;
      default:
        if (message.includes("timeout") || errorCode.includes("timeout")) {
          Error(t("login.timeout_error"));
        } else {
          Error(t("login.something_went_wrong"));
        }
        Sentry.captureException(e);
        break;
    }
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "email" && isEmailErr) {
      setIsEmailErr(false);
    }
    if (name === "password" && isPasswordErr) {
      setIsPasswordErr(false);
    }
    setUser((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });
  };

  const resendVerification = async () => {
    const res = await emailVerification(loginUserRes);
    if (res) {
      setLoginUserRes(null)
    }
  }

  const emailClassName = `field ${isEmailErr ? "error" : ""}`;
  const pwdClassName = `field ${isPasswordErr ? "error" : ""}`;
  return (
    <div className="App d-flex flex-column align-items-center justify-content-center w-100 m-auto">
      {/* <Container> */}
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            // minWidth: window.innerWidth > 1100 ? 600 : 260,
            width: "65%",
            // maxWidth: 450,
          }}
        >
          <div className="main-form-block">
            <form className="ui form segment error">
              {/* ----- IMAGE ----- */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  marginBottom: 20,
                }}
                className="main-logo-block-css"
              >
                <img
                  src={imagePath.main_logo}
                  alt="logo"
                  style={{ marginBottom: 10, borderRadius: 10 }}
                ></img>
                <h2 style={{ fontWeight: "600" }}>Login</h2>
              </div>

              {/* ----- EMAIL ----- */}
              <div className={emailClassName}>
                <label>Email</label>
                <input
                  onChange={onInputChange}
                  value={user.email}
                  type="email"
                  name="email"
                  placeholder="Enter Email"
                  required
                />
                {isEmailErr && (
                  <div className="ui pointing red basic label">
                    {user.email === ""
                      ? "Please fill the email field."
                      : "Inavalid email address!"}
                  </div>
                )}
              </div>

              {/* ----- PASSWORD ----- */}
              <div className={pwdClassName}>
                <label>Password</label>
                <input
                  onChange={onInputChange}
                  value={user.password}
                  type="password"
                  name="password"
                  placeholder="Enter Password"
                  autoComplete="off"
                  required
                />
                {isPasswordErr && (
                  <div className="ui pointing red basic label">
                    {Number(user.password.length) > 0
                      ? Number(user.password.length) < 6
                        ? "Password must be atleast 6 characters."
                        : `Wrong password!`
                      : "Please fill the password field."}
                  </div>
                )}
              </div>

              {/* ----- LOGIN BUTTON ----- */}
              <div className="loading_button">
                <LoadingButton
                  onClick={onSubmit}
                  className="ui primary submit button"
                  // style={{ width: "100%" }}
                  type="submit"
                  loadingPosition="start"
                  loading={isLoading}
                >
                  Login
                </LoadingButton>
              </div>

              {/* ----- FORGOT PASSWORD ----- */}
              <div
                className={`field`}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 10,
                }}
              >
                <label
                  style={{ fontSize: 14, cursor: "pointer" }}
                  onClick={() => {
                    navigation("/forgot_password");
                  }}
                >
                  Forgot password?
                </label>
              </div>

              {/* ----- MOVE SIGNUP PAGE ----- */}
              <div className="move_register_container">
                <p className="move_register_text">
                  Don't have an account?{" "}
                  <span
                    className="move_register"
                    onClick={() => navigation("/signup")}
                  >
                    Click here to register
                  </span>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* </Container> */}
      <Modal
        open={showAlert}
        onClose={() => {
          setLoginUserRes(null)
          setShowAlert(false)
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={boxStyle} className="logout-css-modal-block">
          <div style={{ display: "flex", justifyContent: "flex-end", marginTop: -10, marginBottom: 7 }}>
            <i
              className="x icon"
              style={{ color: "#1e274c", fontSize: 22, cursor: "pointer" }}
              onClick={() => {
                setLoginUserRes(null)
                setShowAlert(false);
              }}
            />
          </div>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              paddingTop: 2,
              textAlign: "center",
              marginBottom: 2,
              paddingLeft: 1,
              paddingRight: 1,
            }}
          >
            Your email verification is pending, We have sent you a verification link. Please check the spam folder in case you do not find the link in your inbox
          </Typography>
          <Divider />
          <div className="logout-button-block">
            <Typography sx={textStyle} onClick={async () => {
              setShowAlert(false);
              await resendVerification();
            }}>
              Resend Verification Email
            </Typography>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default Login;

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 360 ? 500 : 400,
  bgcolor: "background.paper",
  border: "2px solid",
  borderColor: "gray",
  borderRadius: 3,
  boxShadow: 24,
};
const textStyle = {
  color: colors.slatery_blue,
  fontSize: 17,
  padding: 1,
  textAlign: "center",
  width: "100%",
  fontWeight: "600",
};