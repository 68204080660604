// app version
export const APP_VERSION = "2.1"

export const isDEV = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
//STRIPE CONFIGS
export const PRODUCT_BASIC_USERS = 25
export const PRODUCT_ENTERPRICE_USERS = 50
export const PRODUCT_ENTERPRICE_PLUS_USERS = -1

export const PRODUCT_BASIC_RECORDS = 10000
export const PRODUCT_ENTERPRICE_RECORDS = 50000
export const PRODUCT_ENTERPRICE_PLUS_RECORDS = 1000000

export const DEVICE_SC_4K = "SC-4K"
export const DEVICE_SC_20K = "SC-20K"
export const DEVICE_SP_1 = "SP-1"
export const N_A = "N/A"
export const SLA = "SLA"
export const S1 = "S1"
export const M3 = "3M"
export const M12 = "12M"
export const YES = "Yes"
export const NO = "No"
export const DEVICE_LICENSE = "DeviceLicense"
export const CLOUD_LICENSE = "CloudLicense"
export const NONE = "None"
export const PAID = "Paid"
export const UNPAID = "Unpaid"
export const UTC = "UTC"
export const WEBAPP = "Webapp"
export const NA = "N/A"

export const PRODUCT_BASIC = isDEV ? "prod_Mjo4reSwfBsOYY" : "prod_NJSlaKHvt7HRKl"
export const PRODUCT_ENTERPRICE = isDEV ? "prod_MjoJlEsDiM1kTn" : "prod_NJSlwnUSU26jQO"
export const PRODUCT_ENTERPRICE_PLUS = isDEV ? "prod_MjoLxOR7muC9k4" : "prod_NJSlZKtwIRGHds"
export const CUSTOMER_PORTAL_URL = isDEV ? "https://billing.stripe.com/p/login/test_7sI5lLbQU13n7ra5kk" : "https://billing.stripe.com/p/login/9AQ00l3XUdbBaIwfYY"
export const STRIPE_TOKEN = isDEV ? "rk_test_51LkplZHTIWVheqSGf6SIM4ceuN34Uy1nPYEOPclly2Zux2WgPLxoaXjbh4prH1R38Tk4Gedh1VVns1XRdcyvkLQ9002JUYyAMN" : "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"
export const PRICING_TABLE_ID = isDEV ? "prctbl_1M0KrjHTIWVheqSGo9gRD2Jp" : "prctbl_1MYqJDHTIWVheqSG41eGaEon"
export const PUBLISHABLE_KEY = isDEV ? "pk_test_51LkplZHTIWVheqSGWq47vhRvgO2h7uI8U2x0OhDbLnWuB7spNChpHdqEMXHwEMrukJfzwKJZyi4p6CsdzFU9MB2e00tKZL7Ks3" : "pk_live_51LkplZHTIWVheqSGv9Lhni9n85dAxXX5Qmhwl6Npa9S3U0ws0CrGVDGtFYmB1flnctyMizMzJ4GIIjJWLTm2vRKZ00jT0HGxxV"

//TEST
// export const PRODUCT_BASIC = "prod_Mjo4reSwfBsOYY"
// export const PRODUCT_ENTERPRICE = "prod_MjoJlEsDiM1kTn"
// export const PRODUCT_ENTERPRICE_PLUS = "prod_MjoLxOR7muC9k4"
// export const CUSTOMER_PORTAL_URL = "https://billing.stripe.com/p/login/test_7sI5lLbQU13n7ra5kk"
// export const STRIPE_TOKEN = "rk_test_51LkplZHTIWVheqSGf6SIM4ceuN34Uy1nPYEOPclly2Zux2WgPLxoaXjbh4prH1R38Tk4Gedh1VVns1XRdcyvkLQ9002JUYyAMN"
//STRIPE CONFIGS