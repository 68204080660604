import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import colors from "../../common/colors";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import i18next from "i18next";
import { Error, Success } from "../../common/CustomToast";
import { confirmPassword, getProfileData, updateProfileById } from "../../database/user/profile";
import "../../style/loader.css";
import { COUNTRY_LIST, TIMEZONE } from "../../common/commonList";
import { LoadingButton, Autocomplete } from "@mui/lab";
import { Save } from "@mui/icons-material";
import { getCountries } from "../../database/user/settings";
import { useNavigate } from "react-router-dom";
import { Box, Modal, TextField, Typography } from "@mui/material";
import { signOut } from "firebase/auth";
import { auth } from "../../config/firebase-config";

function Profile(props) {
  const { profile } = props;
  const { t } = useTranslation();
  const [selectedCountry, setSelectedCountry] = useState("");
  const navigate = useNavigate();
  const refreshPage = () => {
    navigate(0);
  };
  const [settingData, setSettingData] = useState({
    companyName: "",
    country: "",
    // streetNo: "",
    email: "",
    timezone: "",
    vat: "",
    postalCode: "",
    city: "",
    phoneNo: "",
    name: "",
    jobTitle: "",
    address1: "",
    address2: "",
  });
  const [showError, setShowError] = useState({
    isCmpName: false,
    isCountry: false,
    isStreetNo: false,
    isTimezone: false,
    isVat: false,
    isPostalCode: false,
    isCity: false,
    isPhone: false,
    isName: false,
    isJobTitle: false,
  });
  const [isDisable, setIsDisable] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState(0);
  const [segmentList, setSegmentList] = useState([
    "General",
    "Billing",
    "User Management",
  ]);
  const [languages] = useState([
    { code: "en", name: "English", country_code: "en" },
    { code: "es", name: "Spanish", country_code: "es" },
  ]);
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [isLoader, setIsLoader] = useState(false);

  const [isConfirmPassword, setIsConfirmPassword] = useState(false);
  const [changePassForm, setChangePassForm] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [changePassErrors, setChangePassErrors] = useState({});
  const [isLoadingChangePassword, setIsLoadingChangePassword] = useState(false);

  const confirmPassChange = (event) => {
    const { name, value } = event.target;
    setChangePassForm({ ...changePassForm, [name]: value });
    // Clear the error message when the user starts typing again
    setChangePassErrors({ ...changePassErrors, [name]: "" });
  };
  const confirmPassSubmit = async () => {
    setIsLoadingChangePassword(true);
    let errors = {};

    // uid and newPassword
    // Validation logic
    // if (changePassForm.oldPassword.trim() === "") {
    //   errors.oldPassword = t("settings.old_Password_is_required");
    // }
    if (changePassForm.newPassword.trim() === "") {
      errors.newPassword = t("settings.new_Password_is_required");
    }
    if (changePassForm.confirmPassword.trim() === "") {
      errors.confirmPassword = t("settings.confirm_Password_is_required");
    }
    if (changePassForm.newPassword !== changePassForm.confirmPassword) {
      errors.confirmPassword = t("settings.passwords_do_not_match");
    }

    // If there are errors, set them and return
    if (Object.keys(errors).length > 0) {
      setChangePassErrors(errors);
      setIsLoadingChangePassword(false);
      return;
    }
    const uid = localStorage.getItem('uid')
    const res = await confirmPassword({
      uid: uid,
      newPassword: changePassForm.newPassword
    });
    if(res.error!=true){
      await signOut(auth);
      localStorage.setItem("isLogin", "0");
      localStorage.setItem("manager_user", "false");
      localStorage.setItem("uid", null);
      localStorage.setItem("invoiceCid", null);
      localStorage.setItem("premiumCust", null);
      localStorage.setItem("stickyDays", null);
      localStorage.clear();
      navigate("/login");
      setIsLoadingChangePassword(false)
      Success(res.message || t("settings.success"))
    }else{
      Error(res.message || t("something_went_wrong"))
      setIsLoadingChangePassword(false)
    }             
    // If no errors, proceed with submitting the form
    console.log("changePassForm: 106 ===>", changePassForm);
  };

  const countryList = getCountries();
  const timeZoneList = TIMEZONE;

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    if (lang) {
      setSelectedLanguage(lang);
    }
    getAllDatas();
  }, [profile]);

  const getAllDatas = async () => {
    setIsLoader(true);
    try {
      if (profile) {
        setSettingData({
          name: profile.fullName,
          jobTitle: profile.jobTitle,
          companyName: profile.comName,
          email: profile.email,
          phoneNo: profile.phone,
          postalCode: profile.postal_code ? profile.postal_code : "",
          city: profile.city ? profile.city : "",
          country: profile.country ? profile.country : "",
          vat: profile.vatNo ? profile.vatNo : "",
          timezone: profile.timezone ? profile.timezone : "",
          address1: profile.line1 ? profile.line1 : "",
          address2: profile.line2 ? profile.line2 : "",
        });
        const lang = localStorage.getItem("lang");
        if (!lang) {
          if (
            profile &&
            profile.selectedLanguage !== localStorage.getItem("lang")
          ) {
            localStorage.setItem(
              "lang",
              profile.selectedLanguage ? profile.selectedLanguage : "en"
            );
            i18next.changeLanguage(
              profile.selectedLanguage ? profile.selectedLanguage : "en"
            );
          }
        }
      }
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
    }
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setSettingData((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsDisable(true);
    let emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let passwordRegex = /^(?=.*).{6,}$/;
    let phoneRegex = /^\d{10,12}$/;
    let fullNameRegex = /^[a-zA-Z\s]+/gi;
    if (settingData.fullname === "") {
      setIsDisable(false);
      setShowError({ ...showError, isName: true });
      return;
    }
    // else if (!fullNameRegex.test(settingData.fullname)) {
    //   setIsDisable(false);
    //   setShowError({ ...showError, isName: true });
    //   return;
    // }

    // if (user.email === "") {
    //   setIsDisable(false);
    //   return setIsEmailErr(true);
    // } else if (!emailRegex.test(user.email)) {
    //   setIsDisable(false);
    //   return setIsEmailErr(true);
    // }
    if (settingData.phoneNo === "") {
      setIsDisable(false);
      setShowError({ ...showError, isPhone: true });
      return;
    } else if (!settingData.phoneNo?.startsWith("+")) {
      setIsDisable(false);
      setShowError({ ...showError, isPhone: true });
      return;
    }
    // if (user.password === "") {
    //   setIsDisable(false);
    //   return setIsPasswordErr(true);
    // } else if (!passwordRegex.test(user.password)) {
    //   setIsDisable(false);
    //   return setIsPasswordErr(true);
    // }
    if (settingData.companyName === "") {
      setIsDisable(false);
      setShowError({ ...showError, isCmpName: true });
      return;
    }
    if (settingData.timezone === "") {
      setIsDisable(false);
      setShowError({ ...showError, isTimezone: true });
      return;
    }

    try {
      const data = {
        fullName: settingData.name,
        jobTitle: settingData.jobTitle,
        comName: settingData.companyName,
        phone: settingData.phoneNo,
        selectedLanguage: selectedLanguage,
        postal_code:
          settingData.postalCode === "" ? "" : Number(settingData.postalCode),
        city: settingData.city,
        country: settingData.country,
        vatNo: settingData.vat,
        timezone: settingData.timezone,
        line1: settingData.address1,
        line2: settingData.address2,
      };
      let res = await updateProfileById(data);
      if (res) {
        localStorage.setItem("lang", selectedLanguage);
        if (settingData.timezone) {
          localStorage.setItem("timezone", settingData.timezone);
        }
        Success(t("settings.profile_updated_successfully"));
      } else {
        Error(t("settings.profile_not_update_please_try_again_later"));
      }
      setIsDisable(false);
    } catch (error) {
      setIsDisable(false);
    }
  };

  const autoValue = (value) => {
    // Check if value is provided
    if (value) {
      // Attempt to find the object in timeZoneList
      const selectedObj = timeZoneList.find((item) => item.text === value);

      // If the object is not found, use getObjectByKey to retrieve it
      if (!selectedObj) {
        return getObjectByKey(timeZoneList, value);
      }

      // Return the found object
      return selectedObj;
    }

    return null; // Return null if no value is provided
  };

  function getObjectByKey(data, key) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].utc.includes(key)) {
        return data[i];
      }
    }
    return null; // Return null if key is not found in any object
  }

  if (isLoader) {
    return (
      <div
        style={{
          display: "flex",
          height: "50vh",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <div id={`load`} className="loader" />
      </div>
    );
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
      className="form-general-css-block Settings_input_box"
    >
      <form
        className="ui form error"
        style={{
          display: "flex",
          flexDirection: "column",
          width: "75%",
          justifyContent: "center",
        }}
      >
        {/* --- FULL-NAME / EMAIL --- */}
        <div className="two fields">
          <div className={`field ${showError.isName ? "error" : ""}`}>
            <label>{t("profile.full_name")}</label>
            <input
              type="text"
              name="name"
              placeholder=""
              value={settingData.name}
              onChange={(e) => {
                if (showError.isName) {
                  setShowError({ ...showError, isName: false });
                }
                onInputChange(e);
              }}
            />
            {showError.isName && (
              <div className="ui pointing red basic label">
                {settingData.name === "" && t("profile.please_fill_name_field")}
              </div>
            )}
          </div>
          <div className={`field`}>
            <label>{t("profile.email")}</label>
            <input
              type="email"
              name="email"
              placeholder=""
              value={settingData.email}
              disabled={true}
            />
          </div>
        </div>

        {/* --- PHONE / JOB-TITLE --- */}
        <div className="two fields">
          <div className={`field ${showError.isPhone ? "error" : ""}`}>
            <label>{t("profile.phone_no")}</label>
            <input
              type="tel"
              name="phoneNo"
              placeholder=""
              value={settingData.phoneNo}
              onChange={(e) => {
                if (showError.isPhone) {
                  setShowError({ ...showError, isPhone: false });
                }
                onInputChange(e);
              }}
            />
            {showError.isPhone && (
              <div className="ui pointing red basic label">
                {settingData.phoneNo === ""
                  ? t("profile.please_fill_phone_number_field")
                  : t("profile.please_add_country_code")}
              </div>
            )}
          </div>
          <div className={`field ${showError.isJobTitle ? "error" : ""}`}>
            <label>{t("profile.job_title")}</label>
            <input
              type="text"
              name="jobTitle"
              placeholder=""
              value={settingData.jobTitle}
              onChange={(e) => {
                // if (showError.isJobTitle) {
                //   setShowError({ ...showError, isJobTitle: false });
                // }
                onInputChange(e);
              }}
            />
            {/* {showError.isJobTitle && (
              <div className="ui pointing red basic label">
                {settingData.jobTitle === "" &&
                  t("profile.please_fill_job_title_field")}
              </div>
            )} */}
          </div>
        </div>

        {/* --- COMPANY-NAME / VAT --- */}
        <div className="two fields">
          <div className={`field ${showError.isCmpName ? "error" : ""}`}>
            <label>{t("profile.company_name")}</label>
            <input
              type="text"
              name="companyName"
              placeholder=""
              value={settingData.companyName}
              onChange={(e) => {
                if (showError.isCmpName) {
                  setShowError({ ...showError, isCmpName: false });
                }
                onInputChange(e);
              }}
            />
            {showError.isCmpName && (
              <div className="ui pointing red basic label">
                {settingData.companyName === "" &&
                  t("profile.please_fill_company_name_field")}
              </div>
            )}
          </div>
          <div className={`field ${showError.isVat ? "error" : ""}`}>
            <label>{t("profile.vat_company_number")}</label>
            <input
              type="text"
              name="vat"
              placeholder=""
              value={settingData.vat}
              onChange={(e) => {
                // if (showError.isVat) {
                //   setShowError({ ...showError, isVat: false });
                // }
                onInputChange(e);
              }}
            />
            {/* {showError.isVat && (
              <div className="ui pointing red basic label">
                {settingData.vat === "" &&
                  t("profile.please_fill_job_title_field")}
              </div>
            )} */}
          </div>
        </div>

        {/* --- ADDRESS-1 / ADDRESS-2 --- */}
        <div className="two fields">
          <div className={`field`}>
            <label>{t("profile.address_1")}</label>
            <input
              type="text"
              name="address1"
              placeholder=""
              value={settingData.address1}
              onChange={(e) => {
                onInputChange(e);
              }}
            />
          </div>
          <div className={`field`}>
            <label>{t("profile.address_2")}</label>
            <input
              type="text"
              name="address2"
              placeholder=""
              value={settingData.address2}
              onChange={(e) => {
                onInputChange(e);
              }}
            />
          </div>
        </div>

        {/* --- POSTAL CODE / CITY --- */}
        <div className="two fields">
          <div className={`field ${showError.isPostalCode ? "error" : ""}`}>
            <label>{t("profile.postal_code")}</label>
            <input
              type="number"
              name="postalCode"
              placeholder=""
              value={settingData.postalCode}
              onChange={(e) => {
                // if (showError.isPostalCode) {
                //   setShowError({ ...showError, isPostalCode: false });
                // }
                onInputChange(e);
              }}
            />
            {/* {showError.isPostalCode && (
              <div className="ui pointing red basic label">
                {settingData.postalCode === "" &&
                  t("profile.please_fill_job_title_field")}
              </div>
            )} */}
          </div>
          <div className={`field ${showError.isCity ? "error" : ""}`}>
            <label>{t("profile.city")}</label>
            <input
              type="text"
              name="city"
              placeholder=""
              value={settingData.city}
              onChange={(e) => {
                // if (showError.isCity) {
                //   setShowError({ ...showError, isCity: false });
                // }
                onInputChange(e);
              }}
            />
            {/* {showError.isCity && (
              <div className="ui pointing red basic label">
                {settingData.city === "" &&
                  t("profile.please_fill_job_title_field")}
              </div>
            )} */}
          </div>
        </div>

        {/* --- COUNTRY / TIMEZONE --- */}
        <div className="two fields">
          <div className={`field ${showError.isCountry ? "error" : ""}`}>
            <label>{t("profile.country")}</label>
            {/* <input
              type="text"
              name="country"
              placeholder=""
              value={settingData.country}
              onChange={(e) => {
                if (showError.isCountry) {
                  setShowError({ ...showError, isCountry: false });
                }
                onInputChange(e);
              }}
            /> */}
            <div className="before-select-css">
              {" "}
              <select
                className="ui fluid dropdown"
                value={settingData.country}
                onChange={(e) => {
                  // if (showError.isCountry) {
                  //   setShowError({ ...showError, isCountry: false });
                  // }
                  setSettingData((preValue) => {
                    return {
                      ...preValue,
                      country: e.target.value,
                    };
                  });
                }}
              >
                <option value="">None</option>
                {countryList.map((val) => {
                  return (
                    <option key={val.text} value={val.value}>
                      {val.text}
                    </option>
                  );
                })}
              </select>
            </div>
            {/* {showError.isCountry && (
              <div className="ui pointing red basic label">
                {settingData.country === "" &&
                  t("profile.please_fill_job_title_field")}
              </div>
            )} */}
          </div>
          <div
            className={`field timezone-field ${
              showError.isTimezone ? "error" : ""
            }`}
          >
            <label>{t("profile.timezone")}</label>
            {/* <div className="before-select-css">
              <select
                className="ui fluid dropdown"
                value={settingData.timezone}
                onChange={(e) => {
                  setSettingData((preValue) => {
                    return {
                      ...preValue,
                      timezone: e.target.value,
                    };
                  });
                }}
              >
                <option value="">None</option>
                {timezoneList.map((val) => {
                  return (
                    <option key={val.text} value={val.text}>
                      {val.text}
                    </option>
                  );
                })}
              </select>
            </div> */}
            <div className={`field ${showError.isTimezone ? "error" : ""}`}>
              <Autocomplete
                options={timeZoneList}
                getOptionLabel={(option) => option.text}
                renderInput={(params) => <TextField {...params} />}
                value={autoValue(settingData.timezone)}
                onChange={(event, value) => {
                  if (value) {
                    setSettingData((preValue) => {
                      return {
                        ...preValue,
                        timezone: value.utc[0],
                      };
                    });
                    setShowError({
                      ...showError,
                      isTimezone: false,
                    });
                  } else {
                    onInputChange({
                      target: {
                        value: "",
                        name: "timezone",
                      },
                    });
                  }
                }}
              />
              {showError.isTimezone && (
                <div className="ui pointing red basic label">
                  {settingData?.timezone === "" &&
                    t("profile.please_fill_timezone_field")}
                </div>
              )}
            </div>
          </div>
        </div>

        {/* ---  / SELECT LANGUAGE --- */}
        <div className="two fields">
          <div className={`field`}>
            <label>{t("home.select_language")}</label>
            <div className="before-select-css">
              <select
                onChange={(e) => {
                  localStorage.setItem("lang", e.target.value);
                  setSelectedLanguage(e.target.value);
                  i18next.changeLanguage(e.target.value);
                  refreshPage();
                }}
                value={selectedLanguage}
                //   style={{paddingRight: 8,paddingLeft: 10}}
              >
                {languages.map((val) => {
                  return (
                    <option key={val.code} value={val.code}>
                      {val.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className={`field`}>
            <label>{t("settings.change_password")}</label>
            <LoadingButton
              onClick={() => {
                setIsConfirmPassword(true);
                setChangePassForm({
                  oldPassword: "",
                  newPassword: "",
                  confirmPassword: "",
                });
              }}
              // loading={isDisable}
              size="large"
              loadingPosition="start"
              variant="contained"
              sx={{ backgroundColor: colors.slatery_blue }}
            >
              {t("settings.change_password")}
            </LoadingButton>
          </div>
        </div>

        {/* --- SAVE BUTTON --- */}
        <div className="two fields">
          <LoadingButton
            onClick={onSubmit}
            loading={isDisable}
            size="large"
            loadingPosition="start"
            startIcon={<Save />}
            variant="contained"
            sx={{ backgroundColor: colors.slatery_blue }}
          >
            {t("ponds.save")}
          </LoadingButton>
        </div>
      </form>
      {/* ----- ADD/EDIT MODAL ----- */}
      <Modal
        open={isConfirmPassword}
        onClose={() => setIsConfirmPassword(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description "
      >
        <Box sx={boxStyle} className="add-Pond-location">
          <div
            style={{
              display: "flex",
              width: "100%",
              backgroundColor: colors.slatery_blue,
              justifyContent: "space-between",
              padding: 8,
              position: "sticky",
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
            className="hiding-pond-css"
          >
            <Typography
              sx={{
                fontSize: 18,
                color: "white",
                fontWeight: "600",
                marginLeft: 1,
              }}
            >
              {t("settings.change_password")}
            </Typography>
            <i
              className="x icon"
              style={{ color: "white", fontSize: 22 }}
              onClick={() => {
                setIsConfirmPassword(false);
              }}
            />
          </div>

          <div style={{ maxHeight: "650px", overflow: "auto" }}>
            <form
              className="ui form container"
              style={{
                marginTop: 15,
                paddingLeft: 30,
                paddingRight: 30,
                paddingBottom: 15,
              }}
              // onSubmit={onAddSubmit}
            >
              {/* -- old Password -- */}
              {/* <div style={{ marginBottom: 12 }}>
                <label>{t("settings.old_Password")}</label>
                <input
                  type="password"
                  placeholder={t("settings.old_Password")}
                  name="oldPassword"
                  value={changePassForm.oldPassword}
                  onChange={(event) => confirmPassChange(event)}
                />
                {changePassErrors.oldPassword && (
                  <div style={{ color: "red" }}>
                    {changePassErrors.oldPassword}
                  </div>
                )}
              </div> */}

              {/* -- new Password -- */}
              <div style={{ marginBottom: 12 }}>
                <label>{t("settings.new_password")}</label>
                <input
                  type="password"
                  placeholder={t("settings.new_password")}
                  name="newPassword"
                  value={changePassForm.newPassword}
                  onChange={(event) => confirmPassChange(event)}
                />
                {changePassErrors.newPassword && (
                  <div style={{ color: "red" }}>
                    {changePassErrors.newPassword}
                  </div>
                )}
              </div>

              {/* -- confirm Password -- */}
              <div style={{ marginBottom: 12 }}>
                <label>{t("settings.confirm_password")}</label>
                <input
                  type="password"
                  placeholder={t("settings.confirm_password")}
                  name="confirmPassword"
                  value={changePassForm.confirmPassword}
                  onChange={(event) => confirmPassChange(event)}
                />
                {changePassErrors.confirmPassword && (
                  <div style={{ color: "red" }}>
                    {changePassErrors.confirmPassword}
                  </div>
                )}
              </div>

              {/* -- BUTTON -- */}
              <div style={{ display: "flex", justifyContent: "center" }}>
                <LoadingButton
                  onClick={confirmPassSubmit}
                  loading={isLoadingChangePassword}
                  loadingPosition="start"
                  // startIcon={<Save />}
                  variant="contained"
                  sx={{ backgroundColor: colors.slatery_blue }}
                >
                  {t("settings.change_password")}
                </LoadingButton>
              </div>
            </form>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default Profile;

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: window.innerWidth > 375 ? 400 : 270,
  width: 650,
  bgcolor: "background.paper",
  border: "2px solid",
  borderColor: "gray",
  borderRadius: 3,
  boxShadow: 24,
};
