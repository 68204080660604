import {
  Alert,
  Box,
  Divider,
  Modal,
  TextField,
  Typography,
  styled,
  InputBase,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../../style/loader.css";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Popover from "react-bootstrap/Popover";
import colors from "../../../common/colors";
import { useTranslation } from "react-i18next";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CloseIcon from "@mui/icons-material/Close";
import { singUpUser } from "../../../database/auth/signUp";
import { Error } from "../../../common/CustomToast";
import { getUserData } from "../../../database/user/pond";
import { db } from "../../../config/firebase-config";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  deleteUsersFromDb,
  getAllUserByPid,
  updateUserById,
} from "../../../database/user/userManagement";
import "../../../style/loader.css";
import LoadingButton from '@mui/lab/LoadingButton';
import { Save, Tune } from "@mui/icons-material";
import { doc, increment, updateDoc } from "firebase/firestore";
import { getCountersDataFromDb, getPlansRecordsFromDb } from "../../../database/user/home";
import AlertModal from "../../../components/AlertModal";
import {
  updateProfileById,
} from "../../../database/user/profile";
import { getUnpaidInvoice } from "../../../database/user/invoice";
import { getCurrentSubscription } from "../../../api/api";
import {
  CLOUD_LICENSE,
  CUSTOMER_PORTAL_URL,
  PRODUCT_BASIC,
  PRODUCT_BASIC_USERS,
  PRODUCT_ENTERPRICE,
  PRODUCT_ENTERPRICE_PLUS,
  PRODUCT_ENTERPRICE_USERS,
} from "../../../common/configs";
import no_alarms_found from "../../../images/no_alarms_found.png";
import { Multiselect } from "multiselect-react-dropdown";
import { getFarms } from "../../../database/user/pond";
import { getCalculateDaysDifference, getCurrentTimestampInMillis } from "../../../common/utils";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "8px 26px 8px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

function UserManagement(props) {
  const { profile, onClickProfileUpdate, onSelectSegment } = props;
  const navigation = useNavigate();
  const { t } = useTranslation();
  const [userList, setUserList] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [user, setUser] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    cpassword: "",
    status: "Active",
  });
  const [notif, setNotif] = useState(false);
  const [roleList] = useState([
    { value: "Data collector", label: t("userManagement.data_collector"), index: 0 },
    { value: "Farm manager", label: t("userManagement.farm_manager"), index: 1 },
    { value: "Administrator", label: t("userManagement.administrator"), index: 2 },
  ]);
  const [role, setRole] = useState("Data collector");
  const [isHelp, setIsHelp] = useState(false);
  const [isNameErr, setIsNameErr] = useState(false);
  const [isSelectFarmErr, setIsSelectFarmErr] = useState(false);
  const [isEmailErr, setIsEmailErr] = useState(false);
  const [isPasswordErr, setIsPasswordErr] = useState(false);
  const [isCPasswordErr, setIsCPasswordErr] = useState(false);
  // const [isPhoneErr, setIsPhoneErr] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [sortValue, setSortValue] = useState("All");
  const [isDisable, setIsDisable] = useState(false);
  const [isData, setIsData] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [isSubscriptionActive, setSubscriptionActive] = useState(false);

  const [isShowAlert, setIsShowAlert] = useState(false);
  const [isClickUser, setIsClickUser] = useState(false);
  const [isOpenAlertModal, setIsOpenAlertModal] = useState(false);

  const [isOpenSubscribeModal, setIsOpenSubscribeModal] = useState(false);
  const [getRadioName, setGetRadioName] = useState("");

  const [subscribeUser, setSubscribeUser] = useState({
    email: "",
    city: "",
    country: "",
    line1: "",
    line2: "",
    postalCode: "",
    state: "",
    name: "",
    phone: "",
  });
  const [showError, setShowError] = useState({
    isCity: false,
    isCountry: false,
    isLine1: false,
    isLine2: false,
    isPostalCode: false,
    isState: false,
    isName: false,
    isPhone: false,
  });
  const [isPremium, setIsPremium] = useState(false);
  const [isPayInvoice, setIsPayInvoice] = useState(false);
  const [farms, setFarms] = useState([]);

  const [farmItemsSearch, setFarmItemsSearch] = useState();
  const [farmList, setFarmList] = useState([]);
  const [selectEditFarmName, SetSelectEditFarmName] = useState();
  const [isSelectedAll, setIsSelectedAll] = useState(false);

  const handleSelect = (selectedList, selectedItem) => {
    SetSelectEditFarmName([])
    setTimeout(() => {
      if (selectedItem && selectedItem.name == "All farms") {
        let farmsData = [{ name: 'All farms' }];
        setIsSelectedAll(true)
        // if (farmItemsSearch && farmItemsSearch.length > 0) {
        //   for (const farm of farmItemsSearch) {
        //     if (farm.name != "All farms") {
        //       farmsData.push(farm)
        //     }
        //   }
        // }
        setFarmList(farmsData);
        SetSelectEditFarmName(farmsData);
      } else {
        setFarmList(selectedList);
        SetSelectEditFarmName(selectedList);
        setIsSelectedAll(false)
      }
    }, 1)

  };

  const handleRemove = (selectedList) => {
    setFarmList(selectedList);
    SetSelectEditFarmName(selectedList);
  };


  useEffect(() => {
    getUsers();
    getFarmsData();
  }, []);

  const getFarmsData = async () => {
    const farms = await getFarms();
    setFarms(farms)
    setFarmsSelection(farms)
  }

  const setFarmsSelection = (farmsAll) => {
    let farms = [];
    const data1 = {
      name: "All farms"
    }
    farms.push(data1)
    if (Array.isArray(farmsAll)) {
      for (const farm of farmsAll) {
        const data = {
          name: farm
        }
        farms.push(data)
      }
    }
    setFarmItemsSearch(farms);
  }

  const getUsers = async () => {
    try {
      let userId = localStorage.getItem("uid");
      let parentId = localStorage.getItem("uid");
      let res = await getUserData(userId);
      if (res && res.pId) {
        parentId = res.pId;
      }
      let data = await getAllUserByPid(
        parentId,
        res && res.pId ? res.pId : null
      );
      // const profile = await getProfileData();
      const unpaidData = await getUnpaidInvoice();
      if (unpaidData.length > 0) {
        if (unpaidData[0].createdAt) {
          const days = getCalculateDaysDifference(unpaidData[0].createdAt);
          if (days > 7) {
            setIsPayInvoice(true);
          } else {
            setIsPayInvoice(false);
          }
        }
      }
      if (profile && profile.customerId) {
        localStorage.setItem("invoiceCid", profile.customerId);
      } else {
        localStorage.setItem("invoiceCid", null);
      }
      if (profile && profile.isPremium) {
        localStorage.setItem("premiumCust", "true");
      } else if (profile && profile.isSuperPremium) {
        localStorage.setItem("premiumCust", "true");
      } else {
        localStorage.setItem("premiumCust", null);
      }
      if (profile) {
        setSubscribeUser({
          email: profile.email ? profile.email : "",
          city: profile.city ? profile.city : "",
          country: profile.country ? profile.country : "",
          line1: profile.line1 ? profile.line1 : "",
          line2: profile.line2 ? profile.line2 : "",
          postalCode: profile.postal_code ? profile.postal_code : "",
          state: profile.state ? profile.state : "",
          name: profile.comName ? profile.comName : "",
          phone: profile.phone ? profile.phone : "",
        });
      }
      if (data.data.length > 0) {
        let arr = data.data.filter((val) => {
          return val.uid !== userId;
        });
        arr.sort((a, b) => {
          return b.timestamp - a.timestamp;
        });

        if (data.adminData) {
          arr.unshift({ ...data.adminData, isAdmin: true });
        }
        setUserList(arr);
      }
      if (data.data.length <= 0) {
        setIsData(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onAddSubmit = async (e) => {
    e.preventDefault();
    setIsDisable(true);
    let emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let passwordRegex = /^(?=.*).{6,}$/;
    let phoneRegex = /^\d{10,12}$/;
    let fullNameRegex = /^[a-zA-Z\s]+/gi;

    if (user.name === "") {
      setIsDisable(false);
      return setIsNameErr(true);
    } else if (!fullNameRegex.test(user.name)) {
      setIsDisable(false);
      return setIsNameErr(true);
    }
    if (user.email === "") {
      setIsDisable(false);
      return setIsEmailErr(true);
    } else if (!emailRegex.test(user.email)) {
      setIsDisable(false);
      return setIsEmailErr(true);
    }
    // if (user.phone === "") {
    //   setIsDisable(false);
    //   return setIsPhoneErr(true);
    // } else if (!user.phone.startsWith("+")) {
    //   setIsDisable(false);
    //   return setIsPhoneErr(true);
    // }
    if (!isEdit && user.password === "") {
      setIsDisable(false);
      return setIsPasswordErr(true);
    } else if (!isEdit && !passwordRegex.test(user.password)) {
      setIsDisable(false);
      return setIsPasswordErr(true);
    }
    if (!isEdit && user.cpassword === "") {
      setIsDisable(false);
      return setIsCPasswordErr(true);
    } else if (!isEdit && !passwordRegex.test(user.cpassword)) {
      setIsDisable(false);
      return setIsCPasswordErr(true);
    }
    if (!isEdit && user.cpassword !== user.password) {
      setIsDisable(false);
      setIsCPasswordErr(true);
      return;
    }

    if (getRadioName !== "Administrator" && (!farmList || farmList.length <= 0)) {
      setIsDisable(false);
      setIsSelectFarmErr(true);
    }

    let farmsToBeAdded = [];
    if (farmList && farmList.length > 0) {
      for (const farms of farmList) {
        farmsToBeAdded.push(farms.name)
      }
    } else if (selectEditFarmName && selectEditFarmName.length > 0) {
      for (const farms of selectEditFarmName) {
        farmsToBeAdded.push(farms.name)
      }
    }

    try {
      // setIsLoader(true);
      // setIsOpenModal(false);
      let userId = localStorage.getItem("uid");
      let userData = await getUserData(userId);

      const data = {
        fullName: user.name,
        jobTitle: userData.jobTitle,
        comName: userData.comName,
        email: user.email,
        phone: user.phone,
        pId: userId,
        role: role === "Data collector" ? 3 : role === "Farm manager" ? 2 : 1,
        status: user.status === "Active" ? true : false,
        timestamp: getCurrentTimestampInMillis(),
        farmList: getRadioName === "Administrator" ? null : farmsToBeAdded,
        isAllFarm: isSelectedAll
      };
      if (isEdit) {
        let updateData = {
          fullName: user.name,
          phone: user.phone,
          role: role === "Data collector" ? 3 : role === "Farm manager" ? 2 : 1,
          status: user.status === "Active" ? true : false,
          updateTS: getCurrentTimestampInMillis(), // Update timestamp
          farmList: getRadioName === "Administrator" ? null : farmsToBeAdded,
        };
        let res = await updateUserById(editData.uid, updateData);
        let arr = userList.map((val) => {
          let obj = val;
          if (editData.uid === val.uid) {
            obj = { ...val, ...updateData };
          }
          return obj;
        });
        setUserList(arr);
        setIsEdit(false);
      } else {
        let res = await singUpUser(user.email, user.password, data);
        if (res.error) {
          Error(res.error);
        } else {
          let pId = localStorage.getItem("pId");
          const countRef = doc(db, "counters", `${pId}`);
          await updateDoc(countRef, {
            users: increment(1),
          });
          // await addOrUpdateUsersCountById(pId);
          let arr = userList;
          arr.unshift({ ...data, uid: res });
          setUserList(arr);
        }
      }
      setUser({
        name: "",
        email: "",
        phone: "",
        password: "",
        status: "Active",
      });
      setFarmList([]);
      setRole("Data collector");
      // setNotif(false);
      setIsHelp(false);
      if (isEdit) {
        setIsEdit(false);
      }
      setIsOpenModal(false);
      setIsLoader(false);
      setIsDisable(false);
      // navigation("/login");
    } catch (error) {

      // setIsLoader(false);
      if (error.code !== "" && error.code === "auth/email-already-in-use") {
        Error(t("userManagement.this_email_is_already_in_use"));
      } else if (error.message !== "") {

      }
      setIsDisable(false);
    }
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "name" && isNameErr) {
      setIsNameErr(false);
    } else if (name === "email" && isEmailErr) {
      setIsEmailErr(false);
    } else if (name === "password" && isPasswordErr) {
      setIsPasswordErr(false);
    } else if (name === "cpassword" && isCPasswordErr) {
      setIsCPasswordErr(false);
    } else if (farmList && farmList.length >= 0 && isSelectFarmErr) {
      setIsSelectFarmErr(false);
    }
    // else if (name === "phone" && isPhoneErr) {
    //   setIsPhoneErr(false);
    // }
    setUser((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });
  };

  const onCloseAddModal = () => {
    setUser({
      name: "",
      email: "",
      phone: "",
      password: "",
      status: "Active",
    });
    setRole("Data collector");
    // setNotif(false);
    setIsHelp(false);
    if (isEdit) {
      setIsEdit(false);
    }
    setIsOpenModal(false);
    setIsNameErr(false);
    setIsEmailErr(false);
    setIsPasswordErr(false);
    setIsCPasswordErr(false);
    setIsSelectFarmErr(false);
    // setIsPhoneErr(false);
  };

  const onInputSubscribeChange = (data) => {
    setSubscribeUser((preVal) => {
      return { ...preVal, ...data };
    });
  };

  async function onClickSaveSubscribeProfile() {
    setIsDisable(true);
    if (subscribeUser.name === "") {
      setShowError({ ...showError, isName: true });
      setIsDisable(false);
      return;
    }
    if (subscribeUser.phone === "") {
      setShowError({ ...showError, isPhone: true });
      setIsDisable(false);
      return;
    } else if (!subscribeUser.phone?.startsWith("+")) {
      setShowError({ ...showError, isPhone: true });
      setIsDisable(false);
      return;
    }
    if (subscribeUser.city === "") {
      setShowError({ ...showError, isCity: true });
      setIsDisable(false);
      return;
    }
    if (subscribeUser.country === "") {
      setShowError({ ...showError, isCountry: true });
      setIsDisable(false);
      return;
    }
    if (subscribeUser.state === "") {
      setShowError({ ...showError, isState: true });
      setIsDisable(false);
      return;
    }
    if (subscribeUser.line1 === "") {
      setShowError({ ...showError, isLine1: true });
      setIsDisable(false);
      return;
    }
    if (subscribeUser.line2 === "") {
      setShowError({ ...showError, isLine2: true });
      setIsDisable(false);
      return;
    }
    if (subscribeUser.postalCode === "") {
      setShowError({ ...showError, isPostalCode: true });
      setIsDisable(false);
      return;
    }
    try {
      const data = {
        comName: subscribeUser.name,
        phone: subscribeUser.phone,
        postal_code: Number(subscribeUser.postalCode),
        city: subscribeUser.city,
        country: subscribeUser.country,
        state: subscribeUser.state,
        line1: subscribeUser.line1,
        line2: subscribeUser.line2,
        isPremium: true,
      };
      localStorage.setItem("premiumCust", "true");
      onClickProfileUpdate(data);
      await updateProfileById(data);
      setIsPremium(true);
      setIsOpenSubscribeModal(false);
      setIsDisable(false);
    } catch (error) {
      setIsDisable(false);
    }
  }

  const idHelp = isHelp ? "simple-popover" : undefined;
  const nameClassName = `${t("ponds.field")} ${isNameErr ? `{${t("device.error")}}` : ""}`;
  const emailClassName = `${t("ponds.field")} ${isEmailErr ? `{${t("device.error")}}` : ""}`;
  const pwdClassName = `${t("ponds.field")} ${isPasswordErr ? `{${t("device.error")}}` : ""}`;
  const cpwdClassName = `${t("ponds.field")} ${isCPasswordErr ? `{${t("device.error")}}` : ""}`;
  const cpwdClassSelectFarmErr = `${t("ponds.field")} ${isSelectFarmErr ? `{${t("device.error")}}` : ""}`;
  const phoneClassName = `${t("ponds.field")}`;

  return (
    <div className="Settings_margin_cm_main">
      <div>
        {/* ----- HEAD SEARCHES ----- */}
        <div className="head_container setting_padding_css">
          <div className="head_one">
            <div
              style={{ width: "100%", marginTop: 0 }}
              className="ui stackable two column grid"
            >
              {/* <div className="column" style={{ width: "260px" }}>
                <div
                  className="ui icon input"
                  style={{ width: "260px", height: 42.5 }}
                >
                  <input
                    className="prompt"
                    type="text"
                    placeholder={t("ponds.search")}
                    onChange={(e) => {
               
                      // if (e.target.value === "") {
                      //   setItems(pondItems);
                      //   setSearchText(e.target.value);
                      //   setIsData(false);
                      // } else {
                      //   setSearchText(e.target.value);
                      //   setIsData(false);
                      // }
                    }}
                    value={searchText}
                    style={{ border: "1px solid #ced4da", height: 42.5 }}
                  />
                  <i className="search icon"></i>
                </div>
              </div> */}
              <div className="column base_container">
                <LoadingButton
                  loadingPosition="end"
                  loading={isLoader}
                  className="ui primary button"
                  style={{ width: "auto", height: 40, display: "flex", alignItems: "center", justifyContent: "flex-end", paddingRight: 25, paddingLeft: 25 }}
                  onClick={async () => {
                    try {
                      setIsLoader(true);
                      let count = await getCountersDataFromDb();
                      const totalUsers = count.users;
                      let addUser = false;
                      const currentSubscription = await getCurrentSubscription(
                        profile.customerId
                      );
                      let plansRecordsData = await getPlansRecordsFromDb();
                      if (plansRecordsData && plansRecordsData.type === CLOUD_LICENSE) {
                        if (plansRecordsData.tier === "Enterprise +") {
                          addUser = true;
                        } else if (totalUsers < plansRecordsData.planUsers) {
                          addUser = true;
                        } else {
                          addUser = false;
                          setAlertMessage(
                            `${t("subscription.message_plan_cannot")} ${plansRecordsData.planUsers}  ${t("subscription.message_plan_user_in")}  ${plansRecordsData.type}  ${t("subscription.message_plan_upgrade")}`
                          );
                        }
                      } else if (currentSubscription) {
                        setSubscriptionActive(true);
                        if (currentSubscription.productId) {
                          if (currentSubscription.productId == PRODUCT_BASIC) {
                            if (totalUsers >= PRODUCT_BASIC_USERS) {
                              addUser = false;
                              setAlertMessage(
                                t("subscription.message_plan_basic")
                              );
                            } else {
                              addUser = true;
                            }
                          } else if (
                            currentSubscription.productId == PRODUCT_ENTERPRICE
                          ) {
                            if (totalUsers >= PRODUCT_ENTERPRICE_USERS) {
                              addUser = false;
                              setAlertMessage(
                                t("subscription.message_plan_enterprise")
                              );
                            } else {
                              addUser = true;
                            }
                          } else if (
                            currentSubscription.productId ==
                            PRODUCT_ENTERPRICE_PLUS
                          ) {
                            addUser = true;
                          }
                        }
                      } else {
                        if (totalUsers < 3) {
                          addUser = true;
                        } else {
                          setAlertMessage(t("ponds.user_subscibe_msg"));
                          setSubscriptionActive(false);
                        }
                      }
                      if (addUser) {
                        setIsOpenModal(true);
                      } else {
                        setIsClickUser(true);
                        setIsOpenAlertModal(true);
                        setIsShowAlert(true);
                      }
                      setIsLoader(false);
                    } catch (error) {
                      console.log('error: ', error);
                    }
                  }}
                >
                  {t("userManagement.add_user")}
                </LoadingButton>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        userList.length > 0 ? (
          <div className="cards_main_block">
            <div className="inventory_table_wrap">
              <div className="table-responsive cm_table_responsive">
                <table
                  className="ui celled table user-manage-tab cm_table fold-table"
                  style={{ marginTop: 0 }}
                >
                  <thead className="cm_thead">
                    <tr className="cm_thead_list">
                      {/* <th>Picture</th> */}
                      <th className="th_list cm_name">
                        <div className="th_item_wrap">
                          <h6>{t("userManagement.name")}</h6>
                        </div>
                      </th>
                      <th className="th_list cm_name">
                        <div className="th_item_wrap">
                          <h6>{t("profile.email")}</h6>
                        </div>
                      </th>
                      <th className="th_list cm_name">
                        <div className="th_item_wrap">
                          <h6>{t("userManagement.access_level")}</h6>
                        </div>
                      </th>
                      <th className="th_list cm_name">
                        <div className="th_item_wrap">
                          <h6>{t("home.status")}</h6>
                        </div>
                      </th>
                      <th className="th_list cm_name"></th>
                    </tr>
                  </thead>
                  <tbody className="cm_tbody">
                    {userList.map((val) => {
                      return (
                        <>
                          <tr key={Math.random()} className="cm_tr view">
                            <td
                              data-label={t("userManagement.name")}
                              className="cm_td"
                            >
                              <h4>{val.fullName}</h4>
                            </td>
                            <td data-label={t("profile.email")} className="cm_td">
                              <h4>{val.email}</h4>
                            </td>
                            <td
                              data-label={t("userManagement.access_level")}
                              className="cm_td"
                            >
                              <h4>
                                {val.isAdmin
                                  ? t("userManagement.main_admin")
                                  : val.role === 1
                                    ? t("userManagement.administrator")
                                    : val.role === 2
                                      ? t("userManagement.farm_manager")
                                      : t("userManagement.data_collector")}
                              </h4>
                            </td>
                            <td data-label={t("home.status")} className="cm_td">
                              <h4>
                                {val.isAdmin
                                  ? t("home.active")
                                  : val.status
                                    ? t("home.active")
                                    : t("profile.disabled")}
                              </h4>
                            </td>
                            {val.isAdmin ? (
                              <td className="cm_td"></td>
                            ) : (
                              <td className="cm_td">
                                <div
                                  style={style.container}
                                  className="farm-svg-css"
                                >
                                  <div className="green_icon_edit">
                                    <EditIcon
                                      onClick={() => {
                                        setUser((preValue) => {
                                          return {
                                            ...preValue,
                                            name: val.fullName,
                                            email: val.email,
                                            phone: val.phone,
                                            status: val.status
                                              ? "Active"
                                              : "Disabled",
                                          };
                                        });
                                        setRole(
                                          val.role === 1
                                            ? "Administrator"
                                            : val.role === 2
                                              ? "Farm manager"
                                              : "Data collector"
                                        );
                                        setEditData(val);
                                        setIsEdit(true);
                                        setIsOpenModal(true);
                                        let farms = [];
                                        if (val.farmList && val.farmList.length > 0) {
                                          for (const farm of val.farmList && val.farmList) {
                                            const data = {
                                              name: farm
                                            }
                                            farms.push(data)
                                          }
                                        }
                                        SetSelectEditFarmName(farms);
                                        setIsSelectedAll(val.isAllFarm)
                                      }}
                                    />
                                  </div>
                                  <div className="look_icon_css red_icon_eye">
                                    <DeleteIcon
                                      sx={{ marginLeft: 2 }}
                                      onClick={() => {
                                        setDeleteData(val);
                                        setDeleteModal(true);
                                        // alert('Under working')
                                      }}
                                    />
                                  </div>
                                </div>
                              </td>
                            )}
                          </tr>
                          <tr className="cm_hi"></tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : isData ? (
          <div
            style={{
              display: "flex",
              height: "50vh",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
            className="no-alarms-found-css"
          >
            <img src={no_alarms_found} alt="no_alarms_found" />
            <Typography sx={{ fontSize: 17, fontWeight: "600" }}>
              {t("userManagement.users_data_not_found")}
            </Typography>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              height: "50vh",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div id={`load`} className="loader" />
          </div>
        )
      }

      {/* ----- ADD/EDIT MODAL ----- */}
      <Modal
        open={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description "
      >
        <Box sx={boxStyle} className="add-Pond-location">
          <div
            style={{
              display: "flex",
              width: "100%",
              backgroundColor: colors.slatery_blue,
              justifyContent: "space-between",
              padding: 8,
              position: "sticky",
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
            className="hiding-pond-css"
          >
            <Typography
              sx={{
                fontSize: 18,
                color: "white",
                fontWeight: "600",
                marginLeft: 1,
              }}
            >
              {isEdit
                ? t("userManagement.edit_user")
                : t("userManagement.add_user")}
            </Typography>
            <i
              className="x icon"
              style={{ color: "white", fontSize: 22 }}
              onClick={() => {
                if (isDisable) {
                } else {
                  // setIsOpenModal(false);
                  onCloseAddModal();
                }
              }}
            />
          </div>

          <div style={{ maxHeight: isEdit ? "650px" : "715px", overflow: "auto", }}>
            <form
              className="ui form container"
              style={{
                marginTop: 15,
                paddingLeft: 30,
                paddingRight: 30,
                paddingBottom: 15,
              }}
            // onSubmit={onAddSubmit}
            >
              {/* -- FULL NAME -- */}
              <div className={nameClassName}>
                <label>{t("profile.full_name")}</label>
                <input
                  type="text"
                  placeholder={t("profile.enter_full_name")}
                  name="name"
                  value={user.name}
                  onChange={onInputChange}
                />
                {isNameErr && (
                  <div className="ui pointing red basic label">
                    {user.name === ""
                      ? t("userManagement.name_msg_1")
                      : t("userManagement.name_msg_2")}
                  </div>
                )}
              </div>

              {/* -- EMAIL -- */}
              <div className={emailClassName}>
                <label>{t("profile.email")}</label>
                <input
                  type="text"
                  placeholder={t("profile.enter_email")}
                  name="email"
                  value={user.email}
                  onChange={onInputChange}
                  disabled={isEdit}
                />
                {isEmailErr && (
                  <div className="ui pointing red basic label">
                    {user.email === ""
                      ? t("userManagement.email_msg_1")
                      : t("userManagement.email_msg_2")}
                  </div>
                )}
              </div>

              {/* -- PHONE -- */}
              <div className={phoneClassName}>
                <label>{t("profile.phone_number")}</label>
                <input
                  type="text"
                  placeholder={t("profile.enter_phone_number")}
                  name="phone"
                  value={user.phone}
                  onChange={onInputChange}
                />
                {/* {isPhoneErr && (
                  <div className="ui pointing red basic label">
                    {user.phone === ""
                      ? t("userManagement.phone_msg_1")
                      : t("userManagement.phone_msg_2")}
                  </div>
                )} */}
              </div>

              {/* -- PASSWORD -- */}
              {!isEdit && (
                <div className={pwdClassName}>
                  <label>{t("userManagement.password")}</label>
                  <input
                    type="password"
                    placeholder={t("userManagement.enter_password")}
                    name="password"
                    value={user.password}
                    onChange={onInputChange}
                  />
                  {isPasswordErr && (
                    <div className="ui pointing red basic label">
                      {Number(user.password.length) > 0
                        ? Number(user.password.length) < 6
                          ? t("userManagement.password_msg_1")
                          : t("userManagement.password_msg_2")
                        : t("userManagement.password_msg_3")}
                    </div>
                  )}
                </div>
              )}

              {/* -- CONFIRM PASSWORD -- */}
              {!isEdit && (
                <div className={cpwdClassName}>
                  <label>{t("userManagement.confirm_password")}</label>
                  <input
                    type="password"
                    placeholder={t("userManagement.enter_confirm_password")}
                    name="cpassword"
                    value={user.cpassword}
                    onChange={onInputChange}
                  />
                  {isCPasswordErr && (
                    <div className="ui pointing red basic label">
                      {Number(user.cpassword.length) > 0
                        ? Number(user.cpassword.length) < 6
                          ? t("userManagement.confirm_msg_1")
                          : user.cpassword !== user.password
                            ? t("userManagement.confirm_msg_2")
                            : t("userManagement.confirm_msg_3")
                        : t("userManagement.confirm_msg_4")}
                    </div>
                  )}
                </div>
              )}

              {/* -- NOTIFICATION CHECKBOX -- */}
              {/* <div className="field">
                <label htmlFor="notif">
                  {t("userManagement.send_user_notification")}
                </label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="checkbox"
                    tabIndex="0"
                    className="hidden"
                    name="notif"
                    value={notif}
                    onChange={() => setNotif(!notif)}
                    style={{ marginRight: 10 }}
                  />
                  <label>
                    {t("userManagement.send_user_an_email_about_their_account")}
                  </label>
                </div>
              </div> */}

              {/* -- USER ROLE -- */}
              <div className="grouped fields">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label htmlFor="role" style={{ fontWeight: "700" }}>
                    {t("userManagement.user_role")}
                  </label>
                  <HelpOutlineIcon
                    style={{ width: 20, height: 20, marginLeft: 8, cursor: "pointer" }}
                    onClick={() => {
                      setIsHelp(true);
                    }}
                  />
                  {isHelp && (
                    <Popover
                      id={idHelp}
                      open={isHelp}
                      style={{
                        position: "absolute",
                        zIndex: 1,
                        paddingLeft: 15,
                        paddingRight: 15,
                        paddingBottom: 15,
                        left: 100,
                      }}
                    >
                      <CloseIcon
                        onClick={() => {
                          setIsHelp(false);
                        }}
                        style={{
                          position: "absolute",
                          zIndex: 1,
                          top: 2,
                          right: 2,
                          cursor: "pointer"
                        }}
                      />
                      <Typography
                        sx={{ fontSize: 14, fontWeight: "600" }}
                        className="edit_user_popup_css"
                      >
                        {t("userManagement.data_collector")}:{" "}
                        <span style={{ fontWeight: "500" }}>
                          {t("userManagement.data_collector_msg")}
                        </span>
                      </Typography>
                      <Typography
                        sx={{ fontSize: 14, fontWeight: "600" }}
                        className="edit_user_popup_css"
                      >
                        {t("userManagement.farm_manager")}:{" "}
                        <span style={{ fontWeight: "500" }}>
                          {t("userManagement.farm_manager_msg")}
                        </span>
                      </Typography>
                      <Typography
                        sx={{ fontSize: 14, fontWeight: "600" }}
                        className="edit_user_popup_css"
                      >
                        {t("userManagement.administrator")}:{" "}
                        <span style={{ fontWeight: "500" }}>
                          {t("userManagement.administrator_msg")}
                        </span>
                      </Typography>
                    </Popover>
                  )}
                </div>
                {roleList.map((val) => {
                  return (
                    <div className="field" key={Math.random()}>
                      <div
                        className=""
                        style={{
                          marginTop: -6,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type="radio"
                          name="role"
                          id={`${val.value}`}
                          className="hidden"
                          tabIndex={`0`}
                          checked={role === val.label}
                          value={role}
                          onChange={() => {
                            setRole(val.value)
                            setGetRadioName(val.value);
                          }}
                        />
                        <label for={`${val.value}`} style={{ marginLeft: 12 }}>{val.label}</label>
                      </div>
                    </div>
                  );
                })}
              </div>

              {/* -- Select Form -- */}
              {getRadioName === "Administrator" ?
                null :
                <div className={`User_Select_farms`}>
                  <div className="input-block">
                    <div className="multi_select_block">
                      <Typography sx={{ fontSize: 15, fontWeight: "400" }}>
                        {t("settings.select_farm")}&nbsp;
                      </Typography>
                      <div>
                        <Multiselect
                          options={farmItemsSearch}
                          selectedValues={isEdit ? selectEditFarmName : null}
                          // selectionLimit={10}
                          onSelect={handleSelect}
                          onRemove={handleRemove}
                          displayValue="name"
                        />
                      </div>
                    </div>
                    {isSelectFarmErr && (
                      <div className={farmList && farmList.length <= 0 ? "ui pointing red basic label" : ""}>
                        {farmList && farmList.length <= 0
                          ? t("ponds.please_select_farm")
                          : ""}
                      </div>
                    )}
                  </div>
                </div>
              }

              {/* -- STATUS -- */}
              <div className="field">
                <label>{t("home.status")}</label>
                <select
                  className="ui fluid dropdown"
                  name="status"
                  onChange={onInputChange}
                  value={user.status}
                >
                  <option value={"Active"}>{t("home.active")}</option>
                  <option value={"Disabled"}>{t("profile.disabled")}</option>
                </select>
              </div>

              {/* -- BUTTON -- */}
              <div style={{ display: "flex", justifyContent: "center" }}>
                {/* <button
                  className="ui primary submit button"
                  style={{
                    width: "auto",
                    backgroundColor: colors.slatery_blue,
                  }}
                  type="submit"
                  disabled={isDisable}
                >
                  {t("ponds.save")}
                </button> */}
                <LoadingButton
                  onClick={onAddSubmit}
                  loading={isDisable}
                  loadingPosition="start"
                  startIcon={<Save />}
                  variant="contained"
                  sx={{ backgroundColor: colors.slatery_blue }}
                >
                  {t("ponds.save")}
                </LoadingButton>
              </div>
            </form>
          </div>
        </Box>
      </Modal>

      {/* ----- DELETE MODAL ----- */}
      <Modal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={[boxStyle, { width: 400 }]} className="logout-css-modal-block">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              paddingTop: 2,
              textAlign: "center",
              marginBottom: 2,
              paddingLeft: 1,
              paddingRight: 1,
            }}
          >
            {t("ponds.are_you_sure_you_want_to_delete_this")}
          </Typography>

          <Divider />
          <div className="logout-button-block">
            <Typography
              sx={textStyle}
              onClick={() => {
                if (isDisable) {
                } else {
                  setDeleteModal(false);
                }
              }}
            >
              {t("ponds.cancel")}
            </Typography>
            <div
              style={{ width: "2px", border: "1px solid #999", opacity: 0.5 }}
            />
            <Typography
              className="delete_bg_color"
              sx={[textStyle, { color: "red" }]}
              onClick={async () => {
                if (isDisable) {
                } else {
                  try {
                    setIsDisable(true);
                    let res = await deleteUsersFromDb(deleteData.uid);
                    let arr = userList.filter((val) => {
                      return val.uid !== deleteData.uid;
                    });
                    setUserList(arr);
                    setDeleteModal(false);
                    setDeleteData(null);
                    setIsDisable(false);
                  } catch (error) {
                    setIsDisable(false);
                  }
                }
              }}
            >
              {t("ponds.delete")}
            </Typography>
          </div>
        </Box>
      </Modal>

      {
        isShowAlert && (
          <AlertModal
            message={alertMessage}
            isOpenAlertModal={isOpenAlertModal}
            onClickCancel={() => {
              setIsClickUser(false);
              setIsOpenAlertModal(false);
              setIsShowAlert(false);
            }}
            onClickUpgrade={() => {
              setIsClickUser(false);
              setIsOpenAlertModal(false);
              setIsShowAlert(false);
              if (!isSubscriptionActive) {
                onSelectSegment(2)
              } else {
                window.open(CUSTOMER_PORTAL_URL);
              }
            }}
          />
        )
      }

      {/* ----- SUBSCRIBE MODAL ----- */}
      <Modal
        open={isOpenSubscribeModal}
        onClose={() => setIsOpenSubscribeModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={boxStyle}>
          <div
            style={{
              display: "flex",
              width: "100%",
              backgroundColor: colors.slatery_blue,
              justifyContent: "space-between",
              padding: 8,
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
          >
            <Typography
              sx={{
                fontSize: 18,
                color: "white",
                fontWeight: "600",
                marginLeft: 1,
              }}
            >
              {t("invoice.upgrade_to_premium")}
            </Typography>
            <i
              className="x icon"
              style={{ color: "white", fontSize: 22 }}
              onClick={() => {
                if (isDisable) {
                } else {
                  setIsOpenSubscribeModal(false);
                }
              }}
            />
          </div>

          <div
            style={{ height: "auto", maxHeight: "600vh", overflowY: "auto" }}
          >
            {/* -- NAME -- */}
            <TextField
              id="name"
              label={t("home.name")}
              type="text"
              variant="standard"
              onChange={(e) => {
                onInputSubscribeChange({ name: e.target.value });
                if (showError.isName) {
                  setShowError({ ...showError, isName: false });
                }
              }}
              value={subscribeUser.name}
              sx={{
                width: "95%",
                fontSize: 18,
                marginTop: 1.5,
                marginLeft: 2,
              }}
            />
            {showError.isName && (
              <Alert severity="error">{t("home.name_error")}</Alert>
            )}

            {/* -- EMAIL -- */}
            <TextField
              id="email"
              label={t("profile.email")}
              type="text"
              variant="standard"
              value={subscribeUser.email}
              sx={{
                width: "95%",
                fontSize: 18,
                marginTop: 1.5,
                marginLeft: 2,
              }}
              disabled={true}
            />

            {/* -- PHONE -- */}
            <TextField
              id="phone"
              label={t("profile.phone_number")}
              type="text"
              variant="standard"
              onChange={(e) => {
                onInputSubscribeChange({ phone: e.target.value });
                if (showError.isPhone) {
                  setShowError({ ...showError, isPhone: false });
                }
              }}
              value={subscribeUser.phone}
              sx={{
                width: "95%",
                fontSize: 18,
                marginTop: 1.5,
                marginLeft: 2,
              }}
            />
            {showError.isPhone && (
              <Alert severity="error">
                {subscribeUser.phone === ""
                  ? t("home.phone_error")
                  : t("profile.please_add_country_code")}
              </Alert>
            )}

            {/* -- CITY -- */}
            <TextField
              id="city"
              label={t("home.city")}
              type="text"
              variant="standard"
              required
              onChange={(e) => {
                onInputSubscribeChange({ city: e.target.value });
                if (showError.isCity) {
                  setShowError({ ...showError, isCity: false });
                }
              }}
              value={subscribeUser.city}
              sx={{
                width: "95%",
                fontSize: 18,
                marginTop: 1.5,
                marginLeft: 2,
              }}
            />
            {showError.isCity && (
              <Alert severity="error">{t("home.city_error")}</Alert>
            )}

            {/* -- COUNTRY -- */}
            <TextField
              id="country"
              label={t("home.country")}
              type="text"
              variant="standard"
              required
              onChange={(e) => {
                onInputSubscribeChange({ country: e.target.value });
                if (showError.isCountry) {
                  setShowError({ ...showError, isCountry: false });
                }
              }}
              value={subscribeUser.country}
              sx={{
                width: "95%",
                fontSize: 18,
                marginTop: 1.5,
                marginLeft: 2,
              }}
            />
            {showError.isCountry && (
              <Alert severity="error">{t("home.country_error")}</Alert>
            )}

            {/* -- STATE -- */}
            <TextField
              id="state"
              label={t("home.state")}
              type="text"
              variant="standard"
              required
              onChange={(e) => {
                onInputSubscribeChange({ state: e.target.value });
                if (showError.isState) {
                  setShowError({ ...showError, isState: false });
                }
              }}
              value={subscribeUser.state}
              sx={{
                width: "95%",
                fontSize: 18,
                marginTop: 1.5,
                marginLeft: 2,
              }}
            />
            {showError.isState && (
              <Alert severity="error">{t("home.state_error")}</Alert>
            )}

            {/* -- LINE-1 -- */}
            <TextField
              id="line1"
              label={t("home.line_1")}
              type="text"
              variant="standard"
              required
              onChange={(e) => {
                onInputSubscribeChange({ line1: e.target.value });
                if (showError.isLine1) {
                  setShowError({ ...showError, isLine1: false });
                }
              }}
              value={subscribeUser.line1}
              sx={{
                width: "95%",
                fontSize: 18,
                marginTop: 1.5,
                marginLeft: 2,
              }}
            />
            {showError.isLine1 && (
              <Alert severity="error">{t("home.line_1_error")}</Alert>
            )}

            {/* -- LINE-2 -- */}
            <TextField
              id="line2"
              label={t("home.line_2")}
              type="text"
              variant="standard"
              required
              onChange={(e) => {
                onInputSubscribeChange({ line2: e.target.value });
                if (showError.isLine2) {
                  setShowError({ ...showError, isLine2: false });
                }
              }}
              value={subscribeUser.line2}
              sx={{
                width: "95%",
                fontSize: 18,
                marginTop: 1.5,
                marginLeft: 2,
              }}
            />
            {showError.isLine2 && (
              <Alert severity="error">{t("home.line_2_error")}</Alert>
            )}

            {/* -- POSTAL CODE -- */}
            <TextField
              id="postalCode"
              label={t("home.postal_code")}
              type="number"
              variant="standard"
              required
              onChange={(e) => {
                onInputSubscribeChange({ postalCode: e.target.value });
                if (showError.isPostalCode) {
                  setShowError({ ...showError, isPostalCode: false });
                }
              }}
              value={subscribeUser.postalCode}
              sx={{
                width: "95%",
                fontSize: 18,
                marginTop: 1.5,
                marginLeft: 2,
              }}
            />
            {showError.isPostalCode && (
              <Alert severity="error">{t("home.postal_code_error")}</Alert>
            )}
          </div>

          {/* -- BUTTON -- */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 10,
              marginTop: 10,
            }}
          >
            <LoadingButton
              onClick={onClickSaveSubscribeProfile}
              loading={isDisable}
              loadingPosition="start"
              startIcon={<Save />}
              variant="contained"
              sx={{ backgroundColor: colors.slatery_blue }}
            >
              {t("ponds.save")}
            </LoadingButton>
            {/* </div> */}
          </div>
        </Box>
      </Modal>
    </div>
  );
}

const style = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: window.innerWidth > 375 ? 400 : 270,
  width: 650,
  bgcolor: "background.paper",
  border: "2px solid",
  borderColor: "gray",
  borderRadius: 3,
  boxShadow: 24,
};
const textStyle = {
  color: colors.slatery_blue,
  fontSize: 17,
  padding: 1,
  textAlign: "center",
  width: "100%",
  fontWeight: "600",
};

export default UserManagement;
