import React, { useEffect, useState } from "react";
import ResponsiveDrawer from "../../../common/ResponsiveDrawer";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
  DrawingManager,
} from "@react-google-maps/api";
import "../../../style/loader.css";
import { getActivePondCycle, getPond } from "../../../database/user/pond";
import {
  Typography,
  styled,
  InputBase,
  Box,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import colors from "../../../common/colors";
import "../../../style/user/Map.css";
import { Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  getFarms,
} from "../../../database/user/pond";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "8px 26px 8px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const marker_1 =
  "https://firebasestorage.googleapis.com/v0/b/sincere-aquaculture.appspot.com/o/markers%2Fmarker_1.png?alt=media&token=f9298a11-90ae-4dd4-9926-0563edcbe132";
const marker_2 =
  "https://firebasestorage.googleapis.com/v0/b/sincere-aquaculture.appspot.com/o/markers%2Fmarker_2.png?alt=media&token=a2d5957a-e270-4436-8e59-2b188c128261";
const marker_3 =
  "https://firebasestorage.googleapis.com/v0/b/sincere-aquaculture.appspot.com/o/markers%2Fmarker_3.png?alt=media&token=44acb20c-400a-4b40-801d-ec440febe602";

function Map() {
  const navigation = useNavigate();
  const { t } = useTranslation();
  const [center, setCenter] = useState({
    lat: 55.6761,
    lng: 12.5683,
  });
  // const [selectedCenter, setSelectedCenter] = useState(null);
  const [selectedPond, setSelectedPond] = useState(null);
  const [pondList, setPondList] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [isData, setIsData] = useState(false);
  const [sortValue, setSortValue] = useState(t("ponds.all"));
  const [markerMap, setMarkerMap] = useState({});
  const [infoOpen, setInfoOpen] = useState(false);
  const [cycleData, setCycleData] = useState([]);
  const [sortCycleData, setSortCycleData] = useState(null);
  const [visibleHover, setVisibleHover] = useState(null);
  const [farms, setFarms] = useState([]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      function (position) {

        setCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      (error) => {

      }
    );
  }, []);

  useEffect(() => {
    getFarmsData();
  }, []);

  const getFarmsData = async () => {
    const farms = await getFarms();
    setFarms(farms);
    if (!farms || farms.length <= 0) {
      setIsData(true);
    }
  }

  useEffect(() => {
    if (farms && farms.length > 0) {
      getPondData();
    }
  }, [farms]);
  const getPondData = async () => {
    try {
      let pId = localStorage.getItem("pId");
      let data = await getPond(pId, null, farms);
      data.sort(function (a, b) {
        return a.pondName - b.pondName;
      });
      setDataList(data);
      setPondList(data);
      if (data.length <= 0) {
        setIsData(true);
      }
    } catch (error) {
    }
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAARdYXZPxgm25l-W4YSO49BgAswqE_G5o",
    // libraries: ["drawing"],
  });

  const markerLoadHandler = (marker, pond) => {
    return setMarkerMap((prevState) => {
      return { ...prevState, [pond.id]: marker };
    });
  };

  const markerClickHandler = async (event, pond) => {
    try {
      setSelectedPond(pond);
      // let data = await getActivePondCycle(pond.id);
      // if (data.length > 0) {
      //   setSortCycleData(data[0]);
      // } else {
      //   setSortCycleData(null);
      // }
      // setCycleData(data);

      if (infoOpen) {
        setInfoOpen(false);
      }
      setTimeout(() => {
        setInfoOpen(true);
      }, 50);
      // setSelectedCenter({
      //   lat: pond.latitude,
      //   lng: pond.longitude,
      // });
    } catch (error) {
    }
  };

  // const google = window.google;

  return (
    <ResponsiveDrawer
      isShowAppBar={true}
      headText={t("map.map")}
      isHideToolBar={true}
    >
      <div className="select-main-block" style={{ marginTop: -20 }}>
        <Box sx={{ minWidth: 260 }}>
          <Typography sx={{ fontSize: 15, fontWeight: "400" }}>
            {t("ponds.filter_by_health_condition")}
          </Typography>
          <FormControl fullWidth sx={{ width: 260 }}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={sortValue}
              // label="Age"
              onChange={(e) => {
                if (e.target.value === t("ponds.all")) {
                  setSortValue(e.target.value);
                  setPondList(dataList);
                } else {
                  let data = dataList.filter((ele) => {
                    return ele.healthCondition === e.target.value;
                  });
                  setSortValue(e.target.value);
                  setPondList(data);
                  if (data.length <= 0) {
                    setIsData(true);
                  }
                }
              }}
              input={<BootstrapInput />}
            >
              <MenuItem value={t("ponds.all")}>{t("ponds.all")}</MenuItem>
              <MenuItem value="Good">{t("ponds.good")}</MenuItem>
              <MenuItem value="Bad">{t("ponds.bad")}</MenuItem>
              <MenuItem value="Concern">{t("ponds.concern")}</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {isLoaded ? (
          <div style={{ marginTop: 40 }} className="google-map-css">
            <GoogleMap
              mapContainerStyle={{
                height: window.innerHeight - 240,
                width: "100%",
              }}
              options={{
                styles: [
                  {
                    elementType: "labels",
                    featureType: "poi",
                    stylers: [{ visibility: "off" }],
                  },
                ],
              }}
              center={center}
              zoom={14}
              onClick={() => {
                // setSelectedCenter(null);
                if (visibleHover) {
                  setVisibleHover(null);
                }
                setSelectedPond(null);
                setInfoOpen(false);
              }}
              clickableIcons={false}
            >
              {pondList.length > 0
                ? pondList.map((val, i) => {
                  if (!val.latitude && !val.longitude) {
                    return;
                  }
                  return (
                    <Marker
                      key={val.id}
                      position={{ lat: val.latitude, lng: val.longitude }}
                      icon={
                        val.healthCondition === "Bad"
                          ? marker_1
                          : val.healthCondition === "Good"
                            ? marker_2
                            : marker_3
                      }
                      onMouseOver={() => {
                        // if (!visibleHover) {
                        setSelectedPond(val);
                        setVisibleHover({
                          map: { lat: val.latitude, lng: val.longitude },
                          pond: val.pondName,
                          hoverId: val.id,
                        });
                        // }
                      }}
                      onMouseOut={() => {
                        // if (visibleHover) {
                        //   setVisibleHover(null);
                        // }
                      }}
                      // icon={{
                      //   // url: "https://lh3.googleusercontent.com/bECXZ2YW3j0yIEBVo92ECVqlnlbX9ldYNGrCe0Kr4VGPq-vJ9Xncwvl16uvosukVXPfV=w300",
                      //   // url: (imagePath.marker_green),
                      //   path: "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z",
                      //   fillColor:
                      //     val.healthCondition === "Bad"
                      //       ? colors.red
                      //       : val.healthCondition === "Good"
                      //       ? colors.green
                      //       : colors.yellow,
                      //   fillOpacity: 1.0,
                      //   strokeWeight: 0,
                      //   scale: 2,
                      // }}
                      onLoad={(marker) => {
                        // const customIcon = (opts) =>
                        //   Object.assign(
                        //     {
                        //       // path: "M12.75 0l-2.25 2.25 2.25 2.25-5.25 6h-5.25l4.125 4.125-6.375 8.452v0.923h0.923l8.452-6.375 4.125 4.125v-5.25l6-5.25 2.25 2.25 2.25-2.25-11.25-11.25zM10.5 12.75l-1.5-1.5 5.25-5.25 1.5 1.5-5.25 5.25z",
                        //       path: "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z",
                        //       fillColor:
                        //         val.healthCondition === "Bad"
                        //           ? colors.red
                        //           : val.healthCondition === "Good"
                        //           ? colors.green
                        //           : colors.yellow,
                        //       fillOpacity: 1,
                        //       // strokeColor: "#000",
                        //       strokeWeight: 0,
                        //       scale: 2,
                        //     },
                        //     opts
                        //   );
                        // marker.setIcon(
                        //   customIcon({
                        //     fillColor:
                        //       val.healthCondition === "Bad"
                        //         ? colors.red
                        //         : val.healthCondition === "Good"
                        //         ? colors.green
                        //         : colors.yellow,
                        //     // strokeColor: "white",
                        //   })
                        // );
                        return markerLoadHandler(marker, val);
                      }}
                      onClick={(event) => markerClickHandler(event, val)}
                    >
                      {visibleHover &&
                        visibleHover.hoverId === val.id &&
                        selectedPond && (
                          <InfoWindow
                            anchor={markerMap[selectedPond.id]}
                          // onCloseClick={() => {
                          //   setSelectedPond(null);
                          //   setInfoOpen(false);
                          //   // setSelectedCenter(null);
                          // }}
                          // className="gm-ui-hover-effect"
                          >
                            <div
                              style={{
                                width: "auto",
                                maxWidth: 270,
                                height: "auto",
                              }}
                            // onClick={async (e) => {
                            //   e.stopPropagation();
                            //   try {
                            //     if (selectedPond) {
                            //       // let data = await getActivePondCycle(selectedPond.id);
                            //       navigation(`/pond_detail`, {
                            //         state: {
                            //           cardData: selectedPond,
                            //           // cycleData: cycleData,
                            //         },
                            //       });
                            //     }
                            //   } catch (error) {

                            //   }
                            // }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginRight: 8,
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: 15,
                                    fontWeight: "600",
                                    marginTop: 0,
                                  }}
                                >
                                  {t("home.pond")}: {selectedPond.pondName}
                                </p>
                                <Close
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (visibleHover) {
                                      setVisibleHover(null);
                                    }
                                    setSelectedPond(null);
                                    setInfoOpen(false);
                                  }}
                                  sx={{
                                    opacity: 0.6,
                                    width: 21,
                                    height: 21,
                                    position: "absolute",
                                    right: 5,
                                    top: 5,
                                  }}
                                />
                              </div>
                              <div style={{ marginTop: 5 }}>
                                {/* <div style={{ display: "flex" }}>
                                    <Typography sx={style.text_1}>
                                      {t("ponds.ready_to_harvest")}:&nbsp;
                                    </Typography>
                                    <Typography sx={style.text_2}>
                                      {" "}
                                      {selectedPond.harvestDays} days
                                    </Typography>
                                  </div> */}
                                <div style={{ display: "flex" }}>
                                  <Typography sx={style.text_1}>
                                    {t("ponds.health_condition")}:&nbsp;
                                  </Typography>
                                  <Typography sx={style.text_2}>
                                    {" "}
                                    {selectedPond.healthCondition
                                      ? selectedPond.healthCondition
                                      : "-"}
                                    {/* {selectedPond.healthCondition} */}
                                  </Typography>
                                </div>
                                <div style={{ display: "flex" }}>
                                  <Typography sx={style.text_1}>
                                    {t("ponds.number_of_biomass")}:&nbsp;
                                  </Typography>
                                  <Typography sx={style.text_2}>
                                    {" "}
                                    {sortCycleData
                                      ? sortCycleData.biomass
                                        ? sortCycleData.biomass
                                        : 0
                                      : 0}
                                    {/* {selectedPond.noOfBiomass} */}
                                  </Typography>
                                </div>
                                <div style={{ display: "flex" }}>
                                  <Typography sx={style.text_1}>
                                    {t("ponds.average_size")}:&nbsp;
                                  </Typography>
                                  <Typography sx={style.text_2}>
                                    {" "}
                                    {sortCycleData
                                      ? sortCycleData.totalWeight &&
                                        sortCycleData.noOfRecord
                                        ? Math.round(
                                          sortCycleData.totalWeight /
                                          sortCycleData.noOfRecord
                                        )
                                        : 0
                                      : 0}
                                    g
                                  </Typography>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <button
                                    className=""
                                    style={style.viewButton}
                                    onClick={async (e) => {
                                      e.stopPropagation();
                                      try {
                                        if (selectedPond) {
                                          // let data = await getActivePondCycle(selectedPond.id);
                                          navigation(`/pond_detail`, {
                                            state: {
                                              cardData: selectedPond,
                                              // cycleData: cycleData,
                                            },
                                          });
                                        }
                                      } catch (error) {
                                      }
                                    }}
                                  >
                                    {t("ponds.view_pond")}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </InfoWindow>
                        )}
                    </Marker>
                  );
                })
                : !isData && (
                  <div
                    style={{
                      display: "flex",
                      height: "80vh",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <div id={`load`} className="loader" />
                  </div>
                )}

              {/* <DrawingManager
                options={{
                  drawingControl: true,
                  drawingControlOptions: {
                    position: google.maps.ControlPosition.TOP_CENTER,
                    drawingModes: [google.maps.drawing.OverlayType.MARKER],
                  },
                  markerOptions: {
                    draggable: false,
                  },
                }}
                // onLoad={onLoad}
                onMarkerComplete={onMarkerComplete}
                onOverlayComplete={(e) => {
                
                }}
              >
                <Marker position={center}></Marker>
              </DrawingManager> */}
            </GoogleMap>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              height: "80vh",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div id={`load`} className="loader" />
          </div>
        )}
      </div>
    </ResponsiveDrawer>
  );
}

const style = {
  text_1: { textAlign: "center", fontSize: 14, fontWeight: "600" },
  text_2: { textAlign: "center", fontSize: 14, fontWeight: "400" },
  viewButton: {
    height: 30,
    paddingBottom: 2,
    border: "none",
    borderRadius: 8,
    backgroundColor: colors.slatery_blue,
    color: "white",
    fontWeight: "500",
    marginTop: 8,
  },
};

export default Map;
