import React, { useState } from "react";
import { Box, Divider, Modal, Switch, Typography } from "@mui/material";
import { userOperationWithAllChildById } from "../../database/user/pond";
import colors from "../../common/colors";
import { useTranslation } from "react-i18next";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import certificate from "../../images/certificate.svg";

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",
  border: "2px solid",
  borderColor: "gray",
  borderRadius: 3,
  boxShadow: 24,
};

const textStyle = {
  color: colors.slatery_blue,
  fontSize: 17,
  padding: 1,
  textAlign: "center",
  width: "100%",
  fontWeight: "600",
};

const UserList = (props) => {
  const { t } = useTranslation();
  const { list, setList, val ,onClickDelete , onClickList,onClickView } = props;
  const [isLockModal, setIsLockModal] = useState(false);
  const [isSuperModal, setIsSuperModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // console.log('val: ', val);

  return (
    <>
      <td className="cm_td" >
        <h4>{val.fullName}</h4>
      </td>
      <td className="cm_td" >
        <h4>{val.email}</h4>
      </td>
      <td className="cm_td" >
        <h4>{val.comName}</h4>
      </td>
      <td className="cm_td" >
        {val.isPremium && (
          <h4
            style={{
              display: "flex",
              justifyContent: "center",
              marginLeft: "20px",
            }}
          >
            {t("userManagement.paid")}
          </h4>
        )}
        {val.isSuperPremium && (
          <h4
            style={{
              display: "flex",
              justifyContent: "center",
              marginLeft: "20px",
            }}
          >
            {t("licenseDetails.paid_via_super_admin")}
          </h4>
        )}
        {!val.isPremium && !val.isSuperPremium && (
          <h4
            style={{
              display: "flex",
              justifyContent: "center",
              marginLeft: "20px",
            }}
          >
            {t("userManagement.free")}
          </h4>
        )}
      </td>
      <td className="cm_td">
        <div
          onClick={() => {
            setIsLockModal(true);
          }}
          style={{
            display: "flex",
            justifyContent: "center",
            marginLeft: "30px",
          }}
        >
          <Switch
            checked={val.isLock ? true : false}
            // style={}
          />
        </div>
      </td>
      <td className="cm_td">
        {!val.isPremium && (
          <div
            onClick={() => {
              setIsSuperModal(true);
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              marginLeft: "30px",
            }}
          >
            <Switch checked={val.isSuperPremium ? true : false} />
          </div>
        )}
      </td>
      <td className="cm_td">
        {val?.licenseData?.filter((obj)=>obj.paymentStatus==="Paid").length}/{val?.licenseData?.length}
      </td>
      <td className="cm_td dev_pool_cm user_cm">
        <p
          onClick={onClickView}
          style={{ marginRight: 8 }}
          className="look_icon_css blue_icon_eye"
        >
          <RemoveRedEyeIcon />
        </p>
        <p onClick={onClickDelete} className="look_icon_css red_icon_eye">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.5 5H4.16667H17.5"
              stroke="#EF5350"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M15.8332 5.00008V16.6667C15.8332 17.1088 15.6576 17.5327 15.345 17.8453C15.0325 18.1578 14.6085 18.3334 14.1665 18.3334H5.83317C5.39114 18.3334 4.96722 18.1578 4.65466 17.8453C4.3421 17.5327 4.1665 17.1088 4.1665 16.6667V5.00008M6.6665 5.00008V3.33341C6.6665 2.89139 6.8421 2.46746 7.15466 2.1549C7.46722 1.84234 7.89114 1.66675 8.33317 1.66675H11.6665C12.1085 1.66675 12.5325 1.84234 12.845 2.1549C13.1576 2.46746 13.3332 2.89139 13.3332 3.33341V5.00008"
              stroke="#EF5350"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M8.3335 9.16675V14.1667"
              stroke="#EF5350"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M11.6665 9.16675V14.1667"
              stroke="#EF5350"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
        </p>
        <div>
          <p className="green_icon_edit" onClick={onClickList}>
          <img height={20} width={20} src={certificate} alt="main_wrapper_bg" />
          </p>
        </div>
      </td>
      {/* Lock model */}
      <Modal
        open={isLockModal}
        onClose={() => setIsLockModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={[boxStyle, { width: 400 }]} className="logout-css-modal-block">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              paddingTop: 2,
              textAlign: "center",
              marginBottom: 2,
              paddingLeft: 1,
              paddingRight: 1,
            }}
          >
            {t("ask_lock_message", {
              lockUnlock: val.isLock ? t("unlock") : t("lock"),
              email: val.email,
            })}
          </Typography>

          <Divider />
          <div className="logout-button-block">
            <Typography
              sx={textStyle}
              onClick={() => {
                setIsLockModal(false);
              }}
            >
              {t("ponds.cancel")}
            </Typography>
            <div
              style={{ width: "2px", border: "1px solid #999", opacity: 0.5 }}
            />
            <Typography
              className="delete_bg_color"
              sx={[textStyle, { color: "red" }]}
              onClick={async () => {
                if (!isLoading) {
                  setIsLoading(true);
                  const data = { isLock: val.isLock ? false : true };
                  await userOperationWithAllChildById(val.uid, data);
                  let lstTmp = list.map((obj) => {
                    if (obj.uid === val.uid) {
                      return { ...obj, isLock: val.isLock ? false : true };
                    } else {
                      return obj;
                    }
                  });
                  setList(lstTmp);
                  setIsLockModal(false);
                  setIsLoading(false);
                }
              }}
            >
              {isLoading
                ? `${t("licenseDetails.loading")}`
                : val.isLock
                ? `${t("unlock")}`
                : `${t("lock")}`}
            </Typography>
          </div>
        </Box>
      </Modal>

      {/* Super Premium model */}
      <Modal
        open={isSuperModal}
        onClose={() => setIsSuperModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={[boxStyle, { width: 400 }]} className="logout-css-modal-block">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              paddingTop: 2,
              textAlign: "center",
              marginBottom: 2,
              paddingLeft: 1,
              paddingRight: 1,
            }}
          >
            {t("ask_premium_message", {
              giveOrRemove: val.isSuperPremium ? t("removeLC") : t("giveLC"),
              toOrFrom: val.isSuperPremium ? t("fromLC") : t("toLC"),
              email: val.email,
            })}
          </Typography>

          <Divider />
          <div className="logout-button-block">
            <Typography
              sx={textStyle}
              onClick={() => {
                setIsSuperModal(false);
              }}
            >
              {t("ponds.cancel")}
            </Typography>
            <div
              style={{ width: "2px", border: "1px solid #999", opacity: 0.5 }}
            />
            <Typography
              className="delete_bg_color"
              sx={[textStyle, { color: "red" }]}
              onClick={async () => {
                if (!isLoading) {
                  setIsLoading(true);
                  const data = {
                    isSuperPremium: val.isSuperPremium ? false : true,
                    isPremium: val.isSuperPremium ? false : true,
                  };
                  try {
                    await userOperationWithAllChildById(val.uid, data);
                    let lstTmp = list.map((obj) => {
                      if (obj.uid === val.uid) {
                        return {
                          ...obj,
                          isSuperPremium: val.isSuperPremium ? false : true,
                        };
                      } else {
                        return obj;
                      }
                    });
                    setList(lstTmp);
                    setIsSuperModal(false);
                    setIsLoading(false);
                  } catch (e) {
                    setIsSuperModal(false);
                    setIsLoading(false);
                  }
                }
              }}
            >
              {isLoading
                ? `${t("licenseDetails.loading")}`
                : val.isSuperPremium
                ? `${t("unlock")}`
                : `${t("licenseDetails.premium")}`}
            </Typography>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default UserList;
