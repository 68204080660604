import React from "react";
import { createRoot } from "react-dom/client";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "./index.css";

import * as Sentry from "@sentry/react";
import { isDEV } from "./common/configs";
import { firebaseConfig } from "./config/firebase-config";

Sentry.init({
  dsn: "https://5710c2fcdddd40d24c96c30173375b48@o4507938923282432.ingest.us.sentry.io/4507939295068160",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
  environment: firebaseConfig.projectId == "sincere-aqua-dev" ? "development" : "production",
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["https://sincere-aqua-dev.web.app/","https://app.sincereaqua.com/"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["en", "es"],
    // fallbackLng: "en",
    fallbackLng: `${
      localStorage.getItem("lang")
        ? localStorage.getItem("lang") === "en"
          ? "en"
          : "es"
        : "en"
    }`,
    detection: {
      order: [
        // "querystring",
        "cookie",
        "htmlTag",
        "localStorage",
        // "sessionStorage",
        // "navigator",
        "path",
        "subdomain",
      ],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
    react: {
      useSuspense: false,
    },
  });

const root = createRoot(document.getElementById("root"));
root.render(
  <>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </>
);
