import { Delete } from "@mui/icons-material";
import { Box, Typography, Divider, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import colors from "../../../common/colors";
import ResponsiveDrawer from "../../../common/ResponsiveDrawer";
import "../../../style/loader.css";
import "../../../style/user/Ponds.css";
import { getMeasurementReports } from "../../../database/user/pond";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { getTimestampWithFormatDate } from "../../../common/utils";

function MeasurementReports() {
  const navigation = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation();
  // console.log("state", state);
  const [isLoading, setLoading] = useState(true);
  const [reports, setReports] = useState([]);
  // const [batchId, setBatchId] = useState(
  //   state.cycleData && state.cycleData.batchId ? state.cycleData.batchId : ""
  // );

  // useEffect(() => {
  //   async function getReport() {
  //     const reportsLocal = await getMeasurementReports(batchId,state.pondData.id);
  //     console.log("reportsLocal", reportsLocal);
  //     setReports(reportsLocal);
  //     setLoading(false);
  //   }
  //   if (batchId) {
  //     getReport();
  //   } else {
  //     setLoading(false);
  //   }
  // }, [batchId]);

  return (
    <ResponsiveDrawer
      isShowAppBar={true}
      headText={t("ponds.measurementForPond", {
        pondName: state.pondData.pondName,
        batchName: state.cycleData.cycleName,
      })}
      isHideToolBar={true}
      isBackArrow={true}
      onClickBack={() => {
        navigation(`/pond_detail`, {
          state: {
            cardData: state.pondData,
            cycleData: state.cycleData,
          },
        });
      }}
    >
      {/* ----- TABLE ----- */}
      {reports.length > 0 ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            overflow: "auto",
            marginTop: 30,
          }}
        >
          <table
            className="ui celled table user-manage-tab"
            style={{ marginTop: 0 }}
          >
            <thead>
              <tr>
                <th>{t("home.time")}</th>
                <th>{t("ponds.averageSize")}</th>
                <th>{t("device.device")}</th>
                <th>{t("device.device_type")}</th>
                <th>{t("ponds.totalMeasured")}</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {reports.map((val, index) => {
                return (
                  <tr
                    // style={{ backgroundColor: val.color }}
                    key={Math.random()}
                  >
                    <td
                      data-label={t("home.time")}
                      style={{ verticalAlign: "middle" }}
                    >
                      {getTimestampWithFormatDate(val.timeStamp,"DD-MM-YYYY @ hh:mma")}
                    </td>
                    <td
                      data-label={t("ponds.averageSize")}
                      style={{ verticalAlign: "middle" }}
                    >
                      {val.averageSize ? Number(val.averageSize).toFixed(2) : 0}
                    </td>
                    <td
                      data-label={t("device.device")}
                      style={{ verticalAlign: "middle" }}
                    >
                      {val.deviceId}
                    </td>
                    <td
                      data-label={t("device.device_type")}
                      style={{ verticalAlign: "middle" }}
                    >
                      {val.deviceType}
                    </td>
                    <td
                      data-label={t("ponds.totalRecords")}
                      style={{ verticalAlign: "middle" }}
                    >
                      {val.records}
                    </td>
                    <td className="cm_td">
                      <p
                        onClick={() => {
                          console.log("val: 120", val.id);
                          navigation("/MeasurementReportsDetail", {
                            state: {
                              report: val,
                              cardData: state.pondData,
                              cycleData: state.cycleData,
                            },
                          });
                        }}
                        className="look_icon_css blue_icon_eye"
                      >
                        <RemoveRedEyeIcon />
                      </p>
                    </td>
                    {/* <td>
                      <div
                        style={style.container}
                        className="farm-svg-css delete_icon_td"
                      >
                        <div className="look_icon_css red_icon_eye">
                          <Delete sx={{ marginLeft: 2 }} onClick={() => {}} />
                        </div>
                      </div>
                    </td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : !isLoading ? (
        <div
          style={{
            display: "flex",
            height: "60vh",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Typography sx={{ fontSize: 17, fontWeight: "600" }}>
            {state.cycleData && state.cycleData.cycleName
              ? t("ponds.no_size_report_found", {
                  cycleName: state.cycleData.cycleName,
                })
              : t("ponds.no_report_found_no_cycle")}
          </Typography>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            height: "60vh",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div id={`load`} className="loader" />
        </div>
      )}
    </ResponsiveDrawer>
  );
}

const style = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 375 ? 400 : 270,
  // width: 650,
  bgcolor: "background.paper",
  border: "2px solid",
  borderColor: "gray",
  borderRadius: 3,
  boxShadow: 24,
};
const textStyle = {
  color: colors.slatery_blue,
  fontSize: 17,
  padding: 1,
  textAlign: "center",
  width: "100%",
  fontWeight: "600",
};

export default MeasurementReports;
